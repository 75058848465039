import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import withStyles from "@material-ui/core/styles/withStyles"
import { Polygon, Polyline } from "react-leaflet"
import polylabel from "polylabel"
import DivIcon from "components/DivIcon"
import Typography from "@material-ui/core/Typography"
import { focusMasters, focusNodes } from "constants/dataView"

const styles = (_theme) => ({
	label: {
		width: 90,
		textAlign: "center",
		backgroundColor: "rgb(128, 128, 128, .5)",
		color: "white",
		transform: "translateX(-45px)",
		borderRadius: "15px",
		pointerEvents: "none",
		opacity: 0,
		// transition: ['opacity'],
		// transitionDuration: 300,
		// visibility: 'hidden',
		// '-webkit-transition': 'opacity 0.3s',
		// '-moz-transition': 'opacity 0.3s',
		// '-o-transition': 'opacity 0.3s',
	},
	// '@keyframes fadein': {
	//   from: {
	//     opacity: 0,
	//     animationTiminFunction: 'ease-in',
	//   },
	//   to: { opacity: .75 },
	// },
	visible: {
		// animation: '$fadein .2s',
		// visibility: 'visible',
		opacity: 0.75,
	},
	areaBorder: {
		strokeWidth: 1,
	},
})

const MasterArea = ({
	classes,
	selected,
	name,
	label,
	masterArea,
	setMasterActive,
	openMasterDetails,
	labelVisible,
	markerFill,
	dataView,
}) => {
	const [hover, setHover] = useState(false)

	if (!masterArea || masterArea.length < 1) return null

	let poly
	if (focusNodes.includes(dataView)) {
		if (selected) {
			poly = <Polyline className={classes.areaBorder} color={"black"} positions={[...masterArea, masterArea[0]]} />
		} else {
			poly = (
				<Polygon
					className={classes.areaBorder}
					onClick={setMasterActive}
					color={"black"}
					fillColor={"grey"}
					positions={masterArea}
					onMouseOver={() => setHover(true)}
					onMouseOut={() => setHover(false)}
				/>
			)
		}
	} else if (focusMasters.includes(dataView)) {
		if (selected) {
			poly = (
				<Polygon
					className={classes.areaBorder}
					onClick={openMasterDetails}
					color={"black"}
					fillColor={markerFill}
					positions={masterArea}
					onMouseOver={() => setHover(true)}
					onMouseOut={() => setHover(false)}
				/>
			)
		} else {
			poly = (
				<Polygon
					className={classes.areaBorder}
					onClick={setMasterActive}
					color={"black"}
					fillColor={"grey"}
					positions={masterArea}
					onMouseOver={() => setHover(true)}
					onMouseOut={() => setHover(false)}
				/>
			)
		}
	}

	return (
		<Fragment>
			{!name ? null : (
				<DivIcon position={polylabel([masterArea], 1.0)}>
					<Typography className={clsx(classes.label, { [classes.visible]: hover || labelVisible })}>
						{name} {label || ""}
					</Typography>
				</DivIcon>
			)}
			{poly}
		</Fragment>
	)
}

MasterArea.propTypes = {
	classes: PropTypes.object.isRequired,
	masterArea: PropTypes.array,
	selected: PropTypes.bool,
	name: PropTypes.string,
	label: PropTypes.string,
	setMasterActive: PropTypes.func,
	openMasterDetails: PropTypes.func,
	labelVisible: PropTypes.bool,
	markerFill: PropTypes.string,
	dataView: PropTypes.string,
}

export default withStyles(styles)(MasterArea)

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import NodesSummary from "./NodesSummary"
import {
	alarmEnums,
	alarmTypeProperties,
	allNodeAlarmTypes,
	flagModes,
	getBitIndex,
	getFlagBitmask,
	getFlagModesList,
	NO_DATA_IN_DATABASE,
} from "constants/statusBits"
import { deviceTypes } from "constants/deviceTypes"
import { chargingLabels, chargingModes, checkChargerShutdownFlag, checkNoDataAlarms } from "constants/charging"
import { alarmKey, auxNodeDataList } from "constants/deviceData"
import { DEFAULT_OVERLAY, overlays } from "constants/overlays"
import { getMask, getMasterLocIds, updateMasks } from "utils/url"
import { getRecentMasterDataFromMLocId } from "../../../../../utils/stateSelectors"
import { getDuplicateNodeIdSummary } from "../../../../../utils/validation"

const getDeviceType = (state, mLocId, nLocId) => {
	let { deviceType } = state.masterDetails[mLocId] || {}
	if (deviceType === deviceTypes.GAMECHANGE_GEN3_DUAL) {
		const nodeDetails = (state.nodeDetails[mLocId] || {})[nLocId]
		if (!nodeDetails) {
			// nodeDetails may be undefined on initial load, but will be updated. Best approximation in this case:
			return deviceType
		}
		if (nodeDetails.role === "aux") {
			return deviceTypes.GAMECHANGE_GEN3_DUAL_AUX
		} else {
			return deviceTypes.GAMECHANGE_GEN3_DUAL_PRIMARY
		}
	}
	return deviceType
}

const getNodeData = (state, mLocId, nLocId, timestamp, deviceType) => {
	const nodeData = state.nodeData[`${mLocId}#${timestamp}`] || {}

	let data = {}
	if (deviceType === deviceTypes.GAMECHANGE_GEN3_DUAL_AUX) {
		const nodeDetails = (state.nodeDetails[mLocId] || {})[nLocId]
		Object.keys(nodeData[nodeDetails.relative] || {}).forEach((dataKey) => {
			if (auxNodeDataList.includes(dataKey)) {
				data[dataKey] = nodeData[nodeDetails.relative][dataKey]
			}
		})
	} else if (deviceType === deviceTypes.GAMECHANGE_GEN3_DUAL_PRIMARY) {
		Object.keys(nodeData[nLocId] || {}).forEach((dataKey) => {
			if (!auxNodeDataList.includes(dataKey)) {
				data[dataKey] = nodeData[nLocId][dataKey]
			}
		})
	} else {
		data = nodeData[nLocId] || {}
	}
	return data
}

export const getUrlTimestamp = (urlSearch = "") => {
	const params = new URLSearchParams(urlSearch)
	return parseInt(params.get("ts"), 10) || undefined
}

const handleFilterChange = (props) => (e, filterBit) => {
	e.persist()
	const { search, pathname } = props.location || {}
	const newSearch = updateMasks(search)(e.ctrlKey || e.metaKey, filterBit)

	props.history.push({
		pathname,
		search: newSearch,
	})
}

const getActiveOverlay = (search) => {
	const params = new URLSearchParams(search)
	return params.get("overlay")
}

const getSummaryHeading = (activeOverlay) => {
	switch (activeOverlay) {
		case overlays.ALARMS:
			return ["Node Alarms", "Count"]
		case overlays.FLAGS:
			return ["Status Flag Description", "Count"]
		case overlays.CHARGINGSTATUS:
			return ["Charging Status", "Count"]
		case overlays.DEVICEIDASSIGNMENT:
			return ["Status", "Count"]
		default:
			return null
	}
}

const getNodeCount = (nodeDetails, activeMasters) => {
	return activeMasters.reduce((accum, mLocId) => accum + Object.keys(nodeDetails[mLocId] || {}).length, 0)
}

const getAlarmsSummary = (state, props, activeMasters) => {
	const summaryData = []
	let summaryNodeCount = {}

	let alarmCounts = {}
	allNodeAlarmTypes.forEach((type) => (alarmCounts[type] = 0))
	let nodesWithAlarms = 0

	const userPrivileges = state.user.privileges
	const urlSearch = (props.location || {}).search
	activeMasters.forEach((mLocId) => {
		const timestamp = getUrlTimestamp(urlSearch) || state.nodeDataActiveTimestamp[mLocId]

		const duplicateNodeIds = props.duplicateNodeIdsByMaster[mLocId]

		const nodeDetails = state.nodeDetails[mLocId] || {}
		const nLocIds = Object.keys(nodeDetails)
		for (let i = 0; i < nLocIds.length; i++) {
			const nLocId = nLocIds[i]

			const deviceType = getDeviceType(state, mLocId, nLocId)
			const nodeData = getNodeData(state, mLocId, nLocId, timestamp, deviceType)
			const duplicateNodeIdAlarm = duplicateNodeIds.includes(
				state.nIdsPendingUpload[`${mLocId}#${nLocId}`] || nodeDetails[nLocId].nId,
			)
			const noDataAvailableServerAlarm = (Object.keys(nodeData).length === 0 && NO_DATA_IN_DATABASE) || 0

			if (state.nodeDataStatus[mLocId] === "resolved") {
				let alarms = nodeData[alarmKey[deviceType]] || "0"
				alarms = BigInt(`0x${alarms}`)

				let hasAlarms = false
				if (alarms) {
					allNodeAlarmTypes.forEach((alarmLabel) => {
						const alarm = alarmEnums[deviceType][alarmLabel]
						if (!alarm) {
							// Not all alarms are defined for all device types
							return
						}
						if ((BigInt(alarm) & BigInt(alarms)) > 0n) {
							if ((userPrivileges & alarmTypeProperties[alarmLabel].privilege) !== 0) {
								alarmCounts[alarmLabel]++
								hasAlarms = true
							}
						}
					})
				}
				if (duplicateNodeIdAlarm) {
					if ((userPrivileges & alarmTypeProperties["DUPLICATE_NODEID"].privilege) !== 0) {
						alarmCounts["DUPLICATE_NODEID"]++
						hasAlarms = true
					}
				}
				if (noDataAvailableServerAlarm) {
					if ((userPrivileges & alarmTypeProperties["NO_DATA_IN_DATABASE"].privilege) !== 0) {
						alarmCounts["NO_DATA_IN_DATABASE"]++
						hasAlarms = true
					}
				}

				if (hasAlarms) {
					nodesWithAlarms++
				}
			}
		}
	})

	for (let i = 0; i < allNodeAlarmTypes.length; i++) {
		let alarmType = allNodeAlarmTypes[i]
		const alarmTypeDetails = alarmTypeProperties[alarmType]
		if (!alarmTypeDetails) {
			if (alarmType === "NONE") {
				// There is a special alarm bit that means "no alarms". This is the first bit in the bitmask.
				continue
			}
			throw new Error(`No alarm type details for ${alarmType}`)
		}
		if ((userPrivileges & alarmTypeDetails.privilege) === 0) continue
		if (alarmCounts[alarmType] > 0) {
			let description = alarmTypeDetails.description
			if (typeof description === "function") {
				throw new Error(
					"Callable function descriptions is not supported for alarms yet. Follow the same logic as flags.",
				)
			}
			const item = {
				action: BigInt(i),
				description,
				value: alarmCounts[alarmType],
			}
			summaryData.push(item)
		}
	}

	summaryNodeCount["Nodes in Alarm"] = nodesWithAlarms

	return {
		summaryData,
		summaryNodeCount,
	}
}

let nbPossibleFlags = 0
for (const flagModesOfDeviceType of Object.values(flagModes)) {
	nbPossibleFlags = Math.max(nbPossibleFlags, flagModesOfDeviceType.length)
}

const getFlagsSummary = (state, props, activeMasters) => {
	let summaryData
	let summaryNodeCount = {}

	let flagCounts = {}
	let activeFlagModes = 0n
	for (let x = 0; x < nbPossibleFlags; x++) {
		const mask = 1n << BigInt(x)
		flagCounts[mask] = 0
	}

	let nodesWithFlags = 0

	const userPrivileges = state.user.privileges
	let deviceType

	activeMasters.forEach((mLocId) => {
		deviceType = getDeviceType(state, mLocId) // TODO: only handles display of single device type at a time
		const flagBitmask = getFlagBitmask(userPrivileges, deviceType)
		const urlSearch = (props.location || {}).search
		const timestamp = getUrlTimestamp(urlSearch) || state.nodeDataActiveTimestamp[mLocId]
		const nLocIds = Object.keys(state.nodeDetails[mLocId] || {})

		for (let i = 0; i < nLocIds.length; i++) {
			const nLocId = nLocIds[i]
			let { flags } = state.nodeData[`${mLocId}#${timestamp}`]?.[nLocId] || {}
			flags = (flags ? BigInt(`0x${flags}`) : 0n) & flagBitmask
			if (flags) {
				nodesWithFlags++
				for (let x = 0; x < nbPossibleFlags; x++) {
					const mask = 1n << BigInt(x)
					if ((mask & flags) !== 0n) {
						flagCounts[mask]++
					}
				}
			}
		}
		for (let x = 0; x < nbPossibleFlags; x++) {
			const mask = 1n << BigInt(x)
			if (flagCounts[mask]) {
				activeFlagModes |= mask
			}
		}
	})

	const flagModesList = getFlagModesList(userPrivileges, deviceType)
	const firstMasterLocationId = activeMasters[0] || ""

	if (!firstMasterLocationId) console.warn("No active master")

	summaryData = []

	if (activeFlagModes) {
		for (let i = 0; i < flagModesList.length; i++) {
			const flagMode = flagModesList[i]

			if (flagCounts[flagMode.bit] === 0) continue // flag is not active; do not include it

			let description = flagMode.description

			if (typeof description === "function") {
				// Descriptions can be getters that depend on the node firmware version
				const masterData = getRecentMasterDataFromMLocId(state, props, firstMasterLocationId)
				const nodeFirmwareVersion = masterData["fwNode"]
				description = description(nodeFirmwareVersion)
			}

			summaryData.push({
				action: getBitIndex(flagMode.bit),
				description: description,
				value: flagCounts[flagMode.bit],
			})
		}
	}

	summaryNodeCount["Nodes with Flags"] = nodesWithFlags

	return {
		summaryData,
		summaryNodeCount,
	}
}

const getChargerSummary = (state, props, activeMasters) => {
	let summaryData
	let chargingModeCounts = {}
	Object.values(chargingModes).forEach((mode) => {
		chargingModeCounts[mode] = 0
	})
	let deviceType
	activeMasters.forEach((mLocId) => {
		const urlSearch = (props.location || {}).search
		const timestamp = getUrlTimestamp(urlSearch) || state.nodeDataActiveTimestamp[mLocId]
		deviceType = getDeviceType(state, mLocId) // TODO: only handles display of single device type at a time
		const nLocIds = Object.keys(state.nodeData[`${mLocId}#${timestamp}`] || {})
		for (let i = 0; i < nLocIds.length; i++) {
			const nLocId = nLocIds[i]
			let { chargeStatus, alarms, flags } = state.nodeData[`${mLocId}#${timestamp}`][nLocId] || {}
			alarms = alarms ? BigInt(`0x${alarms}`) : 0n
			flags = flags ? BigInt(`0x${flags}`) : 0n
			const noDataAvailable = checkNoDataAlarms(deviceType, alarms)
			const chargerShutdownFlag = checkChargerShutdownFlag(deviceType, flags)
			if (noDataAvailable) {
				chargingModeCounts[chargingModes.UNKNOWN]++
			} else if (chargerShutdownFlag) {
				chargingModeCounts[chargingModes.TEMPERATURE_SHUTDOWN]++
			} else {
				chargingModeCounts[chargeStatus]++
			}
		}
	})

	Object.keys(chargingModeCounts).forEach((chargingMode) => {
		if (chargingModeCounts[chargingMode] === 0) {
			delete chargingModeCounts[chargingMode]
		}
	})
	summaryData = Object.keys(chargingModeCounts).map((chargingMode) => {
		return {
			action: null,
			description: chargingLabels[chargingMode],
			value: chargingModeCounts[chargingMode],
		}
	})
	return {
		summaryData,
	}
}

const getDeviceIdAssignmentSummary = (state, props, activeMasters) => {
	let totalUnassigned = 0
	let summaryNodeCount = {}
	let summaryData
	let deviceIdAssignmentCounts = {
		"Duplicate NodeID": 0,
		"Pending Upload": 0,
		"Pending Sync": 0,
		Accepted: 0,
	}

	activeMasters.forEach((mLocId) => {
		const nLocIds = Object.keys(state.nodeDetails[mLocId] || {})

		for (let i = 0; i < nLocIds.length; i++) {
			const nLocId = nLocIds[i]
			const nodeDetails = (state.nodeDetails[mLocId] || {})[nLocId]
			const duplicateNodeIds = props.duplicateNodeIdsByMaster[mLocId]
			const acceptedNodeId = (nodeDetails || {})["nId"]
			const isUnassignedNodeId = (acceptedNodeId || "").slice(-4) === "0000"
			const pendingSync = !isUnassignedNodeId && (nodeDetails || {})["pendingSync"]
			const pendingNodeId = !isUnassignedNodeId && state.nIdsPendingUpload[`${mLocId}#${nLocId}`]
			const duplicateNodeId =
				!isUnassignedNodeId &&
				duplicateNodeIds.includes(state.nIdsPendingUpload[`${mLocId}#${nLocId}`] || nodeDetails.nId)

			if (duplicateNodeId) {
				// TODO: duplicateNodeId logic not complete
				deviceIdAssignmentCounts["Duplicate NodeID"]++
			} else if (pendingNodeId) {
				deviceIdAssignmentCounts["Pending Upload"]++
			} else if (pendingSync) {
				deviceIdAssignmentCounts["Pending Sync"]++
			} else if (acceptedNodeId && !isUnassignedNodeId) {
				deviceIdAssignmentCounts["Accepted"]++
			} else {
				totalUnassigned++
			}
		}
	})
	summaryNodeCount["Device IDs Unassigned"] = totalUnassigned

	Object.keys(deviceIdAssignmentCounts).forEach((deviceIdState) => {
		if (deviceIdAssignmentCounts[deviceIdState] === 0) {
			delete deviceIdAssignmentCounts[deviceIdState]
		}
	})

	summaryData = Object.keys(deviceIdAssignmentCounts).map((deviceIdState) => {
		return {
			action: null,
			description: deviceIdState,
			value: deviceIdAssignmentCounts[deviceIdState],
		}
	})

	return {
		summaryData,
		summaryNodeCount,
	}
}

const mapStateToProps = (state, props) => {
	const activeLayoutUuid = props.location.pathname.split("/")[2]
	let summaryData = null
	let summaryNodeCount

	let activeOverlay = getActiveOverlay(props.location.search)
	if (!activeOverlay) {
		activeOverlay = DEFAULT_OVERLAY
	}
	const summaryHeading = getSummaryHeading(activeOverlay)
	const activeMasters = getMasterLocIds(props.location.search)
	const siteNodeCount = getNodeCount(state.nodeDetails, activeMasters)
	let displaySiteNodeCount = true

	switch (activeOverlay) {
		case overlays.ALARMS: {
			const alarmSummary = getAlarmsSummary(state, props, activeMasters)
			summaryNodeCount = alarmSummary.summaryNodeCount
			summaryData = alarmSummary.summaryData
			break
		}
		case overlays.FLAGS: {
			const flagSummary = getFlagsSummary(state, props, activeMasters)
			summaryNodeCount = flagSummary.summaryNodeCount
			summaryData = flagSummary.summaryData
			break
		}
		case overlays.CHARGINGSTATUS: {
			const chargerSummary = getChargerSummary(state, props, activeMasters)
			summaryData = chargerSummary.summaryData
			break
		}
		case overlays.DEVICEIDASSIGNMENT: {
			const deviceIdAssignmentSummary = getDeviceIdAssignmentSummary(state, props, activeMasters)
			summaryNodeCount = deviceIdAssignmentSummary.summaryNodeCount
			summaryData = deviceIdAssignmentSummary.summaryData
			break
		}
		case overlays.SMART_STOW: {
			displaySiteNodeCount = false
			break
		}
		default:
			break
	}

	return {
		summaryHeading,
		summaryData,
		summaryNodeCount,
		siteNodeCount,
		displaySiteNodeCount,
		visibilityMask: getMask((props.location || {}).search),
		summaryIsOpen: state.summaryIsOpen,
		duplicatesSummary: getDuplicateNodeIdSummary(state, activeLayoutUuid),
	}
}

const mapDispatchToProps = (dispatch, props) => ({
	handleFilterChange: handleFilterChange(props),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(NodesSummary)

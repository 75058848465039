import { connect } from "react-redux"
import LayoutMarker from "./LayoutMarker"
import { setLayoutSecondarySelection, clearLayoutSecondarySelection } from "store/actions/reduxActions"

const getCoord = (type, layoutDetails) => {
	if (!layoutDetails || !layoutDetails["coordinates"]) return null
	return layoutDetails["coordinates"][type]
}

const mapStateToProps = (state, props) => ({
	latitude: getCoord("latitude", state.layoutDetails[props.layoutId]),
	longitude: getCoord("longitude", state.layoutDetails[props.layoutId]),
	published: (state.layoutDetails[props.layoutId] || {})["published"],
})

const mapDispatchToProps = (dispatch, props) => ({
	setLayoutSecondarySelection: () => dispatch(setLayoutSecondarySelection(props.layoutId)),
	clearLayoutSecondarySelection: () => dispatch(clearLayoutSecondarySelection(props.layoutId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LayoutMarker)

import React from "react"
import { flagEnums } from "../../constants/statusBits"
import { deviceTypes } from "../../constants/deviceTypes"
import PropTypes from "prop-types"
import { InputLabel } from "@material-ui/core"
import SelectMultiple from "./SelectMultiple"

const FlagsSelector = (props) => {
	// `value` holds a bit mask in a BigInt (see flagEnums[deviceTypes.GAMECHANGE_GEN3])
	const { value, setValue } = props
	const allFlags = flagEnums[deviceTypes.GAMECHANGE_GEN3]
	const items = Object.keys(allFlags)

	const currentlySelected = items.filter((item) => {
		const flagBit = allFlags[item]
		return value !== null && BigInt(value & flagBit) !== 0n
	})

	const handleValueChange = (value) => {
		// value is an array of strings (flag identifiers).
		// We need to convert this array of strings to a bitmask.
		let newValue = 0n
		for (const flagIdentifier of value) {
			const flagBit = allFlags[flagIdentifier]
			newValue |= flagBit
		}
		setValue(newValue)
	}

	return (
		<div>
			<InputLabel style={{ margin: "15px" }}>Select Flags to Reset</InputLabel>
			<SelectMultiple value={currentlySelected} items={items} setValue={handleValueChange} />
		</div>
	)
}

FlagsSelector.propTypes = {
	setValue: PropTypes.func.isRequired,
	value: PropTypes.number,
}

export default FlagsSelector

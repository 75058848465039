import React from "react"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"

const YesNoQuestion = ({ value, setTaskInfo, name }) => {
	const handleChange = (event) => {
		const { value } = event.target
		setTaskInfo({ [name]: value })
	}

	return (
		<FormControl component="fieldset">
			<RadioGroup aria-label="Yes Or No" name="yesOrNo" value={value || ""} onChange={handleChange}>
				<FormControlLabel value="yes" color="primary" control={<Radio />} label="Yes" />
				<FormControlLabel value="no" control={<Radio />} label="No" />
			</RadioGroup>
		</FormControl>
	)
}

export default YesNoQuestion

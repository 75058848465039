import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import NodePopups from "./NodePopups"

const getLocationIds = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	let activeIds = params.get("locIds")
	let activeNavId = params.get("navLocId")
	if (!activeIds && !activeNavId) return []
	const locIds = []
	activeIds.split(",").forEach((ids) => {
		let [mLocId, nodeLocationIds] = ids.split(":")
		if (nodeLocationIds) {
			locIds.push({
				mLocId,
				nodeLocationIds: nodeLocationIds.split(";"),
			})
		}
	})
	if (activeNavId) {
		let [mLocId, nodeLocationId] = activeNavId.split(":")
		if (nodeLocationId) {
			locIds.push({
				mLocId,
				nodeLocationIds: [nodeLocationId],
				nav: true,
			})
		}
	}
	return locIds
}

const mapStateToProps = (state, props) => ({
	locIds: getLocationIds(state, props),
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(NodePopups)

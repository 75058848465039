import { connect } from "react-redux"
import EditSpaParameters from "./EditSpaParameters"
import { compose } from "redux"
import { withRouter } from "react-router"
import { withSnackbar } from "notistack"
import { fetchMasterDetails } from "store/actions/fetchers"

const handleNewSpaParameterRedirect = (props) => (newParamId) => {
	const params = new URLSearchParams(props.location.search)
	params.set("modify-spa-parameters", `${props.layoutId}:${props.mLocId}:${newParamId}`)
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const getMasterList = (state, props) => {
	const masterDetails = state.masterDetails
	const layoutMasters = (state.layoutDetails[props.layoutId] || {})["mLocIds"] || []
	let masterList = {}
	if (layoutMasters && masterDetails) {
		layoutMasters.forEach((mLocId) => {
			masterList[mLocId] = (masterDetails[mLocId] || {})["name"]
		})
		return masterList
	}
}

const handleSpaParamsType = (props) => (type, spaParamId) => {
	const params = new URLSearchParams(props.location.search)
	const deviceIds = params.get("device-details")
	if (type === "edit") {
		params.set("modify-spa-parameters", `${deviceIds}:${spaParamId}`)
	} else if (type === "copy") {
		params.set("modify-spa-parameters", `${deviceIds}:copy`)
	} else if (type === "new") {
		params.set("modify-spa-parameters", `${deviceIds}:new`)
	}
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const mapStateToProps = (state, props) => ({
	masterDetails: state.masterDetails,
	//layoutMasters: (state.layoutDetails[props.layoutId] || {})['mLocIds'] || [],
	masterList: getMasterList(state, props),
	spaParamIdFromMaster: (state.masterDetails[props.mLocId] || {})["spaParamId"],
})

const mapDispatchToProps = (dispatch, props) => ({
	handleNewSpaParameterRedirect: (newParamId) => handleNewSpaParameterRedirect(props)(newParamId),
	fetchMasterDetails: (layoutId) => dispatch(fetchMasterDetails(layoutId)),
	handleSpaParamsType: (type, spaParamId) => handleSpaParamsType(props)(type, spaParamId),
})

export default compose(withSnackbar, withRouter, connect(mapStateToProps, mapDispatchToProps))(EditSpaParameters)

/* eslint-disable no-console */

import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import { AutoSizer, Column, SortDirection, Table } from "react-virtualized"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import FilterListIcon from "@material-ui/icons/FilterList"
import AddIcon from "@material-ui/icons/Add"

const toolbarStyles = (theme) => ({
	root: {
		paddingRight: theme.spacing(1),
	},
	spacer: {
		flex: "1 1 100%",
	},
	actions: {
		display: "flex",
		color: theme.palette.text.secondary,
	},
	title: {
		flex: "0 0 auto",
	},
})

let EnhancedTableToolbar = ({ classes, title }) => {
	return (
		<Toolbar className={classes.root}>
			<div className={classes.title}>
				<Typography variant="h6" id="tableTitle">
					{title}
				</Typography>
			</div>
			<div className={classes.spacer} />
			<div className={classes.actions}>
				<Tooltip title="New layout">
					<IconButton aria-label="New layout">
						<AddIcon />
					</IconButton>
				</Tooltip>
				<Tooltip title="Filter list">
					<IconButton aria-label="Filter list">
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			</div>
		</Toolbar>
	)
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const styles = (theme) => ({
	table: {
		fontFamily: theme.typography.fontFamily,
	},
	flexContainer: {
		display: "flex",
		alignItems: "center",
		boxSizing: "border-box",
	},
	tableRow: {
		cursor: "pointer",
	},
	tableRowHover: {
		"&:hover": {
			backgroundColor: theme.palette.grey[200],
		},
	},
	tableCell: {
		flex: 1,
	},
	noClick: {
		cursor: "initial",
	},
})

class MuiVirtualizedTable extends React.PureComponent {
	getRowClassName = ({ index }) => {
		const { classes, rowClassName, onRowClick } = this.props

		return clsx(classes.tableRow, classes.flexContainer, rowClassName, {
			[classes.tableRowHover]: index !== -1 && onRowClick != null,
		})
	}

	cellRenderer = ({ cellData, columnIndex = null }) => {
		const { columns, classes, rowHeight, onRowClick } = this.props
		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, {
					[classes.noClick]: onRowClick == null,
				})}
				variant="body"
				style={{ height: rowHeight }}
				align={(columnIndex != null && columns[columnIndex].numeric) || false ? "right" : "left"}
			>
				{cellData}
			</TableCell>
		)
	}

	headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
		const { headerHeight, columns, classes, sort } = this.props
		const direction = {
			[SortDirection.ASC]: "asc",
			[SortDirection.DESC]: "desc",
		}

		const inner =
			!columns[columnIndex].disableSort && sort != null ? (
				<TableSortLabel active={dataKey === sortBy} direction={direction[sortDirection]}>
					{label}
				</TableSortLabel>
			) : (
				label
			)

		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
				variant="head"
				style={{ height: headerHeight }}
				align={columns[columnIndex].numeric || false ? "right" : "left"}
			>
				{inner}
			</TableCell>
		)
	}

	render() {
		const { classes, columns, ...tableProps } = this.props
		return (
			<AutoSizer>
				{({ height, width }) => (
					<Table
						className={classes.table}
						height={height}
						width={width}
						{...tableProps}
						rowClassName={this.getRowClassName}
					>
						{columns.map(({ cellContentRenderer = null, className, dataKey, ...other }, index) => {
							let renderer
							if (cellContentRenderer != null) {
								renderer = (cellRendererProps) =>
									this.cellRenderer({
										cellData: cellContentRenderer(cellRendererProps),
										columnIndex: index,
									})
							} else {
								renderer = this.cellRenderer
							}

							return (
								<Column
									key={dataKey}
									headerRenderer={(headerProps) =>
										this.headerRenderer({
											...headerProps,
											columnIndex: index,
										})
									}
									className={clsx(classes.flexContainer, className)}
									cellRenderer={renderer}
									dataKey={dataKey}
									{...other}
								/>
							)
						})}
					</Table>
				)}
			</AutoSizer>
		)
	}
}

MuiVirtualizedTable.propTypes = {
	classes: PropTypes.object.isRequired,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			cellContentRenderer: PropTypes.func,
			dataKey: PropTypes.string.isRequired,
			width: PropTypes.number.isRequired,
		}),
	).isRequired,
	headerHeight: PropTypes.number,
	onRowClick: PropTypes.func,
	rowClassName: PropTypes.string,
	rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
	sort: PropTypes.func,
}

MuiVirtualizedTable.defaultProps = {
	headerHeight: 56,
	rowHeight: 56,
}

const WrappedVirtualizedTable = withStyles(styles)(MuiVirtualizedTable)

const outerStyles = (theme) => ({
	root: {
		height: "100%",
		width: "100%",
	},
})

const ReactVirtualizedTable = ({ classes, rows, columns, onRowClick, tableTitle }) => {
	return (
		<div className={classes.root}>
			<EnhancedTableToolbar title={tableTitle} />
			<WrappedVirtualizedTable
				rowCount={rows.length}
				rowGetter={({ index }) => rows[index]}
				onRowClick={onRowClick}
				columns={columns}
			/>
		</div>
	)
}

ReactVirtualizedTable.propTypes = {
	classes: PropTypes.object.isRequired,
	rows: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	onRowClick: PropTypes.func,
}

export default withStyles(outerStyles)(ReactVirtualizedTable)

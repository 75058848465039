import React from "react"
import PropTypes from "prop-types"
import LayoutMarker from "./LayoutMarker"

const LayoutMarkers = ({ layoutIds }) => {
	if (layoutIds.length === 0) return null

	const layoutMarkers = []
	for (let i = 0; i < layoutIds.length; i++) {
		let layoutId = layoutIds[i]
		layoutMarkers.push(<LayoutMarker key={layoutId} layoutId={layoutId} />)
	}
	return layoutMarkers
}

LayoutMarkers.propTypes = {
	layoutIds: PropTypes.array.isRequired,
}

export default LayoutMarkers

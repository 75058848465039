import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import { useSnackbar } from "notistack"

import { groupPrivileges } from "constants/auth"

import API, { graphqlOperation } from "@aws-amplify/api"
import { getUserGroups as getUserGroupsGql } from "graphql/queries"
import { updateUserGroups as updateUserGroupsGql } from "graphql/mutations"

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
	},
	table: {
		width: 300,
		margin: "auto",
	},
	button: {
		width: 300,
		margin: "auto",
		marginTop: theme.spacing(1),
	},
	tableRow: {
		cursor: "pointer",
	},
}))

const UserGroups = ({ username }) => {
	const classes = useStyles()
	const [groups, setGroups] = useState([])
	const [pendingResponse, setPendingResponse] = useState(true)
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		if (username) {
			const getGroups = async () => {
				setPendingResponse(true)
				try {
					const { data } = await API.graphql(
						graphqlOperation(getUserGroupsGql, {
							username,
						}),
					)
					setGroups(data.getUserGroups.groups)
				} catch (err) {
					console.log("ERROR: getUserGroupsGql", err)
					err.errors.forEach((error) => {
						enqueueSnackbar(error.message, {
							variant: "error",
						})
					})
				}
				setPendingResponse(false)
			}
			getGroups()
		}
	}, [username, enqueueSnackbar])

	const handleSelectAllClick = () => {
		if (groups.length > 0) {
			setGroups([])
		} else {
			setGroups(Object.keys(groupPrivileges))
		}
	}

	const handleClick = (groupName) => {
		setGroups((currGroups) => {
			if (currGroups.includes(groupName)) {
				return currGroups.filter((g) => g !== groupName)
			} else {
				return [...currGroups, groupName]
			}
		})
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		setPendingResponse(true)
		try {
			const { data } = await API.graphql(
				graphqlOperation(updateUserGroupsGql, {
					input: {
						username,
						groups,
					},
				}),
			)
			setGroups(data.updateUserGroups.groups)
			enqueueSnackbar("Save Successful", {
				variant: "success",
			})
		} catch (err) {
			console.log("ERROR: updateUserGroupsGql", err)
			err.errors.forEach((error) => {
				enqueueSnackbar(error.message, {
					variant: "error",
				})
			})
		}
		setPendingResponse(false)
	}

	return (
		<div className={classes.root}>
			<TableContainer component={Paper} className={classes.table}>
				<Table size="medium">
					<TableHead>
						<TableRow>
							<TableCell padding="checkbox">
								<Checkbox
									color="primary"
									indeterminate={groups.length > 0 && !(Object.keys(groupPrivileges).length === groups.length)}
									checked={Object.keys(groupPrivileges).length === groups.length}
									onChange={handleSelectAllClick}
									inputProps={{ "aria-label": "select all groups" }}
								/>
							</TableCell>
							<TableCell padding="none">Group Name</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow
							hover
							onClick={() => handleClick("CONTROLS")}
							role="checkbox"
							padding="checkbox"
							aria-checked={groups.includes("CONTROLS")}
							tabIndex={-1}
							key={"CONTROLS"}
							selected={groups.includes("CONTROLS")}
							id={"CONTROLS"}
							className={classes.tableRow}
						>
							<TableCell padding="checkbox">
								<Checkbox
									color="primary"
									checked={groups.includes("CONTROLS")}
									inputProps={{ "aria-labelledby": "CONTROLS" }}
								/>
							</TableCell>
							<TableCell component="th" scope="row" padding="none">
								{"CONTROLS"}
							</TableCell>
						</TableRow>
						{Object.keys(groupPrivileges).map((groupName) => (
							<TableRow
								hover
								onClick={() => handleClick(groupName)}
								role="checkbox"
								padding="checkbox"
								aria-checked={groups.includes(groupName)}
								tabIndex={-1}
								key={groupName}
								selected={groups.includes(groupName)}
								id={groupName}
								className={classes.tableRow}
							>
								<TableCell padding="checkbox">
									<Checkbox
										color="primary"
										checked={groups.includes(groupName)}
										inputProps={{ "aria-labelledby": groupName }}
									/>
								</TableCell>
								<TableCell component="th" scope="row" padding="none">
									{groupName}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<form action="#" onSubmit={handleSubmit} className={classes.button}>
				<Button type="submit" fullWidth variant="contained" color="primary" disabled={pendingResponse}>
					Apply
				</Button>
			</form>
		</div>
	)
}

export default UserGroups

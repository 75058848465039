import React from "react"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"

const FailPositionQuestion = ({ value, setTaskInfo }) => {
	const handleChange = (event) => {
		setTaskInfo({ failPosition: event.target.value })
	}

	return (
		<FormControl component="fieldset">
			<RadioGroup aria-label="Issue Type" name="failPosition" value={value || ""} onChange={handleChange}>
				<FormControlLabel value="East" color="primary" control={<Radio />} label="East" />
				<FormControlLabel value="West" control={<Radio />} label="West" />
				<FormControlLabel value="Flat" control={<Radio />} label="Flat" />
			</RadioGroup>
		</FormControl>
	)
}

export default FailPositionQuestion

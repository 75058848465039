import React, { useEffect, useRef, useState } from "react"
import Tooltip from "@material-ui/core/Tooltip"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import MuiAppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import SearchIcon from "@material-ui/icons/Search"
import ChartIcon from "@material-ui/icons/ShowChart"
import MapIcon from "@material-ui/icons/Map"
import DeviceHubIcon from "@material-ui/icons/DeviceHub"
import { privileges } from "constants/auth"
import { useAuthorization } from "utils/auth"
import { useUrlParams, useUrlPath } from "utils/url"
import PropTypes from "prop-types"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import WeatherSmartToggle from "./WeatherSmartToggle"
import api from "../../constants/api"

const useStyles = makeStyles({
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
	},
	dropdownPadding: {
		padding: "3px 14px",
	},
})

const AppBar = ({
	title,
	drawerIsOpen,
	setDrawerIsOpen,
	searchAvailable,
	openSearch,
	chartAvailable,
	toggleChart,
	downloadAvailable,
	// TODO: Use or remove
	downloadOfflineGui: _downloadOfflineGui,
	userPrivileges,
	handleGeniusHubDownload,
}) => {
	const classes = useStyles()
	const accessAllowed = useAuthorization(userPrivileges)
	const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("xs"))
	const { toggleMapView } = useUrlParams()
	const { mapIsAvailable } = useUrlPath()
	const [canEnableWeatherSmart, setCanEnableWeatherSmart] = useState(null)
	const [geniusFileVersionDropdownVisible, setGeniusFileVersionDropdownVisible] = useState(false)
	// Maps .genius file version numbers to human-readable labels/help texts
	const supportedVersions = { 0: "1.2 or older", 1: "1.3 or newer" }
	const geniusDownloadButtonRef = useRef(null)
	const matchesSm = useMediaQuery((theme) => theme.breakpoints.up("sm"))

	const isAuthenticated = !!api.idToken

	useEffect(() => {
		if (isAuthenticated) {
			// TODO: Do this on signup and store in redux instead of re-requesting every time
			api
				.getUserPermissions()
				.then((response) => {
					const render = response?.body.includes("sites.can_toggle_weathersmart")
					setCanEnableWeatherSmart(render)
				})
				.catch((error) => {
					console.error(error)
				})
		}
	}, [isAuthenticated])

	return (
		<MuiAppBar position="static">
			<Toolbar>
				<IconButton
					onClick={() => setDrawerIsOpen(!drawerIsOpen)}
					className={classes.menuButton}
					color="inherit"
					aria-label="Menu"
				>
					<MenuIcon />
				</IconButton>
				<Typography variant="h6" color="inherit" className={classes.grow}>
					{title}
				</Typography>
				{canEnableWeatherSmart && matchesSm && <WeatherSmartToggle />}
				{downloadAvailable && (
					<Tooltip title="Download GeniusHub configuration">
						<IconButton
							ref={geniusDownloadButtonRef}
							onClick={() => setGeniusFileVersionDropdownVisible((prev) => !prev)}
							color="inherit"
							aria-label="Download GeniusHub configuration"
						>
							<DeviceHubIcon />
						</IconButton>
					</Tooltip>
				)}
				<Menu
					anchorEl={geniusDownloadButtonRef.current}
					keepMounted
					open={geniusFileVersionDropdownVisible}
					onClose={() => setGeniusFileVersionDropdownVisible(false)}
					getContentAnchorEl={null}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
				>
					<div className={classes.dropdownPadding}>
						<strong>Target GeniusHub Version</strong>
					</div>
					{Object.entries(supportedVersions).map(([version, label]) => {
						return (
							<MenuItem
								value={version}
								key={version}
								onClick={() => {
									handleGeniusHubDownload(version)
									setGeniusFileVersionDropdownVisible(false)
								}}
							>
								{label}
							</MenuItem>
						)
					})}
				</Menu>
				{mapIsAvailable && !matchesXs ? (
					<IconButton onClick={toggleMapView} color="inherit" aria-label="Toggle map">
						<MapIcon />
					</IconButton>
				) : null}
				{/* REASON "currently the download option is doing more harm than good" -- Alex undo this false when other issues are resolved */}
				{/*{false && downloadAvailable && accessAllowed(privileges.CONFIGURATION) ? (*/}
				{/*	<Tooltip title="Download Offline GUI">*/}
				{/*		<IconButton onClick={() => downloadOfflineGui()} color="inherit" aria-label="Download offline GUI">*/}
				{/*			<DownloadIcon />*/}
				{/*		</IconButton>*/}
				{/*	</Tooltip>*/}
				{/*) : null}*/}
				{chartAvailable && accessAllowed(privileges.DATA_HISTORY) ? (
					<Tooltip title="Display Historical Graphs">
						<IconButton onClick={() => toggleChart(matchesXs)} color="inherit" aria-label="Display historical graphs">
							<ChartIcon />
						</IconButton>
					</Tooltip>
				) : null}
				{searchAvailable ? (
					<Tooltip title="Search for a Device">
						<IconButton onClick={openSearch} color="inherit" aria-label="Search for a device">
							<SearchIcon />
						</IconButton>
					</Tooltip>
				) : null}
			</Toolbar>
		</MuiAppBar>
	)
}

AppBar.propTypes = {
	title: PropTypes.string,
	drawerIsOpen: PropTypes.bool,
	setDrawerIsOpen: PropTypes.func,
	searchAvailable: PropTypes.bool,
	openSearch: PropTypes.func,
	chartAvailable: PropTypes.bool,
	toggleChart: PropTypes.func,
	downloadAvailable: PropTypes.bool,
	downloadOfflineGui: PropTypes.func,
	userPrivileges: PropTypes.number,
	handleGeniusHubDownload: PropTypes.func.isRequired,
}

export default AppBar

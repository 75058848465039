import React, { Children, memo, useMemo } from "react"
import PropTypes from "prop-types"
// import { makeStyles } from '@material-ui/core/styles'
import { Circle, Marker, Polygon } from "react-leaflet"
import ReactDOMServer from "react-dom/server"
import { divIcon } from "leaflet"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(() => ({
	componentsScanned: {
		width: "100%",
		height: "25px",
		margin: "20px 0 0 0",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		border: "solid thin #fff",
		textAlign: "center",
		fontSize: "15px",
		fontWeight: "bold",
	},
}))

const CustomMarker = memo((props) => {
	const { position, children, ...otherProps } = props
	const icon = divIcon({
		html: ReactDOMServer.renderToString(Children.only(children)),
		//Added to override the default marker styling from divIcon
		className: "",
	})

	return <Marker position={position} icon={icon} {...otherProps}></Marker>
})

CustomMarker.displayName = "CustomMarker"

CustomMarker.propTypes = {
	position: PropTypes.array.isRequired,
	children: PropTypes.node.isRequired,
}

const NodeMarker = memo((props) => {
	const { isDeviceIdAssignment, componentsScanned, handleClick, center, radius, markerColor, markerFill, role } = props
	if (center.length !== 2) return null
	const styles = useStyles()

	const numComponentsScanned = useMemo(() => {
		if (!componentsScanned) return 0
		return Object.keys(componentsScanned).length
	}, [componentsScanned])

	const dynamicStyles = useMemo(() => {
		switch (numComponentsScanned) {
			case 1:
				return {
					background: "silver",
					color: "#000",
				}
			case 2:
				return {
					background: "grey",
					color: "#000",
				}
			case 3:
				return {
					background: "black",
					color: "#fff",
				}
			case 4:
				return {
					background: "green",
					color: "#fff",
				}
			default:
				return {
					background: "yellow",
					color: "#000",
				}
		}
	}, [numComponentsScanned])

	if (role === "solo") {
		return (
			<>
				{isDeviceIdAssignment && numComponentsScanned > 0 && (
					<CustomMarker position={center} onClick={handleClick}>
						<div
							className={styles.componentsScanned}
							style={{ background: dynamicStyles.background, color: dynamicStyles.color }}
						>
							<p>{componentsScanned ? numComponentsScanned : 0}</p>
						</div>
					</CustomMarker>
				)}
				<Circle center={center} radius={radius} color={markerColor} fill={markerFill} onClick={handleClick} />
			</>
		)
	} else if (role === "primary") {
		return (
			<Polygon
				positions={[
					[center[0], center[1] - radius],
					[center[0] - radius * 1.5, center[1]],
					[center[0], center[1] + radius],
					[center[0] + radius * 1.5, center[1]],
				]}
				color={markerColor}
				fill={markerFill}
				onClick={handleClick}
			/>
		)
	} else if (role === "aux") {
		return (
			<Polygon
				positions={[
					[center[0] - radius, center[1] - radius],
					[center[0] - radius, center[1] + radius],
					[center[0] + radius, center[1]],
				]}
				color={markerColor}
				fill={markerFill}
				onClick={handleClick}
			/>
		)
	}
})

NodeMarker.displayName = "NodeMarker"

NodeMarker.propTypes = {
	center: PropTypes.array.isRequired,
	radius: PropTypes.number.isRequired,
	handleClick: PropTypes.func.isRequired,
	isDeviceIdAssignment: PropTypes.bool,
	componentsScanned: PropTypes.object,
	markerColor: PropTypes.string,
	markerFill: PropTypes.string,
	role: PropTypes.string,
}

export default NodeMarker

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import SearchList from "./SearchList"

const data = [
	["FCC23D00000AE2F3", "Master 1", 58, 3],
	["FCC23D000001D8B9", "Master 2", 33, 2],
	["FCC23D000002F661", "Master 3", 102, 2],
	["FCC23D00000A8FB9", "Master 4", 51, 2],
	["FCC23D00000ABEF0", "Master 5", 20, 3],
]

let id = 0
function createData(nId, master, rowNum, fromSouth) {
	id += 1
	return { id, nId, master, rowNum, fromSouth }
}

const rows = []

for (let i = 0; i < 200; i += 1) {
	const randomSelection = data[Math.floor(Math.random() * data.length)]
	rows.push(createData(...randomSelection))
}

const columns = [
	{
		width: 300,
		flexGrow: 1.0,
		label: "NodeID",
		dataKey: "nId",
	},
	{
		width: 200,
		flexGrow: 1.0,
		label: "Master",
		dataKey: "master",
		numeric: false,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "Row Number",
		dataKey: "rowNum",
		numeric: true,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "From South",
		dataKey: "fromSouth",
		numeric: true,
	},
]

const mapStateToProps = (state, props) => ({
	rows,
	columns,
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SearchList)

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import FormGroup from "@material-ui/core/FormGroup"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import green from "@material-ui/core/colors/green"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Input from "@material-ui/core/Input"
import ListItemText from "@material-ui/core/ListItemText"
import Checkbox from "@material-ui/core/Checkbox"
import Chip from "@material-ui/core/Chip"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import FormControl from "@material-ui/core/FormControl"
import Collapse from "@material-ui/core/Collapse"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import AddIcon from "@material-ui/icons/Add"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useSnackbar } from "notistack"
import { Prompt } from "react-router-dom"
import { listNodeParameters as listNodeParametersGql, getNodeParameters as getNodeParametersGql } from "graphql/queries"
import API, { graphqlOperation } from "@aws-amplify/api"
import CreateNodeParameters from "./CreateNodeParameters"
import { nodeParameters } from "../../../../constants/nodeParameters"
const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		overflow: "auto",
		flex: 1,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: 0,
		display: "flex",
		flexDirection: "column",
		overflowY: "auto",
		flex: 1,
	},
	divider: {
		margin: theme.spacing(1.5, 0),
	},
	wrapper: {
		margin: theme.spacing(0, 1),
		position: "relative",
	},
	actionWrapper: {
		justifyContent: "flex-end",
	},
	actionWrapperRight: {
		display: "flex",
	},
	buttonSuccess: {
		backgroundColor: green[500],
		"&:hover": {
			backgroundColor: green[700],
		},
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		width: "100%",
	},
	paper: {
		padding: 0,
		width: "70%",
		overflowY: "hidden",
		display: "flex",
		margin: "auto",
		flexDirection: "row",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexDirection: "column",
		},
	},
	table: {
		width: "100%",
	},
	tableTitle: {
		display: "flex",
		width: "100%",
		alignItems: "center",
		justifyContent: "center",
		fontWeight: "bold",
		fontFamily: theme.typography.fontFamily,
	},
	createNewBtn: {
		margin: theme.spacing(1),
	},
	title: {
		paddingBottom: 0,
		display: "flex",
		justifyContent: "space-between",
	},
	dialogPaperSmall: {
		minHeight: "80vh",
		maxHeight: "80vh",
		display: "flex",
		height: "100%",
		width: "100%",
	},
	dialogPaperLarge: {
		height: "100%",
	},
	chip: {}, // TODO: Use or remove
}))

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: "70%",
		},
	},
}
const AssignNodeParameters = ({
	layoutId,
	masterDetails,
	fetchMasterDetails,
	masterList,
	mLocId,
	updateMasterDetails,
}) => {
	const [pendingResponse, setPendingResponse] = useState(false)
	const [success, setSuccess] = useState(true)
	const [values, setValues] = useState({})
	const [templates, setTemplates] = useState([])
	const [selectedTemplate, setSelectedTemplate] = useState("")
	const [selectedMasters, setSelectedMasters] = useState([mLocId])
	const [allMastersSelected, setAllMastersSelected] = useState(false)
	const [expandCurrentTable, setExpandCurrentTable] = useState(true)
	const [expandTemplateTable, setExpandTemplateTable] = useState(true)
	const [createNewNodeParmOpen, setCreateNewNodeParmOpen] = useState(false)
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const nodeParamId = (masterDetails[mLocId] || {}).nodeParamId

	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

	useEffect(() => {
		if (nodeParamId) {
			const getNodeParameters = async () => {
				try {
					const result = await API.graphql(
						graphqlOperation(getNodeParametersGql, {
							id: nodeParamId,
						}),
					)
					let values = result.data["getNodeParameters"]
					if (values) {
						setValues(values)
					}
				} catch (err) {
					console.error(err)
				}
			}
			// noinspection JSIgnoredPromiseFromCall
			getNodeParameters()
		}
	}, [nodeParamId, mLocId])

	useEffect(() => {
		const listNodeParameters = async () => {
			try {
				const result = await API.graphql(graphqlOperation(listNodeParametersGql, {}))
				let values = result.data["listNodeParameters"]
				if (values) {
					let templates = values.items
					let sortedTemplates = templates.sort((a, b) => (a.label > b.label ? 1 : -1))
					setTemplates(sortedTemplates)
				}
			} catch (err) {
				console.error(err)
			}
		}
		// noinspection JSIgnoredPromiseFromCall
		listNodeParameters()
	}, [])

	const handleTemplateChange = (event) => {
		const { value } = event.target
		setSelectedTemplate(value)
		setPendingResponse(false)
		setSuccess(false)
	}

	const handleMLocIdChange = (event) => {
		const { value } = event.target

		if (value.includes("selectAll")) {
			setSelectedMasters(Object.keys(masterList))
			setAllMastersSelected(true)
		} else if (value.includes("deselectAll")) {
			setSelectedMasters([])
			setAllMastersSelected(false)
		} else {
			setSelectedMasters(value)
		}
	}

	const submit = async (e) => {
		e.preventDefault()
		let timer = setTimeout(() => {
			setPendingResponse(false)
			setSuccess(false)
			enqueueSnackbar("Save Timeout", {
				variant: "error",
			})
		}, 20000)

		const requests = selectedMasters.map((mLocId) => {
			return updateMasterDetails(mLocId, layoutId, { nodeParamId: selectedTemplate.id })
		})

		setPendingResponse(true)
		try {
			const results = await Promise.all(requests)
			console.log(results)
			fetchMasterDetails(layoutId)
			clearTimeout(timer)
			enqueueSnackbar("Save Successful", {
				variant: "success",
			})
			setPendingResponse(false)
			setSuccess(true)
			setExpandCurrentTable(true)
			setExpandTemplateTable(true)
			setSelectedTemplate("")
		} catch (err) {
			console.error(err)
			clearTimeout(timer)
			enqueueSnackbar("Save Error", {
				variant: "error",
			})
			setPendingResponse(false)
			setSuccess(false)
		}
	}

	const formatValue = (value) => {
		return value === null || value === undefined ? "Unknown" : value.toString()
	}

	const createValuesRows = (values) => {
		let rows = []

		Object.keys(values).forEach((key) => {
			if (key !== "label" && key !== "id" && key !== "newNode") {
				const nodeParam = nodeParameters[key]
				let unit = ""
				if (nodeParam && nodeParam.unit) {
					unit = `(${nodeParameters[key].unit})`
				}
				let name = nodeParam?.label || key
				const value = values[key]
				rows.push({ name: `${name} ${unit}`, value: formatValue(value) })
			}
		})
		return rows
	}

	const currentValuesRows = createValuesRows(values)
	const templateValuesRows = createValuesRows(selectedTemplate)

	const createDoubleRows = (rows) => {
		const half = Math.ceil(rows.length / 2)

		const firstHalf = rows.splice(0, half)
		const secondHalf = rows.splice(-half)

		const rows1 = firstHalf.map((row) => (
			<TableRow key={row.name}>
				<TableCell size="small" component="th" scope="row">
					{row.name}
				</TableCell>
				<TableCell size="small" align="right">
					{row.value}
				</TableCell>
			</TableRow>
		))

		const rows2 = secondHalf.map((row) => (
			<TableRow key={row.name}>
				<TableCell size="small" component="th" scope="row">
					{row.name}
				</TableCell>
				<TableCell size="small" align="right">
					{row.value}
				</TableCell>
			</TableRow>
		))

		return [rows1, rows2]
	}
	const [currentRows1, currentRows2] = createDoubleRows(currentValuesRows)
	const [templateRows1, templateRows2] = createDoubleRows(templateValuesRows)

	return (
		<div className={classes.root}>
			<Prompt when={!success} message={"Continue without saving?"} />
			<div className={classes.title}>
				<DialogTitle id="form-dialog-title">Assign Node Parameters</DialogTitle>
				<Tooltip className={classes.createNewBtn} title="Create Custom">
					<IconButton edge="end" color="inherit" onClick={() => setCreateNewNodeParmOpen(true)} aria-label="Close">
						<AddIcon />
					</IconButton>
				</Tooltip>
			</div>
			<form className={classes.form} onSubmit={submit}>
				<DialogContent>
					<div className={classes.tableTitle}>
						<div>Current Node Parameters : {values["label"] ? values["label"] : "None Assigned"}</div>
						{currentRows1.length !== 0 && currentRows2.length !== 0 ? (
							<Tooltip title={expandCurrentTable ? "Hide Values" : "Show Values"}>
								<IconButton
									edge="end"
									color="inherit"
									onClick={() => setExpandCurrentTable(!expandCurrentTable)}
									aria-label="Close"
								>
									{expandCurrentTable ? <ExpandLessIcon /> : <ExpandMoreIcon />}
								</IconButton>
							</Tooltip>
						) : null}
					</div>
					{currentRows1.length !== 0 && currentRows2.length !== 0 ? (
						<Collapse in={expandCurrentTable}>
							<TableContainer className={classes.paper} component={Paper}>
								<Table className={classes.table} size="small" aria-label="a dense table">
									<TableHead>
										<TableRow>
											<TableCell>Name</TableCell>
											<TableCell align="right">Value</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>{currentRows1}</TableBody>
								</Table>
								<Table className={classes.table} size="small" aria-label="a dense table">
									<TableHead>
										<TableRow>
											<TableCell>Name</TableCell>
											<TableCell align="right">Value</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>{currentRows2}</TableBody>
								</Table>
							</TableContainer>
						</Collapse>
					) : null}
					<FormControl className={classes.formControl}>
						<InputLabel id="demo-simple-select-label">Node Parameters</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={selectedTemplate}
							onChange={handleTemplateChange}
						>
							{templates.map((template) => (
								<MenuItem key={template.id} value={template}>
									{template.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormGroup className={classes.formControl}>
						<InputLabel id="demo-mutiple-checkbox-label">Masters</InputLabel>
						<Select
							labelId="masters-mutiple-checkbox-label"
							id="masters-mutiple-checkbox"
							multiple
							value={selectedMasters ? selectedMasters : []}
							onChange={handleMLocIdChange}
							input={<Input />}
							renderValue={(selected) => {
								return selected.map((mLocId) => {
									return <Chip key={mLocId} label={masterList[mLocId]} className={classes.chip} />
								})
							}}
							MenuProps={MenuProps}
						>
							{!allMastersSelected ? (
								<MenuItem key={"selectAll"} value={"selectAll"}>
									<ListItemText primary={"Select All"} style={{ display: "flex", alignItems: "center" }} />
								</MenuItem>
							) : (
								<MenuItem key={"deselectAll"} value={"deselectAll"}>
									<ListItemText primary={"Deselect All"} style={{ display: "flex", alignItems: "center" }} />
								</MenuItem>
							)}
							{Object.keys(masterList).map((master) => {
								return (
									<MenuItem key={master} value={master}>
										<Checkbox color="primary" checked={selectedMasters && selectedMasters.includes(master)} />
										<ListItemText
											primary={masterList[master]}
											secondary={(masterDetails[master] || {})["nodeParamId"] && "(Parameters Assigned)"}
											style={{ display: "flex", alignItems: "center" }}
										/>
									</MenuItem>
								)
							})}
						</Select>
					</FormGroup>
					{templateRows1.length !== 0 && templateRows2.length !== 0 ? (
						<div className={classes.tableTitle}>
							<div>
								Selected Node Parameters : {selectedTemplate["label"] ? selectedTemplate["label"] : "None Assigned"}
							</div>
							<Tooltip title={expandTemplateTable ? "Hide Values" : "Show Values"}>
								<IconButton
									edge="end"
									color="inherit"
									onClick={() => setExpandTemplateTable(!expandTemplateTable)}
									aria-label="Close"
								>
									{expandTemplateTable ? <ExpandLessIcon /> : <ExpandMoreIcon />}
								</IconButton>
							</Tooltip>
						</div>
					) : null}
					{templateRows1.length !== 0 && templateRows2.length !== 0 ? (
						<Collapse in={expandTemplateTable}>
							<TableContainer className={classes.paper} component={Paper}>
								<Table className={classes.table} size="small" aria-label="a dense table">
									<TableHead>
										<TableRow>
											<TableCell>Name</TableCell>
											<TableCell align="right">Value</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>{templateRows1}</TableBody>
								</Table>
								<Table className={classes.table} size="small" aria-label="a dense table">
									<TableHead>
										<TableRow>
											<TableCell>Name</TableCell>
											<TableCell align="right">Value</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>{templateRows2}</TableBody>
								</Table>
							</TableContainer>
						</Collapse>
					) : null}
				</DialogContent>
				<DialogActions className={classes.actionWrapper}>
					<div className={classes.actionWrapperRight}>
						<div className={classes.wrapper}>
							<Button type="submit" variant="contained" color="primary" disabled={pendingResponse || success}>
								{success ? "Saved" : "Save Changes"}
							</Button>
							{pendingResponse && <CircularProgress size={24} className={classes.buttonProgress} />}
						</div>
					</div>
				</DialogActions>
			</form>
			<div>
				<Dialog
					scroll="paper"
					aria-labelledby="form-dialog-title"
					open={createNewNodeParmOpen}
					fullScreen={fullScreen}
					maxWidth={fullScreen ? false : "sm"}
					fullWidth={fullScreen}
					onClose={() => setCreateNewNodeParmOpen(false)}
					classes={fullScreen ? { paper: classes.dialogPaperLarge } : { paper: classes.dialogPaperSmall }}
				>
					<CreateNodeParameters
						setCreateNewNodeParmOpen={setCreateNewNodeParmOpen}
						templates={templates}
						masterList={masterList}
						mLocId={mLocId}
						layoutId={layoutId}
					/>
				</Dialog>
			</div>
		</div>
	)
}

AssignNodeParameters.propTypes = {
	masterList: PropTypes.object.isRequired,
	layoutId: PropTypes.string,
	masterDetails: PropTypes.any,
	fetchMasterDetails: PropTypes.func,
	mLocId: PropTypes.any,
	updateMasterDetails: PropTypes.any,
}

export default AssignNodeParameters

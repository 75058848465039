import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormGroup from "@material-ui/core/FormGroup"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import green from "@material-ui/core/colors/green"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Input from "@material-ui/core/Input"
import ListItemText from "@material-ui/core/ListItemText"
import Checkbox from "@material-ui/core/Checkbox"
import Chip from "@material-ui/core/Chip"
import Toolbar from "@material-ui/core/Toolbar"
import Fab from "@material-ui/core/Fab"
import Menu from "@material-ui/core/Menu"
import Tooltip from "@material-ui/core/Tooltip"
import AddIcon from "@material-ui/icons/Add"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { Prompt } from "react-router-dom"
import { getSpaParameters as getSpaParametersGql } from "graphql/queries"
import {
	createSpaParametersRequest as createConfigurationRequestGql,
	updateSpaParametersRequest as updateConfigurationRequestGql,
} from "graphql/mutations"
import API, { graphqlOperation } from "@aws-amplify/api"
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import configurableSettings from "../../../../constants/configurableSettings"
import LabeledSelect from "../../../CustomMui/LabeledSelect"
import { truncateWords } from "../../../../utils/formatters"

// TODO: Rename "SpaParameter" to "ConfigurationSetting" or similar

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		overflow: "auto",
		flex: 1,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: 0,
		display: "flex",
		flexDirection: "column",
		overflowY: "auto",
		flex: 1,
	},
	divider: {
		margin: theme.spacing(1.5, 0),
	},
	wrapper: {
		margin: theme.spacing(0, 1),
		position: "relative",
	},
	actionWrapper: {
		justifyContent: "flex-end",
	},
	actionWrapperRight: {
		display: "flex",
	},
	buttonSuccess: {
		backgroundColor: green[500],
		"&:hover": {
			backgroundColor: green[700],
		},
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	title: {
		paddingBottom: 0,
	},
	formControl: {
		margin: theme.spacing(1),
		width: "100%",
	},
	toolbar: {
		paddingRight: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		justifyContent: "space-between",
	},
	actions: {
		display: "flex",
		color: theme.palette.text.secondary,
	},
	mLocSelect: {
		width: "100%",
	},
	categories: {
		marginTop: "20px",
	},
	category: {
		backgroundColor: "#e7e3e3",
	},
	categoryDetails: {
		display: "block",
	},
	chip: {
		// TODO: Use or remove
	},
}))

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: "70%",
		},
	},
}

const EditSpaParameters = ({
	layoutId,
	handleClose,
	enqueueSnackbar,
	handleNewSpaParameterRedirect: handleNewConfigurationRedirect,
	masterDetails,
	fetchMasterDetails,
	masterList,
	mLocId,
	spaParamId: configureAction, // either "copy", "new" or the ID of an existing configuration
	handleSpaParamsType: setConfigureAction,
	spaParamIdFromMaster, // TODO: Rename to what?
}) => {
	const [success, setSuccess] = useState(true)
	const [values, setValues] = useState({})
	const [oldValues, setOldValues] = useState({})
	const [anchorEl, setAnchorEl] = useState(null)
	const [errors, setErrors] = useState({})
	const [pendingResponses, setPendingResponses] = useState(false) // TODO: Invert
	const [expandedCategories, setExpandedCategories] = useState({})
	const classes = useStyles()
	// Optimization (don't calculate on every render)
	const [allSettingsSet, setAllSettingsSet] = useState(false)

	const getInitialValues = () => {
		const initialValues = {}
		Object.entries(configurableSettings).forEach((categorySettings) => {
			const settings = categorySettings[1]
			for (let setting of settings) {
				const defaultValue = setting.defaultValue
				if (defaultValue !== undefined) {
					initialValues[setting.id] = defaultValue
				}
			}
		})
		return initialValues
	}

	useEffect(() => {
		const getConfiguration = async () => {
			try {
				if (spaParamIdFromMaster) {
					const result = await API.graphql(
						graphqlOperation(getSpaParametersGql, {
							id: spaParamIdFromMaster,
						}),
					)
					const values = result.data["getSpaParameters"]
					if (values) {
						// TODO: Object.entries
						Object.keys(values).forEach((key) => values[key] == null && delete values[key])
						const newValues = { ...getInitialValues() }
						Object.assign(newValues, values)
						newValues["oldMLocIds"] = values.mLocIds
						// TODO: Can't set state in async function called from useEffect. This is technically a memory leak.
						// NOTE: The problem described above is not a big deal, but there is a better pattern to be discovered.
						setValues(newValues)
						// This happens after getting the configuration for the first time. We should not consider there to be any
						// changes after the first load, so we set both `values` and `oldValues` to the same config.
						setOldValues(newValues)
					}
				}
			} catch (err) {
				console.error(err)
			}
			// TODO: Enum
			if (configureAction === "new") {
				// TODO: Get rid of these "oldMLocIds"
				if (values && values["oldMLocIds"]) {
					setValues({ ...getInitialValues(), mLocIds: [mLocId], oldMLocIds: values["oldMLocIds"] })
				} else {
					setValues({ ...getInitialValues(), mLocIds: [mLocId] })
				}
			} else if (configureAction === "copy") {
				setValues((prev) => {
					const { label: _, ...values } = prev
					return { ...getInitialValues(), ...values, mLocIds: [mLocId] }
				})
			}
		}
		// noinspection JSIgnoredPromiseFromCall
		getConfiguration()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configureAction, mLocId, spaParamIdFromMaster])

	const getValue = useCallback(
		(setting) => {
			if (typeof setting === "string") {
				return values[setting] || ""
			}

			let value = values[setting.id]

			if (value === undefined || value === null) {
				// Some components require a special initial value that is either undefined or null
				switch (setting.elementType) {
					case TextField:
						value = null
						break
					case LabeledSelect:
						value = ""
						break
				}
			}

			return value
		},
		[values],
	)

	useEffect(() => {
		const initialValues = {}

		for (const categorySettingsSet of Object.values(configurableSettings)) {
			for (const settings of categorySettingsSet) {
				initialValues[settings.id] = getValue(settings)
			}
		}

		setValues(initialValues)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const checkAllSettings = useCallback((values) => {
		const newErrors = {}
		const missingSettings = []

		if (!values["label"]) {
			missingSettings.push("Label")
		}

		if (!values["mLocIds"]) {
			return {
				missingSettings: ["mLocIds"],
				errors: {},
			}
		}

		for (const categorySettingsSet of Object.values(configurableSettings)) {
			for (const setting of categorySettingsSet) {
				const currentValue = values[setting.id]

				if (
					(currentValue === null || currentValue === undefined) &&
					(setting.required || setting.required === undefined) &&
					setting.show &&
					(setting.defaultValue === undefined || setting.defaultValue === null)
				) {
					newErrors[setting.id] = "This field is required"
					missingSettings.push(setting.id)
				} else if (setting.getError) {
					const errorText = setting.getError(currentValue)
					if (errorText) {
						newErrors[setting.id] = errorText
					}
				}
			}
		}

		return {
			missingSettings,
			errors: newErrors,
		}
	}, [])

	const checkThisSetting = useCallback(
		(settingId, category, value) => {
			const thisCategorySettings = configurableSettings[category]
			if (thisCategorySettings) {
				const setting = thisCategorySettings.find((setting) => setting.id === settingId)
				if (setting) {
					let errorText

					if (setting.getError) {
						errorText = setting.getError(value)
						if (errorText !== errors[settingId]) {
							const newErrors = {
								...errors,
							}
							newErrors[settingId] = errorText
							setErrors(newErrors)
							return false
						}
					}
				}
			}
			return true
		},
		[errors],
	)

	// TODO: Enum for type
	const handleChange = useCallback(
		(category, settingId, type) => (val) => {
			setPendingResponses(false)
			setSuccess(false)

			let value
			switch (type) {
				case "string":
				case "array":
					value = val
					break
				case "eInt":
					value = parseInt(val.target.value)
					if (isNaN(value)) value = ""
					break
				case "eFloat":
					value = parseFloat(val.target.value)
					if (isNaN(value)) value = ""
					break
				case "object":
					value = JSON.stringify(val)
					break
				case "element":
				default:
					value = val.target.value
					break
			}

			let newValues = { ...values }

			if (value === undefined || value === "") {
				delete newValues[settingId]
			} else {
				newValues[settingId] = value
				if (!checkThisSetting(settingId, category, value)) {
					setValues(newValues)
					return
				}
			}

			let { missingSettings, errors: newErrors } = checkAllSettings(newValues)

			if (missingSettings && missingSettings.length) {
				newErrors["missing"] = truncateWords(missingSettings.join(", "), 80)
			}

			setErrors(newErrors)
			setAllSettingsSet(!missingSettings || !missingSettings.length)
			setValues(newValues)
		},
		[values, checkThisSetting, checkAllSettings],
	)

	const createConfiguration = async (input) => {
		// info: if not removed it will trigger a type error. __typename is automatically added in responses
		// but unneeded in requests
		delete input.__typename
		return API.graphql(
			graphqlOperation(createConfigurationRequestGql, {
				input: input,
			}),
		)
	}

	const updateConfiguration = async (input) => {
		// info: if not removed it will trigger a type error. __typename is automatically added in responses
		// but unneeded in requests
		delete input.__typename
		return API.graphql(
			graphqlOperation(updateConfigurationRequestGql, {
				input: input,
			}),
		)
	}

	const createParamRequest = async (historical) => {
		// historical is true when saving configurations for unselected masters
		const configuration = historical ? { ...oldValues } : { ...values }
		const { id: _, ...details } = configuration
		if (historical) {
			const filteredIds = oldValues.oldMLocIds.filter((id) => !values.mLocIds.includes(id))
			details.mLocIds = filteredIds
		}
		if (!details.mLocIds.length) return
		try {
			const result = await createConfiguration({
				layoutId,
				spaParamIdFromMaster,
				...details,
			})
			fetchMasterDetails(layoutId)
			return result
		} catch (err) {
			console.error("createParamRequest error", err)
		}
	}

	const updateParamRequest = async () => {
		const configuration = { ...values }
		const { id: _, ...details } = configuration
		try {
			const result = await updateConfiguration({
				spaParamId: configureAction,
				mLocId,
				layoutId,
				...details,
			})
			fetchMasterDetails(layoutId)
			return result
		} catch (err) {
			console.error("updateParamRequest error", err)
		}
	}

	// triggers request to save a copy of unselected masters configuration
	useEffect(() => {
		if (!oldValues || !oldValues.mLocIds || !values || !values.mLocIds || !success) return
		if (oldValues.mLocIds.length !== values.mLocIds.length && success) {
			createParamRequest(true).then()
		}
		/* eslint-disable-next-line */
	}, [values, oldValues, success])

	const submit = async (e) => {
		e.preventDefault()
		let timer = setTimeout(() => {
			setPendingResponses(false)
			setSuccess(false)
			enqueueSnackbar("Save Timeout", {
				variant: "error",
			})
		}, 20000)

		setPendingResponses(true)
		try {
			let result = null
			let resultString = ""
			if (configureAction === "new" || configureAction === "copy") {
				result = await createParamRequest()
				resultString = "createSpaParametersRequest"
			} else {
				result = await updateParamRequest()
				resultString = "updateSpaParametersRequest"
			}
			fetchMasterDetails(layoutId)
			clearTimeout(timer)
			enqueueSnackbar("Save Successful", {
				variant: "success",
			})
			setPendingResponses(false)
			setSuccess(true)

			if ((configureAction === "new" || configureAction === "copy") && result.data) {
				console.log(`New Configuration Id: ${result.data[resultString]["id"]}`)
				handleNewConfigurationRedirect(result.data[resultString]["id"])
			}
		} catch (err) {
			console.error(err)
			clearTimeout(timer)
			enqueueSnackbar("Save Error", {
				variant: "error",
			})
			setPendingResponses(false)
			setSuccess(false)
		}
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const newClick = () => {
		setConfigureAction("new")
		setAnchorEl(null)
	}

	const copyClick = () => {
		setConfigureAction("copy")
		setAnchorEl(null)
	}

	const handleBack = () => {
		const { spaParamId: configureAction } = masterDetails[mLocId] || {}
		setConfigureAction("edit", configureAction)
		setAnchorEl(null)
	}

	const getHtmlElementForSetting = (setting, category) => {
		// TODO: Validate setting.elementType and ensure that it accepts all the props
		//  (especially margin, variant, fullWidth, which are Mui props).

		let value = getValue(setting)

		if (setting.internalValueTypeIdentifier === "eBool") {
			value = !!value
		}

		const props = {
			key: setting.id,
			id: setting.id,
			value: value === null || value === undefined ? "" : value,
			onChange: handleChange(category, setting.id, setting.internalValueTypeIdentifier),
			margin: "normal",
			variant: "outlined",
			fullWidth: true,
			InputLabelProps: { shrink: true },
			...setting.elementAttrs,
		}

		const errorText = (errors || {})[setting.id]

		// errorText overwrites any existing elementAttrs.helperText
		if (errorText && errorText !== "") {
			props["error"] = !!errorText
			props["helperText"] = errorText
		}

		return React.createElement(setting.elementType, props)
	}

	const handleAccordionExpanded = useCallback(
		(category) => () => {
			const thisCategoryExpanded = !expandedCategories[category]
			setExpandedCategories({ ...expandedCategories, [category]: thisCategoryExpanded })
		},
		[expandedCategories, setExpandedCategories],
	)

	return (
		<div className={classes.root}>
			<Prompt when={!success} message={"Continue without saving?"} />
			<Toolbar className={classes.toolbar}>
				{spaParamIdFromMaster && (configureAction === "new" || configureAction === "copy") ? (
					<Tooltip key={"back"} title="Back">
						<Fab size="small" aria-label="Toggle Summary" onClick={handleBack}>
							<ArrowBackIcon />
						</Fab>
					</Tooltip>
				) : null}
				<DialogTitle className={classes.title} id="form-dialog-title">
					{configureAction === "new" || configureAction === "copy" ? "Create Configuration" : "Update Configuration"}
				</DialogTitle>
				<div className={classes.actions}>
					<div>
						{spaParamIdFromMaster ? (
							<Tooltip key={"add"} title="Add New Configuration">
								<Fab size="small" aria-label="Toggle Summary" onClick={handleClick}>
									<AddIcon />
								</Fab>
							</Tooltip>
						) : null}
						<Menu
							id="simple-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleCloseMenu}
							getContentAnchorEl={null}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "center",
							}}
						>
							<MenuItem selected={configureAction === "new"} onClick={newClick}>
								New
							</MenuItem>
							<MenuItem selected={configureAction === "copy"} onClick={copyClick}>
								Copy
							</MenuItem>
						</Menu>
					</div>
				</div>
			</Toolbar>
			<form className={classes.form} onSubmit={submit}>
				<DialogContent>
					<FormGroup>
						<TextField
							id="label" // TODO: Change to configuration-name (also requires mutation change; understand consequences)
							label="Configuration Name"
							type="text"
							margin="normal"
							variant="outlined"
							value={getValue("label")}
							onChange={handleChange("main", "label", "label")}
							required
							fullWidth
						/>
						<Typography variant={"caption"} color={"error"} style={{ marginBottom: "10px" }}>
							{getValue("label") ? "" : "This field is required"}
						</Typography>
						<InputLabel id="demo-multiple-checkbox-label">Masters</InputLabel>
						<Select
							labelId="masters-mutiple-checkbox-label"
							id="masters-mutiple-checkbox"
							multiple
							value={values.mLocIds || []}
							className={classes.mLocSelect}
							onChange={handleChange("main", "mLocIds", "element")}
							input={<Input />}
							renderValue={(selected) => {
								// Filter out non-existent mLocIds from previous saves (this was added due to a bug where masters were
								// likely removed after a configuration was created).
								// See: https://gamechangesolar.atlassian.net/browse/SW-322
								selected = selected.filter((mLocId) => !!masterDetails[mLocId])

								// Sort by master name
								selected = selected.sort((a, b) => {
									const aName = (masterDetails[a] || { name: a }).name
									const bName = (masterDetails[b] || { name: b }).name
									return aName.localeCompare(bName)
								})

								// Map to Chip components (little bubbles)
								return selected.map((mLocId) => {
									return <Chip key={mLocId} label={masterList[mLocId]} className={classes.chip} />
								})
							}}
							MenuProps={MenuProps}
						>
							{Object.keys(masterList).map((master) => {
								return (
									<MenuItem key={master} value={master}>
										<Checkbox color="primary" checked={(values.mLocIds && values.mLocIds.includes(master)) || false} />
										<ListItemText
											primary={masterList[master]}
											secondary={(masterDetails[master] || {})["spaParamId"] && "(Parameters Assigned)"}
											style={{ display: "flex", alignItems: "center" }}
										/>
									</MenuItem>
								)
							})}
						</Select>

						<div className={classes.categories}>
							{Object.entries(configurableSettings).map((categorySettings) => {
								const [categoryName, settingsUnderCategory] = categorySettings

								// Do not show categories with no visible settings.
								if (!settingsUnderCategory.find((setting) => setting.show || setting.show === undefined)) return null

								return (
									<Accordion
										id={categoryName}
										key={categoryName}
										expanded={expandedCategories[categoryName] || false}
										onChange={handleAccordionExpanded(categoryName)}
									>
										<AccordionSummary className={classes.category} expandIcon={<ExpandMoreIcon />}>
											<Typography>{categoryName}</Typography>
										</AccordionSummary>
										<AccordionDetails className={classes.categoryDetails}>
											{settingsUnderCategory.map((setting) => {
												if (setting.show === false) return null
												return getHtmlElementForSetting(setting, categoryName)
											})}
										</AccordionDetails>
									</Accordion>
								)
							})}
						</div>
					</FormGroup>
				</DialogContent>
				<DialogActions className={classes.actionWrapper}>
					<Typography color={"error"}>{errors.missing ? `Missing: ${errors.missing}` : ""}</Typography>
					<div className={classes.actionWrapperRight}>
						<Button color="primary" onClick={handleClose}>
							Close
						</Button>
						<div className={classes.wrapper}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={pendingResponses || success || Object.keys(errors).length > 0 || !allSettingsSet}
							>
								{success ? "Saved" : "Save Changes"}
							</Button>
							{pendingResponses && <CircularProgress size={24} className={classes.buttonProgress} />}
						</div>
					</div>
				</DialogActions>
			</form>
		</div>
	)
}

EditSpaParameters.propTypes = {
	layoutId: PropTypes.string.isRequired,
	handleClose: PropTypes.func.isRequired,
	enqueueSnackbar: PropTypes.func.isRequired,
	tabIndex: PropTypes.number, // TODO: Use or remove
	layoutDetails: PropTypes.object.isRequired,
	handleNewSpaParameterRedirect: PropTypes.func.isRequired,
	masterDetails: PropTypes.object.isRequired,
	layoutMasters: PropTypes.any, // TODO: Use or remove
	fetchMasterDetails: PropTypes.func.isRequired,
	masterList: PropTypes.array.isRequired,
	mLocId: PropTypes.string.isRequired,
	spaParamId: PropTypes.string.isRequired,
	handleSpaParamsType: PropTypes.func.isRequired,
	spaParamIdFromMaster: PropTypes.any.isRequired, // TODO: Find out
}

EditSpaParameters.propTypes = {
	masterList: PropTypes.object.isRequired,
}

export default EditSpaParameters

import { getAlarmsString, getFlagsString, getP4QStatus } from "./statusBits"
import { getChargingLabel } from "./charging"

import { deviceTypes } from "./deviceTypes"
import { formatTimestamp } from "utils/formatters"

// TODO: Refactor
export const getNodeDataFormats = (userPrivileges, deviceType, tz) => {
	const NodeDataFormats = {
		[deviceTypes.P4Q]: [
			{
				id: "lastUpdate",
				label: "Last Update From NCU",
				args: ["timestamp", "hoursSinceSuccess"],
				formatString: (time, hoursSinceSuccess) => {
					if (time) {
						const displayTime = formatTimestamp(time, tz)
						if (hoursSinceSuccess > 0) {
							return displayTime + " ( " + hoursSinceSuccess.toString() + " Hours Since Update )"
						} else {
							return displayTime
						}
					} else {
						return "---"
					}
				},
			},
			{
				id: "activeAlarms",
				label: "Active Alarms",
				args: ["alarms"],
				formatString: getAlarmsString(userPrivileges, deviceTypes.P4Q),
			},
			{
				id: "activeFlags",
				label: "Active Flags",
				args: ["flags", "fwNode"],
				formatString: getFlagsString(userPrivileges, deviceTypes.P4Q),
			},
			{
				id: "statusRegisters",
				label: "Status",
				args: ["statusRegisters"],
				formatString: getP4QStatus,
			},
			{
				id: "panelAngle",
				label: "Angle - Panel",
				args: ["anglePanel", "deviation"],
				formatString: (anglePanel, deviation) => {
					if (anglePanel) {
						const displayAngle = Math.abs(anglePanel)

						let displayDirection
						let displayDeviation

						if (anglePanel > 0) {
							displayDirection = " West (Deg) "
						} else {
							displayDirection = " East (Deg) "
						}
						if (deviation === null || isNaN(deviation)) {
							displayDeviation = ""
						} else {
							displayDeviation = "(" + deviation.toString() + " deviation)"
						}
						return displayAngle + displayDirection + displayDeviation
					} else {
						return "---"
					}
				},
			},
			{
				id: "targetAngle",
				label: "Angle - Target",
				args: ["angleTarget"],
				formatString: (anglePanel) => {
					if (anglePanel) {
						const displayAngle = Math.abs(anglePanel)
						let displayDirection
						if (anglePanel > 0) {
							displayDirection = " West (Deg) "
						} else {
							displayDirection = " East (Deg) "
						}
						return displayAngle + displayDirection
					} else {
						return "---"
					}
				},
			},
			{
				id: "angleGoal",
				label: "Panel Angle Goal",
				args: ["angleGoal", "deviation"],
				// TODO: Remove duplication (see above)
				formatString: (angleGoal) => {
					if (angleGoal) {
						const displayAngle = Math.abs(angleGoal)

						let displayDirection

						if (angleGoal > 0) {
							displayDirection = " West (Deg) "
						} else {
							displayDirection = " East (Deg) "
						}

						return displayAngle + displayDirection
					} else {
						return "---"
					}
				},
			},
			{
				id: "batteryCharge",
				label: "Battery Charge",
				args: ["BatteryCharge", "BatteryRemainingCapacity_mAh", "BatteryFullCapacity_mAh"],
				formatString: (percent, remainingCapacity, fullCapacity) => {
					return (
						percent.toString() +
						"% - " +
						(remainingCapacity / 1000).toFixed(2).toString() +
						" (Ah) / " +
						(fullCapacity / 1000).toFixed(2).toString() +
						" (Ah)"
					)
				},
			},
			{
				id: "batteryVoltage",
				label: "Battery Voltage",
				args: ["BatteryVoltage_mV"],
				formatString: (mV) => {
					return (mV / 1000).toFixed(2).toString() + " (V)"
				},
			},
			{
				id: "batteryCurrent",
				label: "Battery Current",
				args: ["BatteryCurrent_mA", "BatteryAveCurr_mA"],
				formatString: (mA, avemA) => {
					return (mA / 1000).toFixed(2).toString() + " (A) - Average: " + (avemA / 1000).toFixed(2).toString() + " (A)"
				},
			},
			{
				id: "batteryStateOfHealth",
				label: "Battery State of Health",
				args: ["BatteryStateOfHealth"],
				formatString: (percent) => {
					return percent.toString() + " (%)"
				},
			},
			{
				id: "batteryStateOfCharge",
				label: "Battery State of Charge",
				args: ["BatteryStateOfCharge"],
				formatString: (percent) => {
					return percent.toString() + " (%)"
				},
			},
			{
				id: "batteryTemp",
				label: "Battery Temperature",
				args: ["BatteryTempExternal_k_x10", "BatteryTempInternal_k_x10"],
				formatString: (BatteryTempExternal_k_x10, BatteryTempInternal_k_x10) => {
					if (BatteryTempExternal_k_x10 !== null && BatteryTempInternal_k_x10 !== null) {
						return (
							"Internal: " +
							((BatteryTempInternal_k_x10 / 10) * (9 / 5) - 459.67).toFixed(2).toString() +
							" (F) - External: " +
							((BatteryTempExternal_k_x10 / 10) * (9 / 5) - 459.67).toFixed(2).toString() +
							" (F)"
						)
					} else {
						return "---"
					}
				},
			},
			{
				id: "motorCurrent",
				label: "Battery Current",
				args: ["MotorCurrent_mA", "MotorPeakCurrent_mA"],
				formatString: (mA, peakmA) => {
					return (mA / 1000).toFixed(2).toString() + " (A) - Peak: " + (peakmA / 1000).toFixed(2).toString() + " (A)"
				},
			},

			{
				id: "panelReadings",
				label: "Panel Readings",
				args: ["PanelVoltage_mV", "PanelCurrent_mA"],
				formatString: (mV, mA) => {
					return (
						"Voltage: " +
						(mV / 1000).toFixed(2).toString() +
						" (V) - Current: " +
						(mA / 1000).toFixed(2).toString() +
						" (A)"
					)
				},
			},
			// TODO: Remove
			{
				id: "versions",
				label: "Version Info",
				args: ["SoftwareID", "HardwareID"],
				formatString: (swId, hwId) => {
					return "Software: v" + swId.toString() + " - Hardware: v" + hwId.toString()
				},
			},
			{
				id: "firmwareVersion",
				label: "Firmware Version",
			},
			{
				id: "hardwareVersion",
				label: "Hardware Version",
			},
		],
		[deviceTypes.GAMECHANGE_GEN2]: [
			{
				id: "uuid",
				label: "Node UUID (rest-api)",
				args: ["uuid"],
				formatString: (uuid) => {
					return uuid?.toString() ?? "unable to reach API"
				},
			},
			{
				id: "lastUpdate",
				label: "Last Update From Master",
				args: ["timestamp", "hoursSinceSuccess"],
				formatString: (time, hoursSinceSuccess) => {
					if (time) {
						const displayTime = formatTimestamp(time, tz)
						if (hoursSinceSuccess > 0) {
							return displayTime + " ( " + hoursSinceSuccess.toString() + " Hours Since Update )"
						} else {
							return displayTime
						}
					} else {
						return "---"
					}
				},
			},
			{
				id: "activeAlarms",
				label: "Active Alarms",
				args: ["alarms"],
				formatString: getAlarmsString(userPrivileges, deviceTypes.GAMECHANGE_GEN2),
			},
			{
				id: "activeFlags",
				label: "Active Flags",
				args: ["flags", "fwNode"],
				formatString: getFlagsString(userPrivileges, deviceTypes.GAMECHANGE_GEN2),
			},
			{
				id: "panelAngle",
				label: "Angle - Panel",
				args: ["anglePanel", "deviation"],
				formatString: (anglePanel, deviation) => {
					if (anglePanel) {
						const displayAngle = Math.abs(anglePanel)
						let displayDirection
						let displayDeviation

						if (anglePanel > 0) {
							displayDirection = " West (Deg) "
						} else {
							displayDirection = " East (Deg) "
						}
						if (deviation === null || isNaN(deviation)) {
							displayDeviation = ""
						} else {
							displayDeviation = "(" + deviation.toString() + " deviation)"
						}
						return displayAngle + displayDirection + displayDeviation
					} else {
						return "---"
					}
				},
			},
			{
				id: "targetAngle",
				label: "Angle - Target",
				args: ["angleTarget"],
				formatString: (anglePanel) => {
					if (anglePanel) {
						const displayAngle = Math.abs(anglePanel)
						let displayDirection

						if (anglePanel > 0) {
							displayDirection = " West (Deg) "
						} else {
							displayDirection = " East (Deg) "
						}
						return displayAngle + displayDirection
					} else {
						return "---"
					}
				},
			},
			{
				id: "angleTableIndex",
				label: "Angle Table Index",
				args: ["angleTableIndex"],
				formatString: (tableIndex) => {
					if (tableIndex === null) return null
					const minutesSinceMidnight = tableIndex + 1
					return `${tableIndex} / ${Math.floor(minutesSinceMidnight / 60)
						.toString()
						.padStart(2, "0")}:${(minutesSinceMidnight % 60).toString().padStart(2, "0")}:00`
				},
			},
			{
				id: "motorCurrent",
				label: "Motor Current",
				args: ["motorCurrentAve", "motorCurrentPeak"],
				formatString: (currentAve_mV, currentPeak_mV) => {
					return `Average: ${currentAve_mV} (A) / Peak: ${currentPeak_mV} (A)`
				},
			},
			{
				id: "voltage",
				label: "Battery Voltage",
				args: ["batteryVoltage"],
				formatString: (voltageON_mV) => {
					if (voltageON_mV === null) return null
					return `${voltageON_mV} (V)`
				},
			},
			{
				id: "chargingCurrent",
				label: "Charging Current",
				args: ["chargeCurrent"],
				formatString: (current_mA) => {
					if (current_mA === null) return null
					return `${current_mA} (A)`
				},
			},
			{
				id: "chargerStatus",
				label: "Charger Status",
				args: ["chargeStatus"],
				formatString: getChargingLabel,
			},
			{
				id: "temperature",
				label: "Temperature",
				args: ["tempMin", "tempMax"],
				formatString: (temperatureLow_C, temperatureHigh_C) => {
					return (
						"Low: " +
						(temperatureLow_C * (9 / 5) + 32).toFixed(1) +
						" (F) / High: " +
						(temperatureHigh_C * (9 / 5) + 32).toFixed(1) +
						" (F)"
					)
				},
			},
			{
				id: "zigbeeInfo",
				label: "Zigbee Check-in Info",
				args: ["checkinRound", "hopCount"],
				formatString: (checkinRound, hopCount) => {
					if (checkinRound === null || hopCount === null) return null
					return `Round ${checkinRound}, ${hopCount} Hops`
				},
			},
		],
		[deviceTypes.GAMECHANGE_GEN3]: [
			{
				id: "uuid",
				label: "Node UUID (rest-api)",
				args: ["uuid"],
				formatString: (uuid) => {
					return uuid?.toString() ?? "unable to reach API"
				},
			},
			{
				id: "hardwareVersion",
				label: "Hardware Version",
				args: ["hardwareVersion"],
				formatString: (hwId) => {
					return "Hardware: v" + hwId
				},
			},
			{
				id: "lastUpdate",
				label: "Last Update From Master",
				args: ["timestamp", "hoursSinceSuccess"],
				formatString: (time, hoursSinceSuccess) => {
					if (time) {
						const displayTime = formatTimestamp(time, tz)
						if (hoursSinceSuccess > 0) {
							return displayTime + " ( " + hoursSinceSuccess.toString() + " Hours Since Update )"
						} else {
							return displayTime
						}
					} else {
						return "---"
					}
				},
			},
			{
				id: "activeAlarms",
				label: "Active Alarms",
				args: ["alarms"],
				formatString: getAlarmsString(userPrivileges, deviceTypes.GAMECHANGE_GEN3),
			},
			{
				id: "activeFlags",
				label: "Active Flags",
				args: ["flags", "fwNode"],
				formatString: getFlagsString(userPrivileges, deviceTypes.GAMECHANGE_GEN3),
			},
			{
				id: "panelAngle",
				label: "Angle - Panel",
				args: ["anglePanel", "deviation"],
				formatString: (anglePanel, deviation) => {
					if (anglePanel) {
						const displayAngle = Math.abs(anglePanel)
						let displayDirection
						let displayDeviation

						if (anglePanel > 0) {
							displayDirection = " West (Deg) "
						} else {
							displayDirection = " East (Deg) "
						}
						if (deviation === null || isNaN(deviation)) {
							displayDeviation = ""
						} else {
							displayDeviation = "(" + deviation.toString() + " deviation)"
						}
						return displayAngle + displayDirection + displayDeviation
					} else {
						return "---"
					}
				},
			},
			{
				id: "targetAngle",
				label: "Angle - Target",
				args: ["angleTarget"],
				formatString: (anglePanel) => {
					if (anglePanel) {
						const displayAngle = Math.abs(anglePanel)
						let displayDirection

						if (anglePanel > 0) {
							displayDirection = " West (Deg) "
						} else {
							displayDirection = " East (Deg) "
						}
						return displayAngle + displayDirection
					} else {
						return "---"
					}
				},
			},
			{
				id: "angleTableIndex",
				label: "Angle Table Index",
				args: ["angleTableIndex"],
				formatString: (tableIndex) => {
					if (tableIndex === null) return null
					const minutesSinceMidnight = tableIndex + 1
					return `${tableIndex} / ${Math.floor(minutesSinceMidnight / 60)
						.toString()
						.padStart(2, "0")}:${(minutesSinceMidnight % 60).toString().padStart(2, "0")}:00`
				},
			},
			{
				id: "motorCurrent",
				label: "Motor Current",
				args: ["motorCurrentAve", "motorCurrentPeak"],
				formatString: (currentAve_mV, currentPeak_mV) => {
					return `Average: ${currentAve_mV} (A) / Peak: ${currentPeak_mV} (A)`
				},
			},
			{
				id: "voltage",
				label: "Battery Voltage",
				args: ["batteryVoltage"],
				formatString: (voltageON_mV) => {
					if (voltageON_mV === null) return null
					return `${voltageON_mV} (V)`
				},
			},
			{
				id: "chargingCurrent",
				label: "Charging Current",
				args: ["chargeCurrent"],
				formatString: (current_mA) => {
					if (current_mA === null) return null
					return `${current_mA} (A)`
				},
			},
			{
				id: "chargerStatus",
				label: "Charger Status",
				args: ["chargeStatus"],
				formatString: getChargingLabel,
			},
			{
				id: "temperature",
				label: "Temperature",
				args: ["tempMin", "tempMax"],
				formatString: (temperatureLow_C, temperatureHigh_C) => {
					return (
						"Low: " +
						(temperatureLow_C * (9 / 5) + 32).toFixed(1) +
						" (F) / High: " +
						(temperatureHigh_C * (9 / 5) + 32).toFixed(1) +
						" (F)"
					)
				},
			},
			{
				id: "zigbeeInfo",
				label: "Zigbee Check-in Info",
				args: ["checkinRound", "hopCount"],
				formatString: (checkinRound, hopCount) => {
					if (checkinRound === null || hopCount === null) return null
					return `Round ${checkinRound}, ${hopCount} Hops`
				},
			},
		],
		[deviceTypes.GAMECHANGE_GEN3_DUAL_PRIMARY]: [
			{
				id: "uuid",
				label: "Node UUID (rest-api)",
				args: ["uuid"],
				formatString: (uuid) => {
					return uuid?.toString() ?? "unable to reach API"
				},
			},
			{
				id: "lastUpdate",
				label: "Last Update From Master",
				args: ["timestamp", "hoursSinceSuccess"],
				formatString: (time, hoursSinceSuccess) => {
					if (time) {
						const displayTime = formatTimestamp(time, tz)
						if (hoursSinceSuccess > 0) {
							return displayTime + " ( " + hoursSinceSuccess.toString() + " Hours Since Update )"
						} else {
							return displayTime
						}
					} else {
						return "---"
					}
				},
			},
			{
				id: "activeAlarms",
				label: "Active Alarms",
				args: ["alarms"],
				formatString: getAlarmsString(userPrivileges, deviceTypes.GAMECHANGE_GEN3),
			},
			{
				id: "activeFlags",
				label: "Active Flags",
				args: ["flags", "fwNode"],
				formatString: getFlagsString(userPrivileges, deviceTypes.GAMECHANGE_GEN3),
			},
			{
				id: "panelAngle",
				label: "Angle - Panel",
				args: ["anglePanel", "deviation"],
				formatString: (anglePanel, deviation) => {
					if (anglePanel) {
						const displayAngle = Math.abs(anglePanel)
						let displayDirection
						let displayDeviation

						if (anglePanel > 0) {
							displayDirection = " West (Deg) "
						} else {
							displayDirection = " East (Deg) "
						}
						if (deviation === null || isNaN(deviation)) {
							displayDeviation = ""
						} else {
							displayDeviation = "(" + deviation.toString() + " deviation)"
						}
						return displayAngle + displayDirection + displayDeviation
					} else {
						return "---"
					}
				},
			},
			{
				id: "targetAngle",
				label: "Angle - Target",
				args: ["angleTarget"],
				formatString: (anglePanel) => {
					if (anglePanel) {
						const displayAngle = Math.abs(anglePanel)
						let displayDirection

						if (anglePanel > 0) {
							displayDirection = " West (Deg) "
						} else {
							displayDirection = " East (Deg) "
						}
						return displayAngle + displayDirection
					} else {
						return "---"
					}
				},
			},
			{
				id: "angleTableIndex",
				label: "Angle Table Index",
				args: ["angleTableIndex"],
				formatString: (tableIndex) => {
					if (tableIndex) {
						const minutesSinceMidnight = tableIndex + 1
						return (
							tableIndex.toString() +
							" / " +
							Math.floor(minutesSinceMidnight / 60)
								.toString()
								.padStart(2, "0") +
							":" +
							(minutesSinceMidnight % 60).toString().padStart(2, "0") +
							":00"
						)
					} else {
						return "---"
					}
				},
			},
			{
				id: "motorCurrent",
				label: "Motor Current",
				args: ["motorCurrentAve", "motorCurrentPeak"],
				formatString: (currentAve_mV, currentPeak_mV) => {
					return `Average: ${currentAve_mV} (A) / Peak: ${currentPeak_mV} (A)`
				},
			},
			{
				id: "voltage",
				label: "Voltage",
				args: ["batteryVoltage", "batteryVoltageChargerOff"],
				formatString: (voltageOFF_mV, voltageON_mV) => {
					return `Charger Off: ${voltageOFF_mV} (V) / Charger On: ${voltageON_mV} (V)`
				},
			},
			{
				id: "chargingCurrent",
				label: "Charging Current",
				args: ["chargeCurrent"],
				formatString: (current_mA) => {
					return `${current_mA} (A)`
				},
			},
			{
				id: "chargerStatus",
				label: "Charger Status",
				args: ["chargeStatus"],
				formatString: getChargingLabel,
			},
			{
				id: "temperature",
				label: "Temperature",
				args: ["tempMin", "tempMax"],
				formatString: (temperatureLow_C, temperatureHigh_C) => {
					return (
						"Low: " +
						(temperatureLow_C * (9 / 5) + 32).toFixed(2) +
						" (F) / High: " +
						(temperatureHigh_C * (9 / 5) + 32).toFixed(2) +
						" (F)"
					)
				},
			},
			{
				id: "zigbeeInfo",
				label: "Zigbee Check-in Info",
				args: ["checkinRound", "hopCount"],
				formatString: (checkinRound, hopCount) => {
					if (checkinRound === undefined) checkinRound = "Unknown"
					if (hopCount === undefined) hopCount = "Unknown"
					return "Round " + checkinRound.toString() + ", " + hopCount.toString() + " Hops"
				},
			},
		],
		[deviceTypes.GAMECHANGE_GEN3_DUAL_AUX]: [
			{
				id: "uuid",
				label: "Node UUID (rest-api)",
				args: ["uuid"],
				formatString: (uuid) => {
					return uuid?.toString() ?? "unable to reach API"
				},
			},
			{
				id: "activeAlarms",
				label: "Active Alarms",
				args: ["auxAlarms"],
				formatString: getAlarmsString(userPrivileges, deviceTypes.GAMECHANGE_GEN3_DUAL_AUX),
			},
			{
				id: "panelAngle",
				label: "Angle - Panel",
				args: ["auxAnglePanel", "deviation"],
				formatString: (anglePanel, deviation) => {
					if (anglePanel) {
						const displayAngle = Math.abs(anglePanel)
						let displayDirection
						let displayDeviation

						if (anglePanel > 0) {
							displayDirection = " West (Deg) "
						} else {
							displayDirection = " East (Deg) "
						}
						if (deviation === null || isNaN(deviation)) {
							displayDeviation = ""
						} else {
							displayDeviation = "(" + deviation.toString() + " deviation)"
						}
						return displayAngle + displayDirection + displayDeviation
					} else {
						return "---"
					}
				},
			},
			{
				id: "motorCurrent",
				label: "Motor Current",
				args: ["auxMotorCurrentAve", "auxMotorCurrentPeak"],
				formatString: (currentAve_mV, currentPeak_mV) => {
					return `Average: ${currentAve_mV} (A) / Peak: ${currentPeak_mV} (A)`
				},
			},
			{
				id: "temperature",
				label: "Temperature",
				args: ["auxTempMin", "auxTempMax"],
				formatString: (temperatureLow_C, temperatureHigh_C) => {
					return (
						"Low: " +
						(temperatureLow_C * (9 / 5) + 32).toFixed(2) +
						" (F) / High: " +
						(temperatureHigh_C * (9 / 5) + 32).toFixed(2) +
						" (F)"
					)
				},
			},
		],
		[deviceTypes.GAMECHANGE_GEN4]: [
			{
				id: "uuid",
				label: "Node UUID (rest-api)",
				args: ["uuid"],
				formatString: (uuid) => {
					return uuid?.toString() ?? "unable to reach API"
				},
			},
			{
				id: "lastUpdate",
				label: "Last Update From Master",
				args: ["timestamp", "hoursSinceSuccess"],
				formatString: (time, hoursSinceSuccess) => {
					if (time) {
						const displayTime = formatTimestamp(time, tz)
						if (hoursSinceSuccess > 0) {
							return displayTime + " ( " + hoursSinceSuccess.toString() + " Hours Since Update )"
						} else {
							return displayTime
						}
					} else {
						return "---"
					}
				},
			},
			{
				id: "activeAlarms",
				label: "Active Alarms",
				args: ["alarms"],
				formatString: getAlarmsString(userPrivileges, deviceTypes.GAMECHANGE_GEN4),
			},
			{
				id: "activeFlags",
				label: "Active Flags",
				args: ["flags", "fwNode"],
				formatString: getFlagsString(userPrivileges, deviceTypes.GAMECHANGE_GEN4),
			},
			{
				id: "panelAngle",
				label: "Angle - Panel",
				args: ["anglePanel", "deviation"],
				formatString: (anglePanel, deviation) => {
					if (anglePanel) {
						const displayAngle = Math.abs(anglePanel)
						let displayDirection
						let displayDeviation

						if (anglePanel > 0) {
							displayDirection = " West (Deg) "
						} else {
							displayDirection = " East (Deg) "
						}
						if (deviation === null || isNaN(deviation)) {
							displayDeviation = ""
						} else {
							displayDeviation = "(" + deviation.toString() + " deviation)"
						}
						return displayAngle + displayDirection + displayDeviation
					} else {
						return "---"
					}
				},
			},
			{
				id: "targetAngle",
				label: "Angle - Target",
				args: ["angleTarget"],
				formatString: (anglePanel) => {
					if (anglePanel) {
						const displayAngle = Math.abs(anglePanel)
						let displayDirection

						if (anglePanel > 0) {
							displayDirection = " West (Deg) "
						} else {
							displayDirection = " East (Deg) "
						}
						return displayAngle + displayDirection
					} else {
						return "---"
					}
				},
			},
			{
				id: "angleTableIndex",
				label: "Angle Table Index",
				args: ["angleTableIndex"],
				formatString: (tableIndex) => {
					if (tableIndex) {
						const minutesSinceMidnight = tableIndex + 1
						return (
							tableIndex.toString() +
							" / " +
							Math.floor(minutesSinceMidnight / 60)
								.toString()
								.padStart(2, "0") +
							":" +
							(minutesSinceMidnight % 60).toString().padStart(2, "0") +
							":00"
						)
					} else {
						return "---"
					}
				},
			},
			{
				id: "motorCurrent",
				label: "Motor Current",
				args: ["motorCurrentAve", "motorCurrentPeak"],
				formatString: (currentAve_mV, currentPeak_mV) => {
					return `Average: ${currentAve_mV} (A) / Peak: ${currentPeak_mV} (A)`
				},
			},
			{
				id: "voltage",
				label: "Voltage",
				args: ["batteryVoltage", "batteryVoltageChargerOff"],
				formatString: (voltageOFF_mV, voltageON_mV) => {
					return `Charger Off: ${voltageOFF_mV} (V) / Charger On: ${voltageON_mV} (V)`
				},
			},
			{
				id: "chargingCurrent",
				label: "Charging Current",
				args: ["chargeCurrent"],
				formatString: (current_mA) => {
					return `${current_mA} (A)`
				},
			},
			{
				id: "chargerStatus",
				label: "Charger Status",
				args: ["chargeStatus"],
				formatString: getChargingLabel,
			},
			{
				id: "temperature",
				label: "Temperature",
				args: ["tempMin", "tempMax"],
				formatString: (temperatureLow_C, temperatureHigh_C) => {
					return (
						"Low: " +
						(temperatureLow_C * (9 / 5) + 32).toFixed(2) +
						" (F) / High: " +
						(temperatureHigh_C * (9 / 5) + 32).toFixed(2) +
						" (F)"
					)
				},
			},
			{
				id: "zigbeeInfo",
				label: "Zigbee Check-in Info",
				args: ["checkinRound", "hopCount"],
				formatString: (checkinRound, hopCount) => {
					if (checkinRound === undefined) checkinRound = "Unknown"
					if (hopCount === undefined) hopCount = "Unknown"
					return "Round " + checkinRound.toString() + ", " + hopCount.toString() + " Hops"
				},
			},
		],
	}
	return NodeDataFormats[deviceType]
}

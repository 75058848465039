import React from "react"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import PropTypes from "prop-types"

const TaskComment = ({ value, setTaskInfo }) => {
	const handleChange = (event) => {
		const { value } = event.target
		setTaskInfo({ comment: value })
	}

	return (
		<div>
			<p>Record issues and relevant information.</p>
			<TextareaAutosize id="text-area" minRows={3} label={"Comment"} value={value || ""} onChange={handleChange} />
		</div>
	)
}

TaskComment.propTypes = {
	value: PropTypes.any,
	setTaskInfo: PropTypes.func.isRequired,
}

export default TaskComment

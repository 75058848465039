import React, { useState, useEffect, useCallback } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import GraphSelector from "./GraphSelector"

import Grid from "@material-ui/core/Grid"
import { KeyboardDatePicker } from "@material-ui/pickers"

import set from "date-fns/set"
import getUnixTime from "date-fns/getUnixTime"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
	contentText: {
		margin: theme.spacing(1),
	},
}))
const MS_IN_DAY = 86400000

const OptionSelector = ({ chartOptionsOpen, setChartOptionsOpen, graphOptions, setGraphOptions }) => {
	const classes = useStyles()
	const [selected, setSelected] = useState(graphOptions.graphTypes)
	const [startDate, setStartDate] = useState(new Date(graphOptions.dateRange[0] * 1000))
	const [endDate, setEndDate] = useState(new Date(graphOptions.dateRange[1] * 1000))
	const [startDateError, setStartDateError] = useState("")
	const [endDateError, setEndDateError] = useState("")
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

	useEffect(() => {
		let start = new Date(startDate),
			end = new Date(endDate)

		const helperText = () => {
			if (start.toString() === "Invalid Date") {
				return "Invalid Date"
			} else if (start.getTime() > end.getTime()) {
				return "Cannot be after end date"
			} else if (end - start > MS_IN_DAY * 14) {
				return "Exceeds 14 days prior to end date"
			} else return ""
		}
		setStartDateError(helperText())

		if (end.toString() === "Invalid Date") {
			setEndDateError("Invalid Date")
		} else setEndDateError("")
	}, [startDate, endDate, startDateError, endDateError])

	const handleClose = () => {
		setChartOptionsOpen(false)
		setStartDate(new Date(graphOptions.dateRange[0] * 1000))
		setEndDate(new Date(graphOptions.dateRange[1] * 1000))
	}

	const handleAccept = useCallback(() => {
		setGraphOptions({
			graphTypes: selected,
			dateRange: [
				getUnixTime(
					set(startDate, {
						hours: 0,
						minutes: 0,
						seconds: 0,
						milliseconds: 0,
					}),
				),
				getUnixTime(
					set(endDate, {
						hours: 23,
						minutes: 59,
						seconds: 59,
						milliseconds: 0,
					}),
				),
			],
		})
		setChartOptionsOpen(false)
	}, [endDate, selected, setChartOptionsOpen, setGraphOptions, startDate])

	return (
		<Dialog
			fullScreen={fullScreen}
			open={chartOptionsOpen}
			onClose={handleClose}
			aria-labelledby="option-selector-title"
		>
			<DialogContent>
				<DialogContentText className={classes.contentText}>Select Graph Data</DialogContentText>
				<GraphSelector selected={selected} setSelected={setSelected} />
				<DialogContentText>Select Date Range</DialogContentText>
				<Grid container justifyContent="space-around" spacing={2}>
					<Grid item>
						<KeyboardDatePicker
							disableToolbar
							disableFuture
							autoOk
							variant="inline"
							format="yyyy-MM-dd"
							margin="normal"
							id="start-date-picker"
							label="Start Date"
							value={startDate}
							onChange={setStartDate}
							error={!!startDateError}
							helperText={startDateError}
							inputVariant="outlined"
							KeyboardButtonProps={{
								"aria-label": "change start date",
							}}
						/>
					</Grid>
					<Grid item>
						<KeyboardDatePicker
							disableToolbar
							disableFuture
							autoOk
							variant="inline"
							format="yyyy-MM-dd"
							margin="normal"
							id="end=date-picker"
							label="End Date"
							value={endDate}
							onChange={setEndDate}
							error={!!endDateError}
							helperText={endDateError}
							inputVariant="outlined"
							KeyboardButtonProps={{
								"aria-label": "change end date",
							}}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Close
				</Button>
				<Button
					onClick={handleAccept}
					variant="contained"
					color="primary"
					disabled={!!startDateError || !!endDateError || (!startDate && !endDate)}
				>
					Apply
				</Button>
			</DialogActions>
		</Dialog>
	)
}

OptionSelector.propTypes = {
	chartOptionsOpen: PropTypes.bool,
	setChartOptionsOpen: PropTypes.func,
	graphOptions: PropTypes.object,
	setGraphOptions: PropTypes.func,
}

export default OptionSelector

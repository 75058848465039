import React, { createRef, memo, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { ImageOverlay, Map } from "react-leaflet"
import leaflet from "leaflet"
import "leaflet/dist/leaflet.css"

import LayoutOverlay from "./LayoutOverlay"
import NodeMarkers from "./NodeMarkers"
import NodeLabels from "./NodeLabels"
import MasterAreas from "./MasterAreas"
import MasterMarkers from "./MasterMarkers"
import NodePopups from "./NodePopups"
import MasterPopups from "./MasterPopups"
import ReactResizeDetector from "react-resize-detector"
import { privileges } from "constants/auth"
import { useAuthorization } from "utils/auth"
import { focusNodes } from "constants/dataView"
import { ZendeskAPI } from "react-zendesk"
import ZigbeeNetwork from "./ZigbeeNetwork"
import { fetchLayoutDetailBySiteUuid } from "../../store/actions/fetchers"

const bounds = [
	[-65, -50],
	[1065, 1050],
]

const maxBounds = [
	[-1000, -1000],
	[2000, 2000],
]

const center = [500, 500]

const imageOverlayBounds = [
	[0, 0],
	[1000, 1000],
]

const styles = {
	root: {
		position: "relative",
		height: "100%",
		width: "100%",
	},
	overlay: {
		position: "absolute",
	},
	map: {
		position: "absolute",
		height: "100%",
		width: "100%",
	},
	fade: {
		animation: "$fadein .5s",
	},
	"@keyframes fadein": {
		from: { opacity: 0 },
		to: { opacity: 1 },
	},
}

const LayoutMap = memo(
	({
		classes,
		fetchMasterDetails,
		fetchNodeDetails,
		setLayoutZoomLevel,
		layoutDetails,
		layoutUrl,
		fetchMasterDataByLayoutId,
		userPrivileges,
		masterDetails,
		dataView,
		timestamp,
	}) => {
		const mapRef = createRef()
		const accessAllowed = useAuthorization(userPrivileges)

		const layoutId = layoutDetails.id
		const deviceType = (masterDetails[((layoutDetails || {}).mLocIds || {})[0]] || {})["deviceType"]

		useEffect(() => {
			ZendeskAPI("webWidget", "hide")
		}, [])

		useEffect(() => {
			if (layoutId) {
				fetchMasterDetails(layoutId)
			}
		}, [layoutId, fetchMasterDetails])

		useEffect(() => {
			if (layoutId && masterDetails) {
				fetchNodeDetails(layoutId, masterDetails)
			}
		}, [layoutId, fetchNodeDetails, masterDetails])

		useEffect(() => {
			if (layoutId && deviceType && accessAllowed(privileges.DATA_SNAPSHOT)) {
				fetchMasterDataByLayoutId(layoutId, userPrivileges, deviceType, timestamp)
			}
		}, [layoutId, fetchMasterDataByLayoutId, accessAllowed, masterDetails, userPrivileges, deviceType, timestamp])

		const onResize = useCallback(() => {
			if (mapRef.current && mapRef.current.leafletElement) {
				mapRef.current.leafletElement.invalidateSize(true)
			}
		}, [mapRef])

		useEffect(() => {
			if (layoutId && !layoutDetails.layoutUrl) {
				fetchLayoutDetailBySiteUuid(layoutId)
			}
		})

		// TODO: Keeping track of this seems needlessly complicated. I removed it, and everything felt fine.
		//  Re-added it to keep the changes minimal (just trying to fix problems here), but I don't think we need to remember
		//  zoom levels.
		const handleZoomEnd = useCallback(
			(e) => {
				setLayoutZoomLevel(e.target.getZoom())
			},
			[setLayoutZoomLevel],
		)

		return (
			<div className={classes.root}>
				<ReactResizeDetector handleWidth handleHeight onResize={onResize} />
				<LayoutOverlay className={classes.overlay} />
				<Map
					ref={mapRef}
					className={classes.map}
					crs={leaflet.CRS.Simple}
					zoomControl={false}
					doubleClickZoom={true}
					attributionControl={false}
					onZoomEnd={handleZoomEnd}
					keyboard={false}
					bounds={bounds}
					maxBounds={maxBounds}
					center={center}
					zoom={0}
					minZoom={-2}
					zoomSnap={0.1}
				>
					<ImageOverlay className={classes.fade} key={layoutUrl} url={layoutUrl} bounds={imageOverlayBounds} />
					<NodePopups className={classes.fade} layoutId={layoutId} />
					{focusNodes.includes(dataView) && <NodeMarkers className={classes.fade} layoutId={layoutId} />}
					<NodeLabels className={classes.fade} layoutId={layoutId} />
					{/* TODO: These className props are not supported in these components. Remove? */}
					<MasterPopups className={classes.fade} layoutId={layoutId} />
					<MasterMarkers className={classes.fade} layoutId={layoutId} />
					<MasterAreas className={classes.fade} layoutId={layoutId} />
					<ZigbeeNetwork layoutId={layoutId} />
				</Map>
			</div>
		)
	},
)

LayoutMap.displayName = "LayoutMap"

LayoutMap.propTypes = {
	classes: PropTypes.object.isRequired,
	layoutId: PropTypes.string.isRequired,
	layoutUrl: PropTypes.string.isRequired,
	fetchMasterDetails: PropTypes.func,
	fetchNodeDetails: PropTypes.func,
	setLayoutZoomLevel: PropTypes.func.isRequired,
	layoutDetails: PropTypes.any,
	fetchMasterDataByLayoutId: PropTypes.func,
	userPrivileges: PropTypes.any,
	masterDetails: PropTypes.any,
	dataView: PropTypes.any,
	timestamp: PropTypes.any,
}

export default withStyles(styles)(LayoutMap)

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import Graphs from "./Graphs"

const getActiveLocationIds = (props) => {
	const activeLocationIds = []
	const params = new URLSearchParams(props.location.search)
	let locIds = params.get("locIds")
	if (!locIds) return []
	locIds = locIds.split(",")
	locIds.forEach((locId) => {
		const [mLocId, nLocId] = locId.split(":")
		const nodeLocationIds = (nLocId && nLocId.split(";")) || []
		nodeLocationIds.forEach((nLocId) => {
			activeLocationIds.push(mLocId + ":" + nLocId)
		})
	})
	return activeLocationIds
}

const getMLocIdsAndNames = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	let activeIds = params.get("locIds")
	const mLocIds = []
	const mNames = []
	if (activeIds !== null) {
		activeIds.split(",").forEach((ids) => {
			let [mLocId] = ids.split(":")
			const masterName = (state.masterDetails[mLocId] || {}).name || ""
			mLocIds.push(mLocId)
			mNames.push(masterName)
		})
	}
	return {
		mLocIds,
		mNames,
	}
}

const reverseLookupLabel = (state, props) => (label) => {
	let [nodeLabel, masterLabel] = label.split(" ( ")
	if (!masterLabel) return null
	nodeLabel = nodeLabel.replace("0x", "")
	masterLabel = masterLabel.replace(" )", "")
	const params = new URLSearchParams(props.location.search)
	let activeIds = params.get("locIds")
	const mLocIds = activeIds.split(",").map((ids) => {
		let [mLocId] = ids.split(";")
		return mLocId
	})
	let mLocId = null
	for (let i = 0; i < mLocIds.length; i++) {
		mLocId = mLocIds[i]
		if ((state.masterDetails[mLocId] || {}).name === masterLabel) break
	}
	if (!mLocId) return null
	const nLocIds = Object.keys(state.nodeDetails[mLocId] || {})
	let nLocId = null
	for (let i = 0; i < nLocIds.length; i++) {
		nLocId = nLocIds[i]
		if (state.nodeDetails[mLocId][nLocId].nId === nodeLabel) break
	}
	if (!nLocId || !mLocId) return null
	return {
		nLocId,
		mLocId,
	}
}

const setLayoutTimestamp = (state, props) => (timestamp) => {
	const params = new URLSearchParams(props.location.search)
	if (timestamp) {
		params.set("ts", timestamp)
	}
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const mapStateToProps = (state, props) => ({
	layoutName: (state.layoutDetails[props.match.params.layoutId] || {})["name"],
	layoutId: props.match.params.layoutId,
	tz: (state.layoutDetails[props.match.params.layoutId] || {})["tz"],
	reverseLookupLabel: (label) => reverseLookupLabel(state, props)(label),
	...getMLocIdsAndNames(state, props),
	setLayoutTimestamp: (timestamp) => setLayoutTimestamp(state, props)(timestamp),
	activeLocationIds: getActiveLocationIds(props),
	masterDetails: state.masterDetails,
	nodeDetails: state.nodeDetails,
})

const mapDispatchToProps = (state, dispatch) => ({})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Graphs)

import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ListAltIcon from "@material-ui/icons/ListAlt"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import Typography from "@material-ui/core/Typography"
import { Link as RouterLink } from "react-router-dom"
import { privileges } from "constants/auth"
import { useAuthorization } from "utils/auth"

import SignalIcon from "@material-ui/icons/SignalCellularConnectedNoInternet0Bar"

import logo from "images/logo-dark.png"

class ListItemLink extends React.Component {
	// eslint-disable-next-line react/display-name
	renderLink = React.forwardRef((itemProps, ref) => (
		// with react-router-dom@^5.0.0 use `ref` instead of `innerRef`
		<RouterLink to={this.props.to} {...itemProps} innerRef={ref} />
	))

	render() {
		const { icon, primary } = this.props
		return (
			<li>
				<ListItem button component={this.renderLink}>
					<ListItemIcon>{icon}</ListItemIcon>
					<ListItemText primary={primary} />
				</ListItem>
			</li>
		)
	}
}

ListItemLink.propTypes = {
	icon: PropTypes.node.isRequired,
	primary: PropTypes.node.isRequired,
	to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
}

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		zIndex: "999999 !important",
	},
	logo: {
		width: 230,
		height: "auto",
		margin: theme.spacing(0.5, 1),
	},
	version: {
		margin: theme.spacing(2),
		position: "absolute",
		bottom: 0,
		left: 0,
		right: 0,
	},
	offlineStatus: {
		margin: theme.spacing(2),
		position: "absolute",
		bottom: 30,
		left: 0,
		right: 0,
		display: "flex",
	},
	offlineIcon: {
		marginLeft: "auto",
	},
	offlineText: {
		marginRight: "auto",
		marginLeft: "5px",
	},
}))

const TemporaryDrawer = ({ isOpen, setIsOpen, signOut, isOffline, userPrivileges }) => {
	const classes = useStyles()
	const accessAllowed = useAuthorization(userPrivileges)

	const toggleDrawer = (open) => (event) => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return
		}

		setIsOpen(open)
	}

	const buildDate = process.env.REACT_APP_BUILD_DATE || "Unknown 123"

	const sideList = (
		<div role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
			<List component="nav">
				<ListItemLink to={{ pathname: "/layouts", search: "?view=list" }} primary="Layouts" icon={<ListAltIcon />} />
				{accessAllowed(privileges.ADMIN) && (
					<ListItemLink to={{ pathname: "/users-list" }} primary="Users" icon={<ListAltIcon />} />
				)}
			</List>
			<Divider />
			<List>
				<ListItem button onClick={signOut}>
					<ListItemIcon>
						<AccountCircleIcon />
					</ListItemIcon>
					<ListItemText primary="Logout" />
				</ListItem>
			</List>
			<Typography className={classes.version} align="center" color="textSecondary" variant="body2">
				{/* NOTE: It's technically the build date, but "release" is more understandable to users */}
				Release date: {buildDate}
			</Typography>
			{isOffline && (
				<div className={classes.offlineStatus}>
					<SignalIcon className={classes.offlineIcon} />
					<Typography align="center" color="textSecondary" variant="body1" className={classes.offlineText}>
						- Offline
					</Typography>
				</div>
			)}
		</div>
	)

	return (
		<Drawer open={isOpen} onClose={toggleDrawer(false)} className={classes.root}>
			<img src={logo} alt="Logo" className={classes.logo} />
			<Divider />
			{sideList}
		</Drawer>
	)
}

TemporaryDrawer.propTypes = {
	isOpen: PropTypes.any,
	setIsOpen: PropTypes.func,
	signOut: PropTypes.func,
	isOffline: PropTypes.any,
	userPrivileges: PropTypes.any,
}

export default TemporaryDrawer

import React from "react"

const TaskSummary = ({ taskInfo, requiredAttributes }) => {
	let items = Object.keys(requiredAttributes).map((item) => {
		if (taskInfo[item] && taskInfo[item].length !== 0) {
			if (item === "commandQueue") {
				return (
					<p key={item}>
						{requiredAttributes[item]} : {`${taskInfo[item]},`}
					</p>
				)
			} else {
				return (
					<p key={item}>
						{requiredAttributes[item]} : {taskInfo[item]}
					</p>
				)
			}
		} else {
			return null
		}
	})
	return <div>{items}</div>
}

export default TaskSummary

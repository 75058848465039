import InputDropdown from "./InputDropdown"
import React from "react"
import PropTypes from "prop-types"
import InputQuestion from "./InputQuestion"

const BoardMiscForm = ({ taskInfo, setTaskInfo, generation, buildDate }) => {
	return (
		<div>
			<div>
				<p>What generation is this site?</p>
				<InputDropdown
					options={["Generation 2", "Generation 3"]}
					taskInfo={taskInfo}
					value={generation}
					setTaskInfo={setTaskInfo}
					name={"generation"}
					label={"Generation"}
				/>
			</div>
			<div>
				<p>What is the build date?</p>
				<InputQuestion
					taskInfo={taskInfo}
					value={buildDate}
					setTaskInfo={setTaskInfo}
					name={"buildDate"}
					label={"Build date"}
					isDate={true}
				/>
			</div>
		</div>
	)
}

BoardMiscForm.propTypes = {
	setTaskInfo: PropTypes.func.isRequired,
	taskInfo: PropTypes.object.isRequired,
	generation: PropTypes.string,
	// PropTypes.instanceOf(Date) would reject string inputs from a datepicker
	buildDate: PropTypes.string,
}

export default BoardMiscForm

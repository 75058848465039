import React, { useEffect, useState, useCallback } from "react"
import { makeStyles } from "@material-ui/core/styles"
import GraphGrid from "../GraphGrid"
import ReactDygraphs from "../ReactDygraphs"
import useGraphSync from "../ReactDygraphs/utils/synchronizer"
import { graphDetails, graphSeriesDetails } from "constants/graphs"
import CircularProgress from "@material-ui/core/CircularProgress"
import Fab from "@material-ui/core/Fab"
import RefreshIcon from "@material-ui/icons/Refresh"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import DownloadIcon from "@material-ui/icons/SaveAlt"
import CloseIcon from "@material-ui/icons/Close"
import Divider from "@material-ui/core/Divider"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import { useEscapeDeselect } from "utils/custom-hooks"

import API, { graphqlOperation } from "@aws-amplify/api"
import {
	getGraphsData as getGraphsDataGql,
	listNodeIdHistoriesByNLocIdAndTimestamp as listNodeIdHistoriesByNLocIdAndTimestampGql,
	getNodeIdHistoryLessThanTimestamp as getNodeIdHistoryLessThanTimestampGql,
} from "graphql/queries"

import { useLocation, useHistory } from "react-router-dom"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

import format from "date-fns/format"
import OptionSelector from "./OptionSelector"

import sub from "date-fns/sub"
import set from "date-fns/set"
import getUnixTime from "date-fns/getUnixTime"

import { formatTimestamp } from "utils/formatters"

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		height: "100%",
		position: "relative",
	},
	graphDiv: {
		flex: 1,
		position: "relative",
	},
	status: {
		position: "absolute",
		margin: "auto",
		top: "38%",
		bottom: 0,
		right: 0,
		left: 0,
		zIndex: 1000,
		textAlign: "center",
		pointerEvents: "none",
	},
	inlineDate: {
		display: "inline-block",
	},
	loading: {
		position: "absolute",
		margin: "auto",
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		zIndex: 1000,
	},
	options: {
		position: "absolute",
		zIndex: 1000,
		top: theme.spacing(5),
		right: theme.spacing(2),
	},
	refresh: {
		position: "absolute",
		zIndex: 1000,
		top: theme.spacing(11),
		right: theme.spacing(2),
	},
	clearSelection: {
		position: "absolute",
		zIndex: 1000,
		top: theme.spacing(17),
		right: theme.spacing(2),
	},
	popup: {
		position: "absolute",
		zIndex: 1005,
	},
	downloadButton: {
		position: "absolute",
		zIndex: 900,
		bottom: "5px",
		left: "5px",
	},
}))

const Graphs = ({
	mLocIds,
	mNames,
	reverseLookupLabel,
	setLayoutTimestamp,
	layoutName,
	tz,
	layoutId,
	activeLocationIds,
	masterDetails,
	nodeDetails,
}) => {
	const classes = useStyles()
	useEscapeDeselect()
	const { syncSelection, setSyncSelection, syncXAxisRange, handleHighlight, handleUnhighlight, handleDraw } =
		useGraphSync({ selection: true, zoom: true })
	let { search, pathname } = useLocation()
	let history = useHistory()
	const [menuElements, setMenuElements] = React.useState(null)
	const [isLoading, setIsLoading] = useState(false)
	// const [graphOptions, setGraphOptions] = useState({
	//   graphTypes: ['nodeAnglePanel', 'nodeMotorCurrentPeak', 'nodeBatteryVoltage'],
	//   dateRange: [
	//     1578891600,
	//     1579582799
	//   ]
	// })
	const [graphOptions, setGraphOptions] = useState({
		graphTypes: ["nodeAnglePanel", "nodeMotorCurrentPeak", "nodeBatteryVoltage"],
		dateRange: [
			getUnixTime(
				set(sub(new Date(), { days: 2 }), {
					hours: 0,
					minutes: 0,
					seconds: 0,
					milliseconds: 0,
				}),
			),
			getUnixTime(new Date()),
		],
	})
	const [chartOptionsOpen, setChartOptionsOpen] = useState(false)
	const [graphInfo, setGraphInfo] = useState({})
	const graphTypes = graphOptions.graphTypes
	const [dataId, setDataId] = useState(0)
	const [startDate, endDate] = graphOptions.dateRange
	const mLocIdsString = mLocIds.join(",")
	const mNamesString = mNames.join(",")
	const [selectedLabels, setSelectedLabels] = useState([])
	const joinedSelectedLabels = selectedLabels.join(",")

	const getSelectedLabels = () => {
		const params = new URLSearchParams(search)
		let activeIds = params.get("locIds")
		const labels = []
		if (activeIds !== null) {
			activeIds.split(",").forEach((ids) => {
				let [mLocId, nodeLocationIds] = ids.split(":")
				const masterName = (masterDetails[mLocId] || {}).name || ""
				if (nodeLocationIds) {
					nodeLocationIds.split(";").forEach((nLocId) => {
						const nId = ((nodeDetails[mLocId] || {})[nLocId] || {}).nId || ""
						labels.push(`0x${nId} ( ${masterName} )`)
					})
				}
			})
		}
		return labels
	}

	useEffect(() => {
		const getNIdHistory = async () => {
			let labels = getSelectedLabels()
			if (!activeLocationIds.length) {
				setSelectedLabels(labels)
			}
			for (let ids of activeLocationIds) {
				let [mLocId, nodeLocationIds] = ids.split(":")
				if (nodeLocationIds) {
					for (let nLocId of nodeLocationIds.split(";")) {
						let result
						let result1
						try {
							result = await API.graphql(
								graphqlOperation(listNodeIdHistoriesByNLocIdAndTimestampGql, {
									layoutId,
									mLocId,
									nLocId,
									startTimestamp: startDate,
									endTimestamp: endDate,
								}),
							)
							result = result.data

							result1 = await API.graphql(
								graphqlOperation(getNodeIdHistoryLessThanTimestampGql, {
									layoutId,
									mLocId,
									nLocId,
									startTimestamp: startDate,
								}),
							)
							result1 = result1.data
						} catch (err) {
							console.log("FETCH NODE HISTORY DATA ERROR", err)
							setSelectedLabels(labels)
						}
						if (result && result1) {
							const { listNodeIdHistoriesByNLocIdAndTimestamp } = result
							const { getNodeIdHistoryLessThanTimestamp } = result1
							if (
								listNodeIdHistoriesByNLocIdAndTimestamp &&
								getNodeIdHistoryLessThanTimestamp &&
								listNodeIdHistoriesByNLocIdAndTimestamp.items.length
							) {
								let entries = listNodeIdHistoriesByNLocIdAndTimestamp.items
								let entry = getNodeIdHistoryLessThanTimestamp.items[0]
								entries.push(entry)
								let ids = labels
								entries.forEach((entry) => {
									if (!ids.includes(`0x${entry.nId.slice(-4)} ( ${entry.masterName} )`) && entry.nLocId === nLocId) {
										ids.push(`0x${entry.nId.slice(-4)} ( ${entry.masterName} )`)
									}
								})
								setSelectedLabels(ids)
							} else {
								setSelectedLabels(labels)
							}
						}
					}
				}
			}
		}
		getNIdHistory()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeLocationIds, graphInfo])

	useEffect(() => {
		if (selectedLabels.length) {
			console.log(selectedLabels)
			const sel = {
				row: false,
				seriesName: selectedLabels,
				locked: true,
			}
			setSyncSelection(sel)
		} else {
			setSyncSelection(null)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [joinedSelectedLabels, activeLocationIds])

	const fetchData = async () => {
		if (mNamesString === "" || mLocIdsString === "") {
			return null
		}
		const queryInput = {
			graphTypes,
			startDate,
			endDate,
			mLocIds,
			mNames,
		}
		setIsLoading(true)
		setGraphInfo({})
		console.log("FETCH GRAPH DATA", queryInput)
		const start = performance.now()
		let result
		try {
			result = await API.graphql(graphqlOperation(getGraphsDataGql, queryInput))
			result = result.data
		} catch (err) {
			console.log("FETCH GRAPH DATA ERROR", err)
		}
		if (result) {
			const { getGraphsData } = result
			if (getGraphsData) {
				console.log(getGraphsData)
				const { graphTypes: graphTypesReturn, data, timestamps, labelIndexes, labels } = getGraphsData
				if (timestamps.length) {
					const graphData = {}
					graphTypesReturn.forEach((type, typeIndex) => {
						graphData[type] = {
							data: timestamps.map((timestamp, timestampIndex) => [
								new Date(timestamp * 1000),
								...data[typeIndex][timestampIndex],
							]),
							labels: labels[labelIndexes[typeIndex]],
						}
						if (typeof graphSeriesDetails[type] === "function") {
							graphData[type]["series"] = graphSeriesDetails[type](labels[labelIndexes[typeIndex]])
						}
					})
					console.log(graphData)
					setGraphInfo(graphData)
				}
			}
		}
		const end = performance.now()
		console.log(`GRAPH TIME ${(end - start) / 1000} (secs)`)
		setIsLoading(false)

		setDataId(Math.random())

		// currently selected labels are being re-added to selectedLabels temporarily adding duplicate labels to trigger a reset of the graph before being filtered out
		// clearing the selectedLabels and setting the previous labels do not trigger a graph reset
		selectedLabels.forEach((label) => {
			setSelectedLabels((prev) => {
				return [...prev, label]
			})
		})
	}

	useEffect(() => {
		if (mNamesString !== "" && mLocIdsString !== "") {
			fetchData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mLocIdsString, mNamesString, graphTypes, startDate, endDate])

	const clearSelection = useCallback(
		(e) => {
			const params = new URLSearchParams(search)
			let activeIds = params.get("locIds")
			if (!activeIds) return null
			activeIds = activeIds.split(",").map((ids) => {
				let [mLocId] = ids.split(":")
				return mLocId
			})
			params.set("locIds", activeIds.join(","))
			history.push({
				pathname,
				search: `?${params.toString()}`,
			})
		},
		[search, pathname, history],
	)

	const setSelection = useCallback(
		(mLocId, nLocId) => {
			const params = new URLSearchParams(search)
			let activeIds = params.get("locIds")
			if (!activeIds) {
				params.set("locIds", `${mLocId}:${nLocId}`)
			} else {
				const mLocIds = activeIds.split(",").map((ids) => ids.split(":")[0])
				const mLocIdIndex = mLocIds.indexOf(mLocId)
				if (mLocIdIndex > -1) {
					mLocIds[mLocIdIndex] = `${mLocId}:${nLocId}`
				} else {
					mLocIds.push(`${mLocId}:${nLocId}`)
				}
				params.set("locIds", mLocIds.join(","))
			}
			history.push({
				pathname,
				search: `?${params.toString()}`,
			})
		},
		[search, pathname, history],
	)

	const handleGraphClick = (e, x, points, dygraphRef) => {
		setMenuElements({
			position: {
				top: e.clientY,
				left: e.clientX,
			},
			seriesName: dygraphRef.getHighlightSeries(),
			timestamp: x,
		})
	}

	const handleClose = () => {
		setMenuElements((currMenu) => {
			const { position, ...otherElements } = currMenu
			return otherElements
		})
	}

	const handleLabelSelect = (label) => {
		if (label) {
			const ids = reverseLookupLabel(label)
			if (ids) {
				const { mLocId, nLocId } = ids
				setSelection(mLocId, nLocId)
			}
		} else {
			clearSelection()
		}
		handleClose()
	}

	const handleSelectBoth = (label, timestamp) => {
		const params = new URLSearchParams(search)

		if (label) {
			const ids = reverseLookupLabel(label)
			if (ids) {
				const { mLocId, nLocId } = ids
				let activeIds = params.get("locIds")
				if (!activeIds) {
					params.set("locIds", `${mLocId}:${nLocId}`)
				} else {
					const mLocIds = activeIds.split(",").map((ids) => ids.split(":")[0])
					const mLocIdIndex = mLocIds.indexOf(mLocId)
					if (mLocIdIndex > -1) {
						mLocIds[mLocIdIndex] = `${mLocId}:${nLocId}`
					} else {
						mLocIds.push(`${mLocId}:${nLocId}`)
					}
					params.set("locIds", mLocIds.join(","))
				}
			}
		}

		if (timestamp) {
			params.set("ts", timestamp)
		}

		history.push({
			pathname,
			search: `?${params.toString()}`,
		})
	}

	const downloadToCsv = (type) => {
		const data = (graphInfo[type] || {}).data
		const columnHeaders = (graphInfo[type] || {}).labels
		const columnDelimiter = ","
		const lineDelimiter = "\n"
		let filename = `${layoutName}-${graphDetails[type]["title"].replace(" ", "-")}-${mNamesString.replace(
			",",
			"-",
		)}.csv`

		let fileContent = []

		const headerRow = ["data:text/csv;charset=utf-8"].concat(columnHeaders)
		fileContent.push(headerRow.join(columnDelimiter))
		fileContent = fileContent.concat(
			data.map((rowData) =>
				rowData
					.map((cell) => {
						if (cell === null) {
							return ""
						} else {
							return cell.toString()
						}
					})
					.join(columnDelimiter),
			),
		)

		let formattedData = encodeURI(fileContent.join(lineDelimiter))
		let link = document.createElement("a")
		link.setAttribute("href", formattedData)
		link.setAttribute("download", filename)
		link.click()
	}

	return (
		<div className={classes.root}>
			<OptionSelector
				graphOptions={graphOptions}
				setGraphOptions={setGraphOptions}
				chartOptionsOpen={chartOptionsOpen}
				setChartOptionsOpen={setChartOptionsOpen}
			/>
			<div className={classes.popup}>
				<Menu
					id="simple-menu"
					anchorReference="anchorPosition"
					anchorPosition={menuElements && menuElements.position}
					anchorOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					keepMounted
					open={Boolean(menuElements && menuElements.position)}
					onClose={handleClose}
				>
					{menuElements && [
						<MenuItem
							dense={true}
							key={menuElements.seriesName}
							onClick={
								selectedLabels.length > 0
									? () => handleLabelSelect(null)
									: () => handleLabelSelect(menuElements.seriesName)
							}
						>
							{selectedLabels.length > 0 ? (
								<span>Deselect Series</span>
							) : (
								<span>
									Select: <b>{menuElements.seriesName}</b>
								</span>
							)}
						</MenuItem>,
						<MenuItem
							dense={true}
							key={menuElements.timestamp}
							disabled={false}
							onClick={() => {
								setLayoutTimestamp(menuElements.timestamp / 1000)
								handleClose()
							}}
						>
							<span>
								Display: <b>{format(new Date(menuElements.timestamp), "yyyy-MM-dd HH:mm:ss")}</b>
							</span>
						</MenuItem>,
						<Divider key="divider" />,
						<MenuItem
							dense={true}
							key="select both"
							disabled={false}
							onClick={() => {
								if (selectedLabels.length === 0) {
									handleSelectBoth(menuElements.seriesName, menuElements.timestamp / 1000)
								} else {
									setLayoutTimestamp(menuElements.timestamp / 1000)
								}
								handleClose()
							}}
						>
							<span>Select Both</span>
						</MenuItem>,
					]}
				</Menu>
			</div>
			<Fab
				size="small"
				color="primary"
				aria-label="options"
				className={classes.options}
				onClick={() => setChartOptionsOpen(true)}
			>
				<MoreVertIcon />
			</Fab>
			<Fab
				size="small"
				color="primary"
				aria-label="refresh"
				disabled={isLoading}
				className={classes.refresh}
				onClick={fetchData}
			>
				<RefreshIcon />
			</Fab>
			{selectedLabels.length > 0 && (
				<Fab
					size="small"
					color="secondary"
					aria-label="clear-selection"
					className={classes.clearSelection}
					onClick={clearSelection}
				>
					<CloseIcon />
				</Fab>
			)}
			<GraphGrid>
				{graphTypes.map((type) => (
					<div key={type} className={classes.graphDiv}>
						{isLoading && <CircularProgress className={classes.loading} />}
						{!isLoading && !((graphInfo[type] || {}).data || []).length && (
							<div className={classes.status}>
								<Typography variant="h4">No Data</Typography>
								<Typography variant="subtitle1">{"for interval"}</Typography>
								<Typography variant="body1" className={classes.inlineDate}>
									{`${format(new Date(startDate * 1000), "yyyy-MM-dd")}`}
								</Typography>
								<Typography variant="caption">{" to "}</Typography>
								<Typography variant="body1" className={classes.inlineDate}>
									{`${format(new Date(endDate * 1000), "yyyy-MM-dd")}`}
								</Typography>
							</div>
						)}
						<ReactDygraphs
							key={`${(graphInfo[type] || {}).labels}#${type}#${dataId}`}
							data={(graphInfo[type] || {}).data}
							labels={(graphInfo[type] || {}).labels}
							selected={syncSelection}
							dateWindow={syncXAxisRange}
							onHighlight={handleHighlight}
							onUnhighlight={handleUnhighlight}
							onGraphClick={handleGraphClick}
							{...graphDetails[type]}
							series={
								typeof graphSeriesDetails[type] === "function"
									? (graphInfo[type] || {}).series
									: graphSeriesDetails[type]
							}
							axes={{
								x: {
									valueFormatter: function (ms) {
										return formatTimestamp(ms / 1000, tz)
									},
								},
							}}
							onDraw={handleDraw}
						/>
						<div className={classes.downloadButton}>
							<Tooltip title="Download to CSV">
								<div>
									<Fab
										disabled={(graphInfo[type] || {}).data === null}
										size="small"
										color="secondary"
										aria-label="Download to CSV"
										onClick={() => {
											downloadToCsv(type)
										}}
									>
										<DownloadIcon />
									</Fab>
								</div>
							</Tooltip>
						</div>
					</div>
				))}
			</GraphGrid>
		</div>
	)
}

export default Graphs

import { combineReducers } from "redux"
import * as actionTypes from "../actions/types"
import { getAlarmBitmask, getFlagBitmask, getMasterAlarmBitmask, getMasterFlagBitmask } from "constants/statusBits"
// import { findDuplicates } from 'utils'
import { groupsToPrivileges } from "utils/auth"

const user = (state = null, action) => {
	switch (action.type) {
		case actionTypes.RECEIVE_USER_AUTH:
			if (action.payload !== null) {
				const userAttrMap = {
					privileges: groupsToPrivileges(action.payload.groups),
				}
				action.payload.attributes.forEach((attr) => {
					switch (attr.Name) {
						case "sub":
							userAttrMap["id"] = attr.Value
							break
						case "email":
							userAttrMap[attr.Name] = attr.Value
							break
						case "custom:nameFirst":
						case "custom:nameLast":
							userAttrMap[attr.Name.split(":")[1]] = attr.Value
							break
						case "custom:allLayouts":
							userAttrMap[attr.Name.split(":")[1]] = attr.Value === "1"
							break
						case "custom:expiration":
							userAttrMap[attr.Name.split(":")[1]] = parseInt(attr.Value, 10)
							break
						default:
							break
					}
				})
				return userAttrMap
			} else {
				return {}
			}
		default:
			return state
	}
}

const drawerIsOpen = (state = false, action) => {
	switch (action.type) {
		case actionTypes.TOGGLE_DRAWER_IS_OPEN:
			return action.isOpen
		default:
			return state
	}
}

const summaryIsOpen = (state = { small: false, large: true }, action) => {
	switch (action.type) {
		case actionTypes.TOGGLE_SUMMARY_IS_OPEN:
			return { small: action.isOpen, large: action.isOpen }
		default:
			return state
	}
}

const zigbeeNetworkIsVisible = (state = false, action) => {
	switch (action.type) {
		case actionTypes.TOGGLE_ZIGBEE_NETWORK_IS_VISIBLE:
			return action.isVisible
		default:
			return state
	}
}

const dataTableIsOpen = (state = { small: false, large: false }, action) => {
	switch (action.type) {
		case actionTypes.TOGGLE_DATA_TABLE_IS_OPEN:
			return { small: action.isOpen, large: action.isOpen }
		default:
			return state
	}
}

const wizardInfo = (
	state = { isOpen: false, processRunning: false, mLocId: null, nLocId: null, newDeviceId: null, error: null },
	action,
) => {
	switch (action.type) {
		case actionTypes.SET_WIZARD_INFO:
			return { ...state, ...action.info }
		default:
			return state
	}
}

const taskInfo = (state = { issueType: null, activeStep: 0, commandQueue: [], comment: "" }, action) => {
	switch (action.type) {
		case actionTypes.SET_TASK_INFO:
			return { ...state, ...action.info }
		default:
			return state
	}
}

const overlayNIds = (state = false, action) => {
	switch (action.type) {
		case actionTypes.DISPLAY_NODEIDS:
			return action.isVisible
		default:
			return state
	}
}

const overlayNLocIds = (state = false, action) => {
	switch (action.type) {
		case actionTypes.DISPLAY_NODE_LOCATION_IDS:
			return action.isVisible
		default:
			return state
	}
}

const siteView = (state = ["layout"], action) => {
	switch (action.type) {
		case actionTypes.SET_SITE_VIEW:
			return action.view
		default:
			return state
	}
}

const timeLocale = (state = "site", action) => {
	switch (action.type) {
		case actionTypes.SET_TIME_LOCALE:
			return action.timeLocale
		default:
			return state
	}
}

const dataView = (state = "node", action) => {
	switch (action.type) {
		case actionTypes.SET_DATA_VIEW:
			return action.dataView
		default:
			return state
	}
}

const layoutDetails = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.RECEIVE_USER_AUTH:
			if (action.payload === null) return {}
			return state
		case actionTypes.SET_LAYOUT_DETAILS:
			return action.layoutDetails
		case actionTypes.UPDATE_LAYOUT_DETAILS:
			return { ...state, [action.layoutDetails.id]: { ...action.layoutDetails } }
		case actionTypes.REMOVE_LAYOUT_DETAILS: {
			const { [action.layoutId]: _discard, ...newState } = state
			return newState
		}
		case actionTypes.UPDATE_MASTER_DETAILS:
			if (
				state[action.masterDetails.layoutId] &&
				!state[action.masterDetails.layoutId].mLocIds.includes(action.masterDetails.id)
			) {
				return {
					...state,
					[action.masterDetails.layoutId]: {
						...state[action.masterDetails.layoutId],
						mLocIds: [...state[action.masterDetails.layoutId].mLocIds, action.masterDetails.id],
					},
				}
			} else {
				return state
			}
		default:
			return state
	}
}

const layoutDetailsFromCache = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.SET_LAYOUT_DETAILS:
			return action.fromCache
		default:
			return state
	}
}

const masterDetails = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.RECEIVE_USER_AUTH:
			if (action.payload === null) return {}
			return state
		case actionTypes.SET_MASTER_DETAILS:
			return { ...state, ...action.masterDetails }
		case actionTypes.UPDATE_MASTER_DETAILS:
			return { ...state, [action.masterDetails.id]: { ...action.masterDetails } }
		case actionTypes.RECEIVE_MASTER_DETAILS:
			return action.payload.data
		case actionTypes.UPDATE_MASTER_ID:
			return { ...state, [action.mLocId]: { ...state[action.mLocId], mId: action.mId, mIdStatus: action.status } }
		default:
			return state
	}
}

const masterDetailsFromCache = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.SET_MASTER_DETAILS:
			return action.fromCache
		default:
			return state
	}
}

const configLocations = (state = [], action) => {
	switch (action.type) {
		case actionTypes.RESET_CONFIG_STEPPER:
			return []
		case actionTypes.SET_CONFIG_LOCATIONS:
			return action.locations
		default:
			return state
	}
}

const configNodeTranslations = (state = { scale: null, xTrans: null, yTrans: null, markerRadius: 0.5 }, action) => {
	switch (action.type) {
		case actionTypes.RESET_CONFIG_STEPPER:
			return {
				scale: null,
				xTrans: null,
				yTrans: null,
				markerRadius: 0.5,
			}
		case actionTypes.SET_CONFIG_TRANS:
			return {
				scale: action.scale,
				xTrans: action.xTrans,
				yTrans: action.yTrans,
				markerRadius: action.markerRadius,
			}
		default:
			return state
	}
}

const configAssign = (state = null, action) => {
	switch (action.type) {
		case actionTypes.SET_CONFIG_ASSIGN:
			return action.assignment
		default:
			return state
	}
}

const configMasterAreas = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.RESET_CONFIG_STEPPER:
			return {}
		case actionTypes.SET_CONFIG_MASTER_AREA:
			return action.areaEdits
		case actionTypes.UPDATE_CONFIG_MASTER_AREA:
			return { ...state, ...action.areaEdits }
		default:
			return state
	}
}

const configMasterCoordinates = (state = null, action) => {
	switch (action.type) {
		case actionTypes.SET_CONFIG_MASTER_COORDINATES:
			return action.coordParams
		default:
			return state
	}
}

const nodeDetails = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.RECEIVE_USER_AUTH:
			if (action.payload === null) return {}
			return state
		case actionTypes.UPDATE_NODE_DETAILS: {
			const nLocsByMaster = {}
			const bitmask_group_count = 16
			action.nodeDetails.forEach((masterLocation) => {
				if (!masterLocation.nLocs || !masterLocation.nIds) return
				const masterNodeLocations = {}
				for (let i = 0; i < masterLocation.nLocs.length; i++) {
					const { index, ...otherDetails } = masterLocation.nLocs[i]
					masterNodeLocations[index.toString().padStart(3, "0")] = otherDetails || {}
				}
				for (let i = 0; i < masterLocation.nIds.length; i++) {
					const { index, id } = masterLocation.nIds[i] || {}
					if (index !== undefined) {
						const nLocId = index.toString().padStart(3, "0")
						if (!Object.prototype.hasOwnProperty.call(masterNodeLocations, nLocId)) continue
						masterNodeLocations[nLocId]["nId"] = id.padStart(4, "0").slice(-4)
						masterNodeLocations[nLocId]["pendingSync"] =
							((masterLocation.syncBitmasks[Math.floor(index / bitmask_group_count)] || 0) &
								(1 << index % bitmask_group_count)) >
							0
					}
				}
				nLocsByMaster[masterLocation.mLocId] = masterNodeLocations
			})
			return { ...state, ...nLocsByMaster }
		}
		case actionTypes.UPDATE_NODE_ID:
			if (action.status !== "pendingUpload") {
				return {
					...state,
					[action.mLocId]: {
						...(state[action.mLocId] || {}),
						[action.nLocId]: {
							...((state[action.mLocId] || {})[action.nLocId] || {}),
							nId: action.nId.slice(action.nId.length - 4),
							pendingSync: true,
						},
					},
				}
			}
			return state
		case actionTypes.UPDATE_NODE_SYNC_BITMASKS: {
			const updatedMaster = { ...state[action.mLocId] }
			const BITMASK_GROUP_BIT_COUNT = 16
			Object.keys(state[action.mLocId]).forEach((nLocId) => {
				const groupIndex = Math.floor(parseInt(nLocId, 10) / BITMASK_GROUP_BIT_COUNT)
				const indexBit = parseInt(nLocId, 10) % BITMASK_GROUP_BIT_COUNT
				updatedMaster[nLocId]["pendingSync"] = (action.bitmasks[groupIndex] & (1 << indexBit)) > 0
			})
			return {
				...state,
				[action.mLocId]: updatedMaster,
			}
		}
		default:
			return state
	}
}

const nodeDetailsFromCache = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_NODE_DETAILS:
			return action.fromCache || true
		default:
			return state
	}
}

const nodeData = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_LATEST_NODE_DATA: {
			const nData = {}
			for (let i = 0; i < action.data.nodeData.length; i++) {
				let { alarms, flags, timestamp } = action.data.nodeData[i]
				alarms = alarms ? BigInt(`0x${alarms}`) : 0n
				flags = flags ? BigInt(`0x${flags}`) : 0n
				if (timestamp && action.data.timestamp > timestamp + 3600) {
					alarms |= 1n << 31n
				}
				const alarmBitMask = getAlarmBitmask(action.userPrivileges, action.deviceType)
				const flagBitMask = getFlagBitmask(action.userPrivileges, action.deviceType)
				action.data.nodeData[i].alarms = BigInt(BigInt(alarms) & BigInt(alarmBitMask)).toString(16)
				action.data.nodeData[i].flags = BigInt(BigInt(flags) & BigInt(flagBitMask)).toString(16)
				nData[action.data.nodeData[i].nLocId] = action.data.nodeData[i]
			}
			return { ...state, [`${action.data.mLocId}#${action.data.timestamp}`]: nData }
		}
		default:
			return state
	}
}

const nodeDataActiveTimestamp = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_LATEST_NODE_DATA:
			return { ...state, [action.data.mLocId]: action.data.timestamp }
		default:
			return state
	}
}

const masterData = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_LATEST_MASTER_DATA: {
			const newState = { ...state }
			action.data.forEach((mData) => {
				let { alarms, flags } = mData
				alarms = alarms ? BigInt(`0x${alarms}`) : 0n
				flags = flags ? BigInt(`0x${flags}`) : 0n
				const masterAlarmBitMask = getMasterAlarmBitmask(action.userPrivileges, action.deviceType)
				const masterFlagBitMask = getMasterFlagBitmask(action.userPrivileges, action.deviceType)
				mData.alarms = (alarms & masterAlarmBitMask).toString(16)
				mData.flags = (flags & masterFlagBitMask).toString(16)
				newState[`${mData.mLocId}#${mData.timestamp}`] = mData
			})
			return newState
		}
		default:
			return state
	}
}

const replacementCampaignInfo = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.SET_REPLACEMENT_CAMPAIGN_INFO:
			return { ...state, ...action.data }
		case actionTypes.UPDATE_REPLACEMENT_CAMPAIGN_INFO: {
			const newMasterReplacements = { ...state[action.mLocId] }
			newMasterReplacements["replacements"] = { ...newMasterReplacements["replacements"], [action.nLocId]: action.nId }
			return { ...state, [action.mLocId]: newMasterReplacements }
		}
		case actionTypes.DELETE_REPLACEMENT_CAMPAIGN_INFO: {
			const { [action.mLocId]: _discard, ...newMasterReplacements } = state
			return newMasterReplacements
		}
		default:
			return state
	}
}

const masterDataLatestTimestamp = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_LATEST_MASTER_DATA: {
			const newState = { ...state }
			action.data.forEach((mData) => {
				newState[mData.mLocId] = mData.timestamp
			})
			return newState
		}
		default:
			return state
	}
}

const nIdsPendingUpload = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.RECEIVE_USER_AUTH:
			if (action.payload === null) return {}
			return state
		case actionTypes.UPDATE_NODE_ID:
			if (action.status === "pendingUpload") {
				return {
					...state,
					[`${action.mLocId}#${action.nLocId}`]: action.nId.slice(action.nId.length - 4),
				}
			} else {
				const { [`${action.mLocId}#${action.nLocId}`]: _discard, ...otherState } = state
				return otherState
			}
		default:
			return state
	}
}

const nIdDuplicatesByMaster = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.RECEIVE_USER_AUTH:
			if (action.payload === null) return []
			return state
		// case actionTypes.UPDATE_NODE_DETAILS:
		//   const duplicatesByMasterId = {}
		//   const { nLocIdByMLocId, nodeLocations} = action
		//   const mLocIds = Object.keys(nLocIdByMLocId)
		//   for(let i=0; i<mLocIds.length; i++) {
		//     const nIds = nLocIdByMLocId[mLocIds[i]].map(nLocId => nodeLocations[nLocId].nId)
		//     duplicatesByMasterId[mLocIds[i]] = findDuplicates(nIds)
		//   }
		//   return duplicatesByMasterId
		default:
			return state
	}
}

const appInitialized = (state = false, action) => {
	switch (action.type) {
		case actionTypes.APP_INITIALIZE:
			return action.initialized
		case actionTypes.UPDATE_LAYOUT_DETAILS:
			return true
		case actionTypes.RECEIVE_USER_AUTH:
			// if(action.payload === null) return true
			// return state
			return true // TODO: replace with correct auth flow
		default:
			return state
	}
}

const layoutListFilter = (state = "", action) => {
	switch (action.type) {
		case actionTypes.SET_LAYOUT_LIST_FILTER:
			return action.value
		default:
			return state
	}
}

const layoutListSort = (state = { sortBy: "layoutName", sortDirection: "DESC" }, action) => {
	switch (action.type) {
		case actionTypes.SET_LAYOUT_LIST_SORT:
			return { sortBy: action.sortBy, sortDirection: action.sortDirection }
		default:
			return state
	}
}

const layoutZoomLevel = (state = null, action) => {
	switch (action.type) {
		case actionTypes.SET_ZOOM_LEVEL:
			return action.zoomLevel
		default:
			return state
	}
}

const isOffline = (state = false, action) => {
	switch (action.type) {
		case actionTypes.ONLINE_STATUS:
			return action.status === "offline"
		default:
			return state
	}
}

const nodeDataStatus = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.SET_NODE_DATA_STATUS:
			return { ...state, [action.mLocId]: action.status }
		case actionTypes.UPDATE_LATEST_NODE_DATA:
			return { ...state, [action.data.mLocId]: "resolved" }
		default:
			return state
	}
}

const masterDataStatus = (state = "", action) => {
	switch (action.type) {
		case actionTypes.SET_MASTER_DATA_STATUS:
			return action.status
		case actionTypes.UPDATE_LATEST_MASTER_DATA:
			return "resolved"
		default:
			return state
	}
}

const replacementCampaignStatus = (state = "", action) => {
	switch (action.type) {
		case actionTypes.SET_REPLACEMENT_CAMPAIGN_STATUS:
			return action.status
		case actionTypes.SET_REPLACEMENT_CAMPAIGN_INFO:
			return "resolved"
		default:
			return state
	}
}

const mLocIdSecondarySelections = (state = [], action) => {
	switch (action.type) {
		case actionTypes.ADD_MASTER_LABEL:
			if (!action.mLocId) return state
			if (state.includes(action.mLocId)) return state
			return [...state, action.mLocId]
		case actionTypes.REMOVE_MASTER_LABEL:
			return state.filter((mLocId) => mLocId !== action.mLocId)
		case actionTypes.CLEAR_ALL_MASTER_LABELS:
			return []
		default:
			return state
	}
}

const layoutSecondarySelections = (state = [], action) => {
	switch (action.type) {
		case actionTypes.ADD_LAYOUT_ID:
			if (state.includes(action.layoutId)) return state
			return [...state, action.layoutId]
		case actionTypes.REMOVE_LAYOUT_ID: {
			return state.filter((layoutId) => layoutId !== action.layoutId)
		}
		case actionTypes.CLEAR_ALL_LAYOUT_IDS:
			return []
		default:
			return state
	}
}

const commissioningComponentSelection = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.SET_COMMISSIONING_COMPONENT_SELECTION:
			return action.value
		default:
			return state
	}
}

const rememberComponentSelection = (state = false, action) => {
	switch (action.type) {
		case actionTypes.SET_REMEMBER_COMPONENT_SELECTION:
			return action.value
		default:
			return state
	}
}

const nodeComponentScanned = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.SET_NODE_COMPONENTS_SCANNED: {
			if (!action.value.deviceId) return state
			const nodeComponentsScanned = { ...state }
			nodeComponentsScanned[action.value.deviceId] = {
				...nodeComponentsScanned[action.value.deviceId],
				[action.value.name]: action.value,
			}
			return { ...state, ...nodeComponentsScanned }
		}
		default:
			return state
	}
}

const masterComponentScanned = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.SET_MASTER_COMPONENTS_SCANNED: {
			if (!action.value.deviceId) return state
			const masterComponentsScanned = { ...state }
			masterComponentsScanned[action.value.deviceId] = {
				...masterComponentsScanned[action.value.deviceId],
				[action.value.name]: action.value,
			}
			return { ...state, ...masterComponentsScanned }
		}
		default:
			return state
	}
}

export default combineReducers({
	appInitialized,
	drawerIsOpen,
	summaryIsOpen,
	zigbeeNetworkIsVisible,
	dataTableIsOpen,
	wizardInfo,
	taskInfo,
	overlayNIds,
	overlayNLocIds,
	siteView,
	timeLocale,
	dataView,
	user,
	layoutDetails,
	layoutDetailsFromCache,
	masterDetails,
	masterDetailsFromCache,
	masterData,
	masterDataLatestTimestamp,
	replacementCampaignInfo,
	nodeDetails,
	nodeDetailsFromCache,
	nodeData,
	nodeDataActiveTimestamp,
	nIdsPendingUpload,
	layoutListFilter,
	layoutListSort,
	configAssign,
	configLocations,
	configNodeTranslations,
	configMasterAreas,
	configMasterCoordinates,
	nIdDuplicatesByMaster,
	layoutZoomLevel,
	isOffline,
	nodeDataStatus,
	masterDataStatus,
	replacementCampaignStatus,
	mLocIdSecondarySelections,
	layoutSecondarySelections,
	commissioningComponentSelection,
	rememberComponentSelection,
	nodeComponentScanned,
	masterComponentScanned,
})

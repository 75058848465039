import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import MasterMarker from "./MasterMarker"
import { DEFAULT_OVERLAY, overlays } from "constants/overlays"
import { setMasterLabelNotVisible, setMasterLabelVisible } from "store/actions/reduxActions"
import { getMasterLocIds } from "utils/url"
import { focusMasters } from "constants/dataView"

import { colorBrown, colorGrey, colorPrimary, colorRed, colorYellow } from "../../../../colors"

const getMarkerColors = (state, props) => {
	const activeMasters = getMasterLocIds(props.location.search)
	let masterIsActive
	const { dataView } = state
	const mId = getMId(state, props)
	let markerColor, markerFill
	markerColor = markerFill = colorGrey

	if (activeMasters < 1) {
		masterIsActive = true
	} else {
		masterIsActive = activeMasters.includes(props.mLocId)
	}

	const activeOverlay = props.activeOverlay || DEFAULT_OVERLAY

	switch (activeOverlay) {
		case overlays.ALARMS: {
			const timestamp = props.timestamp || state.masterDataLatestTimestamp[props.mLocId]
			if (state.masterDataStatus === "resolved" && focusMasters.includes(dataView)) {
				const masterData = state.masterData[`${props.mLocId}#${timestamp}`] || {}
				let { alarms } = masterData
				alarms = alarms ? BigInt(`0x${alarms}`) : 0n
				let filter = BigInt(`0b${props.filter}`)
				if (alarms !== undefined) {
					if (alarms) {
						if (!masterIsActive || (filter && (alarms & filter) === 0n)) {
							markerColor = markerFill = colorBrown
						} else {
							markerColor = markerFill = colorRed
						}
					} else {
						markerColor = markerFill = colorPrimary
					}
				} else {
					mId ? (markerColor = markerFill = "black") : (markerColor = markerFill = colorGrey)
				}
			} else {
				mId ? (markerColor = markerFill = "black") : (markerColor = markerFill = colorGrey)
			}
			break
		}
		case overlays.FLAGS: {
			const timestamp = props.timestamp || state.masterDataLatestTimestamp[props.mLocId]
			if (state.masterDataStatus === "resolved" && focusMasters.includes(dataView)) {
				const masterData = state.masterData[`${props.mLocId}#${timestamp}`] || {}
				let { flags } = masterData
				flags = flags ? BigInt(`0x${flags}`) : 0n
				let filter = BigInt(`0b${props.filter}`)
				if (flags) {
					if (!masterIsActive || (filter && (flags & filter) === 0n)) {
						markerColor = markerFill = colorBrown
					} else {
						markerColor = markerFill = colorYellow
					}
				} else {
					markerColor = markerFill = colorPrimary
				}
			} else {
				mId ? (markerColor = markerFill = "black") : (markerColor = markerFill = colorGrey)
			}
			break
		}
		default:
			break
	}
	return { markerColor, markerFill }
}

const getCenter = (state, props) => {
	const masterDetails = state.masterDetails[props.mLocId]
	if (!masterDetails || !masterDetails["xLoc"] || !masterDetails["yLoc"]) return []
	return [masterDetails["yLoc"], masterDetails["xLoc"]]
}

const handleClick = (props) => {
	const params = new URLSearchParams(props.location.search)
	if (params.get("overlay") === overlays.DEVICEIDASSIGNMENT) {
		params.set("deviceid-input", [props.layoutId, props.mLocId].join(":"))
	} else {
		params.set("device-details", [props.layoutId, props.mLocId].join(":"))
	}
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const getMId = (state, props) => {
	return (state.masterDetails[props.mLocId] || {}).mId
}

const isSelected = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	let locIds = params.get("locIds")
	let navLocId = params.get("navLocId")
	if (!locIds && !navLocId) return false
	return (
		(locIds &&
			locIds
				.split(",")
				.map((loc) => loc.split(":")[0])
				.includes(props.mLocId)) ||
		(navLocId && navLocId.split(":")[0] === props.mLocId)
	)
}

const getLatestTimeStamp = (state, props) => {
	return state.masterDataLatestTimestamp[props.mLocId]
}

const mapStateToProps = (state, props) => {
	const { markerColor, markerFill } = getMarkerColors(state, props)
	const isDeviceIdAssignment = props.location.search.split("&").includes("overlay=deviceid-assignement")
	const componentsScanned = state.masterComponentScanned[props.mLocId]

	return {
		isDeviceIdAssignment,
		componentsScanned,
		selected: isSelected(state, props),
		center: getCenter(state, props),
		mId: getMId(state, props),
		radius: parseInt(state.layoutDetails[props.layoutId]["markerRadius"]) * 2.5 || 1.5,
		timestamp: getLatestTimeStamp(state, props),
		markerColor,
		markerFill,
	}
}

const mapDispatchToProps = (dispatch, props) => ({
	onClick: () => handleClick(props),
	onMouseOver: () => dispatch(setMasterLabelVisible(props.mLocId)),
	onMouseOut: () => dispatch(setMasterLabelNotVisible(props.mLocId)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MasterMarker)

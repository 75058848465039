import { Checkbox } from "@material-ui/core"
import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import FilteredItemList from "./FilteredItemList"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((_theme) => ({
	outer: {
		backgroundColor: "rgb(250, 250, 250)",
		maxHeight: "300px",
		overflowY: "scroll",
		overflowX: "hidden",
		borderRadius: "5px",
		border: "1px solid #888888",
		padding: "5px",
	},
	item: {
		width: "100%",
		height: "20px",
		marginBottom: "5px",
		textAlign: "left",
		display: "table",
		padding: "5px",
	},
	left: {
		display: "table-cell",
		verticalAlign: "middle",
		width: "20px",
	},
	right: {
		display: "table-cell",
		verticalAlign: "middle",
		paddingLeft: "5px",
		boxSizing: "border-box",
	},
	checkBox: {
		padding: "0",
	},
}))

const SelectMultipleItem = (props) => {
	const { identifier, onChange, checked } = props
	const classes = useStyles()

	return (
		<div className={classes.item}>
			<div className={classes.left}>
				<Checkbox className={classes.checkBox} checked={checked} onChange={onChange} color="primary" />
			</div>
			<div className={classes.right}>{identifier}</div>
		</div>
	)
}

SelectMultipleItem.propTypes = {
	identifier: PropTypes.string,
	onChange: PropTypes.func,
	checked: PropTypes.bool,
}

const SelectMultiple = (props) => {
	// items is an array of arrays. In the inner array, the first element is the label and the second is the value.
	const { value, setValue, items } = props
	const classes = useStyles()
	const [selectAll, setSelectAll] = useState(false)

	const handleSelectAllChange = useCallback(
		(e) => {
			setSelectAll(e.target.checked)
			if (e.target.checked) {
				setValue(items)
			} else {
				setValue([])
			}
		},
		[setValue, setSelectAll, items],
	)

	const handleItemCheckedChange = useCallback(
		(item) => () => {
			if (value.includes(item)) {
				setValue(value.filter((x) => x !== item))
				setSelectAll(false)
			} else {
				setValue([...value, item])
			}
		},
		[value, setValue],
	)

	const components = items.map((item) => {
		return {
			componentType: SelectMultipleItem,
			props: {
				identifier: item,
				checked: value.includes(item),
				onChange: handleItemCheckedChange(item),
			},
			filterKey: item,
			mapKey: item,
		}
	})

	return (
		<div className={classes.outer}>
			<div>
				<div className={classes.item}>
					<div className={classes.left}>
						<Checkbox
							className={classes.checkBox}
							checked={selectAll}
							onChange={handleSelectAllChange}
							color="primary"
						/>
					</div>
					<div className={classes.right}>Select All</div>
				</div>
			</div>
			<FilteredItemList items={components} pluralName={"flags"} />
		</div>
	)
}

SelectMultiple.propTypes = {
	value: PropTypes.array,
	setValue: PropTypes.func,
	items: PropTypes.any,
}

export default SelectMultiple

/* eslint-disable */
/**
 * To create a "drag" interaction, you typically register a mousedown event
 * handler on the element where the drag begins. In that handler, you register a
 * mouseup handler on the window to determine when the mouse is released,
 * wherever that release happens. This works well, except when the user releases
 * the mouse over an off-domain iframe. In that case, the mouseup event is
 * handled by the iframe and never bubbles up to the window handler.
 *
 * To deal with this issue, we cover iframes with high z-index divs to make sure
 * they don't capture mouseup.
 *
 * Usage:
 * element.addEventListener('mousedown', function() {
 *   var tarper = new IFrameTarp();
 *   tarper.cover();
 *   var mouseUpHandler = function() {
 *     ...
 *     window.removeEventListener(mouseUpHandler);
 *     tarper.uncover();
 *   };
 *   window.addEventListener('mouseup', mouseUpHandler);
 * };
 *
 * @constructor
 */
"use strict"

Object.defineProperty(exports, "__esModule", {
	value: true,
})

function _interopRequireWildcard(obj) {
	if (obj && obj.__esModule) {
		return obj
	} else {
		var newObj = {}
		if (obj != null) {
			for (var key in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]
			}
		}
		newObj["default"] = obj
		return newObj
	}
}

var _dygraphUtils = require("./dygraph-utils")

var utils = _interopRequireWildcard(_dygraphUtils)

function IFrameTarp() {
	/** @type {Array.<!HTMLDivElement>} */
	this.tarps = []
}

/**
 * Find all the iframes in the document and cover them with high z-index
 * transparent divs.
 */
IFrameTarp.prototype.cover = function () {
	var iframes = document.getElementsByTagName("iframe")
	for (var i = 0; i < iframes.length; i++) {
		var iframe = iframes[i]
		var pos = utils.findPos(iframe),
			x = pos.x,
			y = pos.y,
			width = iframe.offsetWidth,
			height = iframe.offsetHeight

		var div = document.createElement("div")
		div.style.position = "absolute"
		div.style.left = x + "px"
		div.style.top = y + "px"
		div.style.width = width + "px"
		div.style.height = height + "px"
		div.style.zIndex = 999
		document.body.appendChild(div)
		this.tarps.push(div)
	}
}

/**
 * Remove all the iframe covers. You should call this in a mouseup handler.
 */
IFrameTarp.prototype.uncover = function () {
	for (var i = 0; i < this.tarps.length; i++) {
		this.tarps[i].parentNode.removeChild(this.tarps[i])
	}
	this.tarps = []
}

exports["default"] = IFrameTarp
module.exports = exports["default"]

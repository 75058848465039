import { store, theme } from "./App"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { SnackbarProvider } from "notistack"
import App from "./index"
import { Provider } from "react-redux"
import React from "react"

export const getAppWithProviders = () => {
	return (
		<Provider store={store}>
			<MuiThemeProvider theme={theme}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<SnackbarProvider
						maxSnack={3}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
					>
						<App />
					</SnackbarProvider>
				</MuiPickersUtilsProvider>
			</MuiThemeProvider>
		</Provider>
	)
}

import React, { useState, useEffect, useRef, useMemo } from "react"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Divider from "@material-ui/core/Divider"
import PropTypes from "prop-types"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MasterDetails = ({ masterInfo, componentClass, selected, setSelected }) => {
	const inputLabel = useRef(null)
	const [labelWidth, setLabelWidth] = useState(0)

	useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth)
	}, [])

	const handleSelect = (event) => {
		const selection = event.target.value
		if (selection.includes("NONE")) {
			setSelected([])
		} else if (selection.includes("ALL")) {
			setSelected(Object.keys(masterInfo))
		} else {
			setSelected(selection)
		}
	}

	const getRenderValue = (values) => {
		if (values.length !== 1 && values.length === Object.keys(masterInfo).length) {
			return `All Selected (${values.length})`
		} else if (values.length <= 2) {
			return values.map((mLocId) => masterInfo[mLocId]).join(", ")
		} else {
			return `(${values.length}) Selected`
		}
	}

	const sortedMastersByName = useMemo(() => {
		const mastersArray = Object.keys(masterInfo).map((mLocId) => {
			return { mLocId: mLocId, name: masterInfo[mLocId] }
		})
		return mastersArray.sort((a, b) => {
			return a.name.localeCompare(b.name, "en", { numeric: true })
		})
	}, [masterInfo])

	const sortedMastersByNameLength = sortedMastersByName.length

	return (
		<FormControl variant="outlined" className={componentClass}>
			<InputLabel ref={inputLabel} id="select-master-label">
				Select Master
			</InputLabel>
			<Select
				labelId="select-master-label"
				id="select-master"
				multiple
				value={selected}
				onChange={handleSelect}
				renderValue={getRenderValue}
				labelWidth={labelWidth}
				MenuProps={{
					PaperProps: {
						style: {
							maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
							width: 250,
						},
					},
					getContentAnchorEl: null,
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "center",
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "center",
					},
				}}
			>
				<MenuItem value={selected.length !== sortedMastersByNameLength ? "ALL" : "NONE"}>
					<em>{selected.length !== sortedMastersByNameLength ? "Select All" : "Deselect All"}</em>
				</MenuItem>
				<Divider />
				{sortedMastersByName.map((master) => (
					<MenuItem key={master.mLocId} value={master.mLocId}>
						{master.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

export default MasterDetails

MasterDetails.propTypes = {
	masterInfo: PropTypes.object.isRequired,
	componentClass: PropTypes.string.isRequired,
	selected: PropTypes.array.isRequired,
	setSelected: PropTypes.func.isRequired,
}

import React from "react"
import Fab from "@material-ui/core/Fab"
import CloseIcon from "@material-ui/icons/Close"
import { useUrlParams } from "utils/url"

const ClearNodeSelectionButton = () => {
	const { clearNodeLocIds, getNodeLocIdsByMasterLocId } = useUrlParams()

	const nLocIdsByMasterLocId = getNodeLocIdsByMasterLocId()
	let activeNodeIdCount = 0
	Object.keys(nLocIdsByMasterLocId).forEach((mLocId) => {
		activeNodeIdCount += nLocIdsByMasterLocId[mLocId].length
	})

	if (activeNodeIdCount <= 1) {
		return null
	}

	return (
		<Fab color="secondary" size="small" aria-label="clear node selection" onClick={clearNodeLocIds}>
			<CloseIcon />
		</Fab>
	)
}

export default ClearNodeSelectionButton

import { connect } from "react-redux"
import GeoMap from "./GeoMap"

const mapStateToProps = (state, props) => ({
	layoutIds: Object.keys(state.layoutDetails),
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(GeoMap)

import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
// import ReactCSSTransitionGroup from 'react-transition-group';
import Typography from "@material-ui/core/Typography"
import "./FlashPage.css"

// import './AppLoading.css'

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		position: "fixed",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		textAlign: "center",
		backgroundColor: "white",
		zIndex: 5000,
	},
	version: {
		position: "absolute",
		bottom: theme.spacing(4),
		width: "100%",
		textAlign: "center",
	},
})

// const FlashPage = ({classes, active}) => (
//     <ReactCSSTransitionGroup
//       transitionName="app-loading"
//       transitionLeave={true}
//       transitionEnter={false}
//       transitionLeaveTimeout={300}
//     >
//       {active ? (
//         <div className={classes.root}>
//           <img className="nav-logo-loading loading-animation" key={"nav-logo-loading"} alt="GameChange Logo" src={require('../../images/logo-dark.png')} />
//           <Typography
//             className={classes.version}
//             align='center'
//             color='textSecondary'
//             variant='body1'
//           >
//             {'Version v' + version}
//           </Typography>
//         </div>
//       ) : null}
//     </ReactCSSTransitionGroup>
// )

const FlashPage = ({ classes }) => (
	<div className={classes.root}>
		<img
			className="nav-logo-loading loading-animation"
			key={"nav-logo-loading"}
			alt="GameChange Logo"
			src={require("../../images/logo-dark.png")}
		/>
		<Typography className={classes.version} align="center" color="textSecondary" variant="body1">
			{"Version v" + React.version}
		</Typography>
	</div>
)

FlashPage.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FlashPage)

import React, { useState, Fragment, useRef } from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import Fab from "@material-ui/core/Fab"
import Menu from "@material-ui/core/Menu"
import Icon from "@material-ui/icons/PictureInPicture"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Checkbox from "@material-ui/core/Checkbox"
import IconButton from "@material-ui/core/IconButton"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import CommentIcon from "@material-ui/icons/Comment"
import Divider from "@material-ui/core/Divider"

const styles = (theme) => ({
	details: {
		marginRight: theme.spacing(1.5),
	},
})

const ITEM_HEIGHT = 50

const textWithNumbersCompare = (a, b) => {
	if (!a.name || !b.name) return 0
	const arrA = a.name.split(" ")
	const arrB = b.name.split(" ")
	for (let i = 0; i < Math.min(arrA.length, arrB.length); i++) {
		if (arrA[i] === arrB[i]) {
			continue
		}
		const numA = parseInt(arrA[i])
		const numB = parseInt(arrB[i])
		if (isNaN(numA) && isNaN(numB)) {
			return arrA[i] < arrB[i] ? -1 : 1
		}
		if (!isNaN(numA) && !isNaN(numB)) {
			return numA < numB ? -1 : 1
		} else if (isNaN(numA)) {
			return 1
		} else {
			return -1
		}
	}

	return arrA.length < arrB.length ? -1 : arrA.length > arrB.length ? 1 : 0
}

const MasterSelection = ({
	classes,
	masterList,
	activeMasterList,
	toggleMaster,
	toggleAllMasters,
	showMasterDetails,
	setMasterLabelVisible,
	setMasterLabelNotVisible,
}) => {
	const anchorEl = useRef(null)
	const [menuDisplayed, setMenuDisplayed] = useState(false)

	const handleClick = (event) => {
		setMenuDisplayed(true)
	}

	const handleClose = () => {
		setMenuDisplayed(false)
	}

	const handleSelectAll = () => {
		if (activeMasterList.length === masterList.length) {
			toggleAllMasters([])
		} else {
			toggleAllMasters(masterList.map((master) => master.mLocId))
		}
	}

	const sortedMasterList = masterList.sort(textWithNumbersCompare)

	return (
		<Fragment>
			<Fab ref={anchorEl} color="primary" size="small" aria-label="Select Master" onClick={handleClick}>
				<Icon />
			</Fab>
			<Menu
				id="master-menu"
				anchorEl={anchorEl.current}
				keepMounted
				open={menuDisplayed}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 5.75,
						width: 200,
					},
				}}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				variant="menu"
			>
				<ListItem key="select-all" role={undefined} dense button onClick={handleSelectAll}>
					<ListItemText
						inset
						primary={activeMasterList.length === sortedMasterList.length ? "Deselect All" : "Select All"}
					/>
				</ListItem>
				<Divider />
				{sortedMasterList.map((master) => (
					<ListItem
						key={master.mLocId}
						role={undefined}
						dense
						button
						onClick={() => toggleMaster(master.mLocId)}
						onMouseEnter={() => setMasterLabelVisible(master.mLocId)}
						onMouseLeave={() => setMasterLabelNotVisible(master.mLocId)}
					>
						<ListItemIcon>
							<Checkbox
								edge="start"
								color="primary"
								checked={activeMasterList.includes(master.mLocId)}
								tabIndex={-1}
								disableRipple
							/>
						</ListItemIcon>
						<ListItemText primary={master.name} />
						<ListItemSecondaryAction className={classes.details}>
							<IconButton
								edge="end"
								aria-label="Details"
								onClick={() => {
									handleClose()
									showMasterDetails(master.mLocId)
								}}
							>
								<CommentIcon />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</Menu>
		</Fragment>
	)
}

MasterSelection.propTypes = {
	classes: PropTypes.object.isRequired,
	activeOverlay: PropTypes.string,
}

export default withStyles(styles)(MasterSelection)

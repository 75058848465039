import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import ZigbeeNetwork from "./ZigbeeNetwork"

function convertKeysToNodeIds(latestData) {
	const ret = {}
	for (const record of Object.values(latestData)) {
		ret[record.nId] = record
	}
	return ret
}

const getLines = (state, props) => {
	if (!state.nodeData || !Object.entries(state.nodeData).length) return []
	const layoutDetails = state.layoutDetails[props.layoutId]
	const masterUuids = layoutDetails["mLocIds"]
	if (!masterUuids) return []
	const lines = []
	// Master selection is stored in URL param
	const selectedMastersParam = new URLSearchParams(props.location.search).get("locIds")
	const selectedMasters = selectedMastersParam ? selectedMastersParam.split(",") : []

	for (const masterUuid of masterUuids) {
		if (!selectedMasters.includes(masterUuid)) {
			continue
		}
		const activeTimestamp = state.nodeDataActiveTimestamp[masterUuid]
		const combinedDataKey = `${masterUuid}#${activeTimestamp}`
		const masterInfo = state.masterDetails[masterUuid]
		let nodeDetails = state.nodeDetails[masterUuid]
		let latestData = state.nodeData[combinedDataKey]

		if (!masterInfo || !nodeDetails || !latestData) {
			// Not loaded yet
			continue
		}

		// For fast and easy lookup, let's change the keys from some random numbers to node IDs.
		// They should all be unique now.
		latestData = convertKeysToNodeIds(latestData)
		for (const nodeInfo of Object.values(nodeDetails)) {
			const { nId } = nodeInfo
			const latestDataForThisNode = latestData[nId]

			if (!latestDataForThisNode) {
				continue
			}
			if (latestDataForThisNode["nId"] !== nId) {
				throw new Error("Unexpected node ID mismatch")
			}

			let nextZigbeeNode = latestDataForThisNode.firstHop

			if (!nextZigbeeNode) {
				console.warn("Encountered missing Zigbee hop for node ID", nId)
			} else {
				// firstHop is given in base 10. Need to convert to base 16 to match it with a node ID.
				nextZigbeeNode = parseInt(nextZigbeeNode).toString(16).toUpperCase().padStart(4, "0")
				// There is a next hop for this node. We need to render a line from this node to the next.
				// First, get the coordinates of this node.
				const { xLoc: x1, yLoc: y1 } = nodeInfo

				if (nextZigbeeNode === "0000") {
					// Direct line to master.
					const { xLoc: x2, yLoc: y2 } = masterInfo
					lines.push([
						[y1, x1],
						[y2, x2],
					])
				} else {
					// Going to another node.
					const nextNodeInfo = Object.values(nodeDetails).find((details) => details["nId"] === nextZigbeeNode)
					if (nextNodeInfo) {
						// The details for the next node are available, so we can find its location.
						const { xLoc: x2, yLoc: y2 } = nextNodeInfo
						// Leaflet uses y first.
						lines.push([
							[y1, x1],
							[y2, x2],
						])
					} else if (nextZigbeeNode !== "FFFF") {
						console.log(
							`Node ${nId} has an invalid firstHop (0x${nextZigbeeNode} / ${
								latestDataForThisNode.firstHop
							} / ${parseInt(latestDataForThisNode.firstHop).toString(2)}).`,
						)
					}
				}
			}
		}
	}
	return lines
}

const mapStateToProps = (state, props) => ({
	lines: getLines(state, props),
	zigbeeNetworkIsVisible: state.zigbeeNetworkIsVisible,
})

const mapDispatchToProps = (_dispatch) => ({})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ZigbeeNetwork)

import { connect } from "react-redux"
import PrivilegedRoute from "./PrivilegedRoute"
import { privileges } from "constants/auth"
import isBefore from "date-fns/isBefore"

const getUserPrivileges = (state) => {
	if (state.user.id === undefined) {
		return privileges.NONE
	} else if (state.user.privileges !== undefined) {
		if (state.user.trialExpiration && !isBefore(new Date(), state.user.trialExpiration.toDate())) {
			return privileges.NONE
		}
		return state.user.privileges
	} else {
		return privileges.NONE
	}
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.user.id !== undefined,
	userPrivileges: getUserPrivileges(state),
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PrivilegedRoute)

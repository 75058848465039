import React, { Children } from "react"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Paper from "@material-ui/core/Paper"

import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
	rootGraphGrid: {
		display: "grid",
		gridGap: theme.spacing(1),
		gridTemplateColumns: "repeat(2, 1fr)",
		gridTemplateRows: "repeat(2, 1fr)",
		height: "100%",
		padding: theme.spacing(0.5),
		boxSizing: "border-box",
	},
	gridQuad: {
		gridTemplateAreas: `
      'graph0 graph1'
      'graph2 graph3'
    `,
	},
	gridTri: {
		gridTemplateAreas: `
      'graph0 graph0'
      'graph1 graph2'
    `,
	},
	gridDual: {
		gridTemplateAreas: `
      'graph0 graph0'
      'graph1 graph1'
    `,
	},
	gridSingle: {
		gridTemplateAreas: `
      'graph0 graph0'
      'graph0 graph0'
    `,
	},
	graphItem: {
		display: "flex",
	},
	item0: {
		gridArea: "graph0",
	},
	item1: {
		gridArea: "graph1",
	},
	item2: {
		gridArea: "graph2",
	},
	item3: {
		gridArea: "graph3",
	},
}))

const GraphGrid = ({ children }) => {
	const classes = useStyles()
	const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("xs"))
	const maxGraphs = matchesXs ? 2 : 4
	const graphArray = Children.toArray(children).slice(0, maxGraphs)

	const containerClassList = [classes.gridSingle, classes.gridDual, classes.gridTri, classes.gridQuad]

	const itemClassList = [classes.item0, classes.item1, classes.item2, classes.item3]

	return (
		<Paper
			className={clsx(classes.rootGraphGrid, {
				[containerClassList[graphArray.length - 1]]: true,
			})}
		>
			{graphArray.map((graph, index) => (
				<Paper
					key={`${graph.key}#${graphArray.length}`}
					className={clsx(classes.graphItem, {
						[itemClassList[index]]: true,
					})}
					elevation={3}
				>
					{graph}
				</Paper>
			))}
		</Paper>
	)
}

// const GraphGrid = ({ children }) => {
//   const classes = useStyles()
//   const matchesXs = useMediaQuery(theme => theme.breakpoints.down('xs'))
//   const graphArray = Children.toArray(children)
//
//   if(graphArray.length === 1) {
//     return (
//       <Paper className={classes.rootGraphGrid}>
//         <Paper className={classes.graph} elevation={3} >
//           {graphArray[0]}
//         </Paper>
//       </Paper>
//     )
//   }
//   else if((graphArray.length === 2) || matchesXs) {
//     return (
//       <Paper className={classes.rootGraphGrid}>
//         <div className={classes.hSplit}>
//           <Paper className={classes.graph} elevation={3}>
//             {graphArray[0]}
//           </Paper>
//         </div>
//         <div className={classes.hSplit}>
//           <Paper className={classes.graph} elevation={3}>
//             {graphArray[1]}
//           </Paper>
//         </div>
//       </Paper>
//     )
//   }
//   else if(graphArray.length === 3) {
//     return (
//       <Paper className={classes.rootGraphGrid}>
//         <div className={classes.hSplit}>
//           <Paper className={classes.graph} elevation={3}>
//             {graphArray[0]}
//           </Paper>
//         </div>
//         <div className={classes.hSplit}>
//           <Paper className={classes.graph} elevation={3}>
//             {graphArray[1]}
//           </Paper>
//           <Paper className={classes.graph} elevation={3}>
//             {graphArray[2]}
//           </Paper>
//         </div>
//       </Paper>
//     )
//   }
//   else if(graphArray.length >= 4) {
//     return (
//       <Paper className={classes.rootGraphGrid}>
//         <div className={classes.hSplit}>
//           <Paper className={classes.graph} elevation={3}>
//             {graphArray[0]}
//           </Paper>
//           <Paper className={classes.graph} elevation={3}>
//             {graphArray[1]}
//           </Paper>
//         </div>
//         <div className={classes.hSplit}>
//           <Paper className={classes.graph} elevation={3}>
//             {graphArray[2]}
//           </Paper>
//           <Paper className={classes.graph} elevation={3}>
//             {graphArray[3]}
//           </Paper>
//         </div>
//       </Paper>
//     )
//   }
//   else {
//     return null
//   }
// }

export default GraphGrid

import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import InputBase from "@material-ui/core/InputBase"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import SearchIcon from "@material-ui/icons/Search"

const styles = (theme) => ({
	root: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: "auto",
		margin: theme.spacing(1),
	},
	input: {
		marginLeft: 8,
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
	divider: {
		width: 1,
		height: 28,
		margin: 4,
	},
})

function SearchBar(props) {
	const { classes } = props

	return (
		<Paper className={classes.root} elevation={1}>
			<IconButton className={classes.iconButton} aria-label="Menu">
				<MenuIcon />
			</IconButton>
			<InputBase className={classes.input} placeholder="Search" />
			<IconButton className={classes.iconButton} aria-label="Search" disabled={true}>
				<SearchIcon />
			</IconButton>
		</Paper>
	)
}

SearchBar.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SearchBar)

import { connect } from "react-redux"
import CoordinateTranslator from "./CoordinateTranslator"
import { setConfigTrans } from "store/actions/reduxActions"

const mapStateToProps = (state, props) => ({
	scale: state.configNodeTranslations.scale,
	xTrans: state.configNodeTranslations.xTrans,
	yTrans: state.configNodeTranslations.yTrans,
	markerRadius: state.configNodeTranslations.markerRadius,
})

const mapDispatchToProps = (dispatch) => ({
	setConfigTrans: (scale, xTrans, yTrans, markerRadius) =>
		dispatch(setConfigTrans(scale, xTrans, yTrans, markerRadius)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CoordinateTranslator)

import React, { memo } from "react"
import PropTypes from "prop-types"
import MasterArea from "./MasterArea"

const MasterAreas = memo(({ layoutId, mLocIds = [], activeOverlay, filter }) => {
	if (!layoutId || !mLocIds?.length) return null
	const masterAreas = []
	for (let i = 0; i < mLocIds.length; i++) {
		const mLocId = mLocIds[i]
		masterAreas.push(
			<MasterArea key={mLocId} mLocId={mLocId} layoutId={layoutId} activeOverlay={activeOverlay} filter={filter} />,
		)
	}
	return <>{masterAreas}</>
})

MasterAreas.displayName = "MasterAreas"

MasterAreas.propTypes = {
	mLocIds: PropTypes.array,
	layoutId: PropTypes.string,
	activeOverlay: PropTypes.string,
	filter: PropTypes.string,
}

export default MasterAreas

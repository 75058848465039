import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { lighten, makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Checkbox from "@material-ui/core/Checkbox"
import { useSnackbar } from "notistack"

import InputBase from "@material-ui/core/InputBase"
import IconButton from "@material-ui/core/IconButton"
import SearchIcon from "@material-ui/icons/Search"
import CloseIcon from "@material-ui/icons/Close"

import { graphDetails } from "constants/graphs"

const rows = Object.entries(graphDetails).map(([id, details]) => {
	const { title, device } = details
	return {
		id,
		title,
		device,
	}
})

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function stableSort(array, cmp) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

function getSorting(order, orderBy) {
	return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

const headCells = [
	{ id: "title", numeric: false, disablePadding: true, label: "Graph Data" },
	{ id: "device", numeric: false, disablePadding: false, label: "Device" },
]

function EnhancedTableHead(props) {
	const { classes, onClearAllClick, order, orderBy, numSelected, onRequestSort } = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					{numSelected > 0 && (
						<Checkbox
							indeterminate={true}
							checked={false}
							onChange={onClearAllClick}
							inputProps={{ "aria-label": "select all desserts" }}
						/>
					)}
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onClearAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
}

const useFilterStyles = makeStyles((theme) => ({
	root: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: 400,
		flex: 1,
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
	divider: {
		height: 28,
		margin: 4,
	},
}))

const Filter = ({ value, setValue }) => {
	const classes = useFilterStyles()

	return (
		<Paper component="form" className={classes.root}>
			<InputBase
				className={classes.input}
				placeholder="Filter Graphs"
				inputProps={{ "aria-label": "filter options" }}
				value={value}
				onChange={(e) => setValue(e.target.value)}
				autoFocus
			/>
			<IconButton
				type="submit"
				className={classes.iconButton}
				onClick={() => setValue("")}
				disabled={value === ""}
				aria-label="filter"
			>
				{value === "" ? <SearchIcon /> : <CloseIcon />}
			</IconButton>
		</Paper>
	)
}

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight:
		theme.palette.type === "light"
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
			  },
	title: {
		flex: 1,
	},
}))

const EnhancedTableToolbar = ({ numSelected, maxSelection, filter, setFilter }) => {
	const classes = useToolbarStyles()

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			{numSelected > 0 ? (
				<Typography className={classes.title} color="inherit" variant="subtitle1">
					{numSelected === maxSelection && "(Max)"} {numSelected} selected
				</Typography>
			) : (
				<Typography className={classes.title} variant="h6" id="tableTitle">
					Select up to {maxSelection}
				</Typography>
			)}
			<Filter value={filter} setValue={setFilter} />
		</Toolbar>
	)
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2),
	},
	table: {},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	tableRow: {
		cursor: "pointer",
	},
}))

const EnhancedTable = ({ selected, setSelected }) => {
	const { enqueueSnackbar } = useSnackbar()
	const classes = useStyles()
	const [order, setOrder] = useState("asc")
	const [orderBy, setOrderBy] = useState("calories")
	const [page, setPage] = useState(0)
	const [filter, setFilter] = useState("")
	const theme = useTheme()
	const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
	const maxSelection = smallScreen ? 2 : 4
	const rowsPerPage = 5
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleClearAllClick = (event) => {
		setSelected([])
	}

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name)
		let newSelected = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
		}

		setSelected(newSelected)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeFilter = (value) => {
		setPage(0)
		setFilter(value)
	}

	const isSelected = (name) => selected.indexOf(name) !== -1

	const filteredRows = stableSort(rows, getSorting(order, orderBy)).filter(
		(row) => row.title.toLowerCase().includes(filter) || row.device.toLowerCase().includes(filter),
	)

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredRows.length - page * rowsPerPage)

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<EnhancedTableToolbar
					numSelected={selected.length}
					maxSelection={maxSelection}
					filter={filter}
					setFilter={handleChangeFilter}
				/>
				<TableContainer>
					<Table className={classes.table} aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
						<EnhancedTableHead
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onClearAllClick={handleClearAllClick}
							onRequestSort={handleRequestSort}
						/>
						<TableBody>
							{filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
								const isItemSelected = isSelected(row.id)
								const labelId = `enhanced-table-checkbox-${index}`
								const disabled = !isItemSelected && selected.length === maxSelection

								return (
									<TableRow
										hover
										onClick={
											disabled
												? () => enqueueSnackbar(`Max (${maxSelection}) selected. Deselect to change selection.`)
												: (event) => handleClick(event, row.id)
										}
										role="checkbox"
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={row.id}
										selected={isItemSelected}
										className={classes.tableRow}
									>
										<TableCell padding="checkbox">
											<Checkbox
												checked={isItemSelected}
												inputProps={{ "aria-labelledby": labelId }}
												disabled={disabled}
											/>
										</TableCell>
										<TableCell component="th" id={labelId} scope="row" padding="none">
											{isItemSelected ? `(${selected.indexOf(row.id) + 1}) ` : ""}
											{row.title}
										</TableCell>
										<TableCell component="th" id={labelId} scope="row" padding="none">
											{row.device}
										</TableCell>
										<TableCell align="right">{row.calories}</TableCell>
									</TableRow>
								)
							})}
							{emptyRows > 0 && (
								<TableRow style={{ height: 25 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					component="div"
					count={filteredRows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPageOptions={[rowsPerPage]}
				/>
			</Paper>
		</div>
	)
}

export default EnhancedTable

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import { setMasterLabelVisible, setMasterLabelNotVisible } from "store/actions/reduxActions"
import MasterSelection from "./MasterSelection"

const getMasterList = (state, props) => {
	const layoutDetails = state.layoutDetails[props.match.params.layoutId]
	if (!(layoutDetails && layoutDetails.mLocIds)) return []
	let mLocIds = layoutDetails.mLocIds
	return mLocIds.map((mLocId) => {
		if (state.masterDetails[mLocId]) {
			return {
				mLocId,
				name: state.masterDetails[mLocId].name,
			}
		} else {
			return {
				mLocId,
			}
		}
	})
}

const getActiveMasterList = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	let locIds = params.get("locIds")
	if (!locIds) return []
	return locIds.split(",").map((loc) => loc.split(":")[0])
}

const showMasterDetails = (props, mLocId) => {
	let params = new URLSearchParams(props.location.search)
	params.set("device-details", [props.match.params.layoutId, mLocId].join(":"))
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const toggleMaster = (props) => (mLocId) => {
	let params = new URLSearchParams(props.location.search)
	let activeIds = params.get("locIds")
	if (activeIds) {
		activeIds = activeIds.split(",")
	} else {
		activeIds = []
	}
	if (activeIds.map((loc) => loc.split(":")[0]).includes(mLocId)) {
		activeIds = activeIds.filter((loc) => loc.split(":")[0] !== mLocId)
	} else {
		activeIds.push(mLocId)
	}
	if (activeIds.length) {
		params.set("locIds", activeIds.join(","))
	} else {
		params.delete("locIds")
	}
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const toggleAllMasters = (props) => (mLocIds) => {
	let params = new URLSearchParams(props.location.search)
	if (!mLocIds.length) {
		params.delete("locIds")
	} else {
		params.set("locIds", mLocIds.join(","))
	}
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const mapStateToProps = (state, props) => ({
	masterList: getMasterList(state, props),
	activeMasterList: getActiveMasterList(state, props),
})

const mapDispatchToProps = (dispatch, props) => ({
	toggleMaster: (mLocId) => toggleMaster(props)(mLocId),
	showMasterDetails: (mLocId) => showMasterDetails(props, mLocId),
	toggleAllMasters: (mLocIds) => toggleAllMasters(props)(mLocIds),
	setMasterLabelVisible: (mLocId) => dispatch(setMasterLabelVisible(mLocId)),
	setMasterLabelNotVisible: (mLocId) => dispatch(setMasterLabelNotVisible(mLocId)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MasterSelection)

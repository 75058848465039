import React, { useState } from "react"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import { Link, Redirect } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
	main: {
		width: "auto",
		display: "flex", // Fix IE 11 issue.
		flexDirection: "column",
		[theme.breakpoints.down(400 + theme.spacing(6))]: {
			width: "100%",
			boxSizing: "border-box",
			paddingLeft: theme.spacing(3),
			paddingRight: theme.spacing(3),
		},
		[theme.breakpoints.up(400 + theme.spacing(6))]: {
			width: 410,
			marginLeft: "auto",
			marginRight: "auto",
		},
	},
	logo: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		objectFit: "contain",
	},
	paper: {
		marginTop: 0,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: theme.spacing(2, 3, 3),
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		marginTop: theme.spacing(3),
	},
	login: {
		marginTop: theme.spacing(2),
		marginBottom: 0,
		margin: "auto",
		display: "flex",
		alignItems: "center",
	},
}))

const getErrorField = (errCode) => {
	switch (errCode) {
		case "InvalidPasswordException":
		case "NotAuthorizedException":
		case "PasswordResetRequiredException":
			return "errorPassword"
		case "InternalErrorException":
		case "InvalidParameterException":
		case "LimitExceededException":
		case "ResourceNotFoundException":
		case "TooManyRequestsException":
		case "UserNotConfirmedException":
		case "UserNotFoundException":
		default:
			return "errorEmail"
	}
}

const defaultEntries = {
	email: "",
	newPassword: "",
	code: "",
}

const ResetPassword = ({
	authorized,
	forgotPassword,
	forgotPasswordSubmit,
	signIn,
	enqueueSnackbar,
	closeSnackbar,
}) => {
	const [entries, setEntries] = useState(defaultEntries)
	const [errorFeedback, setErrorFeedback] = useState({})
	const [pendingResponse, setPendingResponse] = useState(false)
	const [resetSent, setResetSent] = useState(false)
	const [capsLockIsEnabled, setCapsLockIsEnabled] = useState(false)
	const classes = useStyles()

	const handleChange = (name) => (event) => {
		const value = event.target.value
		setErrorFeedback({})
		setPendingResponse(false)
		setEntries((entries) => ({ ...entries, [name]: value }))
	}

	const submit = async (e) => {
		e.preventDefault()
		setErrorFeedback({})
		setPendingResponse(true)
		let timer = setTimeout(() => {
			setPendingResponse(false)
			setErrorFeedback({ errorEmail: "Request Timeout" })
		}, 20000)
		try {
			if (!resetSent) {
				await forgotPassword(entries["email"])
				clearTimeout(timer)
				setPendingResponse(false)
				enqueueSnackbar(`A verification code has been sent to ${entries.email}`, {
					variant: "info",
					action: (key) => (
						<Button
							onClick={() => {
								closeSnackbar(key)
							}}
						>
							Dismiss
						</Button>
					),
				})
				setResetSent(true)
			} else {
				if (entries.newPassword === entries.confirmNewPassword) {
					await forgotPasswordSubmit(entries.email, entries.code, entries.newPassword)
					await signIn(entries.email, entries.newPassword)
				} else {
					setErrorFeedback({
						errorConfirmPassword: "Passwords do not match",
					})
				}
				clearTimeout(timer)
				setPendingResponse(false)
			}
		} catch (err) {
			clearTimeout(timer)
			setErrorFeedback({ [getErrorField(err.code)]: err.message || "An error occurred." })
			setPendingResponse(false)
		}
	}

	if (authorized) {
		return (
			<Redirect
				to={{
					pathname: "/layouts",
					search: "?view=list",
				}}
			/>
		)
	}

	return (
		<main className={classes.main}>
			<CssBaseline />
			<img className={classes.logo} alt="GameChange Logo" src={require("../../../images/logo-dark.png")} />
			{pendingResponse ? <LinearProgress /> : null}
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Password Reset
				</Typography>
				<form className={classes.form} onSubmit={submit}>
					<TextField
						id="email"
						label="Email Address"
						type="email"
						margin="normal"
						variant="outlined"
						value={entries.email}
						onChange={handleChange("email")}
						autoComplete="email"
						error={errorFeedback.errorEmail !== undefined || Boolean(!resetSent && errorFeedback.errorPassword)}
						helperText={errorFeedback.errorEmail || (!resetSent && errorFeedback.errorPassword)}
						autoFocus
						required
						fullWidth
					/>
					{resetSent && (
						<React.Fragment>
							<TextField
								id="newPassword"
								label="New Password"
								type="password"
								margin="normal"
								variant="outlined"
								value={entries.newPassword}
								onKeyDown={(e) => setCapsLockIsEnabled(e.getModifierState("CapsLock"))}
								onChange={handleChange("newPassword")}
								autoComplete="password"
								error={errorFeedback.errorPassword !== undefined}
								helperText={errorFeedback.errorPassword}
								autoFocus
								required
								fullWidth
							/>
							<TextField
								key="confirmNewPassword"
								id="confirmNewPassword"
								label="Confirm New Password"
								type="password"
								margin="normal"
								variant="outlined"
								value={entries.confirmNewPassword}
								onKeyDown={(e) => setCapsLockIsEnabled(e.getModifierState("CapsLock"))}
								onChange={handleChange("confirmNewPassword")}
								autoComplete="confirm-new-password"
								error={errorFeedback.errorConfirmPassword !== undefined}
								helperText={errorFeedback.errorConfirmPassword || (capsLockIsEnabled && "CapsLock Is Enabled")}
								required
								fullWidth
							/>
							<TextField
								id="code"
								label="Verification Code"
								type="text"
								margin="normal"
								variant="outlined"
								value={entries.code}
								onChange={handleChange("code")}
								autoComplete="code"
								error={errorFeedback.errorCode !== undefined}
								helperText={errorFeedback.errorCode}
								required
								fullWidth
							/>
						</React.Fragment>
					)}
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						disabled={pendingResponse}
					>
						{resetSent ? "Submit" : "Send Email"}
					</Button>
					<Button color="primary" className={classes.login} component={Link} to="/">
						Back to Login
					</Button>
				</form>
			</Paper>
		</main>
	)
}

export default ResetPassword

import { authRef, hubRef } from "config/aws"
import { RECEIVE_USER_AUTH } from "./types"
import api from "../../constants/api"
import { CognitoUser } from "@aws-amplify/auth"
import { fetchLayoutDetails } from "./fetchers"

export const initAuthObserver = () => async (dispatch) => {
	// Runs after a page refresh
	hubRef.listen("auth", async ({ payload }) => {
		const { event, data } = payload
		switch (event) {
			case "signIn": {
				const groups = (((data["signInUserSession"] || {}).accessToken || {}).payload || {})["cognito:groups"] || []
				dispatch({
					type: RECEIVE_USER_AUTH,
					payload: {
						attributes: await authRef.userAttributes(data),
						groups,
					},
				})
				break
			}
			case "signOut":
				dispatch({
					type: RECEIVE_USER_AUTH,
					payload: null,
				})
				break
			case "signUp":
			case "signIn_failure":
			case "configured":
			case "tokenRefresh":
			case "completeNewPassword_failure":
				break
			case "signedOutUserPoolsTokenInvalid":
				console.error("signedOutUserPoolsTokenInvalid need to login again.")
				break
			default:
				console.error("Unsupported authentication event:", payload)
				break
		}
	})

	let user = null
	try {
		user = await authRef.currentAuthenticatedUser()
	} catch (err) {
		console.error("currentAuthenticatedUser", err)
		dispatch({
			type: RECEIVE_USER_AUTH,
			payload: null,
		})
	}
	if (user) {
		const userSession = user["signInUserSession"]

		const idToken = extractIdToken(user)
		if (idToken) {
			api.setIdTokens(idToken)
		}

		// An object containing a JWT token used to access graphQL queries/mutations and a payload object containing
		// auth-related user metadata
		const accessToken = userSession["accessToken"] || {}
		const groups = (accessToken["payload"] || {})["cognito:groups"] || []

		if (!user || !user.attributes) {
			console.error("No user or user.attributes:", user)
			return
		}

		let attributes = Object.keys(user.attributes).map((key) => ({
			Name: key,
			Value: user.attributes[key],
		}))
		dispatch({
			type: RECEIVE_USER_AUTH,
			payload: {
				attributes,
				groups,
			},
		})

		try {
			user = await authRef.currentAuthenticatedUser({ bypassCache: true })
		} catch (err) {
			console.error("NETWORK currentAuthenticatedUser", err)
		}
		attributes = null
		try {
			attributes = await authRef.userAttributes(user)
		} catch (err) {
			console.log("Error userAttributes:", err)
		}
		if (attributes) {
			dispatch({
				type: RECEIVE_USER_AUTH,
				payload: {
					attributes,
					groups,
				},
			})
		}
	}
}

function extractIdToken(cognitoUser) {
	if (!cognitoUser) return null
	const signInUserSession = cognitoUser["signInUserSession"]
	if (signInUserSession) {
		const idToken = signInUserSession["idToken"]
		if (idToken) {
			return idToken
		} else {
			console.error("Received no id token:", signInUserSession)
		}
	} else {
		console.error("No signInUserSession in:", cognitoUser)
	}
	return null
}

/** Returns true if the user was successfully logged in */
const _handleSuccessfulAuth = async (password, result) => {
	// Runs after successful login (not on page refresh)
	const idToken = extractIdToken(result)
	if (idToken) {
		api.setIdTokens(idToken)
		await api.verifyAuth(password)
		return true
	}
	console.warn("No id token. A new password may be required by Cognito.")
	return false
}

const _handleAuthFailure = (reason) => reason

export const signInWithEmailAndPassword = (email, password) => async (dispatch) => {
	try {
		const result = await authRef.signIn(email, password)
		const isSignedIn = await _handleSuccessfulAuth(password, result)
		if (isSignedIn) {
			dispatch(fetchLayoutDetails())
		}
		return result
	} catch (e) {
		console.error(e)
		_handleAuthFailure(e)
		return e
	}
}

export const completeNewPassword = (user, newPassword) => (dispatch) => {
	const email = getEmailFromUser(user)
	return authRef.completeNewPassword(user, newPassword).then(dispatch(signInWithEmailAndPassword(email, newPassword)))
}

export const getEmailFromUser = (user) => {
	if (user instanceof CognitoUser) {
		return user.getUsername()
	} else if (typeof user === "object") {
		if (!("userAttributes" in user)) throw new Error(`Invalid user object: ${JSON.stringify(user)}}`)
		return user.userAttributes.email
	}
	return user
}

export const forgotPassword = (email) => (_dispatch) => {
	return authRef.forgotPassword(email)
}

export const forgotPasswordSubmit = (email, code, newPassword) => (_dispatch) => {
	return authRef.forgotPasswordSubmit(email, code, newPassword)
}

// TODO: Use or remove
export const changePassword = (oldPassword, newPassword) => async (_dispatch) => {
	const user = await authRef.currentAuthenticatedUser()
	return authRef.changePassword(user, oldPassword, newPassword)
}

import React, { Fragment, useState } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Fab from "@material-ui/core/Fab"
import Icon from "@material-ui/icons/Close"
import Compass from "components/Compass"

const useStyles = makeStyles((theme) => ({
	compass: {
		position: "absolute",
		zIndex: 500,
		margin: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			marginTop: 50,
		},
	},
	large: {
		[theme.breakpoints.down("xs")]: {
			width: 200,
			height: 200,
			left: 0,
			top: 0,
		},
		[theme.breakpoints.up("sm")]: {
			width: 250,
			height: 250,
			right: 10,
			bottom: 10,
		},
		pointerEvents: "none",
	},
	small: {
		[theme.breakpoints.down("xs")]: {
			width: 57,
			height: 57,
			left: 0,
			top: 0,
		},
		[theme.breakpoints.up("sm")]: {
			width: 75,
			height: 75,
			right: 10,
			bottom: 10,
		},
		cursor: "pointer",
	},
	fab: {
		position: "absolute",
		zIndex: 500,
		margin: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			marginTop: 50,
			left: 0,
			top: 0,
		},
		[theme.breakpoints.up("sm")]: {
			right: 10,
			bottom: 10,
		},
		opacity: 0.5,
	},
}))

const CompassButton = ({ enqueueSnackbar }) => {
	const [large, setLarge] = useState(false)
	const classes = useStyles()

	return (
		<Fragment>
			<div
				className={clsx(classes.compass, { [classes.small]: !large, [classes.large]: large })}
				onClick={async () => {
					if (window.DeviceOrientationEvent && window.DeviceOrientationEvent.requestPermission) {
						const access = await window.DeviceOrientationEvent.requestPermission()
						if (access !== "granted") {
							enqueueSnackbar("Orientation data unavailable: Permission denied", {
								variant: "error",
							})
						}
					}
					setLarge(true)
				}}
			>
				<Compass />
			</div>
			{large && (
				<Fab
					color="secondary"
					size="medium"
					aria-label="Toggle Compass"
					className={classes.fab}
					onClick={() => setLarge(false)}
				>
					<Icon />
				</Fab>
			)}
		</Fragment>
	)
}

export default CompassButton

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import DeviceIdSearch from "./DeviceIdSearch"

const isOpen = (props) => {
	const params = new URLSearchParams(props.location.search)
	return params.get("search") !== null
}

const getActiveLocationIds = (props) => {
	const activeLocationIds = []
	const params = new URLSearchParams(props.location.search)
	let locIds = params.get("locIds")
	if (!locIds) return []
	locIds = locIds.split(",")
	locIds.forEach((locId) => {
		const [mLocId, nLocId] = locId.split(":")
		const nodeLocationIds = (nLocId && nLocId.split(";")) || []
		nodeLocationIds.forEach((nLocId) => {
			activeLocationIds.push(mLocId + ":" + nLocId)
		})
	})
	return activeLocationIds
}

const getNavParams = (props) => {
	const params = new URLSearchParams(props.location.search)
	const locId = params.get("navLocId")
	return {
		navLocId: locId ? [locId] : [],
		navActive: params.get("nav"),
	}
}

const mapStateToProps = (state, props) => ({
	isOpen: isOpen(props),
	activeLocationIds: getActiveLocationIds(props),
	...getNavParams(props),
})

const handleClose = (props) => {
	const params = new URLSearchParams(props.location.search)
	params.delete("search")
	params.delete("nav")
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const handleNavToggle = (props, active) => {
	const params = new URLSearchParams(props.location.search)
	if (active) {
		params.set("nav", true)
	} else {
		params.delete("nav")
	}
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const handleSearch = (props, deviceidList, navLocId) => {
	const params = new URLSearchParams(props.location.search)
	params.delete("search")
	params.delete("nav")
	const nLocIdByMLocId = {}
	deviceidList.forEach((locId) => {
		const [mLocId, nLocId] = locId.split(":")
		if (nLocIdByMLocId[mLocId] !== undefined && nLocId) {
			nLocIdByMLocId[mLocId] += ";" + nLocId
		} else {
			nLocIdByMLocId[mLocId] = nLocId
		}
	})
	let locIdString = []
	Object.keys(nLocIdByMLocId).forEach((mLocId) => {
		if (nLocIdByMLocId[mLocId]) {
			locIdString.push(mLocId + ":" + nLocIdByMLocId[mLocId])
		} else {
			locIdString.push(mLocId)
		}
	})
	params.set("locIds", locIdString.join(","))
	if (navLocId) {
		params.set("navLocId", navLocId)
	} else {
		params.delete("navLocId")
	}
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const mapDispatchToProps = (dispatch, props) => ({
	handleClose: () => handleClose(props),
	handleSearch: (deviceidList, navLocId) => handleSearch(props, deviceidList, navLocId),
	handleNavToggle: (active) => handleNavToggle(props, active),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(DeviceIdSearch)

import React, { Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import Divider from "@material-ui/core/Divider"
import PropTypes, { arrayOf } from "prop-types"

const useStyles = makeStyles((theme) => ({
	container: {
		maxHeight: 420,
		[theme.breakpoints.down("xs")]: {
			maxHeight: 220,
		},
	},
	valueCell: {
		textAlign: "right",
	},
	clickable: {
		cursor: "pointer",
	},
}))

const sortByDesc = (a, b) => {
	const nameA = a.description.toUpperCase() // ignore upper and lowercase
	const nameB = b.description.toUpperCase() // ignore upper and lowercase
	if (nameA < nameB) {
		return -1
	}
	if (nameA > nameB) {
		return 1
	}

	// names must be equal
	return 0
}

const MastersSummary = ({ summaryHeading, summaryData, visibilityMask, handleFilterChange }) => {
	const classes = useStyles()
	return (
		<Fragment>
			{summaryHeading !== null && (
				<Fragment>
					<Divider />
					<Divider />
					<TableContainer className={classes.container}>
						<Table size="small" stickyHeader>
							<TableHead>
								<TableRow>
									{summaryHeading.map((headingDesc, ind, { length }) => (
										<TableCell key={headingDesc} className={ind === length - 1 ? classes.valueCell : undefined}>
											{
												/** @type {string} */
												headingDesc
											}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{summaryData === null ? (
									<TableRow>
										<TableCell>No Data</TableCell>
										<TableCell />
									</TableRow>
								) : summaryData.length === 0 ? (
									<TableRow>
										<TableCell>None</TableCell>
										<TableCell />
									</TableRow>
								) : (
									summaryData.sort(sortByDesc).map((dataRow, index) => {
										let alarmVisibilitySelected
										if (visibilityMask) {
											if (typeof dataRow.action !== "bigint") {
												throw new Error(`Invalid action type (expected BigInt, got ${typeof dataRow.action})`)
											}
											if (typeof visibilityMask !== "bigint") {
												throw new Error(`Invalid visibilityMask type (expected BigInt), got ${typeof visibilityMask}`)
											}
											alarmVisibilitySelected = (visibilityMask & (1n << dataRow.action)) !== 0n
										}
										let selectAction = null
										if (dataRow.action !== null) {
											selectAction = (e) => {
												handleFilterChange(e, dataRow.action)
											}
										}
										return (
											<TableRow
												key={index}
												className={dataRow.action === null ? classes.notClickable : classes.clickable}
												hover={!!dataRow.action}
												selected={alarmVisibilitySelected}
												onClick={selectAction}
											>
												<TableCell>{dataRow.description}</TableCell>
												<TableCell className={classes.valueCell}>{dataRow.value}</TableCell>
											</TableRow>
										)
									})
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Fragment>
			)}
		</Fragment>
	)
}

MastersSummary.propTypes = {
	summaryHeading: arrayOf(PropTypes.string),
	summaryData: arrayOf(PropTypes.object),
	visibilityMask: PropTypes.any, // bigint
	handleFilterChange: PropTypes.func.isRequired,
}
export default MastersSummary

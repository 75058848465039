// REASON: Used dynamically
// noinspection JSUnusedGlobalSymbols

const CONNECT_SEPARATED_POINTS = true

export const graphSeriesDetails = {
	nodeAnglePanel: {
		Benchmark: {
			connectSeparatedPoints: false,
			strokeWidth: 3,
			strokeBorderWidth: 1,
			color: "#20447E",
		},
	},
	nodeBatteryVoltage: {
		"Low Level": {
			stepPlot: true,
			fillGraph: true,
			color: "#F0F031",
		},
		"Critical Level": {
			stepPlot: true,
			fillGraph: true,
			color: "#FF0000",
		},
	},
	masterStow: (labels) => {
		if (!labels) return
		const series = {}
		for (let i = 0; i < labels.length; i++) {
			const label = labels[i]
			if (label.includes("Low Priority Stow Angle")) {
				series[label] = {
					strokePattern: [5, 5],
				}
			}
		}
		return series
	},
}

// NOTE: If adding new attributes, you MUST prefix the name with "node" or "master". For example, "arrrDuration" becomes
//  "masterArrrDuration" because it's a master data attribute.
export const graphDetails = {
	nodeAnglePanel: {
		title: "Angle vs Time",
		device: "Node",
		xlabel: "Time",
		ylabel: "Degrees ( [-]East & [+]West )",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeMotorCurrentPeak: {
		title: "Motor Peak Current vs Time",
		device: "Node",
		xlabel: "Time",
		ylabel: "Current (A)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeBatteryVoltage: {
		title: "Battery Voltages vs Time",
		device: "Node",
		xlabel: "Time",
		ylabel: "Voltage (V)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeBatteryStateOfCharge: {
		title: "Battery State of Charge vs Time",
		device: "Node",
		xlabel: "Time",
		ylabel: "%",
		connectSeparatedPoints: true,
	},
	nodeBatteryStateOfHealth: {
		title: "Battery State of Health vs Time",
		device: "Node",
		xlabel: "Time",
		ylabel: "%",
		connectSeparatedPoints: true,
	},
	nodeMotorCurrentAve: {
		title: "Motor Average Current vs Time",
		device: "Node",
		xlabel: "Time",
		ylabel: "Current (A)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeChargeCurrent: {
		title: "Battery Charge Current vs Time",
		device: "Node",
		xlabel: "Time",
		ylabel: "Current (A)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeChargeStatus: {
		title: "Charger Status vs Time",
		device: "Node",
		xlabel: "Time",
		ylabel: "Status",
		stepPlot: true,
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeTempMin: {
		title: "Temperature Min vs Time",
		device: "Node",
		xlabel: "Time",
		ylabel: "Temperature (C)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeTempMax: {
		title: "Temperature Max vs Time",
		device: "Node",
		xlabel: "Time",
		ylabel: "Temperature (C)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeAngleTarget: {
		title: "Angle (Target) vs Time",
		device: "Node",
		xlabel: "Time",
		ylabel: "Degrees ( [-]East & [+]West )",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeAngleGoal: {
		title: "Angle (Goal) vs Time",
		device: "Node",
		xlabel: "Time",
		ylabel: "Degrees ( [-]East & [+]West )",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeHopCount: {
		title: "Hop Count vs Time",
		device: "Node",
		xlabel: "Time",
		ylabel: "Hop Count",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeSolarPanelCurrentDraw: {
		title: "Solar Panel Current Draw",
		device: "Node",
		xlabel: "Time",
		ylabel: "Current (A)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeSolarPanelVoltage: {
		title: "Solar Panel Voltage Measurement",
		device: "Node",
		xlabel: "Time",
		ylabel: "Voltage (V)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeCheckinRound: {
		title: "Checkin Round vs Time",
		device: "Node",
		xlabel: "Time",
		ylabel: "Checkin Round",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeBatteryVoltageMin: {
		title: "Battery Voltage Min vs Time",
		device: "Node",
		xlabel: "Time",
		ylabel: "Voltage (V)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeResponseTime: {
		title: "Response Time",
		device: "Node",
		xlabel: "Time",
		ylabel: "Milliseconds",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeLinkQualityIndicator: {
		title: "Link Quality Indicator",
		device: "Node",
		xlabel: "Time",
		ylabel: "Quality (out of 255)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	nodeReceivedSignalStrengthIndicator: {
		title: "Received Signal Strength Indicator",
		device: "Node",
		xlabel: "Time",
		ylabel: "Signal Strength (out of -128)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	masterStow: {
		title: "Stow Limits vs Time",
		device: "Master",
		xlabel: "Time",
		ylabel: "Degrees ( [-]East & [+]West )",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	masterWindPeak: {
		title: "Windspeed Peak vs Time",
		device: "Master",
		xlabel: "Time",
		ylabel: "Windspeed (MPH)",
		strokePattern: [5, 5],
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	masterWindAverage: {
		title: "Windspeed Ave vs Time",
		device: "Master",
		xlabel: "Time",
		ylabel: "Windspeed (MPH)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	masterDepth: {
		title: "Depth vs Time",
		device: "Master",
		xlabel: "Time",
		ylabel: "Depth (In)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	masterBatteryVoltage: {
		title: "Master Battery Voltage vs Time",
		device: "Master",
		xlabel: "Time",
		ylabel: "Voltage (V)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	masterChargeCurrent: {
		title: "Master Charge Current vs Time",
		device: "Master",
		xlabel: "Time",
		ylabel: "Current (A)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	masterTempCharger: {
		title: "Master Charger Temp vs Time",
		device: "Master",
		xlabel: "Time",
		ylabel: "Temperature (C)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	masterTempBoard: {
		title: "Master Board Temp vs Time",
		device: "Master",
		xlabel: "Time",
		ylabel: "Temperature (C)",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	masterReadStatus: {
		title: "Master Read Status vs Time",
		device: "Master",
		xlabel: "Time",
		ylabel: "Status",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	masterResetType: {
		title: "Master Reset Type vs Time",
		device: "Master",
		xlabel: "Time",
		ylabel: "Reset Type",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	masterTrackingMode: {
		title: "Master Tracking Mode vs Time",
		device: "Master",
		xlabel: "Time",
		ylabel: "Mode",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
	masterArrrDuration: {
		title: "Call & Response Duration",
		device: "Master",
		xlabel: "Time",
		ylabel: "Minutes",
		connectSeparatedPoints: CONNECT_SEPARATED_POINTS,
	},
}

import { connect } from "react-redux"
import { withRouter, matchPath } from "react-router"
import { compose } from "redux"
import SuggestionsList from "./SuggestionsList"

const getLocationIdMap = (state, props) => {
	const rtnMatchPath = matchPath(props.location.pathname, { path: "/layout/:layoutId" })
	const { layoutId } = rtnMatchPath.params
	const mLocIds = (state.layoutDetails[layoutId] && state.layoutDetails[layoutId].mLocIds) || []
	const locIdMap = {}
	mLocIds.forEach((mLocId) => {
		const masterName = (state.masterDetails[mLocId] && state.masterDetails[mLocId].name) || ""
		const nodeDetails = state.nodeDetails[mLocId] || {}
		Object.keys(nodeDetails).forEach((nLocId) => {
			const nId = state.nIdsPendingUpload[`${mLocId}#${nLocId}`] || nodeDetails[nLocId].nId
			if (nId) {
				locIdMap[[mLocId, nLocId].join(":")] = {
					nId,
					masterName,
				}
			}
		})
	})
	return locIdMap
}

const mapStateToProps = (state, props) => ({
	locIdMap: getLocationIdMap(state, props),
	wizardIsOpen: state.wizardInfo.isOpen,
})

const mapDispatchToProps = (dispatch, props) => ({})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SuggestionsList)

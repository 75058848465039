import { connect } from "react-redux"
import Drawer from "./Drawer"
import { setDrawerIsOpen } from "store/actions/reduxActions"
import api from "../../constants/api"
import { compose } from "redux"
import { withRouter } from "react-router-dom"
import { authRef } from "../../config/aws"

const mapStateToProps = (state, _props) => ({
	isOpen: state.drawerIsOpen,
	isOffline: state.isOffline,
	userPrivileges: (state.user || {}).privileges,
})

const mapDispatchToProps = (dispatch, props) => ({
	setIsOpen: (isOpen) => dispatch(setDrawerIsOpen(isOpen)),
	signOut: () => {
		api.signOut()
		// Reset react router (go back to layout list on login)
		props.history.push({
			pathname: "/",
		})
		authRef.signOut()
	},
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Drawer)

import React from "react"
import PropTypes from "prop-types"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "25ch",
		},
	},
}))

const InputQuestion = ({ value, setTaskInfo, name, label, isDate = false }) => {
	const classes = useStyles()

	const handleChange = (event) => {
		const { value } = event.target
		setTaskInfo({ [name]: value })
	}

	const isValid = (value, name) => {
		if (!value) return false
		if (isDate) {
			try {
				Date.parse(value)
			} catch (e) {
				return false
			}
		} else {
			if (name === "voltage" && !(value > 36 && value < 60)) return false
			if (name === "current" && !(value > 0.25 && value < 2)) return false
			//if((name === 'goodActuatorSerialNum' || name === 'badActuatorSerialNum') && value.length !== 12) return false
			if ((name === "goodPanId" || name === "badPanId") && value.length !== 4) return false
			//if((name === 'badBatteryId' || name === 'goodBatteryId') && value.length !== 6) return false
		}

		return true
	}

	const getHelperText = (name) => {
		if (name === "voltage" || name === "current") {
			return `Value not in range`
		} else if (name === "goodPanId" || name === "badPanId") {
			return "Pan ID needs to be 4 characters"
		} else if (name === "buildDate") {
			return "Not a valid date"
		}
		//else if(name === 'badBatteryId' || name === 'goodBatteryId') {
		//return 'Battery ID needs to be 6 characters'
		//}
		//else if(name === 'goodActuatorSerialNum' || name === 'badActuatorSerialNum') {
		//return 'Serial number needs to be 12 characters'
		//}
	}

	return (
		<form className={classes.root} noValidate autoComplete="off">
			<TextField
				id="outlined-input"
				label={label}
				value={value || ""}
				onChange={handleChange}
				variant="outlined"
				error={value && !isValid(value, name)}
				helperText={value && !isValid(value, name) ? getHelperText(name) : null}
				type={isDate ? "date" : "text"}
			/>
		</form>
	)
}

InputQuestion.propTypes = {
	setTaskInfo: PropTypes.func.isRequired,
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	isDate: PropTypes.bool,
}

export default InputQuestion

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import MasterDetails from "./MasterDetails"
import { getMasterDetailsFormats, getMasterDataFormats } from "constants/masterInfoFormatting"
import { updateMasterDetails } from "../../../store/actions/setters"

const getMasterDetails = (state, props) => {
	if (state.masterDetails[props.mLocId]) {
		const deviceType = state.masterDetails[props.mLocId].deviceType
		const userPrivileges = state.user.privileges
		const masterDetails = state.masterDetails[props.mLocId]
		return getMasterDetailsFormats(userPrivileges, deviceType).map((dataType) => {
			return {
				id: dataType.id,
				label: dataType.label,
				dataFormatted: dataType.formatString(
					...dataType.args.map((arg) => {
						return masterDetails[arg]
					}),
				),
			}
		})
	} else {
		return null
	}
}

const getMasterData = (state, props) => {
	const latestTimestamp = state.masterDataLatestTimestamp[props.mLocId]
	const masterData = state.masterData[`${props.mLocId}#${latestTimestamp}`]
	if (masterData && state.masterDetails[props.mLocId]) {
		const deviceType = state.masterDetails[props.mLocId].deviceType
		const userPrivileges = state.user.privileges
		const tz = state.layoutDetails[props.layoutId]["tz"]
		return getMasterDataFormats(userPrivileges, deviceType, tz)
			.map((dataType) => {
				const dataFormatted = dataType.formatString(
					...dataType.args.map((arg) => {
						return masterData[arg]
					}),
				)
				if (dataFormatted === null) return null
				return {
					id: dataType.id,
					label: dataType.label,
					dataFormatted,
				}
			})
			.filter((data) => data !== null)
	} else {
		return null
	}
}

const mapStateToProps = (state, props) => ({
	masterDetails: getMasterDetails(state, props),
	masterData: getMasterData(state, props),
	masterInfo: state.masterDetails[props.mLocId] || {},
	userPrivileges: state.user.privileges,
	location: props.location,
	history: props.history,
})

const mapDispatchToProps = (dispatch, _props) => ({
	updateLabel: (mLocId, layoutId, label) => dispatch(updateMasterDetails(mLocId, layoutId, { label })),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MasterDetails)

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import MasterArea from "./MasterArea"
import { overlays } from "constants/overlays"
import { getMasterLocIds } from "utils/url"
import { focusMasters } from "constants/dataView"

import { colorBrown, colorGrey, colorPrimary, colorRed, colorYellow } from "../../../../colors"

const getMasterArea = (state, props) => {
	const masterDetailsMLocId = state.masterDetails[props.mLocId]
	if (masterDetailsMLocId && Object.prototype.hasOwnProperty.call(masterDetailsMLocId, ["area"])) {
		return masterDetailsMLocId["area"]
	} else {
		return []
	}
}

const getMasterName = (state, props) => {
	if (state.masterDetails[props.mLocId]) {
		return state.masterDetails[props.mLocId].name
	} else {
		return null
	}
}

const getMasterLabel = (state, props) => {
	const thisMasterDetails = state.masterDetails[props.mLocId]
	return thisMasterDetails ? thisMasterDetails.label : null
}

const isSelected = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	let locIds = params.get("locIds")
	let navLocId = params.get("navLocId")
	if (!locIds && !navLocId) return false
	return (
		(locIds &&
			locIds
				.split(",")
				.map((loc) => loc.split(":")[0])
				.includes(props.mLocId)) ||
		(navLocId && navLocId.split(":")[0] === props.mLocId)
	)
}

const setMasterActive = (props) => (e) => {
	let params = new URLSearchParams(props.location.search)
	let activeIds = params.get("locIds")
	const { originalEvent: oEvent = {} } = e
	if (activeIds && (oEvent.ctrlKey || oEvent.metaKey)) {
		activeIds = activeIds.split(",")
	} else {
		activeIds = []
	}
	activeIds.push(props.mLocId)
	params.set("locIds", activeIds.join(","))
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const openMasterDetails = (props) => () => {
	const params = new URLSearchParams(props.location.search)
	params.set("device-details", [props.layoutId, props.mLocId].join(":"))
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const getLabelVisible = (state, props) => {
	const zoomLevel = state.layoutZoomLevel
	if (!zoomLevel) return false
	const labelZoomThreshold =
		state.layoutDetails[props.layoutId] && state.layoutDetails[props.layoutId]["labelZoomThreshold"]
	if (!labelZoomThreshold) return true
	return zoomLevel >= labelZoomThreshold
}

const getMasterAreaColor = (state, props) => {
	const activeMasters = getMasterLocIds(props.location.search)
	let masterIsActive
	const { dataView } = state
	let markerColor, markerFill
	markerColor = markerFill = colorGrey

	if (activeMasters < 1) {
		masterIsActive = true
	} else {
		masterIsActive = activeMasters.includes(props.mLocId)
	}

	switch (props.activeOverlay) {
		case overlays.ALARMS: {
			const timestamp = props.timestamp || state.masterDataLatestTimestamp[props.mLocId]
			if (state.masterDataStatus === "resolved" && focusMasters.includes(dataView)) {
				const masterData = state.masterData[`${props.mLocId}#${timestamp}`] || {}
				let { alarms } = masterData
				const parsedAlarms = alarms ? BigInt(`0x${alarms}`) : 0n
				let filter = filter ? BigInt(`0b${props.filter}`) : 0n

				if (alarms) {
					if ((filter && (parsedAlarms & filter) === 0n) || !masterIsActive) {
						markerColor = markerFill = colorBrown
					} else {
						markerColor = markerFill = colorRed
					}
				} else {
					markerColor = markerFill = colorPrimary
				}
			}
			break
		}
		case overlays.FLAGS: {
			const timestamp = props.timestamp || state.masterDataLatestTimestamp[props.mLocId]
			if (state.masterDataStatus === "resolved" && focusMasters.includes(dataView)) {
				const masterData = state.masterData[`${props.mLocId}#${timestamp}`] || {}
				let { flags } = masterData
				const parsedFlags = flags ? BigInt(`0x${flags}`) : 0n
				let filter = props.filter ? BigInt(`0b${props.filter}`) : 0n
				if (!isNaN(flags)) {
					if (flags) {
						if ((filter && (parsedFlags & filter) === 0n) || !masterIsActive) {
							markerColor = markerFill = colorBrown
						} else {
							markerColor = markerFill = colorYellow
						}
					} else {
						markerColor = markerFill = colorPrimary
					}
				}
			}
			break
		}
		default:
			break
	}
	return { markerColor, markerFill }
}

const getUrlTimestamp = (props) => {
	const params = new URLSearchParams(props.location.search)
	return parseInt(params.get("ts"))
}

const mapStateToProps = (state, props) => {
	const { markerColor, markerFill } = getMasterAreaColor(state, props)
	return {
		markerColor,
		markerFill,
		masterArea: getMasterArea(state, props),
		name: getMasterName(state, props),
		label: getMasterLabel(state, props),
		selected: isSelected(state, props),
		labelVisible: getLabelVisible(state, props),
		timestamp: getUrlTimestamp(props),
		userPrivileges: state.user.privileges,
		deviceType: (state.masterDetails[props.mLocId] || {})["deviceType"],
		dataView: state.dataView,
	}
}

const mapDispatchToProps = (dispatch, props) => ({
	setMasterActive: setMasterActive(props),
	openMasterDetails: openMasterDetails(props),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MasterArea)

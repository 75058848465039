import { connect } from "react-redux"
import NodeLocLabel from "./NodeLocLabel"

export const getPosition = (nodeDetailsByMLocId, mLocId, nLocId) => {
	const nodeDetails = (nodeDetailsByMLocId[mLocId] || {})[nLocId] || {}
	if (nodeDetails["xLoc"] === undefined || nodeDetails["yLoc"] === undefined) return []
	return [nodeDetails["yLoc"], nodeDetails["xLoc"]]
}

const mapStateToProps = (state, props) => ({
	position: getPosition(state.nodeDetails, props.mLocId, props.nLocId),
	label: props.nLocId || "",
})

const mapDispatchToProps = (dispatch, props) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(NodeLocLabel)

import React, { useState } from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import Button from "@material-ui/core/Button"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import FormControlLabel from "@material-ui/core/FormControlLabel"

const styles = (theme) => ({})

const SelectMasterArea = ({
	classes,
	masters,
	assign,
	configMasterAreas,
	setConfigMasterArea,
	enqueueSnackbar,
	handleClose,
}) => {
	const [selected, setSelected] = useState(null)

	const handleAccept = () => {
		const areaId = assign["value"]
		const { [areaId]: value, ...masterAreas } = configMasterAreas
		setConfigMasterArea({
			...masterAreas,
			[selected]: value,
		})
		handleClose()
		enqueueSnackbar("Master Area not saved to database until final configuration is save.", {
			variant: "warning",
		})
	}

	return (
		<React.Fragment>
			<DialogTitle id="confirmation-dialog-title">Assign to Master</DialogTitle>
			<DialogContent>
				<RadioGroup
					aria-label="Master"
					name="master"
					value={selected || ""}
					onChange={(e) => setSelected(e.target.value)}
				>
					{masters.map((master) => (
						<FormControlLabel
							disabled={configMasterAreas.hasOwnProperty(master.mLocId) && configMasterAreas[master.mLocId].length > 0}
							value={master.mLocId}
							key={master.mLocId}
							control={<Radio color="primary" />}
							label={master.name}
						/>
					))}
				</RadioGroup>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Cancel
				</Button>
				<Button onClick={handleAccept} disabled={!selected} variant="contained" color="primary">
					Accept
				</Button>
			</DialogActions>
		</React.Fragment>
	)
}

SelectMasterArea.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SelectMasterArea)

import React, { useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { Circle } from "react-leaflet"
import { randomColorArray } from "constants/color"

const getMarkerColors = (masterIndex) => {
	if (masterIndex === -1) return "#A6A6A6"
	return randomColorArray[masterIndex % 50]
}

const NodeMarker = ({ nodeInfo, radius, colorIndex }) => {
	const markerColor = getMarkerColors(colorIndex)
	const center = useMemo(() => [nodeInfo.yLoc, nodeInfo.xLoc], [nodeInfo.xLoc, nodeInfo.yLoc])

	const handleClick = useCallback(() => {
		const message = [`X: ${nodeInfo.xLoc}`, `Y: ${nodeInfo.yLoc}`].join("\n")
		alert(message)
	}, [nodeInfo.xLoc, nodeInfo.yLoc])

	return <Circle onClick={handleClick} color={markerColor} fillColor={markerColor} center={center} radius={radius} />
}

NodeMarker.displayName = "NodeMarker"

NodeMarker.propTypes = {
	nodeInfo: PropTypes.object.isRequired,
	radius: PropTypes.number,
	colorIndex: PropTypes.number,
}

export default NodeMarker

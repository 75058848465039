import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom"
import Auth from "@aws-amplify/auth"

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		overflow: "auto",
	},
	paper: {
		maxWidth: "500px",
		textAlign: "center",
		margin: "auto",
		[theme.breakpoints.up("sm")]: {
			marginTop: theme.spacing(5),
			marginBottom: theme.spacing(2),
		},
	},
	content: {
		padding: theme.spacing(2),
	},
	logo: {
		width: "90%",
	},
	button: {
		marginTop: theme.spacing(1),
	},
}))

const getErrorField = (errCode) => {
	switch (errCode) {
		case "InvalidPasswordException":
		case "PasswordResetRequiredException":
			return "password"
		case "InternalErrorException":
		case "InvalidParameterException":
		case "NotAuthorizedException":
		case "LimitExceededException":
		case "ResourceNotFoundException":
		case "TooManyRequestsException":
		case "UserNotConfirmedException":
		case "UserNotFoundException":
		default:
			return "email"
	}
}

const AccessRequest = () => {
	const classes = useStyles()
	const [pendingResponse, setPendingResponse] = useState(false)
	const [errorFeedback, setErrorFeedback] = useState({})
	const [capsLockIsEnabled, setCapsLockIsEnabled] = useState(false)
	const [entries, setEntries] = useState({})

	const handleChange = (event) => {
		event.persist()
		setErrorFeedback({})
		setEntries((currEntries) => ({ ...currEntries, [event.target.id]: event.target.value }))
	}

	const submit = async (event) => {
		event.preventDefault()
		if (entries.email !== entries.emailConf) {
			setErrorFeedback({
				emailConf: "Email confirmation doesn't match",
			})
			setEntries((entries) => ({ ...entries, password: "", passwordConf: "" }))
		} else if (entries.password !== entries.passwordConf) {
			setErrorFeedback({
				passwordConf: "Password confirmation doesn't match",
			})
			setEntries((entries) => ({ ...entries, password: "", passwordConf: "" }))
		} else {
			setPendingResponse(true)
			console.log(entries)
			try {
				const result = await Auth.signUp({
					username: entries.email,
					password: entries.password,
					attributes: {
						"custom:company": entries.companyName,
						"custom:nameFirst": entries.nameFirst,
						"custom:nameLast": entries.nameLast,
					},
				})
				console.log(result)
				setPendingResponse(false)
			} catch (err) {
				console.log(err)
				setErrorFeedback({ [getErrorField(err.code)]: err.message || "An error occurred." })
				setEntries((entries) => ({ ...entries, password: "", passwordConf: "" }))
				setPendingResponse(false)
			}
		}
	}

	return (
		<div className={classes.root}>
			<Paper className={classes.paper} elevation={1}>
				{pendingResponse && <LinearProgress />}
				<div className={classes.content}>
					<img className={classes.logo} alt="GameChange Logo" src={require("../../../images/logo-dark.png")} />
					<Typography component="h1" variant="h5">
						Access Request Form
					</Typography>
					<form className={classes.form} onSubmit={submit}>
						<TextField
							id="nameFirst"
							label="First Name"
							type="text"
							margin="normal"
							variant="outlined"
							value={entries.nameFirst || ""}
							onChange={handleChange}
							autoComplete="given-name"
							autoFocus
							required
							fullWidth
						/>
						<TextField
							id="nameLast"
							label="Last Name"
							type="text"
							margin="normal"
							variant="outlined"
							value={entries.nameLast || ""}
							onChange={handleChange}
							autoComplete="family-name"
							required
							fullWidth
						/>
						<TextField
							id="companyName"
							label="Company Name"
							type="text"
							margin="normal"
							variant="outlined"
							value={entries.companyName || ""}
							onChange={handleChange}
							required
							fullWidth
						/>
						<TextField
							id="email"
							label="Email Address"
							type="email"
							margin="normal"
							variant="outlined"
							value={entries.email || ""}
							error={Boolean(errorFeedback.email)}
							helperText={errorFeedback.email}
							onChange={handleChange}
							autoComplete="email"
							required
							fullWidth
						/>
						<TextField
							id="emailConf"
							label="Confirm Email Address"
							type="email"
							margin="normal"
							variant="outlined"
							value={entries.emailConf || ""}
							error={Boolean(errorFeedback.emailConf)}
							helperText={errorFeedback.emailConf}
							onChange={handleChange}
							autoComplete="email"
							required
							fullWidth
						/>
						<TextField
							id="password"
							label="Password"
							type="password"
							margin="normal"
							variant="outlined"
							value={entries.password || ""}
							error={Boolean(errorFeedback.password)}
							helperText={errorFeedback.password || (capsLockIsEnabled && "CapsLock Is Enabled")}
							onKeyDown={(e) => setCapsLockIsEnabled(e.getModifierState("CapsLock"))}
							onChange={handleChange}
							autoComplete="current-password"
							required
							fullWidth
						/>
						<TextField
							id="passwordConf"
							label="Confirm Password"
							type="password"
							margin="normal"
							variant="outlined"
							value={entries.passwordConf || ""}
							error={Boolean(errorFeedback.passwordConf)}
							helperText={errorFeedback.passwordConf || (capsLockIsEnabled && "CapsLock Is Enabled")}
							onKeyDown={(e) => setCapsLockIsEnabled(e.getModifierState("CapsLock"))}
							onChange={handleChange}
							autoComplete="current-password"
							required
							fullWidth
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.button}
							disabled={pendingResponse}
						>
							Submit Access Request
						</Button>
						<Button fullWidth color="primary" component={Link} to="/" className={classes.button}>
							Go to Sign In
						</Button>
					</form>
				</div>
			</Paper>
		</div>
	)
}

export default AccessRequest

import { connect } from "react-redux"
import EditLayout from "./EditLayout"
import { compose } from "redux"
import { withRouter } from "react-router"
import { withSnackbar } from "notistack"

import { createLayoutDetails, updateLayoutDetails } from "store/actions/setters"

import { fetchMasterDetails } from "store/actions/fetchers"

const handleNewLayoutRedirect = (props) => (newLayoutId) => {
	const params = new URLSearchParams(props.location.search)
	params.set("modify-layout", newLayoutId)
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const mapStateToProps = (state, props) => {
	const urlParams = new URLSearchParams(props.location.search)
	const tabIndex = parseInt(urlParams.get("tab"), 10)
	return {
		tabIndex,
		layoutDetails: state.layoutDetails[props.layoutId] || {},
	}
}

const mapDispatchToProps = (dispatch, props) => ({
	handleNewLayoutRedirect: (newLayoutId) => handleNewLayoutRedirect(props)(newLayoutId),
	fetchMasterDetails: (layoutId) => dispatch(fetchMasterDetails(layoutId)),
	updateLayoutDetails: (layoutId, layoutDetails) => dispatch(updateLayoutDetails(layoutId, layoutDetails)),
	createLayoutDetails: (name, layoutDetails) => dispatch(createLayoutDetails(name, layoutDetails)),
})

export default compose(withSnackbar, withRouter, connect(mapStateToProps, mapDispatchToProps))(EditLayout)

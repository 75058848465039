import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import RefreshIcon from "@material-ui/icons/Refresh"
import CircularProgress from "@material-ui/core/CircularProgress"
import { formatTimestamp } from "utils/formatters"
import { useSnackbar } from "notistack"
import LastPageIcon from "@material-ui/icons/LastPage"
import { privileges } from "constants/auth"
import { useAuthorization } from "utils/auth"

const useStyles = makeStyles({
	layoutDisplayTime: {
		borderRadius: "25px",
		background: "#20447E",
		color: "#ffffff",
		boxShadow: "1px 1px 2px #000000",
		padding: "10px",
		left: "50%",
		transform: "translate(-50%)",
		whiteSpace: "nowrap",
		lineHeight: "20px",
		position: "absolute",
		opacity: "0.8",
		bottom: "15px",
		zIndex: "1000",
	},
	actionIcon: {
		verticalAlign: "middle",
		cursor: "pointer",
		right: "0px",
		paddingLeft: "2px",
	},
	timestamp: {
		display: "inline-block",
		verticalAlign: "middle",
		paddingLeft: "8px",
	},
	nodata: {
		display: "inline-block",
		verticalAlign: "middle",
		paddingLeft: "10px",
		paddingRight: "10px",
	},
})

const LayoutTimestamp = ({
	fetchMasterDataByLayoutId,
	activeMasters,
	dataIsLoaded,
	tz,
	timeLocale,
	fetchNodeData,
	layoutId,
	timestamp,
	latest,
	displayLatest,
	userPrivileges,
	masterDetails,
	mLocIds,
	dataView,
}) => {
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const accessAllowed = useAuthorization(userPrivileges)

	const refreshData = async (layoutId, activeMasters) => {
		const deviceType = (masterDetails[mLocIds[0]] || {})["deviceType"]
		let requests = []
		if (dataView === "node") {
			requests = activeMasters.map((mLocId) =>
				fetchNodeData(layoutId, mLocId, userPrivileges, masterDetails[mLocId]["deviceType"]),
			)
		}
		fetchMasterDataByLayoutId(layoutId, userPrivileges, deviceType)

		const statuses = await Promise.all(requests)

		if (statuses.includes("networkError")) {
			enqueueSnackbar("Network Error: Data Refresh Failed", {
				variant: "error",
				autoHideDuration: 2000,
			})
		} else if (statuses.includes("error")) {
			enqueueSnackbar("Unknown Error: Data Refresh Failed", {
				variant: "error",
				autoHideDuration: 2000,
			})
		} else if (statuses.reduce((acc, curr) => acc && curr === "empty", true)) {
			enqueueSnackbar("No Data Found", {
				variant: "info",
				autoHideDuration: 2000,
			})
		}
	}

	if (dataIsLoaded) {
		return (
			<div className={classes.layoutDisplayTime}>
				{timestamp ? (
					<div className={classes.timestamp}>{formatTimestamp(timestamp, tz)}</div>
				) : (
					<div className={classes.nodata}>No Data</div>
				)}
				{accessAllowed(privileges.DATA_SNAPSHOT) &&
					(latest ? (
						<RefreshIcon
							className={classes.actionIcon}
							onClick={() => {
								refreshData(layoutId, activeMasters)
							}}
						/>
					) : (
						<LastPageIcon className={classes.actionIcon} onClick={displayLatest} />
					))}
			</div>
		)
	} else {
		return (
			<div className={classes.layoutDisplayTime}>
				<div className={classes.timestamp}>{"Loading..."}</div>
				<CircularProgress size={20} thickness={7} style={{ color: "white" }} className={classes.actionIcon} />
			</div>
		)
	}
}

export default LayoutTimestamp

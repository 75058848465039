import React, { Children } from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: theme.spacing(5),
		right: 0,
	},
	button: {
		position: "relative",
		margin: theme.spacing(1),
		zIndex: 500,
	},
}))

const ButtonColumn = ({ children }) => {
	const classes = useStyles()
	const buttonArray = Children.toArray(children)

	return (
		<div className={classes.root}>
			{buttonArray
				.filter((button) => button)
				.map((button) => (
					<div key={button.key} className={classes.button}>
						{button}
					</div>
				))}
		</div>
	)
}

export default ButtonColumn

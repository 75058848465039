import React from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import { Popup } from "react-leaflet"
import CheckIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"
import WifiOffIcon from "@material-ui/icons/WifiOff"

const styles = (theme) => ({
	popup: {
		textAlign: "center",
		cursor: "pointer",
	},
	popupId: {
		fontWeight: "bold",
	},
	close: {
		position: "absolute",
		right: 0,
		top: 0,
		padding: 3,
		paddingRight: 8,
		cursor: "pointer",
	},
	nav: {
		color: theme.palette.primary.main,
		fontWeight: "bold",
	},
	componentList: {
		margin: "10px 0 0 0",
		padding: 0,
	},
	componentListItem: {
		listStyleType: "none",
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	icon: {
		height: "12px",
		width: "12px",
		margin: "0 5px",
	},
	dividingLine: {
		padding: "0",
		margin: "10px 0",
		width: "100%",
		height: "1px",
		backgroundColor: "#000",
	},
})

const NodePopup = ({
	classes,
	mLocId,
	position,
	setNodeInactive,
	nId,
	rowNum,
	fromSouth,
	handleClick,
	nav,
	nodeDataNodeId,
	componentsScanned,
	isDeviceIdAssignment,
}) => {
	if (position.length !== 2) return null

	let originalNodeId

	if (nodeDataNodeId && nodeDataNodeId !== nId) {
		originalNodeId = nodeDataNodeId
	}

	return (
		<Popup position={position} closeButton={false} closeOnClick={false} autoClose={false} autoPan={false}>
			<div>
				<div className={classes.close} onClick={setNodeInactive}>
					x
				</div>
				<div className={classes.popup} onClick={handleClick}>
					{nav && <div className={classes.nav}>You Are Here</div>}
					<div className={classes.popupId}>Node ID: {nId ? `0x${nId.slice(-4)}` : "Not Assigned"}</div>
					{originalNodeId ? <div>Original Node ID: {`0x${originalNodeId.slice(-4)}`}</div> : null}
					<div>Row: {rowNum}</div>
					<div>From South: {fromSouth}</div>
				</div>
			</div>
			{componentsScanned && isDeviceIdAssignment && (
				<div>
					<div className={classes.dividingLine}></div>
					<ul className={classes.componentList}>
						{Object.values(componentsScanned).map((component, index) => {
							return (
								<li key={index} className={classes.componentListItem}>
									{component.status === "success" ? (
										<CheckIcon color={"primary"} className={classes.icon} />
									) : component.status === "pending" ? (
										<WifiOffIcon color={"primary"} className={classes.icon} />
									) : (
										<CloseIcon color={"error"} className={classes.icon} />
									)}
									{component.name}
								</li>
							)
						})}
					</ul>
				</div>
			)}
		</Popup>
	)
}

NodePopup.propTypes = {
	classes: PropTypes.object.isRequired,
	position: PropTypes.array.isRequired,
	setNodeInactive: PropTypes.func.isRequired,
	componentsScanned: PropTypes.object,
	isDeviceIdAssignment: PropTypes.bool,
}

export default withStyles(styles)(NodePopup)

import { useLocation } from "react-router-dom"
import { matchPath } from "react-router"

export const mapIsAvailable = (pathname) => {
	if (matchPath(pathname, { path: "/layouts" }) !== null) return true
	return false
}

export const useUrlPath = () => {
	const { pathname } = useLocation()

	return {
		mapIsAvailable: mapIsAvailable(pathname),
	}
}

import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import PropTypes from "prop-types"
import React from "react"
import { Typography } from "@material-ui/core"

const LabeledSelect = (props) => {
	const helperTextElement = props.helperText ? (
		<Typography variant={"caption"} color={props.error ? "error" : "initial"}>
			{props.helperText}
		</Typography>
	) : null
	return (
		<>
			<InputLabel style={{ marginTop: "8px", marginBottom: "4px" }}>{props.label}</InputLabel>
			<Select
				id={props.id}
				type="number"
				variant={props.variant}
				value={props.value}
				onChange={props.onChange}
				fullWidth={props.fullWidth}
				style={{ width: "100%" }}
			>
				<MenuItem value={""} disabled>
					{props.label}
				</MenuItem>
				{props.items.map(([value, label]) => {
					return (
						<MenuItem value={value} key={label}>
							{label}
						</MenuItem>
					)
				})}
			</Select>
			{helperTextElement}
		</>
	)
}

LabeledSelect.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	variant: PropTypes.string.isRequired,
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired,
	items: PropTypes.arrayOf(PropTypes.array),
	helperText: PropTypes.string,
	error: PropTypes.bool,
	fullWidth: PropTypes.bool,
}

export default LabeledSelect

import { overlays } from "constants/overlays"

export const dataViewLabels = {
	node: "Nodes",
	master: "Masters",
	replacementCampaign: "Replacement Campaign",
}

export const focusMasters = ["master"]

export const focusNodes = ["node", "replacementCampaign"]

export const exclusiveViewOverlay = {
	master: [overlays.ALARMS, overlays.FLAGS],
	replacementCampaign: [overlays.DEVICEIDASSIGNMENT],
}

import { connect } from "react-redux"
import NodeMarker from "./NodeMarker"
import { getTranslatedCenter } from "utils"

function getTranslatedNode(state, props) {
	const node = state.configLocations[props.index]
	const translations = state.configNodeTranslations
	const newCoords = getTranslatedCenter(node, translations)
	return { ...node, xLoc: newCoords[1] ?? node.xLoc, yLoc: newCoords[0] ?? node.yLoc }
}

const mapStateToProps = (state, props) => ({
	nodeInfo: getTranslatedNode(state, props),
	radius: state.configNodeTranslations.markerRadius || 0.5,
})

const mapDispatchToProps = (_dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(NodeMarker)

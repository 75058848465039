import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import { withSnackbar } from "notistack"
import SelectMasterArea from "./SelectMasterArea"
import { setConfigMasterArea, setConfigAssignment } from "store/actions/reduxActions"

const mapStateToProps = (state, props) => {
	const { layoutId } = state.configMasterAreas
	const masters = []
	if (state.layoutDetails.hasOwnProperty(layoutId) && state.layoutDetails[layoutId]["mLocIds"]) {
		state.layoutDetails[layoutId]["mLocIds"].forEach((mLocId) => {
			if (state.masterDetails.hasOwnProperty(mLocId)) {
				masters.push({
					mLocId,
					name: state.masterDetails[mLocId]["name"],
				})
			}
		})
	}
	return {
		masters,
		assign: state.configAssign,
		configMasterAreas: state.configMasterAreas,
	}
}

const mapDispatchToProps = (dispatch) => ({
	setConfigMasterArea: (areaEdits) => dispatch(setConfigMasterArea(areaEdits)),
	handleClose: () => dispatch(setConfigAssignment(null)),
})

export default compose(withSnackbar, withRouter, connect(mapStateToProps, mapDispatchToProps))(SelectMasterArea)

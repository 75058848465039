import { connect } from "react-redux"
import MasterDataView from "./MasterDataView"

const mapStateToProps = (state, props) => ({
	utcOffset: (state.layoutDetails[props.layoutId] || {})["utcOffset"],
	timeLocale: state.timeLocale,
})

const mapDispatchToProps = (dispatch, props) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MasterDataView)

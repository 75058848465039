import React, { useEffect } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { privileges } from "constants/auth"
import PrivilegedRoute from "components/auth/PrivilegedRoute"
import Button from "@material-ui/core/Button"
import { createTheme, makeStyles } from "@material-ui/core/styles"
import FlashPage from "components/FlashPage"
import RegisterUser from "components/auth/RegisterUser"
import SignIn from "components/auth/SignIn"
import ResetPassword from "components/auth/ResetPassword"
import DeviceLocations from "components/DeviceLocations"
import Dialog from "components/Dialog"
import DialogDevice from "components/DialogDevice"
import DialogDeviceIdInput from "components/DialogDeviceIdInput"
import DialogDeviceIdSearch from "components/DialogDeviceIdSearch"
import Drawer from "components/Drawer"
import AppBarContainer from "components/AppBarContainer"
import useServiceworker from "utils/serviceworker"
import AccessRequest from "components/auth/AccessRequest"
import PropTypes from "prop-types"
import grey from "@material-ui/core/colors/grey"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"
import { applyMiddleware, createStore } from "redux"
import rootReducer from "../../store/reducers"
import ZendeskWidget from "../ZendeskWidget/ZendeskWidget"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { useEffectWhenDbIsReady } from "../../utils/custom-hooks"

const useStyles = makeStyles({
	root: {
		display: "flex",
		width: "100%",
		height: "100%",
	},
})

function isEdge() {
	const match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(navigator.userAgent)
	return !!match
}

const App = ({
	enqueueSnackbar,
	closeSnackbar,
	initAuthObserver,
	userId,
	appInitialized,
	loadCachedNodeIdChanges,
	loadCachedMasterIdChanges,
	loadCachedTaskChanges,
	subscribeOnlineStatus,
	loadCachedApiRequests,
}) => {
	const classes = useStyles()
	useServiceworker(enqueueSnackbar)

	useEffect(() => {
		if (isEdge()) {
			enqueueSnackbar("This browser is not supported; Use Chrome or Safari", {
				variant: "error",
				persist: true,
				action: (key) => (
					<Button
						onClick={() => {
							closeSnackbar(key)
						}}
					>
						Dismiss
					</Button>
				),
			})
		}
	}, [enqueueSnackbar, closeSnackbar])

	useEffect(() => {
		if (!("indexedDB" in window)) {
			enqueueSnackbar("Offline access is not supported or is disabled.", {
				variant: "error",
				persist: false,
				action: (key) => (
					<Button
						onClick={() => {
							closeSnackbar(key)
						}}
					>
						Dismiss
					</Button>
				),
			})
		}
	}, [closeSnackbar, enqueueSnackbar])

	useEffectWhenDbIsReady(() => {
		initAuthObserver()
	}, [initAuthObserver])

	useEffectWhenDbIsReady(() => {
		loadCachedNodeIdChanges()
	}, [loadCachedMasterIdChanges, loadCachedNodeIdChanges])

	useEffectWhenDbIsReady(() => {
		loadCachedMasterIdChanges()
	}, [loadCachedMasterIdChanges])

	useEffectWhenDbIsReady(() => {
		loadCachedTaskChanges()
	}, [loadCachedTaskChanges])

	useEffect(() => {
		if (userId) {
			subscribeOnlineStatus()
		}
	}, [subscribeOnlineStatus, userId])

	useEffectWhenDbIsReady(() => {
		loadCachedApiRequests()
	}, [loadCachedApiRequests])

	return (
		<div className={classes.root}>
			{!appInitialized ? (
				<FlashPage />
			) : (
				<BrowserRouter>
					<Switch>
						<Route exact path="/(index.html)?" component={SignIn} />
						<Route exact path="/reset-password" component={ResetPassword} />
						<Route exact path="/request-access" component={AccessRequest} />
						<PrivilegedRoute
							exact
							path="/register-user"
							requiredPrivilege={privileges.ADMIN}
							component={RegisterUser}
						/>
						<PrivilegedRoute
							path="/device-locations/:layoutId"
							requiredPrivilege={privileges.VIEW}
							component={DeviceLocations}
						/>
						<Route component={AppBarContainer} />
					</Switch>
					<Dialog />
					<DialogDevice />
					<DialogDeviceIdInput />
					<DialogDeviceIdSearch />
					<Drawer />
				</BrowserRouter>
			)}
			<ZendeskWidget />
		</div>
	)
}

// TODO: Specify
App.propTypes = {
	enqueueSnackbar: PropTypes.any,
	closeSnackbar: PropTypes.any,
	initAuthObserver: PropTypes.any,
	userId: PropTypes.any,
	appInitialized: PropTypes.any,
	fetchLayoutDetails: PropTypes.any,
	loadCachedNodeIdChanges: PropTypes.any,
	loadCachedMasterIdChanges: PropTypes.any,
	loadCachedTaskChanges: PropTypes.any,
	subscribeOnlineStatus: PropTypes.any,
	loadCachedApiRequests: PropTypes.func,
}

const theme = createTheme({
	overrides: {
		MuiTableRow: {
			root: {
				"&.Mui-selected": {
					backgroundColor: grey[200],
				},
				"&.Mui-selected:hover": {
					backgroundColor: grey[100],
				},
			},
		},
	},
	palette: {
		primary: {
			light: "#7898cc",
			main: "#20447E",
			dark: "#002884",
			contrastText: "#fff",
		},
		secondary: {
			light: grey[200],
			main: grey[300],
			dark: grey[400],
			contrastText: "#000",
		},
	},
	typography: {
		useNextVariants: true,
	},
})

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["nodeComponentScanned"], // Add reducers you want to persist here
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middlewares = [thunk]
let store
if (process.env.NODE_ENV === "development") {
	const composeEnhancers = composeWithDevTools({})
	store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)))
} else {
	store = createStore(persistedReducer, applyMiddleware(...middlewares))
}
persistStore(store)
export { theme, store }
export default App

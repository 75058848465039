import React, { useMemo } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Paper from "@material-ui/core/Paper"
import Drawer from "@material-ui/core/Drawer"

import DataTable from "./DataTable"
import { findDuplicates } from "utils"

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.up("sm")]: {
			position: "absolute",
			left: "20px",
			top: theme.spacing(6),
			zIndex: 1000,
			maxWidth: "35%",
			minWidth: "350px",
			overFlow: "auto",
		},
	},
}))

const OverlayTable = ({ setDataTableIsOpen, dataTableIsOpen, nodeDetails, nIdsPendingUpload, location }) => {
	const classes = useStyles()
	const theme = useTheme()
	const smallScreen = useMediaQuery(theme.breakpoints.down("xs"))

	const params = new URLSearchParams(location.search)
	let locIds = params.get("locIds")

	const duplicateNodeIdsByMaster = useMemo(() => {
		const getActiveMasterList = () => {
			if (!locIds) return []
			return locIds.split(",").map((loc) => loc.split(":")[0])
		}
		const activeMasters = getActiveMasterList()
		let duplicateNodeIdsByMaster = {}
		activeMasters.forEach((mLocId) => {
			const nIds = []
			const nLocIds = Object.keys(nodeDetails[mLocId] || {})
			nLocIds.forEach((nLocId) => {
				const nId = nIdsPendingUpload[`${mLocId}#${nLocId}`] || (nodeDetails[mLocId] || {})[nLocId].nId
				if (nId) {
					nIds.push(nId)
				}
			})
			duplicateNodeIdsByMaster[mLocId] = findDuplicates(nIds)
		})
		return duplicateNodeIdsByMaster
	}, [nodeDetails, nIdsPendingUpload, locIds])

	if (smallScreen && dataTableIsOpen.small) {
		return (
			<Drawer
				anchor="bottom"
				variant="persistent"
				open={dataTableIsOpen.small}
				onClose={() => setDataTableIsOpen(false)}
			>
				<Paper className={classes.paper}>
					<DataTable duplicateNodeIdsByMaster={duplicateNodeIdsByMaster} />
				</Paper>
			</Drawer>
		)
	}
	if (!smallScreen && dataTableIsOpen.large) {
		return <DataTable duplicateNodeIdsByMaster={duplicateNodeIdsByMaster} />
	}
	return null
}

export default OverlayTable

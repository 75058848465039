import OverlaySummaryHeader from "./OverlaySummaryHeader"
import { connect } from "react-redux"
import { setSummaryIsOpen } from "store/actions/reduxActions"

const mapDispatchToProps = (dispatch) => ({
	setSummaryIsOpen(isOpen) {
		return dispatch(setSummaryIsOpen(isOpen))
	},
})

export default connect(null, mapDispatchToProps)(OverlaySummaryHeader)

import React, { useEffect, useCallback } from "react"
import { makeStyles } from "@material-ui/core/styles"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Cancel"
import Button from "@material-ui/core/Button"
import Fab from "@material-ui/core/Fab"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import { useSnackbar } from "notistack"
import clsx from "clsx"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
	root: {
		zIndex: 1000,
		[theme.breakpoints.up("sm")]: {
			bottom: theme.spacing(10),
			left: theme.spacing(2),
		},
		[theme.breakpoints.down("xs")]: {
			bottom: theme.spacing(10),
			left: theme.spacing(1),
			right: theme.spacing(1),
			display: "flex",
		},
	},
	dynamicPositioning: {
		position: "absolute",
	},
	buttonOuter: {
		flex: 1,
	},
	button: {
		margin: theme.spacing(0.5),
	},
}))
const NodeLocationNavigator = ({
	handleRememberComponentSelection,
	rememberComponentSelection,
	handleComponentSelection,
	locationInfo,
	changeActiveNode,
	activeMasterName,
	activateEntry,
	positionAbsolute = true,
	displayOpenBtn = true,
}) => {
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const { eastNLocId, westNLocId, eastMLocId, westMLocId, mLocId, nLocId } = locationInfo

	const handleKeyUp = useCallback(
		(e) => {
			e.preventDefault()
			// TODO: Only when not focused on the input textbox
			if (e.keyCode === 37) {
				changeActiveNode(westMLocId, westNLocId, mLocId, nLocId)
			} else if (e.keyCode === 39) {
				changeActiveNode(eastMLocId, eastNLocId, mLocId, nLocId)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[eastMLocId, eastNLocId, mLocId, nLocId],
	)

	useEffect(() => {
		window.addEventListener("keyup", handleKeyUp)
		return () => window.removeEventListener("keyup", handleKeyUp)
	}, [handleKeyUp])

	useEffect(() => {
		if (activeMasterName) {
			enqueueSnackbar(`Entering ${activeMasterName} Area`, {
				variant: "warning",
				autoHideDuration: 1000,
				resumeHideDuration: 0,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeMasterName])

	return (
		<div className={`${classes.root} ${positionAbsolute ? classes.dynamicPositioning : undefined}`}>
			<Button
				variant="contained"
				color="primary"
				size="large"
				className={clsx(classes.buttonOuter, classes.button)}
				disabled={westNLocId === undefined}
				onClick={() => changeActiveNode(westMLocId, westNLocId, mLocId, nLocId)}
			>
				Move West
			</Button>
			{displayOpenBtn && (
				<Fab
					color="primary"
					size="small"
					aria-label="select-node-location"
					disabled={mLocId === undefined || nLocId === undefined}
					onClick={() => activateEntry(mLocId, nLocId)}
					className={classes.button}
				>
					<KeyboardArrowUpIcon />
				</Fab>
			)}
			<Fab
				color="primary"
				size="small"
				aria-label="save component selected to scan"
				disabled={mLocId === undefined || nLocId === undefined}
				onClick={() => {
					handleRememberComponentSelection(!rememberComponentSelection)
					if (!rememberComponentSelection) handleComponentSelection({})
				}}
				className={classes.button}
			>
				{rememberComponentSelection ? <CancelIcon /> : <SaveIcon />}
			</Fab>
			<Button
				variant="contained"
				color="primary"
				size="large"
				className={clsx(classes.buttonOuter, classes.button)}
				disabled={eastNLocId === undefined}
				onClick={() => changeActiveNode(eastMLocId, eastNLocId, mLocId, nLocId)}
			>
				Move East
			</Button>
		</div>
	)
}

NodeLocationNavigator.propTypes = {
	handleRememberComponentSelection: PropTypes.func,
	rememberComponentSelection: PropTypes.bool,
	handleComponentSelection: PropTypes.func,
	locationInfo: PropTypes.object,
	changeActiveNode: PropTypes.func,
	activeMasterName: PropTypes.string,
	activateEntry: PropTypes.func,
	positionAbsolute: PropTypes.bool,
	displayOpenBtn: PropTypes.bool,
}
export default NodeLocationNavigator

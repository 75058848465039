import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import NodeMarker from "./NodeMarker"

const MAX_NODE_COUNT = 500

const NodeMarkers = ({ locations, enqueueSnackbar, displayAllNodes, masterList }) => {
	const [warningSent, setWarningSent] = useState(false)
	console.log("NodeMarkers", locations)

	useEffect(() => {
		if (!warningSent && locations.length > MAX_NODE_COUNT) {
			enqueueSnackbar(
				`Only the first & last ${parseInt(
					MAX_NODE_COUNT / 2,
				)} node locations displayed to manage perfomance. Total Nodes: ${locations.length}`,
				{
					variant: "warning",
				},
			)
			setWarningSent(true)
		}
	}, [warningSent, locations.length, enqueueSnackbar])

	useEffect(() => {
		setWarningSent(false)
	}, [locations.length])

	const nodeMarkers = []
	if (locations.length < MAX_NODE_COUNT || displayAllNodes) {
		for (let i = 0; i < locations.length; i++) {
			nodeMarkers.push(
				<NodeMarker
					colorIndex={parseInt(locations[i].mLocId) || masterList.indexOf(locations[i].mLocId)}
					key={i}
					index={i}
				/>,
			)
		}
	} else {
		for (let i = 0; i < parseInt(MAX_NODE_COUNT / 2); i++) {
			nodeMarkers.push(
				<NodeMarker
					colorIndex={parseInt(locations[i].mLocId) || masterList.indexOf(locations[i].mLocId)}
					key={i}
					index={i}
				/>,
			)
		}
		for (let i = locations.length - 1; i > locations.length - parseInt(MAX_NODE_COUNT / 2); i--) {
			nodeMarkers.push(
				<NodeMarker
					colorIndex={parseInt(locations[i].mLocId) || masterList.indexOf(locations[i].mLocId)}
					key={i}
					index={i}
				/>,
			)
		}
	}
	return nodeMarkers
}

NodeMarkers.propTypes = {
	locations: PropTypes.array.isRequired,
}

export default NodeMarkers

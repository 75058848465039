import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import NodeMarker from "./NodeMarker"

import { overlays } from "constants/overlays"
import { getChargingColor } from "constants/charging"
import {
	alarmEnums,
	DUPLICATE_NODEID,
	getAlarmBitmask,
	getDeviceTypeFilter,
	getFlagBitmask,
	NO_DATA_IN_DATABASE,
} from "constants/statusBits"
import { focusNodes } from "constants/dataView"

import { deviceTypes } from "constants/deviceTypes"
import { alarmKey, auxNodeDataList } from "constants/deviceData"
import {
	colorAmber,
	colorBrown,
	colorGreen,
	colorGrey,
	colorPink,
	colorPrimary,
	colorRed,
	colorYellow,
} from "../../../../colors"

const getDeviceType = (state, props) => {
	let { deviceType } = state.masterDetails[props.mLocId] || {}
	if (deviceType === deviceTypes.GAMECHANGE_GEN3_DUAL) {
		const nodeDetails = (state.nodeDetails[props.mLocId] || {})[props.nLocId]
		if (nodeDetails.role === "aux") {
			return deviceTypes.GAMECHANGE_GEN3_DUAL_AUX
		} else {
			return deviceTypes.GAMECHANGE_GEN3_DUAL_PRIMARY
		}
	}
	return deviceType
}

const getNodeData = (state, props, timestamp, deviceType) => {
	const nodeData = state.nodeData[`${props.mLocId}#${timestamp}`] || {}

	let data = {}
	if (deviceType === deviceTypes.GAMECHANGE_GEN3_DUAL_AUX) {
		const nodeDetails = (state.nodeDetails[props.mLocId] || {})[props.nLocId]
		Object.keys(nodeData[nodeDetails.relative] || {}).forEach((dataKey) => {
			if (auxNodeDataList.includes(dataKey)) {
				data[dataKey] = nodeData[nodeDetails.relative][dataKey]
			}
		})
	} else if (deviceType === deviceTypes.GAMECHANGE_GEN3_DUAL_PRIMARY) {
		Object.keys(nodeData[props.nLocId] || {}).forEach((dataKey) => {
			if (!auxNodeDataList.includes(dataKey)) {
				data[dataKey] = nodeData[props.nLocId][dataKey]
			}
		})
	} else {
		data = nodeData[props.nLocId] || {}
	}
	return data
}

const getMarkerColors = (state, props) => {
	const { dataView } = state
	let markerColor, markerFill
	markerColor = markerFill = colorGrey
	switch (props.activeOverlay) {
		case overlays.ALARMS: {
			if (focusNodes.includes(dataView)) {
				const deviceType = getDeviceType(state, props)
				const timestamp = props.timestamp || state.nodeDataActiveTimestamp[props.mLocId]
				const nodeData = getNodeData(state, props, timestamp, deviceType)

				const { duplicateNodeId } = props

				const duplicateNodeIdAlarm = BigInt((duplicateNodeId && DUPLICATE_NODEID) || 0)
				const noDataAvailableServerAlarm = BigInt((Object.keys(nodeData).length === 0 && NO_DATA_IN_DATABASE) || 0)

				if (state.nodeDataStatus[props.mLocId] === "resolved") {
					let alarms = nodeData[alarmKey[deviceType]]
					alarms = (alarms ? BigInt(`0x${alarms}`) : 0n) | duplicateNodeIdAlarm | noDataAvailableServerAlarm
					if (!alarms) {
						markerColor = markerFill = colorPrimary
						break
					}
					let filter = new URLSearchParams(props.location.search).get("filter")
					filter = filter ? BigInt(`0b${filter}`) : undefined
					const deviceTypeFilter = getDeviceTypeFilter(filter, deviceType)
					const alarmBitmask = getAlarmBitmask(state.user.privileges, deviceType)
					const maskedAlarms = alarmBitmask & alarms
					if (maskedAlarms) {
						if (deviceTypeFilter && (maskedAlarms & deviceTypeFilter) === 0n) {
							markerColor = markerFill = colorBrown
						} else {
							markerColor = markerFill = colorRed
						}
					} else {
						markerColor = markerFill = colorPrimary
					}
				}
			}
			break
		}
		case overlays.FLAGS: {
			const timestamp = props.timestamp || state.nodeDataActiveTimestamp[props.mLocId]
			if (timestamp) {
				const allNodeData = state.nodeData[`${props.mLocId}#${timestamp}`] || {}
				let { flags } = allNodeData[props.nLocId.toString().padStart(3, "0")] || {}
				flags = flags ? BigInt(`0x${flags}`) : 0n
				let filter = new URLSearchParams(props.location.search).get("filter")
				filter = filter ? BigInt(`0b${filter}`) : undefined
				const flagBitmask = getFlagBitmask(state.user.privileges, (state.masterDetails[props.mLocId] || {}).deviceType)
				const maskedFlags = flagBitmask & flags
				if (focusNodes.includes(dataView)) {
					if (maskedFlags) {
						if (filter && (maskedFlags & filter) === 0n) {
							markerColor = markerFill = colorBrown
						} else {
							markerColor = markerFill = colorYellow
						}
					} else {
						markerColor = markerFill = colorPrimary
					}
				}
			}
			break
		}
		case overlays.ANGLES:
			break
		case overlays.CHARGINGSTATUS: {
			const timestamp = props.timestamp || state.nodeDataActiveTimestamp[props.mLocId]
			if (timestamp) {
				const deviceType = getDeviceType(state, props)
				const allNodeData = state.nodeData[`${props.mLocId}#${timestamp}`] || {}
				let { chargeStatus, alarms } = allNodeData[props.nLocId.toString().padStart(3, "0")] || {}
				alarms = alarms ? BigInt(`0x${alarms}`) : 0n
				const deviceTypeAlarmEnums = alarmEnums[deviceType] || {}
				const noDataAvailable =
					(alarms & deviceTypeAlarmEnums.MASTER_ASSIGNED_INIT_VALUES) !== 0n ||
					(alarms & deviceTypeAlarmEnums.NODE_ASSIGNED_INIT_VALUES) !== 0n
				if (noDataAvailable) {
					chargeStatus = 255
				}
				if (chargeStatus !== undefined) {
					markerColor = markerFill = getChargingColor(chargeStatus)
				}
			}
			break
		}
		case overlays.DEVICEIDASSIGNMENT: {
			const nodeDetails = (state.nodeDetails[props.mLocId] || {})[props.nLocId]
			const currentNodeId = (nodeDetails || {})["nId"]
			const isNodeUnassigned = (currentNodeId || "").slice(-4) === "0000"
			const pendingSync = (nodeDetails || {})["pendingSync"]
			const pendingNodeId = state.nIdsPendingUpload[`${props.mLocId}#${props.nLocId}`]
			const nId = pendingNodeId || currentNodeId

			const { replacements } = state.replacementCampaignInfo[props.mLocId] || {}
			if (
				state.dataView === "replacementCampaign" &&
				state.replacementCampaignStatus === "resolved" &&
				replacements !== undefined &&
				!replacements[props.nLocId]
			) {
				markerColor = markerFill = colorPink
				break
			}

			if (!nId || isNodeUnassigned) break

			if (props.duplicateNodeId) {
				markerColor = markerFill = colorRed
			} else if (pendingNodeId) {
				markerColor = markerFill = colorAmber
			} else if (pendingSync) {
				markerColor = markerFill = colorGreen
			} else if (currentNodeId) {
				markerColor = markerFill = colorPrimary
			}
			break
		}
		case overlays.ZIGBEE_HOPS:
			break
		default:
			break
	}
	return { markerColor, markerFill }
}

const getCenter = (state, props) => {
	const nodeDetails = (state.nodeDetails[props.mLocId] || {})[props.nLocId]
	if (!nodeDetails || !nodeDetails["xLoc"] || !nodeDetails["yLoc"]) return []
	return [nodeDetails["yLoc"], nodeDetails["xLoc"]]
}

const handleClick = (props) => (e) => {
	let params = new URLSearchParams(props.location.search)
	let activeIds = params.get("locIds")
	if (!activeIds) return null
	const { originalEvent: oEvent = {} } = e
	activeIds = activeIds.split(",").map((ids) => {
		let [mLocId, nodeLocationIds = ""] = ids.split(":")
		if (mLocId !== props.mLocId) {
			if (oEvent.ctrlKey || oEvent.metaKey) {
				return ids
			} else {
				return mLocId
			}
		} else {
			nodeLocationIds = nodeLocationIds.split(";")
			if (oEvent.ctrlKey || oEvent.metaKey) {
				if (!nodeLocationIds.includes(props.nLocId)) {
					nodeLocationIds.push(props.nLocId)
				}
			} else {
				nodeLocationIds = [props.nLocId]
			}
			return mLocId + ":" + nodeLocationIds.filter((id) => id !== "").join(";")
		}
	})
	params.set("locIds", activeIds.join(","))
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const getRole = (state, props) => {
	const nodeDetails = (state.nodeDetails[props.mLocId] || {})[props.nLocId] || {}
	return nodeDetails.role || "solo"
}

const mapStateToProps = (state, props) => {
	const { markerColor, markerFill } = getMarkerColors(state, props)
	const nodeDetails = (state.nodeDetails[props.mLocId] || {})[props.nLocId]
	const currentNodeId = (nodeDetails || {})["uuid"]
	const componentsScanned = state.nodeComponentScanned[currentNodeId]
	const isDeviceIdAssignment = props.location.search.split("&").includes("overlay=deviceid-assignement")
	return {
		isDeviceIdAssignment,
		componentsScanned,
		center: getCenter(state, props),
		radius: parseFloat((state.layoutDetails[props.layoutId] || {})["markerRadius"]) || 0.5,
		markerColor,
		markerFill,
		role: getRole(state, props),
	}
}

const mapDispatchToProps = (dispatch, props) => ({
	handleClick: handleClick(props),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(NodeMarker)

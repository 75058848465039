import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Popup } from "react-leaflet"
import leaflet from "leaflet"

const useStyles = makeStyles((_theme) => ({
	root: {
		opacity: ".8 !important",
		"&:hover": {
			opacity: "1 !important",
		},
	},
	popup: {
		textAlign: "center",
		cursor: "pointer",
	},
	popupId: {
		fontWeight: "bold",
	},
	close: {
		position: "absolute",
		right: 0,
		top: 0,
		padding: 3,
		paddingRight: 8,
		cursor: "pointer",
	},
}))

const LayoutPopup = ({ name, latitude, longitude }) => {
	const classes = useStyles()
	if (latitude === null || longitude === null) return null

	return (
		<Popup
			position={[latitude, longitude]}
			closeButton={false}
			closeOnClick={false}
			autoClose={false}
			className={classes.root}
			autoPan={true}
			autoPanPadding={leaflet.point(100, 100)}
		>
			<div>
				<div className={classes.popup}>
					<div className={classes.popupId}>{name}</div>
				</div>
			</div>
		</Popup>
	)
}

LayoutPopup.propTypes = {
	latitude: PropTypes.number,
	longitude: PropTypes.number,
	name: PropTypes.string,
}

export default LayoutPopup

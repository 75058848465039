import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import { Circle, FeatureGroup, Popup } from "react-leaflet"
import { EditControl } from "react-leaflet-draw"
import { useSnackbar } from "notistack"

const styles = () => ({
	selected: {
		cursor: "grab",
		fillOpacity: 0,
	},
	notSelected: {
		cursor: "pointer",
	},
})

const latLngToCoord = (latLng) => {
	return [latLng["lat"], latLng["lng"]]
}

const DEFAULT_DRAW_ITEMS = {
	polyline: false,
	rectangle: false,
	circle: false,
	marker: false,
	circlemarker: false,
	polygon: false,
}

const MasterCoordinateEditor = ({
	masterCoordinates,
	masterLabels,
	radius,
	layoutId,
	setConfigMasterCoordinates,
	updateMasterCoordinates,
}) => {
	const [editKey, setEditKey] = useState(0)
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		setConfigMasterCoordinates(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleZeroOutCoordinates = useCallback(
		async (id) => {
			try {
				await updateMasterCoordinates(id, layoutId, { xLoc: 0, yLoc: 0 })
				enqueueSnackbar("Successfully removed Master coordinates", {
					variant: "success",
					autoHideDuration: 5000,
				})
			} catch (err) {
				console.error("updateMasterCoordinates", err)
				enqueueSnackbar("Unable to edit Master coordinates", {
					variant: "error",
					autoHideDuration: 5000,
				})
			}
		},
		[enqueueSnackbar, layoutId, updateMasterCoordinates],
	)

	let masters = []
	let labels = []
	Object.keys(masterCoordinates).forEach((mLocId) => {
		masters.push(
			<Circle
				key={mLocId + editKey}
				mLocId={mLocId}
				color={"black"}
				center={masterCoordinates[mLocId]}
				radius={radius}
				onClick={() => handleZeroOutCoordinates(mLocId)}
			/>,
		)
		labels.push(
			<Popup
				key={mLocId}
				position={masterCoordinates[mLocId]}
				closeButton={false}
				closeOnClick={false}
				autoClose={false}
				autoPan={false}
			>
				<div>{masterLabels[mLocId]}</div>
			</Popup>,
		)
	})

	/*
    Leaflet deals with event listeners by reference,
    so if you want to add a listener and then remove it, define it as a function.
    useCallback must be used to ensure reference isn't changed on consecutive renders
  */
	const handleCreate = useCallback((e) => {
		// console.log('Popup assign to master', e)
		const layer = e.layer
		const coordinates = latLngToCoord(layer.getLatLng())
		setConfigMasterCoordinates({ layoutId, coordinates })
		layer.remove()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleEdit = useCallback((e) => {
		e.layers.eachLayer((layer) => {
			const coordinates = latLngToCoord(layer.getLatLng())
			updateMasterCoordinates(layer.options.mLocId, layoutId, {
				xLoc: coordinates[1],
				yLoc: coordinates[0],
			})
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleEditStart = useCallback(() => {
		setEditKey((editKey) => editKey + 1) // force redraw master area polygon <- fixes area render bug
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<React.Fragment>
			{labels}
			<FeatureGroup>
				{masters}
				<EditControl
					position="bottomleft"
					onCreated={handleCreate}
					onEdited={handleEdit}
					// onDeleted={(e) => {console.log(e)}}
					onEditStart={handleEditStart}
					// onMounted={() => {console.log('onMounted')}}
					// onDeleteStart={(e) => {console.log(e)}}
					// onDeleteStop={(e) => {console.log(e)}}
					edit={{ remove: false }}
					draw={{
						...DEFAULT_DRAW_ITEMS,
						circlemarker: {
							repeatMode: false,
						},
					}}
				/>
			</FeatureGroup>
		</React.Fragment>
	)
}

MasterCoordinateEditor.propTypes = {
	masterCoordinates: PropTypes.object,
	masterLabels: PropTypes.object,
	radius: PropTypes.number,
	layoutId: PropTypes.string.isRequired,
	setConfigMasterCoordinates: PropTypes.func.isRequired,
	updateMasterCoordinates: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MasterCoordinateEditor)

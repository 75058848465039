import React, { memo } from "react"
import PropTypes from "prop-types"
import MasterMarker from "./MasterMarker"

const MasterMarkers = memo(({ layoutId, mLocIds = [], activeOverlay, filter, timestamp }) => {
	const masterMarkers = []
	for (let i = 0; i < mLocIds.length; i++) {
		const mLocId = mLocIds[i]
		masterMarkers.push(
			<MasterMarker
				key={mLocId}
				mLocId={mLocId}
				layoutId={layoutId}
				activeOverlay={activeOverlay}
				filter={filter}
				timestamp={timestamp}
			/>,
		)
	}
	return <>{masterMarkers}</>
})

MasterMarkers.displayName = "MasterMarkers"

MasterMarkers.propTypes = {
	mLocIds: PropTypes.array,
	layoutId: PropTypes.string,
	activeOverlay: PropTypes.string,
	filter: PropTypes.string,
	timestamp: PropTypes.number,
}

export default MasterMarkers

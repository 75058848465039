import { compose } from "redux"
import { connect } from "react-redux"
import ToggleZigbeeNetworkMapButton from "./ToggleZigbeeNetworkMapButton"
import { withRouter } from "react-router-dom"
import { setZigbeeNetworkIsVisible } from "../../../../store/actions/reduxActions"

const mapStateToProps = (state, _props) => ({
	zigbeeNetworkIsVisible: state.zigbeeNetworkIsVisible,
})

// REASON: Actually used (see props in ToggleZigbeeNetworkMapButton component)
// noinspection JSUnusedGlobalSymbols
const mapDispatchToProps = (dispatch) => ({
	setZigbeeNetworkIsVisible: (isVisible) => dispatch(setZigbeeNetworkIsVisible(isVisible)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ToggleZigbeeNetworkMapButton)

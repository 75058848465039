import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import Fab from "@material-ui/core/Fab"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

const DeviceIdAssignmentOptionsButton = ({
	toggleNodeIds,
	overlayNIds,
	toggleNodeLocIds,
	overlayNLocIds,
	handleDownloadAllNodeIds,
	handleDownloadDeviceCoordinates,
}) => {
	const [anchorEl, setAnchorEl] = useState(null)

	const handleOpenOptions = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseOptions = () => {
		setAnchorEl(null)
	}

	const handleNodeIdsToggle = () => {
		setAnchorEl(null)
		toggleNodeIds(!overlayNIds)
	}

	const handleNodeLocIdsToggle = () => {
		setAnchorEl(null)
		toggleNodeLocIds(!overlayNLocIds)
	}

	return (
		<Fragment>
			<Fab color="primary" size="small" aria-label="Toggle Display NodeIds" onClick={handleOpenOptions}>
				<MoreVertIcon />
			</Fab>
			<Menu id="options-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseOptions}>
				<MenuItem onClick={handleNodeIdsToggle} selected={overlayNIds}>
					Display Node IDs
				</MenuItem>
				<MenuItem onClick={handleNodeLocIdsToggle} selected={overlayNLocIds}>
					Display Node Location IDs
				</MenuItem>
				<MenuItem onClick={handleDownloadAllNodeIds}>Download All NodeIDs</MenuItem>
				<MenuItem onClick={handleDownloadDeviceCoordinates}>Download Device Coordinates</MenuItem>
			</Menu>
		</Fragment>
	)
}

DeviceIdAssignmentOptionsButton.propTypes = {
	toggleNodeIds: PropTypes.func.isRequired,
	overlayNIds: PropTypes.any.isRequired, // TODO: Find out
	toggleNodeLocIds: PropTypes.func.isRequired,
	overlayNLocIds: PropTypes.any.isRequired, // TODO: Find out
	handleDownloadAllNodeIds: PropTypes.func.isRequired,
	handleDownloadDeviceCoordinates: PropTypes.func.isRequired,
}

export default DeviceIdAssignmentOptionsButton

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { overlayDetails, getOverlayList, DEFAULT_OVERLAY } from "constants/overlays"
import { deviceTypes } from "constants/deviceTypes"
import { privileges } from "constants/auth"
import { useAuthorization } from "utils/auth"

const styles = (theme) => ({
	button: {
		paddingRight: 6,
		position: "absolute",
		zIndex: 500,
		marginTop: theme.spacing(1),
		marginRight: "auto",
		marginLeft: "auto",
		right: 0,
		left: 0,
		width: "210px",
	},
})

const ITEM_HEIGHT = 50

const OverlaySelection = ({ classes, location, history, userPrivileges, activeOverlay }) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const accessAllowed = useAuthorization(userPrivileges)
	const overlays = getOverlayList(userPrivileges, deviceTypes.GAMECHANGE_REFACTORED) // TODO: dynamic device type

	const redirectOverlay = (overlayId) => {
		setAnchorEl(null)
		const params = new URLSearchParams(location.search)
		params.set("overlay", overlayId)
		history.push({
			pathname: location.pathname,
			search: params.toString(),
		})
	}

	useEffect(() => {
		const params = new URLSearchParams(location.search)
		const preParamString = params.toString()

		const overlays = getOverlayList(userPrivileges, deviceTypes.GAMECHANGE_REFACTORED) // TODO: dynamic device type
		if (!overlays.reduce((acc, value) => acc | (value.overlayId === activeOverlay), false)) {
			params.set("overlay", DEFAULT_OVERLAY)
		}

		params.delete("filter")

		if (preParamString !== params.toString()) {
			history.push({
				pathname: location.pathname,
				search: params.toString(),
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeOverlay, userPrivileges])

	if (!accessAllowed(privileges.DATA_SNAPSHOT)) return null

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<div>
			<Button
				variant="contained"
				color="secondary"
				className={classes.button}
				aria-controls="overlay-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				{(overlayDetails[activeOverlay] || {}).label || "Select Overlay"}
				<ArrowDropDownIcon>{activeOverlay}</ArrowDropDownIcon>
			</Button>
			<Menu
				id="overlay-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 5.75,
						width: 200,
					},
				}}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				{overlays.map((overlay) => (
					<MenuItem
						key={overlay.overlayId}
						onClick={() => redirectOverlay(overlay.overlayId)}
						selected={activeOverlay === overlay.overlayId}
					>
						{overlay.label}
					</MenuItem>
				))}
			</Menu>
		</div>
	)
}

OverlaySelection.propTypes = {
	classes: PropTypes.object.isRequired,
	activeOverlay: PropTypes.string,
}

export default withStyles(styles)(OverlaySelection)

import DygraphBase from "../src-es5/dygraph.js"

const dygraphTouchstart = (event, g, context) => {
	// This right here is what prevents IOS from doing its own zoom/touch behavior
	// It stops the node from being selected too
	event.preventDefault() // touch browsers are all nice.

	var touches = []
	for (var i = 0; i < event.touches.length; i++) {
		var t = event.touches[i]
		// we dispense with 'dragGetX_' because all touchBrowsers support pageX
		touches.push({
			pageX: t.pageX,
			pageY: t.pageY,
			dataX: g.toDataXCoord(t.pageX),
			dataY: g.toDataYCoord(t.pageY),
			// identifier: t.identifier
		})
	}

	if (touches.length === 1) {
		g.mouseMove_(event)
	}
}

const customInteractionModel = DygraphBase.defaultInteractionModel
customInteractionModel["touchstart"] = dygraphTouchstart
customInteractionModel["touchend"] = () => {}
customInteractionModel["touchmove"] = () => {}

export default customInteractionModel

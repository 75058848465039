import format from "date-fns/format"

export const columns = [
	{
		width: 200,
		flexGrow: 1.0,
		label: "Email",
		dataKey: "email",
		numeric: false,
		disableSort: false,
		default: true,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "First Name",
		dataKey: "nameFirst",
		numeric: false,
		disableSort: false,
		default: true,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "Name Last",
		dataKey: "nameLast",
		numeric: false,
		disableSort: false,
		default: true,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "Creation Date",
		dataKey: "userCreateDate",
		numeric: false,
		disableSort: false,
		formatter: (val) => format(new Date(val * 1000), "yyyy-MM-dd HH:mm:ss"),
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "Last Modified Date",
		dataKey: "userLastModifiedDate",
		numeric: false,
		disableSort: false,
		formatter: (val) => format(new Date(val * 1000), "yyyy-MM-dd HH:mm:ss"),
	},
	{
		width: 80,
		flexGrow: 1.0,
		label: "Account Enabled",
		dataKey: "enabled",
		numeric: false,
		disableSort: false,
		formatter: (val) => (val ? "True" : "False"),
		default: true,
	},
	{
		width: 100,
		flexGrow: 1.0,
		label: "Expiration",
		dataKey: "expiration",
		numeric: false,
		disableSort: false,
		formatter: (val) => (val ? format(new Date(val * 1000), "yyyy-MM-dd HH:mm:ss") : "None"),
		default: true,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "Account Status",
		dataKey: "userStatus",
		numeric: false,
		disableSort: false,
		default: true,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "Company",
		dataKey: "company",
		numeric: false,
		disableSort: false,
		default: true,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "Role",
		dataKey: "companyRole",
		numeric: false,
		disableSort: false,
	},
	{
		width: 115,
		flexGrow: 1.0,
		label: "All Layouts Accessible",
		dataKey: "allLayouts",
		numeric: false,
		disableSort: false,
		formatter: (val) => (val ? "True" : "False"),
	},
]

import React from "react"
import PropTypes from "prop-types"
import LayoutPopup from "./LayoutPopup"

const LayoutPopups = ({ layoutIds }) => {
	if (layoutIds.length === 0) return null

	const layoutPopups = []
	for (let i = 0; i < layoutIds.length; i++) {
		layoutPopups.push(<LayoutPopup key={layoutIds[i]} layoutId={layoutIds[i]} />)
	}
	return layoutPopups
}

LayoutPopups.propTypes = {
	layoutIds: PropTypes.array.isRequired,
}

export default LayoutPopups

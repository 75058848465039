/* eslint-disable no-console */
import React, { useState } from "react"
import PropTypes from "prop-types"
import TableCell from "@material-ui/core/TableCell"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import Table from "@material-ui/core/Table"
import Toolbar from "@material-ui/core/Toolbar"
import Tooltip from "@material-ui/core/Tooltip"
import ViewColumnIcon from "@material-ui/icons/ViewColumn"
import IconButton from "@material-ui/core/IconButton"
import Paper from "@material-ui/core/Paper"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import DownloadIcon from "@material-ui/icons/SaveAlt"
import ColumnSelect from "./ColumnSelect"
import { makeStyles } from "@material-ui/core/styles"
import { sortRows } from "utils/table"

let EnhancedTableHead = ({ sortDirection, sortBy, columns, handleRequestSort }) => {
	return (
		<TableHead>
			<TableRow>
				{columns.map((column) => {
					return (
						<TableCell
							key={column.dataKey}
							numeric={column.numeric ? column.numeric.toString() : "true"}
							sortDirection={sortBy === column.dataKey ? sortDirection : false}
							style={{ paddingTop: 0, paddingBottom: 0 }}
						>
							<Tooltip title="Sort" placement={column.numeric ? "bottom-end" : "bottom-start"} enterDelay={300}>
								<TableSortLabel
									key={column.dataKey}
									active={sortBy === column.dataKey}
									direction={sortDirection}
									onClick={(e) => {
										handleRequestSort(e, column.dataKey)
									}}
								>
									{column.label}
								</TableSortLabel>
							</Tooltip>
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	)
	//}
}

EnhancedTableHead.propTypes = {
	handleRequestSort: PropTypes.func.isRequired,
	sortDirection: PropTypes.string.isRequired,
	sortBy: PropTypes.string.isRequired,
	columns: PropTypes.any,
}
const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		display: "flex",
		justifyContent: "space-between",
	},
	actions: {
		display: "flex",
		color: theme.palette.text.secondary,
	},
	title: {
		flex: "0 0 auto",
	},
	closeIcon: {
		cursor: "pointer",
		color: "black",
	},
}))

let EnhancedTableToolbar = ({ setColumnSelectIsOpen, setDataTableIsOpen, downloadToCsv }) => {
	const classes = useToolbarStyles()
	return (
		<Toolbar className={classes.root}>
			<Typography variant="h6" className={classes.title}>
				Table View
			</Typography>
			<div className={classes.actions}>
				<Tooltip title="Download to CSV">
					<IconButton aria-label="download to CSV" onClick={() => downloadToCsv()}>
						<DownloadIcon />
					</IconButton>
				</Tooltip>
				<Tooltip title="Select columns">
					<IconButton aria-label="select columns" onClick={() => setColumnSelectIsOpen(true)}>
						<ViewColumnIcon />
					</IconButton>
				</Tooltip>
				<CloseIcon
					className={classes.closeIcon}
					aria-label="close data table"
					onClick={() => setDataTableIsOpen(false)}
				/>
			</div>
		</Toolbar>
	)
}

EnhancedTableToolbar.propTypes = {
	setColumnSelectIsOpen: PropTypes.func,
	setDataTableIsOpen: PropTypes.func,
	downloadToCsv: PropTypes.func,
}

const useDataTableStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	tableWrapper: {
		overflowX: "auto",
		maxWidth: 500,
		maxHeight: 500,
	},
	tableRow: {
		cursor: "pointer",
	},
	paper: {
		[theme.breakpoints.up("sm")]: {
			position: "absolute",
			left: "20px",
			top: theme.spacing(6),
			zIndex: 1000,
		},
	},
}))

const DataTable = ({
	history,
	location,
	rows,
	columns: nodeColumns,
	userPrivileges: _1, // TODO: Remove
	setDataTableIsOpen,
	dataTableIsOpen: _2, // TODO: Remove
}) => {
	const [sortBy, setSortBy] = useState("nLocId")
	const [sortDirection, setSortDirection] = useState("desc")
	const [columnSelectIsOpen, setColumnSelectIsOpen] = useState(false)
	const [columns, setColumns] = useState(nodeColumns)
	const [rowsPerPage, setRowsPerPage] = useState(5)
	const [activeNode, setActiveNode] = useState("")
	const [page, setPage] = useState(0)
	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)
	const classes = useDataTableStyles()

	let viewableRows = rows
	if (sortBy && sortDirection) {
		viewableRows = sortRows(viewableRows, sortBy, sortDirection)
	}

	const handleRowClick = (nLocId, mLocId) => {
		let params = new URLSearchParams(location.search)
		const locIds = params.get("locIds")
		const mLocIdsArr = locIds.split(",").map((locId) => locId.split(":")[0])
		let currentMasterIndex = mLocIdsArr.indexOf(mLocId)
		if (currentMasterIndex === -1) {
			currentMasterIndex = mLocIdsArr.length
		}
		mLocIdsArr[currentMasterIndex] = `${mLocId}:${nLocId}`
		params.set("locIds", mLocIdsArr.join(","))
		history.push({
			pathname: location.pathname,
			search: "?" + params.toString(),
		})
		setActiveNode(`${mLocId}:${nLocId}`)
	}

	const handleChangePage = (event, page) => {
		setPage(page)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event.target.value)
	}

	const downloadToCsv = () => {
		const filename = "Data Table.csv"
		const columnDelimiter = ","
		const lineDelimiter = "\n"

		const fileContent = []

		const headerRow = ["data:text/csv;charset=utf-8"]
		columns.forEach((column) => {
			headerRow.push(column.label)
		})
		fileContent.push(headerRow.join(columnDelimiter))

		viewableRows.forEach((rowData) => {
			const thisRow = []
			columns.forEach((column) => {
				try {
					const data = rowData[column.dataKey]
					const dataStr = data ? data.toString().replace(/,/g, "/") : ""
					thisRow.push(dataStr)
				} catch (err) {
					console.warn("Could not parse row data: ", rowData[column.dataKey])
					console.warn("Column is:", column)
				}
			})
			fileContent.push(thisRow.join(columnDelimiter))
		})

		const data = encodeURI(fileContent.join(lineDelimiter))
		const link = document.createElement("a")
		link.setAttribute("href", data)
		link.setAttribute("download", filename)
		link.click()
	}

	const handleRequestSort = (event, property) => {
		let order = "desc"

		if (sortBy === property && sortDirection === "desc") {
			order = "asc"
		}
		setSortBy(property)
		setSortDirection(order)
	}

	return (
		<div>
			<Paper className={classes.paper}>
				<EnhancedTableToolbar
					setColumnSelectIsOpen={setColumnSelectIsOpen}
					setDataTableIsOpen={setDataTableIsOpen}
					downloadToCsv={downloadToCsv}
				/>
				<div className={classes.tableWrapper}>
					<Table size="small">
						<EnhancedTableHead
							sortDirection={sortDirection}
							sortBy={sortBy}
							columns={columns}
							handleRequestSort={handleRequestSort}
						/>
						<TableBody>
							{viewableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n) => {
								const isSelected = `${n.mLocId}:${n.nLocId}` === activeNode
								return (
									<TableRow
										hover
										onClick={() => {
											handleRowClick(n.nLocId, n.mLocId)
										}}
										className={classes.tableRow}
										aria-checked={isSelected}
										tabIndex={-1}
										key={`${n.mLocId}:${n.nLocId}`}
										selected={isSelected}
									>
										{columns.map((column) => {
											return <TableCell key={column.dataKey}> {n[column.dataKey]} </TableCell>
										})}
									</TableRow>
								)
							})}
							{emptyRows > 0 && (
								<TableRow style={{ height: 49 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</div>
				<TablePagination
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					backIconButtonProps={{
						"aria-label": "Previous Page",
					}}
					nextIconButtonProps={{
						"aria-label": "Next Page",
					}}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					rowsPerPageOptions={[5, 10]}
				/>
			</Paper>
			<ColumnSelect
				isOpen={columnSelectIsOpen}
				handleClose={() => setColumnSelectIsOpen(false)}
				handleAccept={setColumns}
				currentSelection={columns}
			/>
		</div>
	)
}

DataTable.propTypes = {
	history: PropTypes.any,
	location: PropTypes.any,
	rows: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	userPrivileges: PropTypes.any,
	setDataTableIsOpen: PropTypes.any,
	dataTableIsOpen: PropTypes.any,
}

export default DataTable

import React from "react"
import PropTypes from "prop-types"
import MasterAreaEditor from "./MasterAreaEditor"
import MasterCoordinateEditor from "./MasterCoordinateEditor"

const DrawLayer = ({ match, location }) => {
	const { layoutId } = match.params
	const params = new URLSearchParams(location.search)
	const activeStepKey = params.get("step")

	if (activeStepKey === "master-coordinates") {
		return <MasterCoordinateEditor layoutId={layoutId} />
	}
	if (activeStepKey === "master-areas") {
		return <MasterAreaEditor layoutId={layoutId} />
	}
	return null
}

DrawLayer.propTypes = {
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
}

export default DrawLayer

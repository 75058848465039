import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import TaskWizard from "./TaskWizard"
import { setTaskInfo, setWizardInfo } from "store/actions/reduxActions"
import { setNodeId, createTask } from "store/actions/setters"

const getDeviceIds = (props) => {
	const params = new URLSearchParams(props.location.search)
	const deviceIds = params.get("device-details").split(":")
	return deviceIds
}

const mapStateToProps = (state, props) => ({
	taskInfo: state.taskInfo,
	layoutId: getDeviceIds(props)[0],
	mLocId: getDeviceIds(props)[1],
	nLocId: getDeviceIds(props)[2],
	nodeDetails: state.nodeDetails || {},
	masterDetails: state.masterDetails || {},
	layoutNumber: (state.layoutDetails[getDeviceIds(props)[0]] || {}).layoutNumber,
	layoutName: (state.layoutDetails[getDeviceIds(props)[0]] || {}).name,
	processRunning: state.wizardInfo.processRunning,
	newDeviceId: state.wizardInfo.newDeviceId,
})

const mapDispatchToProps = (dispatch, props) => ({
	setTaskInfo: (info) => dispatch(setTaskInfo(info)),
	setNodeId: (layoutId, mLocId, nLocId, deviceid) => dispatch(setNodeId(layoutId, mLocId, nLocId, deviceid)),
	createTask: (input) => dispatch(createTask(input)),
	setWizardInfo: (info) => dispatch(setWizardInfo(info)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TaskWizard)

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import { setTaskInfo } from "store/actions/reduxActions"
import { setNodeId } from "store/actions/setters"
import DeviceIdInput from "./DeviceIdInput"

const getDeviceIds = (props) => {
	const params = new URLSearchParams(props.location.search)
	const deviceIds = params.get("device-details").split(":")
	return deviceIds
}

const mapStateToProps = (state, props) => ({
	wizardIsOpen: state.wizardInfo.isOpen,
	layoutId: getDeviceIds(props)[0],
	mLocId: getDeviceIds(props)[1],
	nLocId: getDeviceIds(props)[2],
	nodeDetails: state.nodeDetails || {},
})

const handleClose = (props) => {
	const params = new URLSearchParams(props.location.search)
	params.delete("search")
	params.delete("nav")
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const mapDispatchToProps = (dispatch, props) => ({
	handleClose: () => handleClose(props),
	setTaskInfo: (info) => dispatch(setTaskInfo(info)),
	setNodeId: (layoutId, mLocId, nLocId, deviceid) => dispatch(setNodeId(layoutId, mLocId, nLocId, deviceid)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(DeviceIdInput)

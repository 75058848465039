import React from "react"
import PropTypes from "prop-types"
import BoardAlternateForm from "./BoardAlternateForm"

const BareBoardVersionForm = ({ bareBoardVersions, taskInfo, bareBoardVersion, setTaskInfo, label }) => {
	const alternateToExample = (alternate) => {
		return "SII XXXXXXXX-" + alternate + "XXXXXX"
	}
	return (
		<BoardAlternateForm
			boardAlternates={bareBoardVersions}
			taskInfo={taskInfo}
			boardAlternate={bareBoardVersion}
			setTaskInfo={setTaskInfo}
			label={label}
			displayName={"Bare board version"}
			variableName={"bareBoardVersion"}
			alternateToExample={alternateToExample}
		/>
	)
}

BareBoardVersionForm.propTypes = {
	setTaskInfo: PropTypes.func.isRequired,
	taskInfo: PropTypes.object.isRequired,
	label: PropTypes.string,
	bareBoardVersions: PropTypes.arrayOf(PropTypes.string),
	bareBoardVersion: PropTypes.string,
}

export default BareBoardVersionForm

import { format, utcToZonedTime } from "date-fns-tz"

export const formatDate = (systemDate, timezone, formatString = "yyyy-MM-dd h:mm a zzz") => {
	if (!timezone) {
		return format(systemDate, formatString)
	}

	try {
		const datetime = utcToZonedTime(systemDate, timezone)
		return format(datetime, formatString, {
			timeZone: timezone,
		})
	} catch (err) {
		console.log(err)
	}
}

export const formatTimestamp = (timestamp, timezone, formatString = "yyyy-MM-dd h:mm a zzz") => {
	const systemDate = new Date(timestamp * 1000)

	return formatDate(systemDate, timezone, formatString)
}

const trimPunctuation = (str) => {
	const punctuation = [",", ".", "!", "?"]
	while (punctuation.includes(str.charAt(str.length - 1))) {
		str = str.slice(0, -1)
	}
	return str
}

/**
 * Take a string and, if that string is longer than maxCharacters, truncates it (adding "..." at the end of the nearest
 * word) so that it fits within maxCharacters. If this is not possible (for example, because the string contains no
 * spaces), then it will be truncated down to exactly maxCharacters length. Will also remove the last character of the
 * substring (minus the ellipses) if it's a punctuation symbol. Uses actual periods instead of true ellipses (…) as
 * these are wider visually in proportional fonts and may not be compatible with some encodings. The string will have
 * its end trimmed in all cases.
 *
 * @example
 *
 *	truncateWords("Lorem ipsum dolor sit amet", 15) // "Lorem ipsum..." (14 chars)
 *	truncateWords("Lorem ipsum! Dolor sit amet.", 15) // "Lorem ipsum..." (14 chars)
 *	truncateWords("Lorem ipsum dolor sit amet", 20) // "Lorem ipsum dolor..." (20 chars)
 *
 * @param str The string to truncate
 * @param maxCharacters The maximum number of characters allowed in the result. Minimum 4.
 */
export const truncateWords = (str, maxCharacters) => {
	if (maxCharacters <= 3) {
		throw new Error("maxCharacters must be greater than 3")
	}

	// "Lorem ipsum! " with maxCharacters = 12 results in "Lorem ipsum!"
	str = str.trimEnd()

	if (str.length <= maxCharacters) return str

	let subString = str.substr(0, maxCharacters).trimEnd() // Last character may be a space
	let adjustedLength = subString.length + 3 // Adding "..." will increase the length
	let overflow = adjustedLength - maxCharacters // Probably 3

	if (overflow <= 0) {
		// Possible if there were multiple spaces at the end of the substring.
		// Don't want the string to end with ",...", for example, so trim punctuation before concat.
		return trimPunctuation(subString).trimEnd() + "..."
	}

	const lastSpaceIndex = subString.lastIndexOf(" ")

	if (lastSpaceIndex <= 0) {
		// No spaces; truncate down to exactly maxCharacters length
		return subString.substr(0, subString.length - 4) + "..." // -4 because zero-based and "..."
	}

	// Split by last space and add "..."
	const ret = trimPunctuation(subString.substr(0, lastSpaceIndex)).trimEnd() + "..."

	if (ret.length > maxCharacters) {
		// Possible if the last word was a single-letter word
		return truncateWords(ret, maxCharacters)
	}

	return ret
}

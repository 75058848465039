import React, { useMemo, useEffect } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Paper from "@material-ui/core/Paper"
import Drawer from "@material-ui/core/Drawer"

import OverlaySummaryHeader from "./OverlaySummaryHeader"
import NodesSummary from "./NodesSummary"
import MastersSummary from "./MastersSummary"
import SiteSummary from "./SiteSummary"
import ReplacementCampaignSummary from "./ReplacementCampaignSummary"
import { findDuplicates } from "utils"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.up("sm")]: {
			position: "absolute",
			left: "20px",
			top: theme.spacing(6),
			zIndex: 1000,
			maxWidth: "35%",
			minWidth: "350px",
			overFlow: "auto",
		},
	},
}))

const OverlaySummary = ({
	summaryIsOpen,
	setSummaryIsOpen,
	dataView,
	nodeDetails,
	nIdsPendingUpload,
	location,
	history,
}) => {
	const classes = useStyles()
	const theme = useTheme()
	const smallScreen = useMediaQuery(theme.breakpoints.down("xs"))

	const params = new URLSearchParams(location.search)
	let locIds = params.get("locIds")

	const duplicateNodeIdsByMaster = useMemo(() => {
		const getActiveMasterList = () => {
			if (!locIds) return []
			return locIds.split(",").map((loc) => loc.split(":")[0])
		}
		const activeMasters = getActiveMasterList()
		let duplicateNodeIdsByMaster = {}
		activeMasters.forEach((mLocId) => {
			const nIds = []
			const nLocIds = Object.keys(nodeDetails[mLocId] || {})
			nLocIds.forEach((nLocId) => {
				const nId = nIdsPendingUpload[`${mLocId}#${nLocId}`] || (nodeDetails[mLocId] || {})[nLocId].nId
				if (nId) {
					nIds.push(nId)
				}
			})
			duplicateNodeIdsByMaster[mLocId] = findDuplicates(nIds)
		})
		return duplicateNodeIdsByMaster
	}, [nodeDetails, nIdsPendingUpload, locIds])

	useEffect(() => {
		const params = new URLSearchParams(location.search)
		const preParamString = params.toString()

		params.delete("filter")

		if (preParamString !== params.toString()) {
			history.push({
				pathname: location.pathname,
				search: params.toString(),
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataView])

	const closeSummary = () => {
		setSummaryIsOpen(false)
	}

	if (smallScreen) {
		return (
			<Drawer anchor="bottom" variant="persistent" open={summaryIsOpen.small} onClose={closeSummary}>
				<Paper className={classes.root}>
					<OverlaySummaryHeader />
					<NodesSummary duplicateNodeIdsByMaster={duplicateNodeIdsByMaster} />
					<MastersSummary />
					<SiteSummary />
					{dataView === "replacementCampaign" && <ReplacementCampaignSummary />}
				</Paper>
			</Drawer>
		)
	}
	if (!smallScreen && summaryIsOpen.large) {
		return (
			<Paper className={classes.root}>
				<OverlaySummaryHeader />
				<NodesSummary duplicateNodeIdsByMaster={duplicateNodeIdsByMaster} />
				<MastersSummary />
				<SiteSummary />
				{dataView === "replacementCampaign" && <ReplacementCampaignSummary />}
			</Paper>
		)
	}
	return null
}

OverlaySummary.propTypes = {
	// TODO: Why not a boolean? I don't think this is used as intended.
	summaryIsOpen: PropTypes.shape({
		small: PropTypes.bool.isRequired,
		large: PropTypes.bool.isRequired,
	}).isRequired,
	setSummaryIsOpen: PropTypes.func.isRequired,
	dataView: PropTypes.string.isRequired,
	nodeDetails: PropTypes.object.isRequired,
	nIdsPendingUpload: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
}
export default OverlaySummary

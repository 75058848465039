import React, { Fragment, useState, useEffect, useCallback, useMemo } from "react"
import API, { graphqlOperation } from "@aws-amplify/api"
import { listUsers as listUsersGql } from "graphql/queries"
import { userResendInvitation } from "../../graphql/mutations"
import { makeStyles } from "@material-ui/core/styles"
import VirtualizedTable from "./VirtualizedTable"
import Paper from "@material-ui/core/Paper"
import ColumnSelect from "./ColumnSelect"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import ViewColumnIcon from "@material-ui/icons/ViewColumn"
import RefreshIcon from "@material-ui/icons/Refresh"
import AddIcon from "@material-ui/icons/Add"
import { columns as userDetailColumns } from "constants/userDetails"
import UserDetails from "./UserDetails"
import { useHistory, useLocation } from "react-router-dom"
import { filterRows, sortRows } from "utils/table"
import SearchBar from "./SearchBar"
import { useSnackbar } from "notistack"
import CircularProgress from "@material-ui/core/CircularProgress"

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		margin: theme.spacing(1),
	},
	loaderWrapper: {
		display: "flex",
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
	actions: {
		display: "flex",
		paddingRight: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		paddingTop: theme.spacing(1),
		flexDirection: "row",
		alignItems: "center",
	},
}))

const UsersList = () => {
	const classes = useStyles()
	const [users, setUsers] = useState({})
	const [groupName] = useState(null)
	const [columnSelectIsOpen, setColumnSelectIsOpen] = useState(false)
	const [columns, setColumns] = useState(userDetailColumns.filter((col) => col.default))
	const [sortBy, setSortBy] = useState("email")
	const [sortDirection, setSortDirection] = useState("DESC")
	const [userListFilter, setUserListFilter] = useState("")
	const { search, pathname } = useLocation()
	const history = useHistory()
	const params = useMemo(() => {
		return new URLSearchParams(search)
	}, [search])
	const username = params.get("username")
	const [resend, setResend] = useState(false)
	const [loading, setLoading] = useState(false)
	const [usernamePassed, setUsernamePassed] = useState()
	const [viewUserDetails, setViewUserDetails] = useState(false)
	const { enqueueSnackbar } = useSnackbar()

	const listUsers = async () => {
		try {
			setLoading(true)
			setUsers({})
			const result = await API.graphql(
				graphqlOperation(listUsersGql, {
					groupName,
				}),
			)
			const userDict = {}
			result.data.listUsers.items.forEach((item) => {
				userDict[item.username] = item
			})
			setUsers(userDict)
		} catch (err) {
			console.error(err)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		listUsers()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupName])

	const handleSortClick = (sortInfo) => {
		setSortBy(sortInfo.sortBy)
		setSortDirection(sortInfo.sortDirection)
	}

	const handleFilterChange = (filter) => {
		setUserListFilter(filter)
	}
	const handleAttrsUpdate = (attributes) => {
		setUsers((currUsers) => ({
			...currUsers,
			[attributes.username]: attributes,
		}))
	}

	const handleAddUser = () => {
		params.set("username", "new")
		history.push({
			pathname,
			search: "?" + params.toString(),
		})
	}

	// Resend invitation handler
	useEffect(() => {
		if (usernamePassed && resend) {
			try {
				API.graphql(
					graphqlOperation(userResendInvitation, {
						input: {
							email: users[usernamePassed].email,
						},
					}),
				)
				setResend(false)
				enqueueSnackbar("Resent invitation!", {
					variant: "success",
				})
			} catch (err) {
				console.error(err)
				enqueueSnackbar("Unable to resend invitation!", {
					variant: "error",
				})
			}
		}
	}, [users, params, usernamePassed, resend, enqueueSnackbar])

	// Open user details handler
	useEffect(() => {
		if (viewUserDetails && !resend) {
			params.set("username", usernamePassed)
			history.push({
				pathname,
				search: "?" + params.toString(),
			})
		}
		setViewUserDetails(false)
		// REASON: params & history are not to be added to dependencies as they will cause an infinite loop
		// eslint-disable-next-line
	}, [usernamePassed, viewUserDetails])

	const handleSelectUser = useCallback(
		(username) => {
			setUsernamePassed(username)
			if (!resend) {
				setViewUserDetails(true)
			}
		},
		[resend],
	)

	const handleResendInvitation = () => {
		setResend(true)
		setViewUserDetails(false)
	}

	const handleDeselectUser = () => {
		params.delete("username")
		history.push({
			pathname,
			search: "?" + params.toString(),
		})
	}

	const getRows = (users, filter, sortBy, sortDirection) => {
		let rows = Object.values(users)
		if (filter !== "") {
			rows = filterRows(rows, filter)
		}
		if (sortBy && sortDirection) {
			rows = sortRows(rows, sortBy, sortDirection)
		}

		return rows
	}

	const rows = getRows(users, userListFilter, sortBy, sortDirection)

	return (
		<Fragment>
			<Paper className={classes.root}>
				<div className={classes.actions}>
					<SearchBar
						handleChange={handleFilterChange}
						filter={userListFilter}
						handleClearFilter={() => {
							setUserListFilter("")
						}}
					/>
					<Tooltip title="Add user">
						<IconButton aria-label="add user" onClick={handleAddUser}>
							<AddIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Select columns">
						<IconButton aria-label="select columns" onClick={() => setColumnSelectIsOpen(true)}>
							<ViewColumnIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Refresh">
						<IconButton aria-label="refresh" onClick={listUsers}>
							<RefreshIcon />
						</IconButton>
					</Tooltip>
				</div>
				{loading ? (
					<div className={classes.loaderWrapper}>
						<CircularProgress size={40} color="primary" />
					</div>
				) : (
					<VirtualizedTable
						rowCount={rows.length}
						rowGetter={({ index }) => rows[index]}
						columns={columns}
						sort={handleSortClick}
						sortBy={sortBy}
						sortDirection={sortDirection}
						onRowClick={(e) => handleSelectUser(e.rowData.username)}
						onResendInvitation={handleResendInvitation}
					/>
				)}
			</Paper>
			<ColumnSelect
				isOpen={columnSelectIsOpen}
				handleClose={() => setColumnSelectIsOpen(false)}
				handleAccept={setColumns}
				currentSelection={columns}
			/>
			<UserDetails
				isOpen={Boolean(username)}
				handleClose={handleDeselectUser}
				username={username}
				handleAttrsUpdate={handleAttrsUpdate}
			/>
		</Fragment>
	)
}

export default UsersList

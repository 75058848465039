import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import LayoutView from "./LayoutView"
import { getLayoutMasterIds } from "utils"
import {
	fetchLayoutDetailBySiteUuid,
	subscribeMasterIdUpdates,
	subscribeNodeIdUpdates,
	subscribeNodeSyncBitmasksUpdate,
} from "store/actions/fetchers"

const mapStateToProps = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	return {
		params,
		mLocIds: getLayoutMasterIds(state.layoutDetails, props.match.params.layoutId),
		userPrivileges: state.user.privileges,
	}
}

const mapDispatchToProps = (dispatch) => ({
	subscribeNodeIdUpdates: (layoutId) => dispatch(subscribeNodeIdUpdates(layoutId)),
	subscribeMasterIdUpdates: (layoutId) => dispatch(subscribeMasterIdUpdates(layoutId)),
	subscribeNodeSyncBitmasksUpdate: (layoutId) => dispatch(subscribeNodeSyncBitmasksUpdate(layoutId)),
	fetchLayoutDetailsBySiteUuid: (layoutId) => dispatch(fetchLayoutDetailBySiteUuid(layoutId)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LayoutView)

import { connect } from "react-redux"
import LayoutPopups from "./LayoutPopups"

const mapStateToProps = (state, props) => ({
	layoutIds: state.layoutSecondarySelections,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPopups)

import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import green from "@material-ui/core/colors/green"
import { makeStyles } from "@material-ui/core/styles"
import { Workbox } from "workbox-window"
import PropTypes from "prop-types"

const useStyles = makeStyles({
	wrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
})

const FeedbackButton = ({ onClick: handleClick, timeout = 6000 }) => {
	const [restartPending, setRestartPending] = useState(false)
	const classes = useStyles()

	useEffect(() => {
		if (restartPending) {
			const timer = setTimeout(() => {
				setRestartPending(false)
			}, timeout)
			return () => clearTimeout(timer)
		}
	}, [restartPending, setRestartPending, timeout])

	return (
		<div className={classes.wrapper}>
			<Button
				disabled={restartPending}
				onClick={async () => {
					setRestartPending(true)
					const success = await handleClick()
					if (success) {
						setRestartPending(false)
					}
				}}
			>
				Update
			</Button>
			{restartPending && <CircularProgress size={24} className={classes.buttonProgress} />}
		</div>
	)
}

FeedbackButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	timeout: PropTypes.number,
}

export default (enqueueSnackbar) => {
	useEffect(() => {
		console.debug("Service worker hook called.")
		if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
			let options, message
			console.debug("Registering service worker. Options = ", options, "Message = ", message)
			const wb = new Workbox("/sw.js")
			console.debug("Adding event listeners to workbox service worker.")
			wb.addEventListener("waiting", (e) => {
				console.warn("Workbox service worker received 'waiting' event:", e)

				message = "A new version of GCS Monitor is available."
				options = {
					variant: "info",
					persist: true,
					action: (
						<FeedbackButton
							onClick={async () => {
								return new Promise((resolve, _reject) => {
									wb.addEventListener("controlling", (e) => {
										console.warn("Workbox service worker received a 'controlling' event:", e)
										window.location.reload()
										resolve(true)
									})
									console.debug("Sending 'SKIP_WAITING' message to workbox service worker")
									wb.messageSW({ type: "SKIP_WAITING" })
								})
							}}
						/>
					),
				}
				setTimeout(() => enqueueSnackbar(message, options), 1500)
			})

			console.debug("Adding 'installed' event listener to Workbox service worker.")
			wb.addEventListener("installed", (e) => {
				console.warn("Workbox service worker received 'installed' event:", e)
				if (!e.isUpdate) {
					console.debug("The 'installed' event is not an update.")
					// TODO: Send the service worker a list of URLs to cache (https://developers.google.com/web/tools/workbox/modules/workbox-window#cache-urls)
					message = "GCS Monitor can now be used offline."
					options = {
						variant: "info",
						autoHideDuration: 3000,
					}
					setTimeout(() => enqueueSnackbar(message, options), 1500)
				} else {
					console.warn("The 'installed' event is an update. Doing nothing (TODO: Consider doing something).")
				}
			})
			console.debug("Registering service worker.")
			wb.register().then((reg) => {
				console.log("Starting update checking...")
				setInterval(() => {
					console.log("Checking for updates...")
					// TODO: This is not working as expected. No update detected.
					reg.update("./sw.js")
				}, 1000 * 60) // Check for updates every minute
			})
		} else {
			console.error(
				`Service worker not registered because: NODE_ENV = ${process.env.NODE_ENV}, serviceWorker in navigator = ${
					"serviceWorker" in navigator
				}`,
			)
		}
	}, [enqueueSnackbar])
}

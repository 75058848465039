import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import Slide from "@material-ui/core/Slide"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import ClearIcon from "@material-ui/icons/Close"
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import ReactQuagga, { useQuagga } from "../ReactQuagga"
import { isHex } from "../../utils/validation"
import API, { graphqlOperation } from "@aws-amplify/api"
import { getMasterLocationByMasterId } from "../../graphql/queries"
import Tooltip from "@material-ui/core/Tooltip"
import ReactQrReader from "../ReactQrReader"
import { useSnackbar } from "notistack"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import NodeLocationNavigator from "../LayoutMap/LayoutOverlay/NodeLocationNavigator"
import ErrorOutline from "@material-ui/icons/ErrorOutline"

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
	content: {
		width: "100%",
		padding: "100px !important",
		height: "fit-content",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	scannerContent: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		padding: "0px !important",
		overflow: "hidden !important",
	},
	scannerModalContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	scannerModalHeader: {
		width: "100%",
		minHeight: "30px",
		padding: "2px 10px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		borderBottom: "solid thin silver",
	},
	scannerModalHeaderChild: {
		flex: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		minHeight: "30px",
		padding: "5px 10px",
		fontSize: "14px",
	},
	scannerModalHeaderCenter: {
		flex: 3,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		minHeight: "30px",
		padding: "5px",
	},
	messageContainer: {
		display: "flex",
		alignItems: "center",
		fontSize: "0.8rem",
		fontWeight: "bold",
	},
	closeMessageBtn: {
		cursor: "pointer",
		margin: "5px",
	},
	scannerModalHeaderBackBtn: {
		cursor: "pointer",
	},
	nodeLocationNavContainer: {
		height: "80px",
	},
	actions: {
		justifyContent: "space-between",
	},
	actionButton: {
		margin: "5px",
		flex: 1,
	},
	textInputBox: {
		margin: "15px 0 0 0",
		padding: "4px",
		width: "90%",
	},
	fabClose: {
		position: "fixed",
		bottom: 0,
		right: 0,
		margin: theme.spacing(2),
		zIndex: 500,
	},
	fabText: {
		position: "fixed",
		bottom: 0,
		right: 40 + 24,
		margin: theme.spacing(2),
		zIndex: 500,
	},
	componentSelectionMenuContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
	},
	componentSelectionList: {
		listStyle: "none",
		padding: 0,
		textAlign: "center",
		fontWeight: "bold",
	},
	componentSelectionListItem: {
		margin: "10px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	componentEnabled: {
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
	},
	scannerParentContainer: {
		overflow: "hidden",
		minHeight: "500px",
	},
	componentOptionButton: {
		width: "200px",
		margin: "5px",
	},
	barCodeButton: {
		transform: "rotate(90deg)",
	},
	rememberSelectionBtn: {
		margin: "5px",
	},
	componentScannedStatusIcon: {
		width: "30px",
		height: "30px",
		padding: "5px",
		border: "solid thin silver",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	icon: {
		height: "20px",
		width: "20px",
	},
	successful: {
		color: "green",
	},
	unsuccessful: {
		color: "red",
	},
	warningTextWrapper: {
		width: "90%",
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		margin: "5px",
		paddingInline: 20,
	},
	warningText: {
		fontSize: "0.8rem",
		margin: "0px 0px 0px 5px",
		paddingTop: "2px",
		lineHeight: "20px",
	},
	warningMessage: {
		color: "black",
		fontSize: "0.8rem",
		margin: "5px",
	},
}))

const DeviceIdInput = ({
	handleClose,
	handleAccept,
	handleComponentSelection,
	commissioningComponentSelection,
	rememberComponentSelection,
	handleRememberComponentSelection,
	locIds,
	scannerSupported,
	deviceIdEntryMethod,
	setDeviceIdEntryMethod,
	isDeviceIdAssignment,
	nodeDetails,
	masterDetails,
	nodeLocIdFromUrl,
}) => {
	const styles = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const [loading, setLoading] = useState(false)
	const locIdsSplit = useMemo(() => (locIds.length ? locIds.split(":") : []), [locIds])
	// nodeLocId follows a format of 3 characters with the index e.g. 010.
	const [_siteUuid, masterUuid] = locIdsSplit
	const isMaster = useMemo(() => locIdsSplit.length === 2, [locIdsSplit])
	const originalDeviceId = useMemo(
		() => (isMaster ? masterDetails?.[masterUuid]?.mId : nodeDetails?.[masterUuid]?.[nodeLocIdFromUrl]?.nId),
		[isMaster, masterDetails, masterUuid, nodeDetails, nodeLocIdFromUrl],
	)
	const [newDeviceId, setNewDeviceId] = useState("")
	const masterOrNode = isMaster ? "Master" : "Node"
	const [scanning, setScanning] = useState(false)
	const [userMessage, setUserMessage] = useState("")
	const [successful, setSuccessful] = useState(false)
	const takenBy = useRef(null)

	useEffect(() => {
		if (successful) {
			takenBy.current = null
		}
	}, [successful])

	// Note: on initial load it'll show the originalDeviceId
	useEffect(() => {
		setNewDeviceId(originalDeviceId || "")
	}, [nodeLocIdFromUrl, originalDeviceId, isMaster])

	const handleIdChange = useCallback((e) => {
		let newValue = e.target.value
		if (newValue) {
			// Remove all non-hex characters
			newValue = newValue.replace("0x", "").toUpperCase()
			newValue = newValue.replace(/[^A-F0-9]/g, "")
		}
		setNewDeviceId(newValue)
	}, [])

	const error = useMemo(() => {
		if (!newDeviceId) return
		const isValid = isHex(newDeviceId)

		if (!isValid) {
			return "Not a valid hex ID."
		}

		if (newDeviceId.length > 16) {
			return "Max 16 characters."
		}

		if (isMaster && newDeviceId.length !== 16) {
			return "MasterID must be length of 16"
		}
	}, [newDeviceId, isMaster])

	const warning = useMemo(() => {
		if (!isMaster) {
			// Check if the new device ID is already taken by another node in the master.
			// The last 4 characters of the node ID must be unique within the master.
			const slicedDeviceId = newDeviceId?.slice(-4)
			// Allow unassignment by resetting to 0000
			if (slicedDeviceId && slicedDeviceId !== "0000") {
				const thisMasterNodeDetails = nodeDetails?.[masterUuid] || {}
				const takenNodeIds = new Set(
					Object.values(thisMasterNodeDetails).map((nodeInfo) => (nodeInfo["nId"] || "0000").slice(-4)),
				)
				if (takenNodeIds.has(slicedDeviceId)) {
					const matchingNode = Object.entries(thisMasterNodeDetails).find(([_nodeIndex, nodeInfo]) => {
						return (nodeInfo["nId"] || "0000").slice(-4) === slicedDeviceId && _nodeIndex !== nodeLocIdFromUrl
					})?.[0]
					if (matchingNode === undefined || matchingNode === nodeLocIdFromUrl) {
						// The matching node is *this* node. We will allow this, even if it might result in redundant scans.
						return null
					}
					return "This node ID is already taken in this master by another node. You can still assign it, but leaving duplicate IDs in the master will lead to unexpected behavior."
				}
			}
		}
	}, [newDeviceId, isMaster, masterUuid, nodeDetails, nodeLocIdFromUrl])

	const isValid = useMemo(() => {
		if (error || !newDeviceId || newDeviceId.length > 16 || newDeviceId.length < 4) return false
		const formatter = (val) => parseInt(val, 16).toString(16).toUpperCase().padStart(8, "0")

		if (newDeviceId.length > 8) {
			const firstHalf = newDeviceId.slice(0, 8).padStart(8, "0")
			const secondHalf = newDeviceId.slice(8, 16).padStart(8, "0")
			if (formatter(firstHalf) !== firstHalf || formatter(secondHalf) !== secondHalf) {
				return false
			}
		} else {
			if (formatter(newDeviceId) !== newDeviceId.padStart(8, "0")) return false
		}

		return true
	}, [newDeviceId, error])

	const helperText = useMemo(
		() =>
			error
				? error
				: newDeviceId !== null && !isValid
				? `Invalid ${masterOrNode} ID`
				: `${masterOrNode} ID - no leading '0x'`,
		[error, newDeviceId, isValid, masterOrNode],
	)

	const handleScanError = useCallback((err) => {
		if (err.message.includes("denied")) {
			alert("Camera permission denied")
		}
		setScanning(false)
	}, [])

	const handleScannerClose = useCallback(() => {
		setScanning(false)
	}, [])

	// Info: handle re-opening of scanner when another node is selected
	useEffect(() => {
		if (!isDeviceIdAssignment) return
		if (rememberComponentSelection && commissioningComponentSelection.isMaster === isMaster) {
			setScanning(true)
		}
	}, [
		commissioningComponentSelection,
		handleComponentSelection,
		handleRememberComponentSelection,
		isDeviceIdAssignment,
		isMaster,
		rememberComponentSelection,
		setDeviceIdEntryMethod,
	])

	const handleCodeDetection = useCallback(
		(data) => {
			setUserMessage("")
			if (deviceIdEntryMethod === "barCode") {
				// Create a synthetic event object, so we can reuse the logic in handleChange for scans
				const e = {
					target: { value: data.codeResult.code },
				}
				handleIdChange(e)
			}
		},
		[deviceIdEntryMethod, handleIdChange],
	)

	// Info: GraphQL Submit
	const handleGraphQLSubmit = useCallback(async () => {
		if (isValid) {
			setLoading(true)
			try {
				// The user may be offline. If so, graphQL queries will not work. At the same time, we need to avoid duplicate
				// master IDs. For now, we will set a clearUserMessage on the check and allow the user to proceed even if we haven't
				// confirmed that there are no duplicates.
				const deviceIdAlreadyInUsePromise = new Promise((resolve) => {
					API.graphql(graphqlOperation(getMasterLocationByMasterId, { mId: newDeviceId }))
						.then((result) => {
							const items = result.data.getMasterLocationByMasterId.items
							if (items && items.length) {
								const firstItem = items[0]
								takenBy.current = `/layout/${firstItem.layoutId}?view=layout&overlay=alarms&locIds=${firstItem.id}`
							}
							resolve(!!items && !!items.length)
						})
						.catch((error) => {
							const networkError = error.errors.reduce((acc, curr) => curr.message === "Network Error" || acc, false)
							const message = networkError
								? `${masterOrNode} request ID: ${newDeviceId} was cached to be sent later.`
								: `Unable to replace ID from ${originalDeviceId || "N/A"} to ${newDeviceId}.`
							setUserMessage(message)
							enqueueSnackbar(message, {
								variant: networkError ? "warning" : "error",
							})

							console.error(error)
							resolve("no-network")
							setSuccessful(false)
						})
				})

				await deviceIdAlreadyInUsePromise.then(async (result) => {
					if (result === "clearUserMessage" || result === "no-network") {
						console.warn(
							"Unable to confirm that this ID is not in use because you are not connected to the internet. " +
								"Proceeding anyway.",
						)
						handleAccept(newDeviceId, nodeLocIdFromUrl)
					} else {
						if (result === true) {
							setUserMessage(
								<span>
									{masterOrNode} ID {newDeviceId} is already in use by <a href={takenBy.current}>this master</a>.
								</span>,
							)
							setNewDeviceId(originalDeviceId)
							setSuccessful(false)
						} else {
							handleAccept(newDeviceId, nodeLocIdFromUrl)
							setSuccessful(true)
							setUserMessage(`Successfully replaced ID from ${originalDeviceId} to ${newDeviceId}`)
						}
					}
				})
			} finally {
				setLoading(false)
			}
		}
	}, [isValid, newDeviceId, masterOrNode, originalDeviceId, enqueueSnackbar, handleAccept, nodeLocIdFromUrl])

	const formSubmitHandler = useCallback(() => {
		handleGraphQLSubmit().then()
	}, [handleGraphQLSubmit])

	const ScannerContainerHeader = () => {
		return (
			<div className={styles.scannerModalHeader}>
				<div className={styles.scannerModalHeaderChild}>
					{scanning ? (
						<ArrowBackIcon onClick={handleScannerClose} className={styles.scannerModalHeaderBackBtn} />
					) : isMaster ? (
						masterDetails[masterUuid].name ?? "N/A"
					) : (
						<>
							Node: <strong>{originalDeviceId ?? "N/A"}</strong>
						</>
					)}
				</div>
				<div className={styles.scannerModalHeaderCenter}>
					{!!userMessage && (
						<div className={styles.messageContainer}>
							<CloseIcon className={styles.closeMessageBtn} onClick={() => setUserMessage("")} />
							<p>
								<span className={`${successful ? styles.successful : styles.unsuccessful}`}>Response: </span>
								{userMessage}
							</p>
						</div>
					)}
				</div>
				<div className={styles.scannerModalHeaderChild}></div>
			</div>
		)
	}

	const endAdornment = newDeviceId ? (
		<InputAdornment position="end">
			<IconButton edge="end" aria-label="Clear" onClick={() => setNewDeviceId("")}>
				<ClearIcon />
			</IconButton>
		</InputAdornment>
	) : scannerSupported ? (
		<InputAdornment position="end">
			<IconButton
				className={styles.barCodeButton}
				edge="end"
				aria-label="Barcode Scan"
				onClick={() => {
					setScanning(true)
					setDeviceIdEntryMethod("barCode")
					handleComponentSelection({
						name: "node assignment",
						validation: [],
						enabled: true,
						isMaster: false,
					})
				}}
			>
				<Tooltip title="Barcode Scan">
					<FormatAlignJustifyIcon />
				</Tooltip>
			</IconButton>
		</InputAdornment>
	) : null

	return !scanning ? (
		<DialogContent className={`${deviceIdEntryMethod === "text" ? styles.content : styles.scannerContent}`}>
			{isMaster && (
				<>
					<ScannerContainerHeader userMessage={userMessage} />
					{!isDeviceIdAssignment && (
						<>
							<TextField
								id="deviceid-input"
								variant="outlined"
								className={styles.textInputBox}
								type="text"
								label={`${masterOrNode} ID`}
								onChange={handleIdChange}
								value={newDeviceId || ""}
								error={!!newDeviceId && !isValid}
								helperText={helperText}
								autoFocus={deviceIdEntryMethod === "text" || !scannerSupported}
								InputProps={!isMaster ? { endAdornment } : {}}
							/>
							{!!warning && <p className={styles.warningMessage}>{warning}</p>}
							<DialogActions className={styles.actions}>
								<Button onClick={handleClose} className={styles.actionButton}>
									Close
								</Button>
								<Button
									variant="contained"
									color="primary"
									type="submit"
									disabled={!isValid || loading}
									className={styles.actionButton}
									onClick={formSubmitHandler}
								>
									Accept
								</Button>
							</DialogActions>
						</>
					)}
					{isDeviceIdAssignment && <p>Coming Soon</p>}
				</>
			)}
			{!isMaster && (
				<>
					<div className={styles.scannerModalContainer}>
						<ScannerContainerHeader userMessage={userMessage} />
						<TextField
							id="deviceid-input"
							variant="outlined"
							className={styles.textInputBox}
							type="text"
							label={`Enter new ${masterOrNode} ID`}
							onChange={handleIdChange}
							value={newDeviceId || ""}
							error={!!newDeviceId && !isValid}
							helperText={helperText}
							autoFocus={deviceIdEntryMethod === "text" || !scannerSupported}
							InputProps={{
								endAdornment,
							}}
						/>
						{!!warning && (
							<div className={styles.warningTextWrapper}>
								<ErrorOutline fontSize="small" color="error" />
								<p className={styles.warningText}>{warning}</p>
							</div>
						)}
						<DialogActions className={styles.actions}>
							<Button onClick={handleClose} className={styles.actionButton}>
								Close
							</Button>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								disabled={!isValid || loading}
								className={styles.actionButton}
								onClick={formSubmitHandler}
							>
								Accept
							</Button>
						</DialogActions>
					</div>

					{isDeviceIdAssignment && (
						<>
							<div className={styles.nodeLocationNavContainer}>
								<NodeLocationNavigator positionAbsolute={false} displayOpenBtn={false} />
							</div>
						</>
					)}
				</>
			)}
		</DialogContent>
	) : (
		<div className={styles.scannerParentContainer}>
			<ScannerContainerHeader userMessage={userMessage} />
			{deviceIdEntryMethod === "barCode" && (
				<ReactQuagga
					nodeIdEntered={newDeviceId}
					onDetected={handleCodeDetection}
					onClose={handleScannerClose}
					onError={(err) => handleScanError(err)}
				/>
			)}
			{deviceIdEntryMethod === "qrCode" && (
				<ReactQrReader
					onDetected={handleCodeDetection}
					onClose={handleScannerClose}
					onError={(err) => handleScanError(err)}
				/>
			)}
		</div>
	)
}

DeviceIdInput.propTypes = {
	handleClose: PropTypes.func.isRequired,
	deviceIdEntryMethod: PropTypes.string,
	setDeviceIdEntryMethod: PropTypes.func,
	locIds: PropTypes.string.isRequired,
	handleAccept: PropTypes.func.isRequired,
	nodeDetails: PropTypes.object,
	masterDetails: PropTypes.object,
	nodeComponentScanned: PropTypes.object,
	nodeLocIdFromUrl: PropTypes.string,
}

const DialogDeviceIdInput = (props) => {
	const [deviceIdEntryMethod, setDeviceIdEntryMethod] = useState(null)
	const scannerSupported = useQuagga()
	const isOpen = props.locIds.length > 0
	const isDeviceIdAssignment = props.location.search.split("&").includes("overlay=deviceid-assignement")

	return (
		<Dialog
			open={isOpen}
			TransitionComponent={Transition}
			keepMounted={false}
			onClose={props.handleClose}
			aria-labelledby="device-id-input-title"
			aria-describedby="device-id-input-description"
			fullWidth={true}
			hideBackdrop={true}
		>
			{isOpen && (
				<DeviceIdInput
					nodeComponentScanned={props.nodeComponentScanned}
					masterComponentScanned={props.masterComponentScanned}
					isDeviceIdAssignment={isDeviceIdAssignment}
					scannerSupported={scannerSupported}
					deviceIdEntryMethod={deviceIdEntryMethod}
					setDeviceIdEntryMethod={setDeviceIdEntryMethod}
					{...props}
				/>
			)}
		</Dialog>
	)
}

DialogDeviceIdInput.propTypes = DeviceIdInput.propTypes

export default DialogDeviceIdInput

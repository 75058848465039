import { PropTypes as p } from "prop-types"

const options = {
	animatedZooms: { type: p.bool },
	annotationClickHandler: { type: p.func, rename: "onAnnotationClick", omitIfUnchanged: true },
	annotationDblClickHandler: { type: p.func, rename: "onAnnotationDblClick", omitIfUnchanged: true },
	annotationMouseOutHandler: { type: p.func, rename: "onAnnotationMouseOut", omitIfUnchanged: true },
	annotationMouseOverHandler: { type: p.func, rename: "onAnnotationMouseOver", omitIfUnchanged: true },
	axes: true,
	axis: { type: p.string },
	axisLabelColor: true,
	axisLabelFontSize: { type: p.number },
	axisLabelFormatter: true,
	axisLabelWidth: { type: p.number },
	axisLineColor: true,
	axisLineWidth: { type: p.number },
	axisTickSize: { type: p.number },
	clickCallback: { type: p.func, omitIfUnchanged: true },
	color: true,
	colorSaturation: { type: p.number },
	colorValue: { type: p.number },
	colors: true,
	connectSeparatedPoints: { type: p.bool },
	customBars: { type: p.bool },
	dataHandler: true,
	dateWindow: true,
	delimiter: { type: p.string },
	digitsAfterDecimal: { type: p.number },
	displayAnnotations: { type: p.bool },
	drawAxesAtZero: { type: p.bool },
	drawAxis: { type: p.bool },
	drawCallback: { type: p.func, omitIfUnchanged: true },
	drawGapEdgePoints: { type: p.bool },
	drawGrid: { type: p.bool },
	drawHighlightPointCallback: { type: p.func, omitIfUnchanged: true },
	drawPointCallback: { type: p.func, omitIfUnchanged: true },
	drawPoints: { type: p.bool },
	errorBars: { type: p.bool },
	file: {
		type: p.oneOfType([
			p.string /* CSV or URL */,
			p.arrayOf(p.arrayOf(p.oneOfType([p.instanceOf(Date), p.number]))),
			p.func,
		]),
		rename: "data",
	},
	fillAlpha: { type: p.number },
	fillGraph: { type: p.bool },
	fractions: { type: p.bool },
	gridLineColor: true,
	gridLinePattern: { type: p.arrayOf(p.number) },
	gridLineWidth: { type: p.number },
	height: { type: p.number },
	hideOverlayOnMouseOut: { type: p.bool },
	highlightCallback: { type: p.func, omitIfUnchanged: true },
	highlightCircleSize: true,
	highlightSeriesBackgroundAlpha: true,
	highlightSeriesOpts: true,
	includeZero: { type: p.bool },
	independentTicks: { type: p.bool },
	// interactionModel: true,
	isZoomedIgnoreProgrammaticZoom: true,
	labels: { type: p.arrayOf(p.string) },
	labelsDiv: true,
	labelsDivStyles: true,
	labelsDivWidth: { type: p.number },
	labelsKMB: { type: p.bool },
	labelsKMG2: { type: p.bool },
	labelsSeparateLines: { type: p.bool },
	labelsShowZeroValues: { type: p.bool },
	labelsUTC: { type: p.bool },
	legend: p.oneOf(["onmouseover", "always", "follow"]),
	legendFormatter: { type: p.func, rename: "onLegendFormat", omitIfUnchanged: true },
	logscale: { type: p.bool },
	maxNumberWidth: { type: p.number },
	panEdgeFraction: { type: p.number },
	pixelsPerLabel: { type: p.number },
	plotter: { type: p.oneOfType([p.func, p.arrayOf(p.func)]) },
	plugins: true,
	pointClickCallback: { type: p.func, rename: "onPointClick", omitIfUnchanged: true },
	pointSize: { type: p.number },
	rangeSelectorHeight: { type: p.number },
	rangeSelectorPlotFillColor: true,
	rangeSelectorPlotStrokeColor: true,
	rightGap: { type: p.number },
	rollPeriod: { type: p.number },
	series: true,
	showInRangeSelector: { type: p.bool },
	showLabelsOnHighlight: { type: p.bool },
	showRangeSelector: { type: p.bool },
	showRoller: { type: p.bool },
	sigFigs: { type: p.number },
	sigma: { type: p.number },
	stackedGraph: { type: p.bool },
	stackedGraphNaNFill: true,
	stepPlot: { type: p.bool },
	strokeBorderColor: true,
	strokeBorderWidth: { type: p.number },
	strokePattern: true,
	strokeWidth: { type: p.number },
	ticker: true,
	timingName: true,
	title: true,
	titleHeight: { type: p.number },
	underlayCallback: { type: p.func, omitIfUnchanged: true },
	unhighlightCallback: { type: p.func, omitIfUnchanged: true },
	valueFormatter: true,
	valueRange: true,
	visibility: { type: p.arrayOf(p.bool) },
	width: { type: p.number },
	wilsonInterval: true,
	xAxisHeight: { type: p.number },
	xLabelHeight: { type: p.number },
	xRangePad: { type: p.number },
	xValueParser: true,
	xlabel: true,
	y2label: true,
	yLabelWidth: { type: p.number },
	yRangePad: { type: p.number },
	ylabel: true,
	zoomCallback: { type: p.func, omitIfUnchanged: true },
}

type PropConfig =
	| boolean
	| {
			type?: Function,
			private?: boolean,
			rename?: string,
	  }

function getPropType(optionPropConfig: PropConfig) {
	if (!optionPropConfig) return
	if (optionPropConfig === true) return p.any
	if (optionPropConfig.private) return
	if (!optionPropConfig.type) return p.any
	return optionPropConfig.type
}

function getPropName(optionPropConfig: PropConfig, optionName?: string) {
	if (!optionPropConfig || optionPropConfig === true) return optionName
	if (typeof optionPropConfig.rename === "string") return optionPropConfig.rename
	return optionName
}

function optionIsPrivate(optionPropConfig: PropConfig) {
	if (optionPropConfig === false) return true
	if (!optionPropConfig) return
	if (optionPropConfig === true) return false
	return optionPropConfig.private
}

function getReactPropTypes(options) {
	const props = {}
	const optionsList = Object.keys(options)
	optionsList.forEach((optionName) => {
		const option = options[optionName]
		if (option && !optionIsPrivate(option)) {
			const propName = getPropName(option, optionName)
			props[propName] = getPropType(option)
		}
	})
	return props
}

function getPropMap(options) {
	const propMap = {}
	const optionsList = Object.keys(options)
	optionsList.forEach((optionName) => {
		const option = options[optionName]
		if (option) {
			const propName = getPropName(option, optionName)
			propMap[propName] = optionName
		}
	})
	return propMap
}

const propMap = getPropMap(options)

function spreadProps(props) {
	const known = {},
		rest = {}
	const propsList = Object.keys(props)
	propsList.forEach((propName) => {
		const isDygraphsProp = !!propMap[propName]
		if (!(isDygraphsProp && optionIsPrivate(options[propMap[propName]]))) {
			let target = isDygraphsProp ? known : rest
			const nameOut = isDygraphsProp ? propMap[propName] : propName
			if (props[propName]) {
				target[nameOut] = props[propName]
			}
		}
	})
	return { known, rest }
}

const propTypes = getReactPropTypes(options)

export default options
export { options, propTypes, propMap, spreadProps }

import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import Divider from "@material-ui/core/Divider"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
	container: {
		maxHeight: 420,
		[theme.breakpoints.down("xs")]: {
			maxHeight: 220,
		},
	},
	clickable: {
		cursor: "pointer",
	},
	valueCell: {
		textAlign: "right",
	},
	error: {
		color: "red",
	},
}))

const sortByDesc = (a, b) => {
	let nameA = a.description
	let nameB = b.description

	// If one is undefined but not the other...
	if (!!nameA !== !!nameB) {
		// If nameA is defined, return -1, else return 1
		return Number(!!nameB) - Number(!!nameA)
	}

	nameA = nameA.toUpperCase() // ignore upper and lowercase
	nameB = nameB.toUpperCase() // ignore upper and lowercase

	if (nameA < nameB) {
		return -1
	}
	if (nameA > nameB) {
		return 1
	}

	// names must be equal
	return 0
}

const NodesSummary = (props) => {
	const {
		summaryNodeCount,
		duplicatesSummary,
		summaryData,
		summaryHeading,
		visibilityMask,
		handleFilterChange,
		siteNodeCount,
		displaySiteNodeCount,
	} = props
	const classes = useStyles()
	return (
		<>
			<Divider />
			<Divider />
			{duplicatesSummary && (
				<>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>Problems with node IDs detected</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{duplicatesSummary.map((duplicateMessage) => (
								<TableRow
									key={
										/** @type {string} */
										duplicateMessage
									}
								>
									<TableCell className={classes.error}>
										{
											/** @type {string} */
											duplicateMessage
										}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<Divider />
					<Divider />
				</>
			)}
			{!!summaryNodeCount && (
				<Table size="small">
					<TableBody>
						{Object.keys(summaryNodeCount).length > 0 ? (
							Object.keys(summaryNodeCount).map((dataRow) => (
								<TableRow key={dataRow}>
									<TableCell>{dataRow}</TableCell>
									<TableCell className={classes.valueCell}>{summaryNodeCount[dataRow]}</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell>No Data</TableCell>
								<TableCell></TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			)}
			{displaySiteNodeCount && (
				<Table size="small">
					<TableBody>
						<TableRow>
							<TableCell>Node Count</TableCell>
							<TableCell className={classes.valueCell}>{siteNodeCount}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			)}
			{summaryHeading !== null && (
				<>
					<Divider />
					<Divider />
					<TableContainer className={classes.container}>
						<Table size="small" stickyHeader>
							<TableHead>
								<TableRow>
									{summaryHeading.map((headingDesc, ind, { length }) => (
										<TableCell key={headingDesc} className={ind === length - 1 ? classes.valueCell : undefined}>
											{headingDesc}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{summaryData === null ? (
									<TableRow>
										<TableCell>No Data</TableCell>
										<TableCell></TableCell>
									</TableRow>
								) : summaryData.length === 0 ? (
									<TableRow>
										<TableCell>None</TableCell>
										<TableCell></TableCell>
									</TableRow>
								) : (
									summaryData.sort(sortByDesc).map((dataRow, index) => {
										let action = dataRow.action
										if (dataRow.action !== null && typeof dataRow.action !== "bigint") {
											if (typeof dataRow.action === "number") {
												throw new Error(`Invalid action type (expected BigInt, got ${dataRow.action})`)
											}
											action = BigInt(dataRow.action)
										}
										let alarmVisibilitySelected = false
										let selectAction = null
										if (action !== null) {
											alarmVisibilitySelected = (visibilityMask & (1n << dataRow.action)) !== 0n
											selectAction = (e) => {
												handleFilterChange(e, dataRow.action)
											}
										}
										return (
											<TableRow
												key={index}
												className={action === null ? undefined : classes.clickable}
												hover={!!dataRow.action}
												selected={alarmVisibilitySelected}
												onClick={selectAction}
											>
												<TableCell>{dataRow.description}</TableCell>
												<TableCell className={classes.valueCell}>{dataRow.value}</TableCell>
											</TableRow>
										)
									})
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			)}
		</>
	)
}

NodesSummary.propTypes = {
	summaryData: PropTypes.arrayOf(PropTypes.object),
	summaryNodeCount: PropTypes.object.isRequired,
	siteNodeCount: PropTypes.number,
	displaySiteNodeCount: PropTypes.bool,
	duplicatesSummary: PropTypes.arrayOf(PropTypes.string),
	summaryHeading: PropTypes.any,
	alarmsSummary: PropTypes.arrayOf(PropTypes.object),
	visibilityMask: (props, propName, componentName) => {
		if (typeof props[propName] !== "bigint") {
			return new Error(`Invalid prop '${propName}' supplied to '${componentName}', expected 'BigInt'.`)
		}
	},
	handleFilterChange: PropTypes.func,
}

export default NodesSummary

import { useHistory, useLocation } from "react-router-dom"

export const toggleMapView = (urlSearch = "") => {
	const params = new URLSearchParams(urlSearch)
	const viewString = params.get("view")
	const viewList = viewString.split(",")
	let updatedViewList
	if (viewList.includes("map")) {
		updatedViewList = viewList.filter((viewItem) => viewItem !== "map")
	} else {
		updatedViewList = [...viewList, "map"]
	}
	params.set("view", updatedViewList.join(","))
	return `?${params.toString()}`
}

export const getMasterLocIds = (urlSearch = "") => {
	const params = new URLSearchParams(urlSearch)
	let locIds = params.get("locIds")
	if (!locIds) return []
	return locIds.split(",").map((loc) => loc.split(":")[0])
}

export const getNodeLocIdsByMasterLocId = (urlSearch = "") => {
	const params = new URLSearchParams(urlSearch)
	const activeIds = params.get("locIds")
	if (!activeIds) return {}
	const nLocIdsByMasterLocId = {}
	activeIds.split(",").forEach((ids) => {
		const [mLocId, nLocIds] = ids.split(":")
		if (!nLocIds) return null
		nLocIdsByMasterLocId[mLocId] = nLocIds
	})
	return nLocIdsByMasterLocId
}

export const clearNodeLocIds = (urlSearch = "") => {
	const params = new URLSearchParams(urlSearch)
	let locIds = params.get("locIds")
	if (!locIds) return urlSearch
	const mLocIds = locIds.split(",").map((ids) => {
		let [mLocId] = ids.split(":")
		return mLocId
	})
	params.set("locIds", mLocIds.join(","))
	return `?${params.toString()}`
}

export const getMask = (urlSearch = "") => {
	const params = new URLSearchParams(urlSearch)
	const filter = params.get("filter")
	if (!filter) return 0n
	return BigInt(`0b${filter}`)
}

export const updateMasks =
	(urlSearch = "") =>
	(eventCtrlKey, maskBit) => {
		const params = new URLSearchParams(urlSearch)
		let newMask

		if (eventCtrlKey) {
			// If the control key is pressed, we want to toggle the bit, leaving other bits untouched.
			newMask = getMask(urlSearch) // Set to the current mask
			// Toggle the bit
			newMask ^= 1n << BigInt(maskBit)
		} else {
			// Not toggling, but selecting a specific bit. We want to set only that bit to 1 and all other bits to 0.
			// Completely overwrite the new mask without regard for the existing mask:
			newMask = 1n << BigInt(maskBit)
		}

		if (newMask === 0n) {
			params.delete("filter")
		} else {
			params.set("filter", newMask.toString(2))
		}

		return `?${params.toString()}`
	}

export const useUrlParams = () => {
	const { search, pathname } = useLocation()
	const history = useHistory()

	const applyParamChange = (searchString) => {
		history.push({
			pathname,
			search: searchString,
		})
	}

	return {
		toggleMapView: () => applyParamChange(toggleMapView(search)),
		getMasterLocIds: () => getMasterLocIds(search),
		getNodeLocIdsByMasterLocId: () => getNodeLocIdsByMasterLocId(search),
		clearNodeLocIds: () => applyParamChange(clearNodeLocIds(search)),
		getMask: () => getMask(search),
		updateMasks: () => applyParamChange(updateMasks(search)),
	}
}

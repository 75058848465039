import red from "@material-ui/core/colors/red"
import green from "@material-ui/core/colors/green"
import amber from "@material-ui/core/colors/amber"
import pink from "@material-ui/core/colors/pink"
import grey from "@material-ui/core/colors/grey"
import brown from "@material-ui/core/colors/brown"
import yellow from "@material-ui/core/colors/yellow"

// TODO: Import from here everywhere instead of re-declaring. Consider better solutions as well.
export const colorPrimary = "#20447E"
export const colorRed = red["A700"]
export const colorGreen = green[700]
export const colorAmber = amber[700]
export const colorPink = pink[300]
export const colorGrey = grey[400]
export const colorBrown = brown[900]
export const colorYellow = yellow[500]

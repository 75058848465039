import { connect } from "react-redux"
import MasterCoordinateEditor from "./MasterCoordinateEditor"
import { getLayoutMasterIds } from "utils"

import { updateMasterDetails } from "store/actions/setters"
import { setConfigMasterCoordinates } from "store/actions/reduxActions"

const getMasterCoordinates = (state, props) => {
	const masterCoordinates = {}
	getLayoutMasterIds(state.layoutDetails, props.layoutId).forEach((mLocId) => {
		const masterDetails = state.masterDetails[mLocId]
		if (masterDetails) {
			const { xLoc = null, yLoc = null } = masterDetails
			if (xLoc !== null && yLoc !== null) {
				masterCoordinates[mLocId] = [yLoc, xLoc]
			}
		}
	})
	return masterCoordinates
}

const getMasterLabels = (state, props) => {
	const masterAreaLabels = {}
	getLayoutMasterIds(state.layoutDetails, props.layoutId).forEach((mLocId) => {
		if (mLocId in state.masterDetails) {
			masterAreaLabels[mLocId] = state.masterDetails[mLocId].name
		}
	})
	return masterAreaLabels
}

const mapStateToProps = (state, props) => ({
	masterCoordinates: getMasterCoordinates(state, props),
	masterLabels: getMasterLabels(state, props),
	radius: (parseInt(state.configNodeTranslations.markerRadius) || 0.5) + 1,
})

const mapDispatchToProps = (dispatch, _props) => ({
	setConfigMasterCoordinates: (coordinates) => dispatch(setConfigMasterCoordinates(coordinates)),
	updateMasterCoordinates: (mLocId, layoutId, coordinates) =>
		dispatch(updateMasterDetails(mLocId, layoutId, coordinates)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MasterCoordinateEditor)

import React, { Children } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import ReactDOMServer from "react-dom/server"
import { divIcon } from "leaflet"
import { Marker } from "react-leaflet"

const useStyles = makeStyles({
	root: {
		pointerEvents: "none !important",
	},
})

const DivIcon = ({ position, children, ...otherProps }) => {
	const classes = useStyles()

	const icon = divIcon({
		className: [classes.root],
		html: ReactDOMServer.renderToString(Children.only(children)),
	})

	return <Marker position={position} icon={icon} {...otherProps} />
}

DivIcon.propTypes = {
	position: PropTypes.array.isRequired,
}

export default DivIcon

/**
 * This function is needed because:
 *
 * 1. Some node flag labels depend on the node firmware version, which is recorded in the MASTER details, not the node
 * details.
 * 2. We could simply fish out the node firmware version using:
 * 		state.masterData[`${props.mLocId}#${timestamp}`]["fwNode"]
 * But unfortunately, the timestamp part of the master data key is missing a digit. It would be dangerous to assume this
 * is true for all masters in all layouts.
 *
 * A temporary solution is to go through all the keys in state.masterData and find the first match.
 * @param state
 * @param props
 * @param mLocId Optionally specify the mLocId of the master. If falsy, the value will be gotten from `props.mLocId`.
 */
export const getRecentMasterDataFromMLocId = (state, props, mLocId = null) => {
	for (const key in state.masterData || {}) {
		const parts = key.split("#")
		if (parts.length !== 2) {
			continue
		}
		// NOTE: The timestamp part in "[mLocId]#[timestamp]" is apparently not relevant, and may be different, so it's
		//  not checked.
		// noinspection JSUnusedLocalSymbols
		const [thisMLocId, _timestampStr] = parts
		if (thisMLocId === (mLocId || props.mLocId)) {
			return state.masterData[key]
		}
	}
	return
}

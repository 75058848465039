import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

const PrivilegedRoute = ({ component: Component, requiredPrivilege, userPrivileges, isAuthenticated, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			isAuthenticated && (requiredPrivilege & userPrivileges) > 0 ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: "/",
						state: { from: props.location },
					}}
				/>
			)
		}
	/>
)

PrivilegedRoute.propTypes = {
	requiredPrivilege: PropTypes.number.isRequired,
	userPrivileges: PropTypes.number.isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
}

export default PrivilegedRoute

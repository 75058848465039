import { deviceTypes } from "./deviceTypes"
import { privileges } from "./auth"

export const overlays = {
	ALARMS: "alarms",
	ANGLES: "angles",
	CHARGINGSTATUS: "charging-status",
	DEVICEIDASSIGNMENT: "deviceid-assignement",
	FLAGS: "status-flags",
	ZIGBEE_HOPS: "zigbee-hops",
	SMART_STOW: "smart-stow",
}

const overlayList = [
	overlays.ALARMS,
	overlays.FLAGS,
	overlays.CHARGINGSTATUS,
	overlays.DEVICEIDASSIGNMENT,
	overlays.SMART_STOW,
]

export const DEFAULT_OVERLAY = overlays.ALARMS

export const overlayDetails = {
	[overlays.ALARMS]: {
		label: "Alarms",
		deviceCompatibility: [
			deviceTypes.GAMECHANGE_REFACTORED,
			deviceTypes.GAMECHANGE_GEN2,
			deviceTypes.GAMECHANGE_GEN2_MODBUS,
			deviceTypes.P4Q,
		],
		privilege: privileges.DATA_SNAPSHOT,
	},
	[overlays.FLAGS]: {
		label: "Status Flags",
		deviceCompatibility: [deviceTypes.GAMECHANGE_REFACTORED],
		privilege: privileges.DATA_SNAPSHOT,
	},
	[overlays.ANGLES]: {
		label: "Angles",
		deviceCompatibility: [
			deviceTypes.GAMECHANGE_REFACTORED,
			deviceTypes.GAMECHANGE_GEN2,
			deviceTypes.GAMECHANGE_GEN2_MODBUS,
			deviceTypes.P4Q,
		],
		privilege: privileges.DATA_SNAPSHOT,
	},
	[overlays.CHARGINGSTATUS]: {
		label: "Charging Statuses",
		deviceCompatibility: [deviceTypes.GAMECHANGE_REFACTORED, deviceTypes.GAMECHANGE_GEN2],
		privilege: privileges.DATA_SNAPSHOT,
	},
	[overlays.ZIGBEE_HOPS]: {
		label: "Zigbee Hops",
		deviceCompatibility: [deviceTypes.GAMECHANGE_REFACTORED],
		privilege: privileges.DEV,
	},
	[overlays.DEVICEIDASSIGNMENT]: {
		label: "Device ID Assignment",
		deviceCompatibility: [
			deviceTypes.GAMECHANGE_REFACTORED,
			deviceTypes.GAMECHANGE_GEN2,
			deviceTypes.GAMECHANGE_GEN2_MODBUS,
		],
		privilege: privileges.VIEW,
	},
	[overlays.SMART_STOW]: {
		label: "Smart Stows",
		deviceCompatibility: [
			deviceTypes.GAMECHANGE_REFACTORED,
			deviceTypes.GAMECHANGE_GEN2,
			deviceTypes.GAMECHANGE_GEN2_MODBUS,
			deviceTypes.P4Q,
		],
		privilege: privileges.VIEW,
	},
}

export const getOverlayList = (userPrivileges, deviceType) => {
	var userOverlayList = []
	overlayList.forEach((overlay) => {
		const { label, deviceCompatibility, privilege } = overlayDetails[overlay]
		if (deviceCompatibility.includes(deviceType) && (userPrivileges & privilege) > 0) {
			userOverlayList.push({ overlayId: overlay, label })
		}
	})
	return userOverlayList
}

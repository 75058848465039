import { connect } from "react-redux"
import AssignNodeParameters from "./AssignNodeParameters"
import { compose } from "redux"
import { withRouter } from "react-router"
import { withSnackbar } from "notistack"
import { fetchMasterDetails } from "store/actions/fetchers"
import { updateMasterDetails } from "store/actions/setters"

const getMasterList = (state, props) => {
	const masterDetails = state.masterDetails
	const layoutMasters = (state.layoutDetails[props.layoutId] || {})["mLocIds"] || []
	let masterList = {}
	if (layoutMasters && masterDetails) {
		layoutMasters.forEach((mLocId) => {
			masterList[mLocId] = (masterDetails[mLocId] || {})["name"]
		})
		return masterList
	}
}

const mapStateToProps = (state, props) => ({
	masterDetails: state.masterDetails,
	masterList: getMasterList(state, props),
})

const mapDispatchToProps = (dispatch, props) => ({
	fetchMasterDetails: (layoutId) => dispatch(fetchMasterDetails(layoutId)),
	updateMasterDetails: (mLocId, layoutId, masterDetails) =>
		dispatch(updateMasterDetails(mLocId, layoutId, masterDetails)),
})

export default compose(withSnackbar, withRouter, connect(mapStateToProps, mapDispatchToProps))(AssignNodeParameters)

export function convertTimezoneToUTCOffset(timezone) {
	// Create a DateTimeFormat object for the specified timezone
	const formatter = new Intl.DateTimeFormat("en", {
		timeZone: timezone,
		timeZoneName: "short",
	})

	// Use a fixed date string for formatting
	const fixedDateString = "2023-01-01T00:00:00Z"

	// Format the fixed date string and get the timezone offset
	const [{ value: offsetString }] = formatter.formatToParts(new Date(fixedDateString))

	return offsetString
}

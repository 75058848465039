import React, { useEffect, useRef, useState } from "react"
import QrScanner from "qr-scanner"
import PropTypes from "prop-types"
import api from "../../constants/api"
import { makeStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"

const useStyles = makeStyles(() => ({
	videoContainer: { width: "100%", display: "flex", justifyContent: "center", alignItems: "center" },
	circularProgress: { position: "absolute", top: "50%", left: "45%" },
}))

const ReactQrReader = ({ onDetected: handleDetected, onClose }) => {
	const styles = useStyles()
	const videoRef = useRef(null)
	const scannerRef = useRef(null)
	const [scannedData, setScannedData] = useState(null)
	const [parsed, setParsed] = useState(false)
	const [loadingSpinner, setLoadingSpinner] = useState(true)
	function parseStringToJSON(input) {
		const keyValuePairs = input.split("&")
		const result = {}

		for (const pair of keyValuePairs) {
			const [key, value] = pair.split(":")
			if (key && value) {
				result[key.toLowerCase().trim().replace(" ", "_")] = value.trim()
			}
		}
		result["replaced_by"] = api.cognitoID // this is the users UUID both in Cognito AND the rest api
		result["scanned_time"] = new Date().toISOString()
		result["schema_version"] = result["version"] || "0"
		delete result["version"]
		return result
	}

	const startCamera = async () => {
		try {
			videoRef.current.srcObject = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
		} catch (e) {
			// if the video ref becomes unavailable, the user probably clicked away and don't actually want the camera
			// we'll log the error, but 9/10 times this is because the user clicked away
			console.error(e)
			if (scannerRef.current) {
				// ensure that the scanner is stopped and destroyed, ending the workers
				scannerRef.current.stop()
				scannerRef.current.destroy()
				scannerRef.current = null
			}
			onClose()
			return
		}
		const scanner = new QrScanner(
			videoRef.current,
			(result) => {
				// The result parameter contains the scanned QR code data.
				// Set the scanned data to the state for processing
				setScannedData(result.data)
			},
			{ highlightScanRegion: true },
		)

		scannerRef.current = scanner
		await scanner.start()
	}

	useEffect(() => {
		if (scannedData) {
			// Use the scanned text to parse into JSON
			const parsedData = parseStringToJSON(scannedData)
			handleDetected(parsedData)
			setParsed(true)
		}
	}, [handleDetected, scannedData])

	useEffect(() => {
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			startCamera()
				.then(() => {
					setLoadingSpinner(false)
				})
				.catch(console.error)

			return () => {
				// we want to ALWAYS call the parents onClose function, even if the scanner is already closed
				if (scannerRef.current) {
					// ensure that the scanner is stopped and destroyed, ending the workers
					scannerRef.current.stop()
					scannerRef.current.destroy()
					scannerRef.current = null
				}
				onClose()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleDetected, onClose, parsed])

	return (
		<div className={styles.videoContainer}>
			{loadingSpinner && <CircularProgress className={styles.circularProgress} size={50} thickness={1} />}
			<video ref={videoRef} autoPlay playsInline />
		</div>
	)
}

ReactQrReader.propTypes = {
	onDetected: PropTypes.func.isRequired,
	onProcessed: PropTypes.func,
	onError: PropTypes.func,
	onClose: PropTypes.func,
}

export default ReactQrReader

import React from "react"
import PropTypes from "prop-types"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import LinearProgress from "@material-ui/core/LinearProgress"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormLabel from "@material-ui/core/FormLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Checkbox from "@material-ui/core/Checkbox"
import Divider from "@material-ui/core/Divider"

import { Link, Redirect } from "react-router-dom"
import format from "date-fns/format"
import addMonths from "date-fns/addMonths"

import { accessModes, accessLabels } from "constants/auth"

const styles = (theme) => ({
	root: {
		height: "100%",
		width: "100%",
		paddingBottom: theme.spacing(4),
		overflowY: "auto",
	},
	main: {
		width: "auto",
		display: "flex", // Fix IE 11 issue.
		flexDirection: "column",
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		[theme.breakpoints.up(400 + theme.spacing(6))]: {
			width: 410,
			marginLeft: "auto",
			marginRight: "auto",
		},
	},
	progress: {
		marginTop: theme.spacing(4),
	},
	paper: {
		marginTop: 0,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: theme.spacing(2, 3, 3),
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		marginTop: theme.spacing(3),
	},
	forgotPassword: {
		marginTop: theme.spacing(2),
		marginBottom: 0,
		margin: "auto",
		display: "flex",
		alignItems: "center",
	},
	textField: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(1),
	},
	group: {
		marginTop: theme.spacing(2),
	},
	divider: {
		margin: theme.spacing(2),
	},
})

const errorCodes = {
	"auth/email-already-in-use": {
		msg: "Email address is alread in use.",
		field: "errorEmail",
	},
	"auth/invalid-email": {
		msg: "Email address is not valid.",
		field: "errorEmail",
	},
	"auth/operation-not-allowed": {
		msg: "Email/password accounts are not enabled.",
		field: "errorEmail",
	},
	"auth/weak-password": {
		msg: "Password is not strong enough.",
		field: "errorPassword",
	},
}

class PrivilegeSelect extends React.Component {
	handleChange = (mode) => (event) => {
		this.props.onChange(this.props.value ^ mode)
	}

	render() {
		return (
			<React.Fragment>
				<FormLabel component="legend">Assign Privileges</FormLabel>
				<FormHelperText>*Select Multiple</FormHelperText>
				<FormGroup>
					{accessModes.map((mode) => (
						<FormControlLabel
							key={accessLabels[mode]}
							control={
								<Checkbox
									checked={(this.props.value & mode) > 0}
									onChange={this.handleChange(mode)}
									value={mode}
									color="primary"
								/>
							}
							label={accessLabels[mode]}
						/>
					))}
				</FormGroup>
			</React.Fragment>
		)
	}
}

class RegisterUser extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			userDetails: {
				email: "",
				emailConf: "",
				nameFirst: "",
				nameLast: "",
				company: "",
				dateExpiration: format(addMonths(new Date(), 1), "yyyy-MM-dd"),
				employee: false,
				trial: false,
				privileges: 0,
			},
			errorEmail: null,
			errorEmailConf: null,
			pendingResponse: false,
		}
	}

	handleChange = (name, type) => (event) => {
		var value = null
		if (type === "text") {
			value = event.target.value
		} else if (type === "check") {
			value = event.target.checked
		} else if (type === "bit-array") {
			value = event
		}
		this.setState((prevState) => ({
			userDetails: { ...prevState.userDetails, [name]: value },
			errorEmail: null,
			errorEmailConf: null,
			pendingResponse: false,
		}))
	}

	submit = (e) => {
		e.preventDefault()
		this.setState({
			errorEmail: null,
			errorEmailConf: null,
			pendingResponse: true,
		})
		// console.warn(this.state)
		const { emailConf, ...userDetails } = this.state.userDetails
		this.props
			.registerUser(userDetails)
			.then(() => {
				console.log("registerUser Success!")
				this.setState({
					pendingResponse: false,
				})
			})
			.catch((err) => {
				this.setState({
					[errorCodes[err.code]["field"]]: errorCodes[err.code]["msg"],
					pendingResponse: false,
				})
			})
	}

	render() {
		const { classes, location, profileLoaded } = this.props
		const { pendingResponse } = this.state

		if (profileLoaded && location.state && location.state.from && location.state.from.pathname !== "/") {
			return <Redirect to={location.state.from.pathname} />
		}

		return (
			<div className={classes.root}>
				<main className={classes.main}>
					<CssBaseline />
					{pendingResponse ? <LinearProgress className={classes.progress} /> : <div className={classes.progress} />}
					<Paper className={classes.paper}>
						<Avatar className={classes.avatar}>
							<AccountCircleOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Register User
						</Typography>
						<form className={classes.form} onSubmit={this.submit}>
							<TextField
								id="nameFirst"
								label="First Name"
								type="text"
								margin="normal"
								variant="outlined"
								value={this.state.userDetails.nameFirst}
								onChange={this.handleChange("nameFirst", "text")}
								autoComplete="given-name"
								autoFocus
								required
								fullWidth
							/>
							<TextField
								id="nameLast"
								label="Last Name"
								type="text"
								margin="normal"
								variant="outlined"
								value={this.state.userDetails.nameLast}
								onChange={this.handleChange("nameLast", "text")}
								autoComplete="family-name"
								required
								fullWidth
							/>
							<TextField
								id="company"
								label="Company Name"
								type="text"
								margin="normal"
								variant="outlined"
								value={this.state.userDetails.company}
								onChange={this.handleChange("company", "text")}
								autoComplete="company-name"
								required
								fullWidth
							/>
							<TextField
								id="email"
								label="Email Address"
								type="text"
								margin="normal"
								variant="outlined"
								value={this.state.userDetails.email}
								onChange={this.handleChange("email", "text")}
								autoComplete="email"
								error={this.state.errorEmail !== null}
								helperText={this.state.errorEmail}
								required
								fullWidth
							/>
							<TextField
								id="emailConf"
								label="Confirm Email Address"
								type="text"
								margin="normal"
								variant="outlined"
								value={this.state.userDetails.emailConf}
								onChange={this.handleChange("emailConf", "text")}
								autoComplete="email"
								error={this.state.errorEmailConf !== null}
								helperText={this.state.errorEmailConf}
								required
								fullWidth
							/>
							<Divider className={classes.divider} />
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.userDetails.employee}
										value="employee"
										color="primary"
										onChange={this.handleChange("employee", "check")}
										disabled={this.state.userDetails.trial}
									/>
								}
								label="GameChange Employee Account"
							/>
							<div className={classes.group}>
								<FormControlLabel
									control={
										<Checkbox
											checked={this.state.userDetails.trial}
											value="trial"
											color="primary"
											onChange={this.handleChange("trial", "check")}
											disabled={this.state.userDetails.employee}
										/>
									}
									label="Trail Account"
								/>
								{this.state.userDetails.trial ? (
									<TextField
										id="dateExpiration"
										label="Trial Expiration"
										type="date"
										value={this.state.userDetails.dateExpiration}
										onChange={this.handleChange("dateExpiration", "text")}
										className={classes.textField}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								) : null}
							</div>
							<Divider className={classes.divider} />
							<div className={classes.group}>
								<PrivilegeSelect
									onChange={this.handleChange("privileges", "bit-array")}
									value={this.state.userDetails.privileges}
								/>
							</div>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								disabled={pendingResponse}
							>
								Register User
							</Button>
							<Button color="primary" className={classes.forgotPassword} component={Link} to="/reset-password">
								Return
							</Button>
						</form>
					</Paper>
				</main>
			</div>
		)
	}
}

RegisterUser.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RegisterUser)

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import NodeLocationNavigator from "./NodeLocationNavigator"
import {
	setCommissioningComponentSelection,
	setRememberComponentSelection,
} from "../../../../store/actions/reduxActions"

const getLocIds = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	let locIds = params.get("locIds")
	if (!locIds) return []
	return locIds.split(",").map((loc) => {
		const [mLocId, nLocIdsString] = loc.split(":")
		return {
			mLocId,
			nLocIds: (nLocIdsString || "").split(";").filter((val) => val !== ""),
		}
	})
}

const getActiveLocIds = (locIds) => {
	const activeLocIds = {}
	for (let i = 0; i < locIds.length; i++) {
		if (locIds[i].nLocIds.length > 0) {
			if (locIds[i].nLocIds.length === 1 && activeLocIds["nLocId"] === undefined) {
				activeLocIds["mLocId"] = locIds[i]["mLocId"]
				activeLocIds["nLocId"] = locIds[i]["nLocIds"][0]
			} else {
				return {}
			}
		}
	}
	return activeLocIds
}

const changeActiveNode = (state, props, newMLocId, newNLocId, mLocId, nLocId) => {
	let params = new URLSearchParams(props.location.search)
	let activeIds = params.get("locIds")

	const newIdStr = activeIds
		.replace(`${mLocId}:${nLocId}`, `${mLocId}`)
		.replace(`${newMLocId}`, `${newMLocId}:${newNLocId}`)
	params.set("locIds", newIdStr)

	let deviceIdInput = params.get("deviceid-input")
	if (deviceIdInput) {
		const deviceIdInputEdited = deviceIdInput.replace(`${mLocId}:${nLocId}`, `${newMLocId}:${newNLocId}`)
		params.set("deviceid-input", deviceIdInputEdited)
	}

	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const getNodeLocations = (state, props) => {
	const locIds = getLocIds(state, props)
	if (locIds.length === 0) return {}
	const { mLocId: activeMLocId, nLocId: activeNLocId } = getActiveLocIds(locIds)
	if (activeNLocId === undefined) return {}
	const { xLoc: activeXLoc, yLoc: activeYLoc } = (state.nodeDetails[activeMLocId] || {})[activeNLocId] || {}
	if (activeXLoc === undefined || activeYLoc === undefined) return {}

	let eastNodeDistance = null
	let westNodeDistance = null
	let eastNLocId = undefined
	let westNLocId = undefined
	let eastMLocId = undefined
	let westMLocId = undefined

	locIds.forEach(({ mLocId }) => {
		Object.keys(state.nodeDetails[mLocId] || {}).forEach((nLocId) => {
			let { xLoc, yLoc } = (state.nodeDetails[mLocId] || {})[nLocId]
			const distance = Math.sqrt(Math.pow(xLoc - activeXLoc, 2) + Math.pow(yLoc - activeYLoc, 2))
			if (activeXLoc < xLoc) {
				if (eastNLocId === undefined || distance < eastNodeDistance) {
					eastNLocId = nLocId
					eastMLocId = mLocId
					eastNodeDistance = distance
				}
			} else if (activeXLoc > xLoc) {
				if (westNLocId === undefined || distance < westNodeDistance) {
					westNLocId = nLocId
					westMLocId = mLocId
					westNodeDistance = distance
				}
			}
		})
	})
	return {
		eastNLocId,
		westNLocId,
		eastMLocId,
		westMLocId,
		mLocId: activeMLocId,
		nLocId: activeNLocId,
	}
}

const activateEntry = (props) => (mLocId, nLocId) => {
	const params = new URLSearchParams(props.location.search)
	params.set("deviceid-input", [props.match.params.layoutId, mLocId, nLocId].join(":"))
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const handleComponentSelection = (dispatch) => async (component) => {
	dispatch(setCommissioningComponentSelection(component))
}
const handleRememberComponentSelection = (dispatch) => async (remember) => {
	dispatch(setRememberComponentSelection(remember))
}

const mapStateToProps = (state, props) => {
	const locationInfo = getNodeLocations(state, props)

	return {
		locationInfo,
		activeMasterName: (state.masterDetails[locationInfo["mLocId"]] || {}).name,
		changeActiveNode: (newMLocId, newNLocId, mLocId, nLocId) =>
			changeActiveNode(state, props, newMLocId, newNLocId, mLocId, nLocId),
		activateEntry: (mLocId, nLocId) => activateEntry(props)(mLocId, nLocId),
		commissioningComponentSelection: state.commissioningComponentSelection,
		rememberComponentSelection: state.rememberComponentSelection,
	}
}

const mapDispatchToProps = (dispatch) => ({
	handleComponentSelection: handleComponentSelection(dispatch),
	handleRememberComponentSelection: handleRememberComponentSelection(dispatch),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(NodeLocationNavigator)

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormGroup from "@material-ui/core/FormGroup"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import green from "@material-ui/core/colors/green"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Input from "@material-ui/core/Input"
import ListItemText from "@material-ui/core/ListItemText"
import Checkbox from "@material-ui/core/Checkbox"
import Chip from "@material-ui/core/Chip"
import { Prompt } from "react-router-dom"
import { getNodeParameters as getNodeParametersGql } from "graphql/queries"
import { createNodeParameters as createNodeParametersGql } from "graphql/mutations"
import API, { graphqlOperation } from "@aws-amplify/api"
import { nodeParametersList } from "../../../../../constants/nodeParameters"

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		overflow: "auto",
		flex: 1,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: 0,
		display: "flex",
		flexDirection: "column",
		overflowY: "auto",
		flex: 1,
	},
	divider: {
		margin: theme.spacing(1.5, 0),
	},
	wrapper: {
		margin: theme.spacing(0, 1),
		position: "relative",
	},
	actionWrapper: {
		justifyContent: "flex-end",
	},
	actionWrapperRight: {
		display: "flex",
	},
	buttonSuccess: {
		backgroundColor: green[500],
		"&:hover": {
			backgroundColor: green[700],
		},
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	title: {
		paddingBottom: 0,
	},
	select: {
		marginBottom: theme.spacing(1),
		width: "100%",
	},
	chip: {}, // TODO: Use or remove
}))

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: "70%",
		},
	},
}

const CreateNodeParameters = ({
	layoutId,
	enqueueSnackbar,
	masterDetails,
	fetchMasterDetails,
	masterList,
	mLocId,
	setCreateNewNodeParmOpen,
	templates,
}) => {
	const [pendingResponse, setPendingResponse] = useState(false)
	const [success, setSuccess] = useState(true)
	const [values, setValues] = useState({})
	const [selectedTemplate, setSelectedTemplate] = useState("")
	const classes = useStyles()

	useEffect(() => {
		if (selectedTemplate.id) {
			const getNodeParameters = async () => {
				try {
					const result = await API.graphql(
						graphqlOperation(getNodeParametersGql, {
							id: selectedTemplate.id,
						}),
					)
					let data = result.data["getNodeParameters"]
					if (data) {
						data["label"] = ""
						delete data["id"]
						delete data["newNode"]
						setValues({ ...values, ...data })
					}
				} catch (err) {
					console.error(err)
				}
			}
			// noinspection JSIgnoredPromiseFromCall
			getNodeParameters()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTemplate.id, mLocId])

	useEffect(() => {
		setValues({ mLocIds: [mLocId] })
	}, [mLocId])

	const handleChange = (name, type) => (val) => {
		let value = null
		switch (type) {
			case "string":
				value = val
				break
			case "eInt":
				value = parseInt(val.target.value)
				if (isNaN(value)) value = ""
				break
			case "eFloat":
				value = parseFloat(val.target.value)
				if (isNaN(value)) value = ""
				break
			case "object":
				value = val
				break
			default:
				value = val.target.value
				break
		}
		if (value === undefined) {
			setValues((values) => {
				const { [name]: _discarded, ...otherValues } = values
				return otherValues
			})
		} else {
			setValues((values) => ({ ...values, [name]: value }))
		}
		setPendingResponse(false)
		setSuccess(false)
	}

	const getValue = (name) => {
		if (values[name] !== undefined && values[name] !== null) {
			return values[name]
		} else {
			return ""
		}
	}

	const handleTemplateChange = (event) => {
		const { value } = event.target
		setSelectedTemplate(value)
		setPendingResponse(false)
		setSuccess(false)
	}

	const submit = async (e) => {
		e.preventDefault()
		const newNodeParameters = { ...values }
		let timer = setTimeout(() => {
			setPendingResponse(false)
			setSuccess(false)
			enqueueSnackbar("Save Timeout", {
				variant: "error",
			})
		}, 20000)

		let result = null
		setPendingResponse(true)
		try {
			newNodeParameters["newNode"] = false
			const { id: _id, ...details } = newNodeParameters
			result = await API.graphql(
				graphqlOperation(createNodeParametersGql, {
					input: {
						layoutId,
						...details,
					},
				}),
			)
			console.log("createNodeParametersGql", result)

			fetchMasterDetails(layoutId)
			clearTimeout(timer)
			enqueueSnackbar("Save Successful", {
				variant: "success",
			})
			setPendingResponse(false)
			setSuccess(true)
			setCreateNewNodeParmOpen(false)
		} catch (err) {
			console.error(err)
			clearTimeout(timer)
			enqueueSnackbar("Save Error", {
				variant: "error",
			})
			setPendingResponse(false)
			setSuccess(false)
		}
	}

	const writeableParameters = nodeParametersList.filter((param) => !param.readOnly)
	// +2 for label and mLocId
	const nbParams = writeableParameters.length + 2

	let anyValueMissing =
		Object.values(values).filter((value) => value !== null && value !== undefined).length < nbParams ||
		Object.values(values).some((value) => {
			return value.toString() === "" || value.length === 0
		})

	const getHtmlElementForParam = (param) => {
		const elementProps = {
			id: param.id,
			value: getValue(param.id),
			onChange: handleChange(param.id, param.valueType),
			margin: "normal",
			variant: "outlined",
			required: true,
			fullWidth: true,
			label: param.label + (param.unit ? ` (${param.unit})` : ""),
			...param.props,
			key: param.id,
		}
		return React.createElement(param.elementType, elementProps)
	}

	return (
		<div className={classes.root}>
			<Prompt when={!success} message={"Continue without saving?"} />
			<DialogTitle className={classes.title} id="form-dialog-title">
				Custom Node Parameters
			</DialogTitle>
			<form className={classes.form} onSubmit={submit}>
				<DialogContent>
					<FormGroup>
						<InputLabel id="demo-simple-select-label">Node Parameters Template</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={selectedTemplate}
							onChange={handleTemplateChange}
							className={classes.select}
						>
							{templates.map((template) => (
								<MenuItem key={template.id} value={template}>
									{template.label}
								</MenuItem>
							))}
						</Select>
						<InputLabel id="demo-mutiple-checkbox-label">Masters</InputLabel>
						<Select
							labelId="masters-mutiple-checkbox-label"
							id="masters-mutiple-checkbox"
							multiple
							value={values.mLocIds ? values.mLocIds : []}
							onChange={handleChange("mLocIds")}
							className={classes.select}
							input={<Input />}
							renderValue={(selected) => {
								return selected.map((mLocId) => {
									return <Chip key={mLocId} label={masterList[mLocId]} className={classes.chip} />
								})
							}}
							MenuProps={MenuProps}
						>
							{Object.keys(masterList).map((master) => {
								return (
									<MenuItem key={master} value={master}>
										<Checkbox
											color="primary"
											checked={
												Object.keys(values).length > 0 &&
												values.mLocIds &&
												values.mLocIds.length > 0 &&
												values.mLocIds.includes(master)
											}
										/>
										<ListItemText
											primary={masterList[master]}
											secondary={(masterDetails[master] || {})["nodeParamId"] && "(Parameters Assigned)"}
											style={{ display: "flex", alignItems: "center" }}
										/>
									</MenuItem>
								)
							})}
						</Select>
						<TextField
							id="Node-Parameters-name"
							label="Node Parameters Name"
							type="text"
							margin="normal"
							variant="outlined"
							value={getValue("label")}
							onChange={handleChange("label")}
							required
							fullWidth
						/>
						{writeableParameters.map((param) => {
							return getHtmlElementForParam(param)
						})}
					</FormGroup>
				</DialogContent>
				<DialogActions className={classes.actionWrapper}>
					<div className={classes.actionWrapperRight}>
						<Button color="primary" onClick={() => setCreateNewNodeParmOpen(false)}>
							Close
						</Button>
						<div className={classes.wrapper}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={pendingResponse || success || anyValueMissing}
							>
								{success ? "Saved" : "Save Changes"}
							</Button>
							{pendingResponse && <CircularProgress size={24} className={classes.buttonProgress} />}
						</div>
					</div>
				</DialogActions>
			</form>
		</div>
	)
}

CreateNodeParameters.propTypes = {
	layoutId: PropTypes.string,
	enqueueSnackbar: PropTypes.func,
	masterDetails: PropTypes.any,
	fetchMasterDetails: PropTypes.func,
	masterList: PropTypes.object.isRequired,
	mLocId: PropTypes.any,
	setCreateNewNodeParmOpen: PropTypes.any,
	templates: PropTypes.any,
}

export default CreateNodeParameters

import { useCallback } from "react"
import { privileges, CONTROLS, groupPrivileges } from "constants/auth"

export const useAuthorization = (userPrivileges) => {
	return useCallback((requiredPrivilege) => accessAllowed(userPrivileges, requiredPrivilege), [userPrivileges])
}

export const accessAllowed = (userPrivileges, requiredPrivilege) => {
	if ((userPrivileges & requiredPrivilege) > 0) {
		return true
	} else {
		return false
	}
}

export const groupsToPrivileges = (groups) => {
	let userPrivileges = groups.reduce((acc, val) => {
		return acc | (groupPrivileges[val] || 0)
	}, 0)
	if (!groups.includes("CONTROLS")) {
		return userPrivileges & ~CONTROLS
	} else {
		return userPrivileges | privileges.VIEW | CONTROLS
	}
}

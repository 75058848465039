import InputDropdown from "./InputDropdown"
import React from "react"
import PropTypes from "prop-types"

const BoardAlternateForm = ({
	boardAlternates,
	taskInfo,
	boardAlternate,
	setTaskInfo,
	label,
	displayName,
	variableName,
	alternateToExample,
}) => {
	return (
		<div>
			<p>What is the {displayName}? This will be labeled on the outside of the node enclosure in the SII</p>
			<p>Example of Alternate A: {alternateToExample("A")}</p>
			<p>Example of Alternate H: {alternateToExample("H")}</p>
			<InputDropdown
				options={boardAlternates}
				taskInfo={taskInfo}
				value={boardAlternate}
				setTaskInfo={setTaskInfo}
				label={label}
				name={variableName}
			/>
		</div>
	)
}

BoardAlternateForm.propTypes = {
	setTaskInfo: PropTypes.func.isRequired,
	taskInfo: PropTypes.object.isRequired,
	label: PropTypes.string,
	boardAlternates: PropTypes.arrayOf(PropTypes.string),
	boardAlternate: PropTypes.string,
	displayName: PropTypes.string.isRequired,
	variableName: PropTypes.string.isRequired,
	alternateToExample: PropTypes.func.isRequired,
}

export default BoardAlternateForm

import { connect } from "react-redux"
import { compose } from "redux"
import { withSnackbar } from "notistack"

import App from "./App"
import { subscribeOnlineStatus } from "store/actions/fetchers"
import {
	loadCachedMasterIdChanges,
	loadCachedNodeIdChanges,
	loadCachedTaskChanges,
	loadCachedApiRequests,
} from "store/actions/setters"
import { initAuthObserver } from "../../store/actions/auth"

const mapStateToProps = (state) => ({
	appInitialized: state.appInitialized,
	userId: (state.user || {})["id"],
})

const mapDispatchToProps = (dispatch) => ({
	initAuthObserver: () => dispatch(initAuthObserver()),
	subscribeOnlineStatus: () => dispatch(subscribeOnlineStatus()),
	loadCachedNodeIdChanges: () => dispatch(loadCachedNodeIdChanges()),
	loadCachedMasterIdChanges: () => dispatch(loadCachedMasterIdChanges()),
	loadCachedTaskChanges: () => dispatch(loadCachedTaskChanges()),
	loadCachedApiRequests: () => dispatch(loadCachedApiRequests()),
})

export default compose(connect(mapStateToProps, mapDispatchToProps), withSnackbar)(App)

import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import Downshift from "downshift"
import TextField from "@material-ui/core/TextField"
import Popper from "@material-ui/core/Popper"
import Paper from "@material-ui/core/Paper"
import Chip from "@material-ui/core/Chip"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

const renderInput = (inputProps) => {
	const { InputProps, classes, ref, ...other } = inputProps

	return (
		<TextField
			variant="outlined"
			autoFocus={true}
			InputProps={{
				inputRef: ref,
				classes: {
					root: classes.inputRoot,
					input: classes.inputInput,
				},
				...InputProps,
			}}
			{...other}
		/>
	)
}

const renderSuggestion = ({ suggestion, index, itemProps, highlightedIndex, selectedItems, suggestionMap }) => {
	const isHighlighted = highlightedIndex === index
	const isSelected = (selectedItems || "").indexOf(suggestion) > -1
	// TODO: selected bold text?
	return (
		<ListItem
			dense
			{...itemProps}
			key={suggestion}
			selected={isHighlighted}
			component="div"
			style={{
				fontWeight: isSelected ? 500 : 400,
			}}
		>
			<ListItemText primary={suggestionMap[suggestion].nId} secondary={suggestionMap[suggestion].masterName} />
		</ListItem>
	)
}
renderSuggestion.propTypes = {
	highlightedIndex: PropTypes.number,
	index: PropTypes.number,
	itemProps: PropTypes.object,
	selectedItems: PropTypes.string,
	suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
}

const getSuggestions = (suggestionsMap, selectedItems, value, { showEmpty = false } = {}) => {
	const suggestionKeys = Object.keys(suggestionsMap)
	const inputValue = value.trim().toLowerCase()
	const inputLength = inputValue.length
	let count = 0
	const suggestions = []

	if (inputLength === 0 && !showEmpty) return []

	for (let i = 0; i < suggestionKeys.length; i++) {
		if (count >= 5) break
		const suggestionKey = suggestionKeys[i]
		if (
			!selectedItems.includes(suggestionKey) &&
			suggestionsMap[suggestionKey].nId.toLowerCase().includes(inputValue)
		) {
			suggestions.push(suggestionKey)
			count++
		}
	}
	return suggestions
}

const useStyles = makeStyles((theme) => ({
	list: {
		zIndex: 2000,
	},
	container: {
		flexGrow: 1,
		position: "relative",
		width: "100%",
		marginBottom: theme.spacing(2),
	},
	paper: {
		position: "absolute",
		zIndex: 1,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0,
	},
	chip: {
		margin: theme.spacing(0.25),
	},
	inputRoot: {
		flexWrap: "wrap",
		paddingTop: theme.spacing(0.75),
	},
	inputInput: {
		width: "auto",
		flexGrow: 1,
	},
	divider: {
		height: theme.spacing(2),
	},
}))

let popperNode

const SuggestionsList = ({
	endAdornment,
	locIdMap: suggestionMap,
	selectedItems,
	setSelectedItem,
	inputValue,
	setInputValue,
	nav,
	wizardIsOpen,
}) => {
	const classes = useStyles()

	const handleKeyDown = (event) => {
		if (selectedItems.length && !inputValue.length && event.key === "Backspace") {
			setSelectedItem(selectedItems.slice(0, selectedItems.length - 1))
		}
	}

	const handleInputChange = (event) => {
		setInputValue(event.target.value)
	}

	const handleChange = (item) => {
		let newSelectedItem = [...selectedItems]
		if (newSelectedItem.indexOf(item) === -1) {
			newSelectedItem = [...newSelectedItem, item]
		}
		setInputValue("")
		setSelectedItem(newSelectedItem)
	}

	const handleDelete = (item) => () => {
		const newSelectedItem = [...selectedItems]
		newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
		setSelectedItem(newSelectedItem)
	}

	const getPlaceholder = (navIsOpen, wizardIsOpen, selectionCount) => {
		if (navIsOpen || wizardIsOpen) {
			if (selectionCount === 0) {
				return "Enter Single"
			} else {
				return ""
			}
		} else {
			return "Enter Multiple"
		}
	}

	return (
		<Downshift inputValue={inputValue} onChange={handleChange} selectedItem={selectedItems}>
			{({
				getInputProps,
				getItemProps,
				getLabelProps,
				getMenuProps,
				isOpen,
				openMenu,
				inputValue: inputValue2,
				selectedItem: selectedItem2,
				highlightedIndex,
			}) => {
				const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
					onKeyDown: handleKeyDown,
					onFocus: openMenu,
					placeholder: getPlaceholder(nav, wizardIsOpen, selectedItem2.length),
					disabled: (nav || wizardIsOpen) && selectedItem2.length === 1,
				})

				return (
					<div className={classes.container}>
						{renderInput({
							fullWidth: true,
							classes,
							label: nav ? "Nearby Device ID" : "Search by Device ID",
							helperText: nav ? "- Scan/Input nearby Device ID -" : null,
							InputLabelProps: getLabelProps(),
							InputProps: {
								endAdornment,
								startAdornment: selectedItem2.map((item) => (
									<Chip
										key={item}
										tabIndex={-1}
										label={suggestionMap[item] && suggestionMap[item].nId}
										className={classes.chip}
										onDelete={handleDelete(item)}
									/>
								)),
								onBlur,
								onChange: (event) => {
									handleInputChange(event)
									onChange(event)
								},
								onFocus,
							},
							inputProps,
							ref: (node) => {
								popperNode = node
							},
						})}

						<Popper open={isOpen} anchorEl={popperNode} style={{ zIndex: 2000 }}>
							<div {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}>
								<Paper square style={{ marginTop: 8, width: popperNode ? popperNode.clientWidth : undefined }}>
									{getSuggestions(suggestionMap, selectedItem2, inputValue).map((suggestion, index) =>
										renderSuggestion({
											suggestionMap,
											suggestion,
											index,
											itemProps: getItemProps({ item: suggestion }),
											highlightedIndex,
											selectedItem: selectedItem2,
										}),
									)}
								</Paper>
							</div>
						</Popper>
					</div>
				)
			}}
		</Downshift>
	)
}

export default SuggestionsList

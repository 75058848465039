import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import DeviceIdAssignmentOptionsButton from "./DeviceIdAssignmentOptionsButton"
import { toggleNodeIds, toggleNodeLocIds } from "store/actions/reduxActions"
import format from "date-fns/format"

function getDistanceBetweenPoints(xA, yA, xB, yB) {
	return Math.sqrt(Math.pow(xB - xA, 2) + Math.pow(yB - yA, 2))
}

function scaleBy10AndRound(value) {
	return Math.round(value * 10)
}

const handleDownloadAllNodeIds = (state, props) => {
	const layoutDetails = state.layoutDetails[props.match.params.layoutId] || {}
	const { mLocIds, name: layoutName } = layoutDetails
	const nodeIdStrings = []

	mLocIds.forEach((mLocId) => {
		const { name: masterName, xLoc: masterX, yLoc: masterY, localIp } = state.masterDetails[mLocId] || {}
		const nodeDetailsDict = state.nodeDetails[mLocId] || {}
		const nodeDetails = Object.keys(nodeDetailsDict)
			.sort(
				(nLocIdA, nLocIdB) =>
					// Sort by ascending distance to master
					getDistanceBetweenPoints(masterX, masterY, nodeDetailsDict[nLocIdA].xLoc, nodeDetailsDict[nLocIdA].yLoc) -
					getDistanceBetweenPoints(masterX, masterY, nodeDetailsDict[nLocIdB].xLoc, nodeDetailsDict[nLocIdB].yLoc),
			)
			.map((nLocId) => {
				// Node IDs can be pending upload. If so, we will use the pending ID instead of the saved one.
				const pendingNodeId = state.nIdsPendingUpload[`${mLocId}#${nLocId}`]
				const savedNode = nodeDetailsDict[nLocId]

				// Get the node radio ID and pad it.
				let nId = (pendingNodeId || savedNode.nId || 0).toString(16).padStart(4, "0")

				// We want only the last four digits of the radio ID.
				nId = nId.slice(nId.length - 4)

				// Scale coordinates by a factor of 10 for some reason, potentially to maintain precision in integers.
				const scaledX = scaleBy10AndRound(savedNode.xLoc || 0)
				const scaledY = scaleBy10AndRound(savedNode.yLoc || 0)

				// Construct a row in the file. The last number, 0000, is not in use.
				return `${nId},${scaledX},${scaledY},0000`
			})
		const sendString = `${masterName},${localIp},0,${nodeDetails.length},0;${nodeDetails.join(";")};`
		nodeIdStrings.push(sendString)
	})
	const element = document.createElement("a")
	const file = new Blob([nodeIdStrings.join("&")], { type: "text/plain" })
	element.href = URL.createObjectURL(file)
	element.download = `NodeIDs ${layoutName} [${format(new Date(), "yyyy-MM-dd HH_mm_ss")}].txt`
	document.body.appendChild(element)
	element.click()
	document.body.removeChild(element)
}

export const handleDownloadDeviceCoordinates = (state, props) => {
	const nodeDetails = { ...state.nodeDetails } || {}
	const masterDetails = state.masterDetails || {}
	const layoutDetails = state.layoutDetails[props.match.params.layoutId] || {}
	const { name: layoutName } = layoutDetails

	// Create header row for masters
	const strings = ["Master Name,X,Y"]

	// Get master details
	const masterDetailValues = Object.values(masterDetails)
	// Sort alphabetically by master name
	masterDetailValues.sort((a, b) => a.name.localeCompare(b.masterName))
	// Build master detail strings

	for (let masterData of masterDetailValues) {
		// Format: [master name],[x],[y]
		strings.push(`${[masterData.name]},${Math.round(masterData.xLoc)},${Math.round(masterData.yLoc)}`)
	}

	strings.push("") // Empty line

	// Create header row for nodes
	strings.push("Master, Node Index, Node ID, Row, From South, X, Y")
	let rows = []

	for (const [masterId, thisNodeDetails] of Object.entries(nodeDetails)) {
		const masterName = masterDetails[masterId].name

		// noinspection JSCheckFunctionSignatures
		for (const [nodeIndex, nodeData] of Object.entries(thisNodeDetails)) {
			const rawNodeId = nodeData["nId"]
			const nodeId = rawNodeId ? rawNodeId : ""
			const locX = scaleBy10AndRound(nodeData["xLoc"] || 0)
			const locY = scaleBy10AndRound(nodeData["yLoc"] || 0)
			const rowNum = nodeData["rowNum"]
			const fromSouth = nodeData["fromSouth"]
			rows.push({ masterName, nodeIndex, nodeId, rowNum, fromSouth, locX, locY })
		}
	}

	// Sorting by master name, and then node index, makes the resulting CSV more readable, especially in cases where
	// "Master 3" was defined before "Master 2," for example.
	rows.sort((a, b) => {
		return a.masterName.localeCompare(b.masterName) || parseInt(a.nodeIndex) - parseInt(b.nodeIndex)
	})

	// Create non-header rows
	const rowStrings = rows.map((row) => {
		// An ID like 93E4 (notice the E) is parsed by Excel in a mathematical sense. Force treatment as a string.
		const literalNodeId = `="${row.nodeId}"`
		return [row.masterName, row.nodeIndex, literalNodeId, row.rowNum, row.fromSouth, row.locX, row.locY].join(",")
	})
	strings.push(...rowStrings)
	// Join to create the entire contents of the CSV file
	const fileContents = strings.join("\n")

	// Need to create an "a" (link) element and click it in order to initiate download
	const element = document.createElement("a")
	const file = new Blob([fileContents], { type: "text/plain" })
	element.href = URL.createObjectURL(file)
	element.download = `Node Coordinates ${layoutName} [${format(new Date(), "yyyy-MM-dd HH_mm_ss")}].csv`
	document.body.appendChild(element)
	element.click()
	document.body.removeChild(element)
}

const mapStateToProps = (state, props) => ({
	overlayNIds: state.overlayNIds,
	overlayNLocIds: state.overlayNLocIds,
	handleDownloadAllNodeIds: () => handleDownloadAllNodeIds(state, props),
	handleDownloadDeviceCoordinates: () => handleDownloadDeviceCoordinates(state, props),
})

const mapDispatchToProps = (dispatch, _ownProps) => ({
	toggleNodeIds: (isVisible) => dispatch(toggleNodeIds(isVisible)),
	toggleNodeLocIds: (isVisible) => dispatch(toggleNodeLocIds(isVisible)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(DeviceIdAssignmentOptionsButton)

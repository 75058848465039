import "date-fns"
import React, { Fragment } from "react"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
	datePicker: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 250,
		display: "flex",
		flexDirection: "column",
	},
}))

const InputDate = ({ value, setTaskInfo, _taskInfo, name, _label }) => {
	const classes = useStyles()

	const handleChange = (event) => {
		let value = event && event.toLocaleDateString()
		if (value) {
			setTaskInfo({ [name]: value })
		}
	}

	const isDefectiveActuator = () => {
		if (name === "badActuatorProductionDate" && value && value.split("/").length === 3) {
			const values = value.split("/").map((val) => parseInt(val))
			const [month, day, year] = values
			const triggerDate = new Date(2020, 7, 1)
			const date = new Date(year, month - 1, day)

			return date >= triggerDate
		}
	}

	// Blake thinks the actual cutoff time is in 2017
	// But he suggested using this date to have some buffer
	const minDate = new Date("2015-01-01")
	return (
		<Fragment>
			<div className={classes.datePicker}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						disableToolbar
						variant="inline"
						format="MM/dd/yyyy"
						margin="normal"
						id="date-picker-inline"
						label="Production Date"
						value={value || null}
						onChange={handleChange}
						KeyboardButtonProps={{
							"aria-label": "change date",
						}}
						minDate={minDate}
						minDateMessage={"Date must no earlier than " + minDate}
						disableFuture={true}
					/>
				</MuiPickersUtilsProvider>
			</div>
			{isDefectiveActuator() ? (
				<p style={{ color: "red" }}>
					*Possible Defective Castle Nut Design Actuator, please contact an FAE immediately!
				</p>
			) : null}
		</Fragment>
	)
}

InputDate.propTypes = {
	_taskInfo: PropTypes.object,
	setTaskInfo: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string,
	_label: PropTypes.string,
}

export default InputDate

/* eslint-disable no-console */

import React, { Component } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import { AutoSizer, Column, SortDirection, Table } from "react-virtualized"
import Toolbar from "@material-ui/core/Toolbar"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import EditIcon from "@material-ui/icons/Edit"
import CheckIcon from "@material-ui/icons/Check"
import AddIcon from "@material-ui/icons/Add"
import SearchBar from "../SearchBar"
import { accessAllowed } from "utils/auth"
import { privileges } from "constants/auth"
import { ZendeskAPI } from "react-zendesk"

const rightAlignTypes = ["number", "boolean"]

const toolbarStyles = (theme) => ({
	root: {
		paddingRight: theme.spacing(1),
		paddingLeft: theme.spacing(1),
	},
	actions: {
		display: "flex",
		color: theme.palette.text.secondary,
	},
	title: {
		flex: "0 0 auto",
	},
})

let EnhancedTableToolbar = ({ classes, userPrivileges, editActive, toggleEdit, openNewLayout }) => {
	let editControl = null
	if (accessAllowed(userPrivileges, privileges.ADMIN)) {
		if (editActive) {
			editControl = (
				<div className={classes.actions}>
					<Tooltip key={"add"} title="Add layout">
						<IconButton aria-label="Add layout" onClick={openNewLayout}>
							<AddIcon />
						</IconButton>
					</Tooltip>
					<Tooltip key={"edit-complete"} title="Edit complete">
						<IconButton color="primary" aria-label="Edit complete" onClick={toggleEdit}>
							<CheckIcon />
						</IconButton>
					</Tooltip>
				</div>
			)
		} else {
			editControl = (
				<div className={classes.actions}>
					<Tooltip key={"edit"} title="Edit layouts">
						<IconButton aria-label="Edit layouts" onClick={toggleEdit}>
							<EditIcon />
						</IconButton>
					</Tooltip>
				</div>
			)
		}
	}

	return (
		<Toolbar className={classes.root}>
			<SearchBar />
			{editControl}
		</Toolbar>
	)
}

EnhancedTableToolbar.propTypes = {
	classes: PropTypes.any,
	userPrivileges: PropTypes.any,
	editActive: PropTypes.any,
	toggleEdit: PropTypes.func,
	openNewLayout: PropTypes.func,
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const styles = (theme) => ({
	root: {
		height: "100%",
		width: "100%",
	},
	table: {
		fontFamily: theme.typography.fontFamily,
	},
	flexContainer: {
		display: "flex",
		alignItems: "center",
		boxSizing: "border-box",
	},
	tableRow: {
		cursor: "pointer",
	},
	tableRowHover: {
		"&:hover": {
			backgroundColor: theme.palette.grey[200],
		},
	},
	tableCell: {
		flex: 1,
	},
	noClick: {
		cursor: "initial",
	},
	grid: {
		outline: "none",
	},
})

class MuiVirtualizedTable extends React.PureComponent {
	getRowClassName = ({ index }) => {
		const { classes, rowClassName, onRowClick } = this.props

		return clsx(classes.tableRow, classes.flexContainer, rowClassName, {
			[classes.tableRowHover]: index !== -1 && onRowClick != null,
		})
	}

	cellRenderer = ({ cellData, columnIndex = null }) => {
		const { columns, classes, rowHeight, onRowClick } = this.props
		const column = columns[columnIndex]

		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, {
					[classes.noClick]: onRowClick == null,
				})}
				variant="body"
				style={{ height: rowHeight }}
				align={columnIndex != null && rightAlignTypes.includes(column.type) ? "right" : "left"}
			>
				{column.type === "boolean" ? <input type="checkbox" checked={cellData || false} disabled={true} /> : cellData}
			</TableCell>
		)
	}

	headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
		const { headerHeight, columns, classes, sort } = this.props
		const direction = {
			[SortDirection.ASC]: "asc",
			[SortDirection.DESC]: "desc",
		}
		const column = columns[columnIndex]
		const inner =
			!column.disableSort && sort != null ? (
				<TableSortLabel active={dataKey === sortBy} direction={direction[sortDirection]}>
					{label}
				</TableSortLabel>
			) : (
				label
			)

		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
				variant="head"
				style={{ height: headerHeight }}
				align={rightAlignTypes.includes(column.type) ? "right" : "left"}
			>
				{inner}
			</TableCell>
		)
	}

	render() {
		const { classes, columns, ...tableProps } = this.props
		return (
			<div className={classes.root}>
				<AutoSizer>
					{({ height, width }) => (
						<Table
							className={classes.table}
							height={height}
							width={width}
							{...tableProps}
							rowClassName={this.getRowClassName}
							gridClassName={classes.grid}
						>
							{columns.map(({ cellContentRenderer = null, className, dataKey, ...other }, index) => {
								let renderer
								if (cellContentRenderer != null) {
									renderer = (cellRendererProps) =>
										this.cellRenderer({
											cellData: cellContentRenderer(cellRendererProps),
											columnIndex: index,
										})
								} else {
									renderer = this.cellRenderer
								}

								return (
									<Column
										key={dataKey}
										headerRenderer={(headerProps) =>
											this.headerRenderer({
												...headerProps,
												columnIndex: index,
											})
										}
										className={clsx(classes.flexContainer, className)}
										cellRenderer={renderer}
										dataKey={dataKey}
										{...other}
									/>
								)
							})}
						</Table>
					)}
				</AutoSizer>
			</div>
		)
	}
}

MuiVirtualizedTable.propTypes = {
	classes: PropTypes.object.isRequired,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			cellContentRenderer: PropTypes.func,
			dataKey: PropTypes.string.isRequired,
			width: PropTypes.number.isRequired,
			type: PropTypes.string,
			disableSort: PropTypes.bool,
		}),
	).isRequired,
	headerHeight: PropTypes.number,
	onRowClick: PropTypes.func,
	rowClassName: PropTypes.string,
	rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
	sort: PropTypes.func,
}

MuiVirtualizedTable.defaultProps = {
	headerHeight: 56,
	rowHeight: 56,
}

const WrappedVirtualizedTable = withStyles(styles)(MuiVirtualizedTable)

const outerStyles = (_theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		width: "100%",
	},
})

class LayoutList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			editActive: false,
		}
	}

	componentDidMount() {
		ZendeskAPI("webWidget", "show")
		// There are some situations where the fetch in App.js is not triggered, but you can still get to the layout list.
		const { fetchLayoutDetails } = this.props
		fetchLayoutDetails()
	}

	openEditor = (event) => {
		const { history, location, fetchLayoutDetailBySiteUuid } = this.props
		if (event) {
			fetchLayoutDetailBySiteUuid(event)
		}

		const queryParams = `${location.search}&modify-layout=${event ? event.rowData.id : "new"}`
		history.push({
			...location,
			search: queryParams,
		})
	}

	navigateLayout = (event) => {
		const { history, siteView, fetchLayoutDetailBySiteUuid } = this.props
		fetchLayoutDetailBySiteUuid(event)

		history.push(`/layout/${event.rowData.id}?view=${siteView.join(",")}&overlay=alarms`)
	}

	render() {
		const {
			classes,
			rows,
			columns,
			sortBy,
			sortDirection,
			userPrivileges,
			handleSortClick,
			setLayoutSecondarySelection,
			clearLayoutSecondarySelection,
		} = this.props
		const { editActive } = this.state
		let filteredRows
		if (editActive) {
			filteredRows = rows
		} else {
			filteredRows = rows.filter((row) => row.published)
		}
		if (!accessAllowed(userPrivileges, privileges.BETA_FEATURE)) {
			filteredRows = filteredRows.filter((row) => row.demo !== true)
		}

		return (
			<div className={classes.root}>
				<EnhancedTableToolbar
					userPrivileges={userPrivileges}
					editActive={this.state.editActive}
					toggleEdit={() => this.setState((state, _props) => ({ editActive: !state.editActive }))}
					openNewLayout={() => this.openEditor()}
				/>
				<WrappedVirtualizedTable
					rowCount={filteredRows.length}
					rowGetter={({ index }) => filteredRows[index]}
					onRowClick={editActive ? this.openEditor : this.navigateLayout}
					onRowMouseOver={setLayoutSecondarySelection}
					onRowMouseOut={clearLayoutSecondarySelection}
					columns={columns}
					sort={handleSortClick}
					sortBy={sortBy}
					sortDirection={sortDirection}
				/>
			</div>
		)
	}
}

LayoutList.propTypes = {
	classes: PropTypes.object.isRequired,
	rows: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	history: PropTypes.any.isRequired,
	location: PropTypes.any.isRequired,
	siteView: PropTypes.any.isRequired,
	sortBy: PropTypes.any,
	sortDirection: PropTypes.any,
	userPrivileges: PropTypes.any,
	handleSortClick: PropTypes.func.isRequired,
	setLayoutSecondarySelection: PropTypes.func.isRequired,
	clearLayoutSecondarySelection: PropTypes.func.isRequired,
	fetchLayoutDetailBySiteUuid: PropTypes.func.isRequired,
	fetchLayoutDetails: PropTypes.func.isRequired,
}

export default withStyles(outerStyles)(LayoutList)

/* eslint-disable no-console */

import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import { AutoSizer, Column, SortDirection, Table } from "react-virtualized"
import Toolbar from "@material-ui/core/Toolbar"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import AddIcon from "@material-ui/icons/Add"
import SearchBar from "./SearchBar"
import { accessAllowed } from "utils/auth"
import { privileges } from "constants/auth"
import { filterRows, sortRows } from "utils/table"

const toolbarStyles = (theme) => ({
	root: {
		paddingRight: theme.spacing(1),
		paddingLeft: theme.spacing(1),
	},
	actions: {
		display: "flex",
		color: theme.palette.text.secondary,
	},
	title: {
		flex: "0 0 auto",
	},
})

let EnhancedTableToolbar = ({ classes, filter, setFilter, userPrivileges, openNewLayout }) => {
	let editControl = null
	if (accessAllowed(userPrivileges, privileges.ADMIN)) {
		editControl = (
			<div className={classes.actions}>
				<Tooltip key={"add"} title="Add master">
					<IconButton aria-label="Add master" onClick={openNewLayout}>
						<AddIcon />
					</IconButton>
				</Tooltip>
			</div>
		)
	}

	return (
		<Toolbar className={classes.root}>
			<SearchBar filter={filter} handleChange={setFilter} handleClearFilter={() => setFilter("")} />
			{editControl}
		</Toolbar>
	)
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const styles = (theme) => ({
	root: {
		height: "100%",
		width: "100%",
		display: "flex",
		flexDirection: "column",
	},
	table: {
		fontFamily: theme.typography.fontFamily,
	},
	flexContainer: {
		display: "flex",
		alignItems: "center",
		boxSizing: "border-box",
	},
	tableRow: {
		cursor: "pointer",
	},
	tableRowHover: {
		"&:hover": {
			backgroundColor: theme.palette.grey[200],
		},
	},
	tableCell: {
		flex: 1,
	},
	noClick: {
		cursor: "initial",
	},
})

class MuiVirtualizedTable extends React.PureComponent {
	getRowClassName = ({ index }) => {
		const { classes, rowClassName, onRowClick } = this.props

		return clsx(classes.tableRow, classes.flexContainer, rowClassName, {
			[classes.tableRowHover]: index !== -1 && onRowClick != null,
		})
	}

	cellRenderer = ({ cellData, columnIndex = null }) => {
		const { columns, classes, rowHeight, onRowClick } = this.props
		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, {
					[classes.noClick]: onRowClick == null,
				})}
				variant="body"
				style={{ height: rowHeight }}
				align={(columnIndex != null && columns[columnIndex].numeric) || false ? "right" : "left"}
			>
				{cellData}
			</TableCell>
		)
	}

	headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
		const { headerHeight, columns, classes, sort } = this.props
		const direction = {
			[SortDirection.ASC]: "asc",
			[SortDirection.DESC]: "desc",
		}
		const inner =
			!columns[columnIndex].disableSort && sort != null ? (
				<TableSortLabel active={dataKey === sortBy} direction={direction[sortDirection]}>
					{label}
				</TableSortLabel>
			) : (
				label
			)

		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
				variant="head"
				style={{ height: headerHeight }}
				align={columns[columnIndex].numeric || false ? "right" : "left"}
			>
				{inner}
			</TableCell>
		)
	}

	render() {
		const { classes, columns, ...tableProps } = this.props

		return (
			<div className={classes.root}>
				<AutoSizer>
					{({ height, width }) => (
						<Table
							className={classes.table}
							height={height}
							width={width}
							{...tableProps}
							rowClassName={this.getRowClassName}
						>
							{columns.map(({ cellContentRenderer = null, className, dataKey, ...other }, index) => {
								let renderer
								if (cellContentRenderer != null) {
									renderer = (cellRendererProps) =>
										this.cellRenderer({
											cellData: cellContentRenderer(cellRendererProps),
											columnIndex: index,
										})
								} else {
									renderer = this.cellRenderer
								}

								return (
									<Column
										key={dataKey}
										headerRenderer={(headerProps) =>
											this.headerRenderer({
												...headerProps,
												columnIndex: index,
											})
										}
										className={clsx(classes.flexContainer, className)}
										cellRenderer={renderer}
										dataKey={dataKey}
										{...other}
									/>
								)
							})}
						</Table>
					)}
				</AutoSizer>
			</div>
		)
	}
}

MuiVirtualizedTable.propTypes = {
	classes: PropTypes.object.isRequired,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			cellContentRenderer: PropTypes.func,
			dataKey: PropTypes.string.isRequired,
			width: PropTypes.number.isRequired,
		}),
	).isRequired,
	headerHeight: PropTypes.number,
	onRowClick: PropTypes.func,
	rowClassName: PropTypes.string,
	rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
	sort: PropTypes.func,
}

MuiVirtualizedTable.defaultProps = {
	headerHeight: 56,
	rowHeight: 56,
}

const WrappedVirtualizedTable = withStyles(styles)(MuiVirtualizedTable)

const outerStyles = (theme) => ({
	root: {
		height: "100%",
		width: "100%",
		display: "flex",
		flexDirection: "column",
	},
})

const MasterList = ({
	classes,
	history,
	location,
	rows,
	columns,
	userPrivileges,
	layoutId,
	getNewMasterLocationId,
	masterLocations,
}) => {
	const [sortBy, setSortBy] = useState("name")
	const [sortDirection, setSortDirection] = useState("DESC")
	const [filter, setFilter] = useState("")

	const openEditor = (type) => (event) => {
		let queryParams = null
		if (type === "edit") {
			queryParams = `${location.search}&modify-master=${layoutId}:${event.rowData.id}`
		} else if (type === "new") {
			queryParams = `${location.search}&modify-master=${layoutId}:new`
		} else {
			return false
		}
		history.push({
			...location,
			search: queryParams,
		})
	}

	let viewableRows = rows
	if (filter !== "") {
		viewableRows = filterRows(viewableRows, filter)
	}
	if (sortBy && sortDirection) {
		viewableRows = sortRows(viewableRows, sortBy, sortDirection)
	}

	return (
		<div className={classes.root}>
			<EnhancedTableToolbar
				filter={filter}
				setFilter={setFilter}
				userPrivileges={userPrivileges}
				openNewLayout={openEditor("new")}
			/>
			<WrappedVirtualizedTable
				rowCount={viewableRows.length}
				rowGetter={({ index }) => viewableRows[index]}
				onRowClick={openEditor("edit")}
				columns={columns}
				sort={({ sortBy, sortDirection }) => {
					setSortBy(sortBy)
					setSortDirection(sortDirection)
				}}
				sortBy={sortBy}
				sortDirection={sortDirection}
			/>
		</div>
	)
}

MasterList.propTypes = {
	classes: PropTypes.object.isRequired,
	rows: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
}

export default withStyles(outerStyles)(MasterList)

import { deviceTypes } from "./deviceTypes"
import { alarmEnums, flagEnums } from "constants/statusBits"

export const chargingModes = {
	NOT_CHARGING: 0,
	FLOAT: 1,
	ABSORPTION: 2,
	BULK: 3,
	TEMPERATURE_SHUTDOWN: 4,
	UNKNOWN: 255,
}

export const chargingLabels = {
	[chargingModes.NOT_CHARGING]: "Not Charging",
	[chargingModes.FLOAT]: "Float",
	[chargingModes.ABSORPTION]: "Absorption",
	[chargingModes.BULK]: "Bulk",
	[chargingModes.TEMPERATURE_SHUTDOWN]: "Temperature Shutdown",
	[chargingModes.UNKNOWN]: "Unknown",
}

export const getChargingLabel = (mode) => {
	switch (mode) {
		case chargingModes.NOT_CHARGING:
			return "Not Charging"
		case chargingModes.FLOAT:
			return "Float"
		case chargingModes.ABSORPTION:
			return "Absorption"
		case chargingModes.BULK:
			return "Bulk"
		case chargingModes.TEMPERATURE_SHUTDOWN:
			return "Temperature Shutdown"
		default:
			return "Unknown"
	}
}

export const getChargingDescription = (mode) => {
	switch (mode) {
		case chargingModes.NOT_CHARGING:
			return "Charging Off"
		case chargingModes.FLOAT:
			return "Charging Stage 3 of 3, 95-100% charged"
		case chargingModes.ABSORPTION:
			return "Charging Stage 2 of 3, 80-95% charged"
		case chargingModes.BULK:
			return "Charging Stage 1 of 3, 0-80% charged"
		case chargingModes.TEMPERATURE_SHUTDOWN:
			return "Charging Off"
		case chargingModes.UNKNOWN:
			return "Data Unavailable"
		default:
			return "Not Defined"
	}
}

export const getChargingColor = (mode) => {
	switch (mode) {
		case chargingModes.NOT_CHARGING:
			return "#000000"
		case chargingModes.FLOAT:
			return "#4D792E"
		case chargingModes.ABSORPTION:
			return "#0076B4"
		case chargingModes.BULK:
			return "#BE5D16"
		case chargingModes.TEMPERATURE_SHUTDOWN:
			return "#660066"
		default:
			return "#ff00ff"
	}
}

export const checkNoDataAlarms = (deviceType, alarms) => {
	alarms = BigInt(alarms)
	switch (deviceType) {
		case deviceTypes.P4Q:
			return false
		case deviceTypes.GAMECHANGE_GEN2:
			return (
				(alarms & alarmEnums[deviceType].MASTER_ASSIGNED_INIT_VALUES) !== 0n ||
				(alarms & alarmEnums[deviceType].NODE_ASSIGNED_INIT_VALUES) !== 0n
			)
		case deviceTypes.GAMECHANGE_GEN3:
			return (
				(alarms & alarmEnums[deviceType].MASTER_ASSIGNED_INIT_VALUES) !== 0n ||
				(alarms & alarmEnums[deviceType].NODE_ASSIGNED_INIT_VALUES) !== 0n
			)
		case deviceTypes.GAMECHANGE_GEN4:
			return (
				(alarms & alarmEnums[deviceType].MASTER_ASSIGNED_INIT_VALUES) !== 0n ||
				(alarms & alarmEnums[deviceType].NODE_ASSIGNED_INIT_VALUES) !== 0n
			)
		default:
			return false
	}
}

export const checkChargerShutdownFlag = (deviceType, flags) => {
	// TODO: Consider throwing if not already a bigint
	flags = BigInt(flags)
	switch (deviceType) {
		case deviceTypes.P4Q:
			return false
		case deviceTypes.GAMECHANGE_GEN2:
			return (flags & flagEnums[deviceType].CHARGER_TEMPERATURE_SHUTDOWN) !== 0n
		case deviceTypes.GAMECHANGE_GEN3:
			return (flags & flagEnums[deviceType].CHARGER_TEMPERATURE_SHUTDOWN) !== 0n
		case deviceTypes.GAMECHANGE_GEN4:
			return (flags & flagEnums[deviceType].CHARGER_TEMPERATURE_SHUTDOWN) !== 0n
		default:
			return false
	}
}

import React from "react"
import PropTypes from "prop-types"
import Fab from "@material-ui/core/Fab"
import Tooltip from "@material-ui/core/Tooltip"
import { LeakAdd } from "@material-ui/icons"

const ToggleZigbeeNetworkMapButton = ({ setZigbeeNetworkIsVisible, zigbeeNetworkIsVisible }) => {
	const handleClick = () => {
		setZigbeeNetworkIsVisible(!zigbeeNetworkIsVisible)
	}

	return (
		<Tooltip title="Display ZigBee Network Map">
			<Fab
				color={zigbeeNetworkIsVisible ? "primary" : "secondary"}
				size="small"
				aria-label="Toggle Summary"
				onClick={handleClick}
			>
				{/* Shows two opposing "signal" (or "wifi" or "beam"), e.g. ))) ((( */}
				<LeakAdd />
			</Fab>
		</Tooltip>
	)
}

ToggleZigbeeNetworkMapButton.propTypes = {
	setZigbeeNetworkIsVisible: PropTypes.func.isRequired,
	zigbeeNetworkIsVisible: PropTypes.bool.isRequired,
}

export default ToggleZigbeeNetworkMapButton

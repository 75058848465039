import { useEffect, useCallback } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { clearNodeLocIds } from "utils/url"
import dbPromise from "../offlineStorage"

export const useEscapeDeselect = () => {
	const { search, pathname } = useLocation()
	const history = useHistory()

	const handleKeyDown = useCallback(
		(e) => {
			if (e.key === "Escape") {
				history.push({
					pathname,
					search: clearNodeLocIds(search),
				})
			}
		},
		[search, pathname, history],
	)

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown)
		return () => window.removeEventListener("keydown", handleKeyDown)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleKeyDown])
}

export const useEffectWhenDbIsReady = (fn, deps) => {
	useEffect(() => {
		async function run() {
			await dbPromise
			fn()
		}
		void run()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps])
}

import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import CircularProgress from "@material-ui/core/CircularProgress"
import Checkbox from "@material-ui/core/Checkbox"
import api from "../../constants/api"
import { useSnackbar } from "notistack"

const useStyles = makeStyles((_theme) => ({
	container: {
		borderRadius: "9999px",
		background: "rgba(0, 0, 0, 0.2)",
		display: "flex",
		fontSize: "0.9em",
		boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
		color: "white",
	},
	enabled: {
		background: "rgb(25,138,52)",
	},
	left: {
		padding: "5px 5px 5px 10px",
		flexGrow: 1,
		flexShrink: 0,
	},
	right: {
		position: "relative",
		width: "24px",
		padding: "5px 5px 5px 0",
		flexGrow: 0,
		flexShrink: 1,
	},
	absolutePositioning: {
		position: "absolute",
	},
	checkbox: {
		position: "absolute",
		borderRadius: "9999px !important",
		margin: 0,
		padding: 0,
		top: "3px",
		color: "white",
	},
}))

const WeatherSmartToggle = (props) => {
	const { layoutId } = props
	const classes = useStyles()
	const [isLoading, setIsLoading] = useState(true)
	const [enabled, setEnabled] = useState(false)
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		// AppBar is also on the layout list screen, but we don't need to hit the API for that
		if (!layoutId) return
		setIsLoading(true)
		api
			.getWeatherSmart(layoutId)
			.then((response) => {
				// if nothing comes in, just load the default value of false
				if (response?.body?.value !== undefined) {
					setEnabled(response.body.value)
				} else {
					setEnabled(false)
					enqueueSnackbar("Unable to retrieve WeatherSmart status", {
						variant: "error",
						autoHideDuration: 5000,
					})
				}
			})
			.catch((error) => {
				console.error(error)
				enqueueSnackbar("Unable to set WeatherSmart status", {
					variant: "error",
					autoHideDuration: 5000,
				})
			})
		setIsLoading(false)
	}, [layoutId, enqueueSnackbar])

	const handleChange = (e) => {
		setIsLoading(true)
		api
			.setWeatherSmart(layoutId, e.target.checked)
			.then((response) => {
				if (response?.body?.value !== undefined) {
					setEnabled(response.body.value)
				} else {
					enqueueSnackbar("Unable to set WeatherSmart status", {
						variant: "error",
						autoHideDuration: 5000,
					})
				}
			})
			.catch((error) => {
				console.error(error)
				enqueueSnackbar("Unable to set WeatherSmart status", {
					variant: "error",
					autoHideDuration: 5000,
				})
			})
		setIsLoading(false)
	}

	// Can't render this component if there is no active layout ID or if the user isn't authorized to enable/disable WS.
	if (!layoutId) return null

	return (
		<div className={`${classes.container} ${enabled ? classes.enabled : ""}`}>
			<div className={classes.left}>
				<span>WeatherSmart</span>
			</div>
			<div className={classes.right}>
				{isLoading ? (
					<CircularProgress size={14} className={classes.absolutePositioning} color="secondary" />
				) : (
					<Checkbox checked={enabled} onChange={handleChange} className={classes.checkbox} size="small" />
				)}
			</div>
		</div>
	)
}

WeatherSmartToggle.propTypes = {
	layoutId: PropTypes.string,
}

function getActiveLayoutId() {
	const fullUrl = location.pathname
	if (fullUrl.startsWith("/layout/")) {
		const urlParts = location.pathname.split("/")
		return urlParts[urlParts.length - 1]
	}
}

const mapStateToProps = () => {
	return {
		layoutId: getActiveLayoutId(),
	}
}

export default connect(mapStateToProps)(WeatherSmartToggle)

import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import Dygraph from "./src-es5/dygraph.js"
import customInteractionModel from "./utils/interactionModel"
import "./ReactDygraphs.css"
import { propTypes as dygraphPropTypes, spreadProps } from "./utils/options"

const arraysAreEqual = (a, b) => {
	if (!Array.isArray(a) || !Array.isArray(b)) return false
	if (a.length !== b.length) return false
	for (let i = 0; i < a.length; i++) {
		if (a[i] instanceof Date && b[i] instanceof Date) {
			if (a[i].getTime() !== b[i].getTime()) return false
		} else {
			if (a[i] !== b[i]) return false
		}
	}
	return true
}

const defaultChartOptions = {
	highlightCircleSize: 2,
	strokeWidth: 1,
	strokeBorderWidth: 1,
	highlightSeriesOpts: {
		strokeWidth: 3,
		strokeBorderWidth: 1,
		highlightCircleSize: 5,
	},
}

const ReactDygraphs = (props) => {
	const {
		debugPrints,
		onGraphClick,
		onHighlight,
		onUnhighlight,
		onDraw,
		selected,
		dateWindow,
		data,
		labels,
		title,
		xlabel,
		ylabel,
		series,
	} = props
	const divRef = useRef(null)
	const dygraphRef = useRef(null)
	// eslint-disable-next-line
	const [clicks, setClicks] = useState(0)

	const file = data || "X\n"

	const handleClicks = (e, x, points) => {
		setClicks((currClicks) => {
			if (currClicks === 1) {
				if (onGraphClick) {
					if (debugPrints) console.log("SINGLE CLICK")
					onGraphClick(e, x, points, dygraphRef.current)
				}
			} else if (currClicks > 1) {
				if (debugPrints) console.log("DOUBLE CLICK")
			}
			return 0
		})
	}

	const clickCallback = (e, x, points) => {
		if (onGraphClick) {
			setClicks((currClick) => {
				if (currClick === 0) {
					setTimeout(handleClicks, 200, e, x, points)
				}
				return currClick + 1
			})
		}
	}

	const highlightCallback = (e, x, points, row, seriesName) => {
		onHighlight && onHighlight(e, x, points, row, seriesName, dygraphRef.current)
	}

	const unhighlightCallback = (e) => {
		onUnhighlight && onUnhighlight(e, dygraphRef.current)
	}

	useEffect(() => {
		if (dygraphRef.current) {
			if (selected) {
				const { row, seriesName, locked } = selected
				dygraphRef.current.setSelection(row, seriesName, locked)
			} else {
				dygraphRef.current.clearSelection()
			}
		}
	}, [selected])

	const minDateWindow = dateWindow && dateWindow[0]
	const maxDateWindow = dateWindow && dateWindow[1]
	useEffect(() => {
		if (dygraphRef.current && !arraysAreEqual(dygraphRef.current.xAxisRange(), dateWindow)) {
			if (debugPrints) console.log("updateOptions => dateWindow")
			dygraphRef.current.updateOptions({
				dateWindow,
			})
			if (selected) {
				const { row, seriesName, locked } = selected
				dygraphRef.current.setSelection(row, seriesName, locked)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minDateWindow, maxDateWindow])

	useEffect(() => {
		if (dygraphRef.current) {
			const { known: options } = spreadProps(props)
			if (debugPrints) console.log("updateOptions", options)
			dygraphRef.current.updateOptions(options)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [title, xlabel, ylabel, series])

	useEffect(() => {
		if (dygraphRef.current) {
			const { known } = spreadProps(props)
			const options = { ...known, file }
			if (file[0][0] instanceof Date) {
				options["dateWindow"] = [file[0][0].getTime(), file[file.length - 1][0].getTime()]
			}
			if (debugPrints) console.log("updateOptions => data", options)
			dygraphRef.current.updateOptions(options)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file, labels])

	useEffect(() => {
		const dygraphRefCurrent = dygraphRef.current
		if (!dygraphRefCurrent) {
			if (debugPrints) console.log("initAttrs")
			const { known } = spreadProps(props)
			const options = {
				...defaultChartOptions,
				...known,
				clickCallback,
				highlightCallback,
				unhighlightCallback,
				drawCallback: onDraw || function () {},
				interactionModel: customInteractionModel,
			}
			dygraphRef.current = new Dygraph(divRef.current, file, options)
			return () => {
				if (dygraphRef.current && dygraphRef.current.destroy) {
					if (debugPrints) console.log("destroy Graph")
					dygraphRef.current.destroy()
					dygraphRef.current = null
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <div ref={divRef} className="dygraph-container" />
}

ReactDygraphs.propTypes = {
	...dygraphPropTypes,
	style: PropTypes.object,
}

export default ReactDygraphs

import React, { memo, useEffect } from "react"
import PropTypes from "prop-types"
import NodeMarkersByMaster from "./NodeMarkersByMaster"

const NodeMarkersOverlay = memo(
	({
		layoutId,
		activeOverlay,
		timestamp,
		selectedMasterLocationIds,
		dataView,
		subscribeReplacementCampaignUpdates,
		listReplacementCampaignInfo,
	}) => {
		useEffect(() => {
			if (!layoutId) {
				return
			}
			if (dataView === "replacementCampaign") {
				listReplacementCampaignInfo(layoutId)
				return subscribeReplacementCampaignUpdates(layoutId)
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [layoutId, dataView])

		const nodeMarkersByMaster = []
		for (let i = 0; i < selectedMasterLocationIds.length; i++) {
			const mLocId = selectedMasterLocationIds[i]
			nodeMarkersByMaster.push(
				<NodeMarkersByMaster
					key={mLocId}
					mLocId={mLocId}
					layoutId={layoutId}
					activeOverlay={activeOverlay}
					timestamp={timestamp}
				/>,
			)
		}
		return <>{nodeMarkersByMaster}</>
	},
)

NodeMarkersOverlay.displayName = "NodeMarkersOverlay"

NodeMarkersOverlay.propTypes = {
	layoutId: PropTypes.string,
	activeOverlay: PropTypes.string.isRequired,
	timestamp: PropTypes.number.isRequired,
	selectedMasterLocationIds: PropTypes.array.isRequired,
	dataView: PropTypes.string.isRequired,
	subscribeReplacementCampaignUpdates: PropTypes.func.isRequired,
	listReplacementCampaignInfo: PropTypes.func.isRequired,
}

export default NodeMarkersOverlay

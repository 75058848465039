import React from "react"
import PropTypes from "prop-types"
import NodeLabelsByMaster from "./NodeLabelsByMaster"
import { overlays } from "constants/overlays"

const NodeLabelsOverlay = ({ layoutId, mLocIds, activeOverlay }) => {
	if (activeOverlay !== overlays.DEVICEIDASSIGNMENT) return null

	const nodeLabelsByMaster = []
	for (let i = 0; i < mLocIds.length; i++) {
		const mLocId = mLocIds[i]
		nodeLabelsByMaster.push(
			<NodeLabelsByMaster key={mLocId} mLocId={mLocId} layoutId={layoutId} activeOverlay={activeOverlay} />,
		)
	}
	return nodeLabelsByMaster
}

NodeLabelsOverlay.propTypes = {
	mLocIds: PropTypes.array.isRequired,
}

export default NodeLabelsOverlay

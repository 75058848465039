import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import OverlaySelection from "./OverlaySelection"

const mapStateToProps = (state, props) => ({
	userPrivileges: state.user.privileges,
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(OverlaySelection)

import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import quagga from "quagga"
import { DEFAULT_CONFIG } from "./utils"
import { makeStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"

const useStyles = makeStyles(() => ({
	container: {
		border: "#0000",
		display: "flex",
		height: "400px",
	},
	// Global styles for the video element within the container
	"@global": {
		video: {
			width: "100% ",
		},
	},
	circularProgress: { position: "absolute", top: "50%", left: "45%" },
}))
const ReactQuagga = ({
	onClose,
	config,
	onDetected: handleDetected,
	onProcessed: handleProcessed,
	onError: handleError,
}) => {
	const quaggaRef = useRef(null)
	const styles = useStyles()
	const [loadingSpinner, setLoadingSpinner] = useState(true)

	useEffect(() => {
		const activeConfig = config || DEFAULT_CONFIG
		const { inputStream: initAttrs } = activeConfig

		initAttrs["target"] = quaggaRef.current
		quagga.init({ ...activeConfig, inputStream: initAttrs }, (error) => {
			if (error) {
				if (handleError) {
					handleError(error)
				}
				return
			}
			quagga.onDetected((data) => {
				// Call onDetected with the detected data
				if (handleDetected) {
					handleDetected(data)
				}
				onClose()
			})
			if (handleProcessed) {
				quagga.onProcessed(handleProcessed)
			}

			setLoadingSpinner(false)
			quagga.start()
		})

		return () => {
			quagga.offDetected(handleDetected)
			if (handleProcessed) {
				quagga.offProcessed(handleProcessed)
			}
			quagga.stop()
		}
	}, [config, handleDetected, handleError, handleProcessed, onClose])

	return (
		<>
			{loadingSpinner && <CircularProgress className={styles.circularProgress} size={50} thickness={1} />}
			<div ref={quaggaRef} className={styles.container} />
		</>
	)
}

ReactQuagga.propTypes = {
	onClose: PropTypes.func,
	onDetected: PropTypes.func.isRequired,
	onProcessed: PropTypes.func,
	onError: PropTypes.func,
	config: PropTypes.object,
}

export default ReactQuagga

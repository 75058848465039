import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Paper from "@material-ui/core/Paper"
import LayoutList from "./LayoutList"
import GeoMap from "../GeoMap"
import { Redirect } from "react-router"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		height: "100%",
		overflow: "hidden",
	},
	layoutListPaperSingle: {
		flex: 1,
		[theme.breakpoints.down("xs")]: {
			margin: theme.spacing(1),
		},
		[theme.breakpoints.up("sm")]: {
			maxWidth: "500px",
			width: "100%",
			margin: `${theme.spacing(2)}px auto`,
		},
		[theme.breakpoints.up("md")]: {
			maxWidth: "800px",
		},
	},
	layoutListPaperSplit: {
		flex: 1,
		width: "100%",
		maxWidth: "500px",
		margin: theme.spacing(1),
		marginRight: 0,
	},
	geoMapPaper: {
		flex: 1,
		[theme.breakpoints.down("xs")]: {
			margin: 0,
		},
		[theme.breakpoints.up("sm")]: {
			width: "100%",
			margin: theme.spacing(1),
		},
	},
}))

const LayoutManager = ({ view }) => {
	const classes = useStyles()

	if (view === null) {
		return <Redirect to={{ pathname: "/layouts", search: "?view=list" }} />
	}

	const viewList = view.split(",")
	const splitView = viewList.length > 1

	return (
		<div className={classes.root}>
			{viewList.includes("list") ? (
				<Paper
					className={clsx({ [classes.layoutListPaperSingle]: !splitView, [classes.layoutListPaperSplit]: splitView })}
				>
					<LayoutList />
				</Paper>
			) : null}
			{viewList.includes("map") ? (
				<Paper className={classes.geoMapPaper}>
					<GeoMap />
				</Paper>
			) : null}
		</div>
	)
}

LayoutManager.propTypes = {
	view: PropTypes.string,
}

export default LayoutManager

import { connect } from "react-redux"
import MasterList from "./MasterList"
import { withRouter } from "react-router"
import { compose } from "redux"

function createData(masterDetails, mLocIds) {
	let rows = []
	mLocIds.forEach((mLocId, index) => {
		const masterLocation = masterDetails[mLocId] || {}
		rows.push({
			id: masterLocation["id"],
			masterName: masterLocation ? masterLocation["name"] : "Click to configure master [ " + (index + 1) + " ]",
			deviceType: masterLocation ? masterLocation["deviceType"] : "",
		})
	})
	return rows
}

const columns = [
	{
		width: 200,
		flexGrow: 1.0,
		label: "Master Name",
		dataKey: "masterName",
		numeric: false,
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "Device Type",
		dataKey: "deviceType",
		numeric: false,
		disableSort: false,
	},
]

const getMLocIds = (layoutDetails) => {
	if (layoutDetails) {
		return layoutDetails.mLocIds || []
	}
	return []
}

const mapStateToProps = (state, props) => ({
	rows: createData(state.masterDetails, getMLocIds(state.layoutDetails[props.layoutId])),
	columns,
	userPrivileges: state.user.privileges,
	masterDetails: state.masterDetails[props.layoutId] || {},
})

const mapDispatchToProps = (dispatch, props) => ({})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MasterList)

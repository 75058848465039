import { connect } from "react-redux"
import MasterCoordinates from "./MasterCoordinates"
import { getLayoutMasterIds } from "utils"

const getMasterCoordsAssigned = (mLocIds, masterDetails) => {
	return mLocIds.map((mLocId) => {
		if (masterDetails.hasOwnProperty(mLocId)) {
			if (masterDetails[mLocId].xLoc && masterDetails[mLocId].yLoc) {
				return {
					mLocId,
					name: masterDetails[mLocId].name,
					assigned: true,
				}
			} else {
				return {
					mLocId,
					name: masterDetails[mLocId].name,
					assigned: false,
				}
			}
		} else {
			return {
				mLocId,
				name: "Unnamed master",
				assigned: false,
			}
		}
	})
}

const mapStateToProps = (state, props) => {
	const mLocIds = getLayoutMasterIds(state.layoutDetails, props.layoutId)
	return {
		masterCoordsAssigned: getMasterCoordsAssigned(mLocIds, state.masterDetails),
	}
}

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MasterCoordinates)

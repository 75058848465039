import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 250,
		display: "flex",
		flexDirection: "column",
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}))

const InputDropdown = ({ value, setTaskInfo, taskInfo, name, label, options }) => {
	const classes = useStyles()

	const handleChange = (event) => {
		const { value } = event.target
		setTaskInfo({ [name]: value })
	}

	return (
		<FormControl className={classes.formControl}>
			<InputLabel id="demo-simple-select-label">{label}</InputLabel>
			<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={value || value === 0 ? value : ""}
				onChange={handleChange}
			>
				{options.map((option) => {
					return (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					)
				})}
			</Select>
		</FormControl>
	)
}

export default InputDropdown

import React from "react"
import PropTypes from "prop-types"
import BoardAlternateForm from "./BoardAlternateForm"

const BOMAlternateForm = ({ bomAlternates, taskInfo, bomAlternate, setTaskInfo, label }) => {
	const alternateToExample = (alternate) => {
		return "SII XXXX" + alternate + "RCXXXXXXXX"
	}
	return (
		<BoardAlternateForm
			boardAlternates={bomAlternates}
			taskInfo={taskInfo}
			boardAlternate={bomAlternate}
			setTaskInfo={setTaskInfo}
			label={label}
			displayName={"BOM alternate"}
			variableName={"bomAlternate"}
			alternateToExample={alternateToExample}
		/>
	)
}

BOMAlternateForm.propTypes = {
	setTaskInfo: PropTypes.func.isRequired,
	taskInfo: PropTypes.object.isRequired,
	label: PropTypes.string,
	bomAlternates: PropTypes.arrayOf(PropTypes.string),
	bomAlternate: PropTypes.string,
}

export default BOMAlternateForm

import React, { Fragment, useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import ErrorIcon from "@material-ui/icons/ErrorOutline"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { KeyboardDatePicker } from "@material-ui/pickers"

const ITEM_HEIGHT = 50

const useStyles = makeStyles((theme) => ({
	container: {
		maxHeight: 420,
		[theme.breakpoints.down("xs")]: {
			maxHeight: 220,
		},
	},
	loadingContainer: {
		display: "flex",
		justifyContent: "space-around",
		padding: theme.spacing(1),
	},
	loadingError: {
		margin: theme.spacing(1),
		marginRight: 0,
	},
	loadingText: {
		margin: theme.spacing(1),
		textAlign: "center",
	},
	startDatePicker: {
		margin: theme.spacing(2),
	},
}))

const ReplacementCampaignSummary = ({
	layoutId,
	mLocIds,
	mNames,
	nodeCount,
	replacements,
	replacementCampaignStatus,
	startReplacementCampaign,
	deleteReplacementCampaignInfo,
}) => {
	const classes = useStyles()
	const [anchorEl, setAnchorEl] = useState({})

	const handleClick = (mLocId) => (event) => {
		setAnchorEl({
			mLocId,
			target: event.currentTarget,
		})
	}

	const handleClose = () => {
		setAnchorEl({})
	}

	const handleDateChange = (layoutId, mLocId) => (date) => {
		startReplacementCampaign(layoutId, mLocId, Math.floor(date.getTime() / 1000))
		setAnchorEl({})
	}

	const handleStart = (layoutId, mLocId) => () => {
		startReplacementCampaign(layoutId, mLocId)
		setAnchorEl({})
	}

	if (!replacementCampaignStatus || replacementCampaignStatus === "pending") {
		return (
			<div className={classes.loadingContainer}>
				<Divider />
				<CircularProgress />
				<Typography variant="subtitle1" className={classes.loadingText}>
					Replacement Campaign Loading...
				</Typography>
			</div>
		)
	}

	if (replacementCampaignStatus === "error") {
		return (
			<div className={classes.loadingContainer}>
				<Divider />
				<ErrorIcon className={classes.loadingError} />
				<Typography variant="subtitle1" className={classes.loadingText}>
					Error Loading Replacement Campaign
				</Typography>
			</div>
		)
	}

	return (
		<Fragment>
			<Divider />
			<Divider />
			<TableContainer className={classes.container}>
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>Master</TableCell>
							<TableCell>Node Count</TableCell>
							<TableCell>Remaining</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{mLocIds.map((mLocId, index) => (
							<TableRow key={mLocId}>
								<TableCell>{mNames[index]}</TableCell>
								<TableCell>{nodeCount[index]}</TableCell>
								{replacements[index] ? (
									<Fragment>
										<TableCell>{nodeCount[index] - Object.keys(replacements[index]["replacements"]).length}</TableCell>
										<TableCell>
											<IconButton aria-label="options" size="small" onClick={handleClick(replacements[index].mLocId)}>
												<MoreVertIcon fontSize="inherit" />
											</IconButton>
										</TableCell>
										<Menu
											id="overlay-menu"
											anchorEl={anchorEl.target}
											open={Boolean(anchorEl.mLocId === replacements[index].mLocId)}
											onClose={handleClose}
											PaperProps={{
												style: {
													maxHeight: ITEM_HEIGHT * 5.75,
													width: 200,
												},
											}}
											getContentAnchorEl={null}
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "center",
											}}
											transformOrigin={{
												vertical: "top",
												horizontal: "left",
											}}
										>
											<MenuItem disableRipple>
												<KeyboardDatePicker
													margin="normal"
													id="campaign-start-date-picker-dialog"
													label="Campaign Start Date"
													format="MM/dd/yyyy"
													value={new Date(replacements[index].timestamp * 1000)}
													onChange={handleDateChange(replacements[index].layoutId, replacements[index].mLocId)}
													KeyboardButtonProps={{
														"aria-label": "change date",
													}}
												/>
											</MenuItem>
											<Divider />
											<MenuItem
												onClick={() =>
													deleteReplacementCampaignInfo(replacements[index].layoutId, replacements[index].mLocId)
												}
											>
												Delete Campaign
											</MenuItem>
										</Menu>
									</Fragment>
								) : (
									<Fragment>
										<TableCell>
											<Button variant="contained" color="primary" onClick={handleStart(layoutId, mLocId)}>
												Start
											</Button>
										</TableCell>
										<TableCell></TableCell>
									</Fragment>
								)}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fragment>
	)
}

export default ReplacementCampaignSummary

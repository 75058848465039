import React, { useState } from "react"
import PropTypes from "prop-types"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import FormControl from "@material-ui/core/FormControl"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import { columns } from "constants/userDetails"

const columnsToSelected = (cols) => {
	const sel = {}
	const columnNames = columns.map((col) => col.label)
	cols.forEach((col) => (sel[columnNames.indexOf(col.label)] = true))
	return sel
}

const ColumnSelect = ({ isOpen, currentSelection, handleClose, handleAccept }) => {
	const [selected, setSelected] = useState(columnsToSelected(currentSelection))

	const handleChange = (index) => (e) => {
		e.preventDefault()
		setSelected((sel) => ({ ...sel, [index]: e.target.checked }))
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		handleAccept(
			Object.keys(selected)
				.filter((index) => selected[index])
				.map((index) => columns[index]),
		)
		handleClose()
	}

	return (
		<Dialog onClose={handleClose} aria-labelledby="column-select-title" open={isOpen}>
			<DialogTitle id="column-select-title">Select Attributes to Display</DialogTitle>
			<form action="#" onSubmit={handleSubmit}>
				<DialogContent>
					<FormControl component="fieldset">
						<FormGroup>
							{columns.map((column, index) => (
								<FormControlLabel
									key={column.label}
									control={
										<Checkbox
											checked={selected[index] || false}
											color="primary"
											onChange={handleChange(index)}
											value={index}
										/>
									}
									label={column.label}
								/>
							))}
						</FormGroup>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={handleClose}>
						Cancel
					</Button>
					<Button type="submit" variant="contained" color="primary">
						Accept
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	)
}

ColumnSelect.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	handleAccept: PropTypes.func.isRequired,
	currentSelection: PropTypes.array.isRequired,
}

export default ColumnSelect

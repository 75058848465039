import React from "react"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"

const IssueTypeQuestion = ({ issueType, setTaskInfo }) => {
	const handleChange = (event) => {
		setTaskInfo({ issueType: event.target.value })
	}

	return (
		<FormControl component="fieldset">
			<RadioGroup aria-label="Issue Type" color="primary" name="issueType" value={issueType} onChange={handleChange}>
				<FormControlLabel value="MI" control={<Radio />} label="Not Tracking" />
				<FormControlLabel value="CI" control={<Radio />} label="Charging Issue" />
				<FormControlLabel value="BI" control={<Radio />} label="Battery Issue" />
				<FormControlLabel value="NC" control={<Radio />} label="No Communication" />
				<FormControlLabel value="BO" control={<Radio />} label="Battery Overcharge Shutdown" />
				<FormControlLabel value="EI" control={<Radio />} label="E-Stop" />
				<FormControlLabel value="RF" control={<Radio />} label="Retrofit" />
			</RadioGroup>
		</FormControl>
	)
}

export default IssueTypeQuestion

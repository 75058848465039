import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Fab from "@material-ui/core/Fab"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import KeyIcon from "@material-ui/icons/VpnKey"
import CircleIcon from "@material-ui/icons/RadioButtonChecked"
import Divider from "@material-ui/core/Divider"
import { overlays } from "constants/overlays"

import { chargingModes, getChargingColor, getChargingDescription, getChargingLabel } from "constants/charging"

import { colorAmber, colorGreen, colorGrey, colorPink, colorPrimary, colorRed } from "../../../colors"

import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
	colorIcon: {
		marginRight: theme.spacing(1.5),
		marginLeft: theme.spacing(1.5),
	},
}))

const StatusKeys = ({ activeOverlay, dataView }) => {
	const classes = useStyles()
	const [isOpen, setChargerKeys] = useState(false)
	let keyItems
	let keyHeading

	const handleClickOpen = () => {
		setChargerKeys(true)
	}

	const handleClose = () => {
		setChargerKeys(false)
	}

	if (activeOverlay === overlays.CHARGINGSTATUS) {
		keyHeading = "Charger Status Key"
		keyItems = Object.values(chargingModes).map((mode) => {
			return (
				<ListItem key={mode}>
					<CircleIcon style={{ color: getChargingColor(mode) }} className={classes.colorIcon} />
					<ListItemText primary={getChargingLabel(mode)} secondary={getChargingDescription(mode)} />
				</ListItem>
			)
		})
	} else if (activeOverlay === overlays.DEVICEIDASSIGNMENT) {
		keyHeading = "Device ID Assignment Key"
		keyItems = [
			<ListItem key={"pendingUpload"}>
				<CircleIcon style={{ color: colorGrey }} className={classes.colorIcon} />
				<ListItemText primary={"Unassigned"} secondary={"Device ID unassigned"} />
			</ListItem>,
			<ListItem key={"pendingUpload"}>
				<CircleIcon style={{ color: colorAmber }} className={classes.colorIcon} />
				<ListItemText primary={"Pending Upload"} secondary={"Upload from App to Cloud Pending"} />
			</ListItem>,
			<ListItem key={"pendingSync"}>
				<CircleIcon style={{ color: colorGreen }} className={classes.colorIcon} />
				<ListItemText
					primary={"Pending Sync"}
					secondary={"Upload from App to Cloud Success, Push from Cloud to Master Controller Pending"}
				/>
			</ListItem>,
			<ListItem key={"Accepted"}>
				<CircleIcon style={{ color: colorPrimary }} className={classes.colorIcon} />
				<ListItemText primary={"Accepted"} secondary={"Assignment Complete"} />
			</ListItem>,
			<Divider key={"Divider"} />,
			<ListItem key={"duplicateNodeID"}>
				<CircleIcon style={{ color: colorRed }} className={classes.colorIcon} />
				<ListItemText primary={"Duplicate NodeID"} secondary={"Duplicate NodeID Detected"} />
			</ListItem>,
		]
		if (dataView === "replacementCampaign") {
			keyItems.unshift(
				<ListItem key={"pendingReplacement"}>
					<CircleIcon style={{ color: colorPink }} className={classes.colorIcon} />
					<ListItemText primary={"Pending Replacement"} secondary={"Replacement Campaign"} />
				</ListItem>,
			)
		}
	}

	return (
		<div>
			<Fab size="small" color="primary" aria-label="add" onClick={handleClickOpen}>
				<KeyIcon />
			</Fab>
			<Dialog
				open={isOpen}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{keyHeading}</DialogTitle>
				<DialogContent>
					<List>{keyItems}</List>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" autoFocus>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

StatusKeys.propTypes = {
	activeOverlay: PropTypes.any,
	dataView: PropTypes.any,
}

export default StatusKeys

export const privileges = {
	NONE: 0,
	VIEW: 1, // Any Authenticated User
	DATA_SNAPSHOT: 1 << 1, // Current Data
	DATA_HISTORY: 1 << 2, // Historical Data
	CONFIGURATION: 1 << 3, // Configuration of webapp (Modify masters)
	CONTROL_STANDARD: 1 << 4, // Customer level controls
	CONTROL_CX: 1 << 5, // Commissioning specific controls
	DATA_DOWNLOAD: 1 << 6, // Data extraction tools available
	GAMECHANGE: 1 << 7, // GameChange specific views
	ADMIN: 1 << 8, // Configuration of webapp (Add/modify users/privileges/layouts/masters)
	DEV: 1 << 9, // ChangeChange development team only views/controls/alarms
	BETA_FEATURE: 1 << 10, // webapp development limited-release features
}

export const CONTROLS = privileges.CONTROL_STANDARD | privileges.CONTROL_CX

export const groupPrivileges = {
	USER: privileges.VIEW | privileges.CONTROL_STANDARD | privileges.DATA_SNAPSHOT | privileges.DATA_HISTORY,
	TEMP_TECH: privileges.VIEW | privileges.CONTROL_STANDARD | privileges.DATA_SNAPSHOT | privileges.DATA_HISTORY,
	FIELD_TECH:
		privileges.VIEW |
		privileges.CONTROL_STANDARD |
		privileges.DATA_SNAPSHOT |
		privileges.DATA_HISTORY |
		privileges.CONFIGURATION,
	CX_TECH: privileges.VIEW | privileges.CONTROL_STANDARD | privileges.CONTROL_CX | privileges.CONFIGURATION,
	ADMIN:
		privileges.VIEW |
		privileges.DATA_SNAPSHOT |
		privileges.DATA_HISTORY |
		privileges.CONFIGURATION |
		privileges.CONTROL_STANDARD |
		privileges.CONTROL_CX |
		privileges.DATA_DOWNLOAD |
		privileges.GAMECHANGE |
		privileges.ADMIN,
	DEV:
		privileges.VIEW |
		privileges.DATA_SNAPSHOT |
		privileges.DATA_HISTORY |
		privileges.CONFIGURATION |
		privileges.CONTROL_STANDARD |
		privileges.CONTROL_CX |
		privileges.DATA_DOWNLOAD |
		privileges.GAMECHANGE |
		privileges.DEV,
	BETA_FEATURES: privileges.BETA_FEATURE,
}

export const accessLabels = {
	[privileges.DATA_SNAPSHOT]: "Current Data",
	[privileges.DATA_HISTORY]: "Historical Data",
	[privileges.CONFIGURATION]: "Configuration",
	[privileges.CONTROL_STANDARD]: "Controls - Basic",
	[privileges.CONTROL_CX]: "Controls - Commissioning",
	[privileges.DATA_DOWNLOAD]: "Data Download",
	[privileges.ADMIN]: "Admin",
	[privileges.DEV]: "Developer",
	[privileges.BETA_FEATURE]: "Beta Features",
}

export const accessModes = [
	privileges.DATA_SNAPSHOT,
	privileges.DATA_HISTORY,
	privileges.CONFIGURATION,
	privileges.CONTROL_STANDARD,
	privileges.CONTROL_CX,
	privileges.DATA_DOWNLOAD,
	privileges.ADMIN,
	privileges.DEV,
	privileges.BETA_FEATURE,
]

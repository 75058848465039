import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import LayoutTimestamp from "./LayoutTimestamp"
import { fetchNodeData, fetchMasterDataByLayoutId } from "store/actions/fetchers"
import { getMasterLocIds } from "utils/url"

const getDataIsLoaded = (state, props) => {
	const mLocIds = (state.layoutDetails[props.match.params.layoutId] || {}).mLocIds || []
	for (let i = 0; i < mLocIds.length; i++) {
		const mLocId = mLocIds[i]
		if (state.nodeDataStatus[mLocId] === "pending") return false
	}
	if (state.masterDataStatus === "pending") return false
	return true
}

const getLatestTimeStamp = (state, props) => {
	const activeTimestamps = getMasterLocIds(props.location.search)
		.map((mLocId) => state.nodeDataActiveTimestamp[mLocId])
		.filter((timestamp) => timestamp !== undefined)

	let latestTimestamp = null
	if (activeTimestamps.length > 0) {
		latestTimestamp = Math.max(...Object.values(activeTimestamps))
	}
	return latestTimestamp
}

const getUrlTimestamp = (props) => {
	const params = new URLSearchParams(props.location.search)
	return parseInt(params.get("ts"))
}

const getTimestamp = (state, props) => {
	return getUrlTimestamp(props) || getLatestTimeStamp(state, props)
}

const displayLatest = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	params.delete("ts")
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const mapStateToProps = (state, props) => {
	const tz = (state.layoutDetails[props.match.params.layoutId] || {}).tz
	const mLocIds = (state.layoutDetails[props.match.params.layoutId] || {}).mLocIds || []

	return {
		tz,
		activeMasters: getMasterLocIds(props.location.search),
		mLocIds,
		layoutId: props.match.params.layoutId,
		timeLocale: state.timeLocale,
		dataIsLoaded: getDataIsLoaded(state, props),
		timestamp: getTimestamp(state, props),
		latest: isNaN(getUrlTimestamp(props)),
		displayLatest: () => displayLatest(state, props),
		userPrivileges: state.user.privileges,
		masterDetails: state.masterDetails,
		dataView: state.dataView,
	}
}

const mapDispatchToProps = (dispatch) => ({
	fetchNodeData: (layoutId, mLocId, userPrivileges, deviceType) =>
		dispatch(fetchNodeData(layoutId, mLocId, userPrivileges, deviceType)),
	fetchMasterDataByLayoutId: (layoutId, userPrivileges, deviceType) =>
		dispatch(fetchMasterDataByLayoutId(layoutId, userPrivileges, deviceType)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LayoutTimestamp)

/**
 * Gets a list of error messages if duplicated node IDs are found under any master in the specified site. Example:
 * 	[
 * 		"Master 2: ABCD encountered 3 times.",
 * 		"Master 3: 1234 encountered 2 times.",
 * 	]
 * @param state The root Redux state.
 * @param siteUuid The summary will consider nodes in this site.
 * @returns {*[]} A list of error messages if duplicates exist, or undefined.
 */
export function getDuplicateNodeIdSummary(state, siteUuid) {
	const encounteredNodeIds = {}
	let duplicatesEncountered = false
	let nbUnassignedEncountered = 0
	const siteDetails = state.layoutDetails[siteUuid]

	if (!siteDetails) {
		return
	}

	const masterUuids = siteDetails.mLocIds || []

	for (const masterUuid of masterUuids) {
		const masterDetails = state.masterDetails[masterUuid]
		if (!masterDetails) {
			continue
		}
		const { name } = masterDetails
		if (!encounteredNodeIds[name]) {
			// Maps node IDs to counters
			encounteredNodeIds[name] = {}
		}
		const nodeCounters = encounteredNodeIds[name]
		const thisMasterNodes = state.nodeDetails[masterUuid]
		if (!thisMasterNodes) {
			continue
		}
		for (const nodeDetails of Object.values(thisMasterNodes)) {
			const { nId } = nodeDetails
			if (nId === undefined || nId.slice(-4) === "0000") {
				nbUnassignedEncountered += 1
				continue
			}
			if (!nodeCounters[nId]) {
				nodeCounters[nId] = 1
			} else {
				nodeCounters[nId] += 1
				duplicatesEncountered = true
			}
		}
	}
	if (nbUnassignedEncountered || duplicatesEncountered) {
		const summary = []
		if (nbUnassignedEncountered) {
			summary.push(`${nbUnassignedEncountered} nodes have no ID and must be scanned.`)
		}
		for (const [masterName, nodeCounters] of Object.entries(encounteredNodeIds)) {
			for (const [nodeId, count] of Object.entries(nodeCounters)) {
				if (nodeId === "0000") {
					// Special handling for 0000: consider it unassigned.
					continue
				}
				if (count > 1) {
					summary.push(`${masterName}: ${nodeId} encountered ${count} times.`)
				}
			}
		}
		return summary
	}
}

/**
 * Returns true if the passed string is a valid hex string. For example:
 *     isHex("ABCD") => true
 *     isHex("ABCX") => false
 *     isHex("") => false
 *     isHex(null) => false
 * @param value A string that may or may not be a valid hex string.
 * @returns {*|boolean} True if the string is a valid hex string.
 */
export function isHex(value) {
	if (value === null) return false
	const hexPattern = /[0-9A-Fa-f]+$/
	return value.match(hexPattern)
}

import { connect } from "react-redux"
import { compose } from "redux"
import { withRouter } from "react-router-dom"
import ConfigureLayout from "./ConfigureLayout"

import { fetchMasterDetails, fetchNodeDetails } from "store/actions/fetchers"

const mapStateToProps = (state, props) => ({
	layoutId: props.match.params.layoutId,
	masterDetails: state.masterDetails || {},
})

const mapDispatchToProps = (dispatch) => ({
	fetchNodeDetails: (layoutId, masterDetails) => dispatch(fetchNodeDetails(layoutId, masterDetails)),
	fetchMasterDetails: (layoutId) => dispatch(fetchMasterDetails(layoutId)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ConfigureLayout)

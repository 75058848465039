import { connect } from "react-redux"
import NodeDetails from "./NodeDetails"
import { getNodeDataFormats } from "constants/nodeInfoFormatting"
import { deviceTypes } from "constants/deviceTypes"
import { auxNodeDataList } from "constants/deviceData"
import { getRecentMasterDataFromMLocId } from "../../../utils/stateSelectors"

const getDeviceType = (state, props) => {
	let { deviceType } = state.masterDetails[props.mLocId] || {}
	if (deviceType === deviceTypes.GAMECHANGE_GEN3_DUAL) {
		const nodeDetails = (state.nodeDetails[props.mLocId] || {})[props.nLocId] || {}
		if (nodeDetails.role === "aux") {
			return deviceTypes.GAMECHANGE_GEN3_DUAL_AUX
		} else {
			return deviceTypes.GAMECHANGE_GEN3_DUAL_PRIMARY
		}
	}
	return deviceType
}

const getNodeData = (state, mLocId, nLocId, timestamp, deviceType) => {
	const nodeData = state.nodeData[`${mLocId}#${timestamp}`] || {}

	let data = {}
	const nodeDetails = state.nodeDetails[mLocId]?.[nLocId] || {}
	if (deviceType === deviceTypes.GAMECHANGE_GEN3_DUAL_AUX) {
		Object.keys(nodeData[nodeDetails.relative] || {}).forEach((dataKey) => {
			if (auxNodeDataList.includes(dataKey)) {
				data[dataKey] = nodeData[nodeDetails.relative][dataKey]
			}
		})
	} else if (deviceType === deviceTypes.GAMECHANGE_GEN3_DUAL_PRIMARY) {
		Object.keys(nodeData[nLocId] || {}).forEach((dataKey) => {
			if (!auxNodeDataList.includes(dataKey)) {
				data[dataKey] = nodeData[nLocId][dataKey]
			}
		})
	} else {
		data = nodeData[nLocId] || {}
	}
	// include the basic nodeDetails information about this specific Node. (x, y, uuid, etc)
	return { data, ...nodeDetails }
}

const getFormattedNodeData = (state, props) => {
	const timestamp = props.timestamp || state.nodeDataActiveTimestamp[props.mLocId]
	const deviceType = getDeviceType(state, props)
	const masterData = getRecentMasterDataFromMLocId(state, props)
	const nodeData = getNodeData(state, props.mLocId, props.nLocId, timestamp, deviceType)

	// Inject node firmware version (used in some conditional flag descriptions)
	nodeData["fwNode"] = (masterData || {})["fwNode"] || undefined

	const tz = (state.layoutDetails[props.layoutId] || {})["tz"]

	if (Object.keys(nodeData).length && deviceType) {
		const userPrivileges = (state.user || {}).privileges || 0

		return getNodeDataFormats(userPrivileges, deviceType, tz)
			.map((dataType) => {
				const dataFormatted = dataType.formatString(
					...dataType.args.map((arg) => {
						// uuid is not in `data`, but in the nodeDetails object.
						if (arg === "uuid") {
							return nodeData[arg]
						}
						return nodeData.data[arg]
					}),
				)
				if (dataFormatted === null) return null
				return {
					id: dataType.id,
					label: dataType.label,
					dataFormatted,
				}
			})
			.filter((data) => data !== null)
	} else {
		return null
	}
}
const getNodeDataNodeId = (state, props) => {
	const timestamp = props.timestamp || state.nodeDataActiveTimestamp[props.mLocId]
	return ((state.nodeData[`${props.mLocId}#${timestamp}`] || {})[props.nLocId] || {})["nId"]
}

const mapStateToProps = (state, props) => ({
	details: (state.nodeDetails[props.mLocId] || {})[props.nLocId] || {},
	mId: (state.masterDetails[props.mLocId] || {}).mId,
	nodeData: getFormattedNodeData(state, props),
	userPrivileges: state.user.privileges,
	nodeDataNodeId: getNodeDataNodeId(state, props),
})

const mapDispatchToProps = (_dispatch, _props) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(NodeDetails)

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import LayoutMap from "./LayoutMap"
import { setLayoutZoomLevel } from "store/actions/reduxActions"

import { fetchNodeDetails, fetchMasterDetails, fetchMasterDataByLayoutId } from "store/actions/fetchers"

const getLayoutUrl = (state, props) => {
	return (
		(state.layoutDetails[props.match.params.layoutId] &&
			state.layoutDetails[props.match.params.layoutId]["layoutUrl"]) ||
		""
	)
}

const getLayoutTimestamp = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	return parseInt(params.get("ts"))
}

const mapStateToProps = (state, props) => ({
	layoutId: props.match.params.layoutId,
	layoutUrl: getLayoutUrl(state, props),
	layoutDetails: state.layoutDetails[props.match.params.layoutId] || {},
	userPrivileges: state.user.privileges,
	masterDetails: state.masterDetails || {},
	dataView: state.dataView,
	timestamp: getLayoutTimestamp(state, props),
})

const mapDispatchToProps = (dispatch) => ({
	fetchNodeDetails: (layoutId, masterDetails) => {
		dispatch(fetchNodeDetails(layoutId, masterDetails))
	},
	fetchMasterDetails: (layoutId) => {
		dispatch(fetchMasterDetails(layoutId))
	},
	fetchMasterDataByLayoutId: (layoutId, userPrivileges, deviceType, timestamp) => {
		dispatch(fetchMasterDataByLayoutId(layoutId, userPrivileges, deviceType, timestamp))
	},
	setLayoutZoomLevel: (zoomLevel) => {
		dispatch(setLayoutZoomLevel(zoomLevel))
	},
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LayoutMap)

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import DeviceIdInput from "./DeviceIdInput"
import { setNodeId, setMasterId } from "store/actions/setters"
import {
	setCommissioningComponentSelection,
	setMaterComponentScanned,
	setNodeComponentScanned,
	setRememberComponentSelection,
} from "../../store/actions/reduxActions"

const getDisplayedLocationIds = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	const deviceIds = params.get("deviceid-input")
	return deviceIds || ""
}

const getLocIds = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	const nLocId = params.get("deviceid-input")?.slice(-3)
	return nLocId || ""
}

const handleClose = (props) => {
	const params = new URLSearchParams(props.location.search)
	params.delete("deviceid-input")
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const handleAccept = (dispatch, props) => async (deviceid, nLocId) => {
	const params = new URLSearchParams(props.location.search)
	let locIds = params.get("deviceid-input")
	if (!locIds) return null
	locIds = locIds.split(":")
	const isNode = locIds.length === 3
	if (isNode) {
		// TODO: props.location.search is not updated when Move East and Move West buttons, so we need to accept the nLocId
		//  as part of the handleAccept function call if applicable.
		const [layoutId, masterId, _nodeIndex] = locIds
		dispatch(setNodeId(layoutId, masterId, nLocId, deviceid))
	} else if (locIds.length === 2) {
		dispatch(setMasterId(locIds[0], locIds[1], deviceid))
	}
}

const handleComponentSelection = (dispatch) => async (component) => {
	dispatch(setCommissioningComponentSelection(component))
}
const handleRememberComponentSelection = (dispatch) => async (remember) => {
	dispatch(setRememberComponentSelection(remember))
}

const handleNodeComponentScanned = (dispatch) => async (nodeComponent) => {
	dispatch(setNodeComponentScanned(nodeComponent))
}

const handleMasterComponentScanned = (dispatch) => async (masterComponent) => {
	dispatch(setMaterComponentScanned(masterComponent))
}

const mapDispatchToProps = (dispatch, props) => ({
	handleClose: () => handleClose(props),
	handleAccept: handleAccept(dispatch, props),
	handleComponentSelection: handleComponentSelection(dispatch),
	handleRememberComponentSelection: handleRememberComponentSelection(dispatch),
	handleNodeComponentScanned: handleNodeComponentScanned(dispatch),
	handleMasterComponentScanned: handleMasterComponentScanned(dispatch),
})

const mapStateToProps = (state, props) => {
	return {
		locIds: getDisplayedLocationIds(state, props),
		nodeLocIdFromUrl: getLocIds(state, props),
		nodeDetails: state.nodeDetails || {},
		masterDetails: state.masterDetails || {},
		commissioningComponentSelection: state.commissioningComponentSelection,
		rememberComponentSelection: state.rememberComponentSelection,
		nodeComponentScanned: state.nodeComponentScanned || {},
		masterComponentScanned: state.masterComponentScanned || {},
	}
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(DeviceIdInput)

import * as actionTypes from "./types"

export const setAppInitState = (initialized) => ({
	type: actionTypes.APP_INITIALIZE,
	initialized: initialized,
})

export const setLayoutListFilter = (value) => ({
	type: actionTypes.SET_LAYOUT_LIST_FILTER,
	value,
})

export const setLayoutListSort = (sortBy, sortDirection) => ({
	type: actionTypes.SET_LAYOUT_LIST_SORT,
	sortBy,
	sortDirection,
})

export const setConfigLocations = (locations) => ({
	type: actionTypes.SET_CONFIG_LOCATIONS,
	locations,
})

export const setConfigTrans = (scale, xTrans, yTrans, markerRadius) => ({
	type: actionTypes.SET_CONFIG_TRANS,
	scale,
	xTrans,
	yTrans,
	markerRadius,
})

export const setConfigMasterArea = (areaEdits) => ({
	type: actionTypes.SET_CONFIG_MASTER_AREA,
	areaEdits,
})

export const resetConfigStepper = () => ({
	type: actionTypes.RESET_CONFIG_STEPPER,
})

export const setConfigMasterCoordinates = (coordParams) => ({
	type: actionTypes.SET_CONFIG_MASTER_COORDINATES,
	coordParams,
})

export const updateConfigMasterArea = (areaEdits) => ({
	type: actionTypes.UPDATE_CONFIG_MASTER_AREA,
	areaEdits,
})

export const setConfigAssignment = (assignment) => ({
	type: actionTypes.SET_CONFIG_ASSIGN,
	assignment,
})

export const setDrawerIsOpen = (isOpen) => ({
	type: actionTypes.TOGGLE_DRAWER_IS_OPEN,
	isOpen,
})

export const setSummaryIsOpen = (isOpen) => ({
	type: actionTypes.TOGGLE_SUMMARY_IS_OPEN,
	isOpen,
})

export const setZigbeeNetworkIsVisible = (isVisible) => ({
	type: actionTypes.TOGGLE_ZIGBEE_NETWORK_IS_VISIBLE,
	isVisible,
})

export const setDataTableIsOpen = (isOpen) => ({
	type: actionTypes.TOGGLE_DATA_TABLE_IS_OPEN,
	isOpen,
})

export const setWizardInfo = (info) => ({
	type: actionTypes.SET_WIZARD_INFO,
	info,
})

export const setTaskInfo = (info) => ({
	type: actionTypes.SET_TASK_INFO,
	info,
})

export const toggleNodeIds = (isVisible) => ({
	type: actionTypes.DISPLAY_NODEIDS,
	isVisible,
})

export const toggleNodeLocIds = (isVisible) => ({
	type: actionTypes.DISPLAY_NODE_LOCATION_IDS,
	isVisible,
})

export const setTimeLocale = (timeLocale) => ({
	type: actionTypes.SET_TIME_LOCALE,
	timeLocale,
})

export const setDataView = (dataView) => ({
	type: actionTypes.SET_DATA_VIEW,
	dataView,
})

export const setNodeDataStatus = (mLocId, status) => ({
	type: actionTypes.SET_NODE_DATA_STATUS,
	mLocId,
	status,
})

export const setMasterDataStatus = (status) => ({
	type: actionTypes.SET_MASTER_DATA_STATUS,
	status,
})

export const setLayoutZoomLevel = (zoomLevel) => ({
	type: actionTypes.SET_ZOOM_LEVEL,
	zoomLevel,
})

export const acceptNodeId = (mLocId, nLocId) => ({
	type: actionTypes.NODE_ID_ACCEPTED,
	mLocId,
	nLocId,
})

export const setMasterLabelVisible = (mLocId) => ({
	type: actionTypes.ADD_MASTER_LABEL,
	mLocId,
})

export const setMasterLabelNotVisible = (mLocId) => ({
	type: actionTypes.REMOVE_MASTER_LABEL,
	mLocId,
})

export const setAllMasterLabelsNotVisible = () => ({
	type: actionTypes.CLEAR_ALL_MASTER_LABELS,
})

export const setLayoutSecondarySelection = (layoutId) => ({
	type: actionTypes.ADD_LAYOUT_ID,
	layoutId,
})

export const clearLayoutSecondarySelection = (layoutId) => ({
	type: actionTypes.REMOVE_LAYOUT_ID,
	layoutId,
})

export const clearAllLayoutSecondarySelections = () => ({
	type: actionTypes.CLEAR_ALL_LAYOUT_IDS,
})

export const removeLayoutDetails = (layoutId) => ({
	type: actionTypes.REMOVE_LAYOUT_DETAILS,
	layoutId,
})

export const setCommissioningComponentSelection = (value) => ({
	type: actionTypes.SET_COMMISSIONING_COMPONENT_SELECTION,
	value,
})

export const setRememberComponentSelection = (value) => ({
	type: actionTypes.SET_REMEMBER_COMPONENT_SELECTION,
	value,
})

export const setNodeComponentScanned = (value) => ({
	type: actionTypes.SET_NODE_COMPONENTS_SCANNED,
	value,
})

export const setMaterComponentScanned = (value) => ({
	type: actionTypes.SET_MASTER_COMPONENTS_SCANNED,
	value,
})

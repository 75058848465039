import React from "react"
import Paper from "@material-ui/core/Paper"
import Table from "components/Table"

const SearchList = (props) => (
	<Paper style={{ height: "100%", width: "100%" }}>
		<Table {...props} />
	</Paper>
)

export default SearchList

import { connect } from "react-redux"
import NodeMarkersByMaster from "./NodeMarkersByMaster"
import { fetchNodeData } from "store/actions/fetchers"

const mapStateToProps = (state, props) => ({
	nodeDetails: state.nodeDetails[props.mLocId] || {},
	nIdsPendingUpload: state.nIdsPendingUpload,
	userPrivileges: state.user.privileges,
	deviceType: (state.masterDetails[props.mLocId] || {})["deviceType"],
})

const mapDispatchToProps = (dispatch, props) => ({
	fetchNodeData: (layoutId, mLocId, userPrivileges, deviceType, timestamp) =>
		dispatch(fetchNodeData(layoutId, mLocId, userPrivileges, deviceType, timestamp)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NodeMarkersByMaster)

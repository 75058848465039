import Amplify, { Hub } from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"
import API from "@aws-amplify/api"
import Storage from "@aws-amplify/storage"
import awsmobile from "aws-exports"

const config = {
	Auth: {
		identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
		region: process.env.REACT_APP_COGNITO_REGION,
		identityPoolRegion: process.env.REACT_APP_COGNITO_REGION,
		userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
		mandatorySignIn: true,
	},
	Storage: {
		level: "public",
		customPrefix: {
			public: "",
			protected: "",
			private: "",
		},
		AWSS3: {
			bucket: "gcs-monitor-resources",
			region: "us-east-1",
		},
	},
	...awsmobile,
	graphql_headers: async () => {
		return { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() }
	},
}
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

Amplify.configure(config)
API.configure(config)
Auth.configure()
Storage.configure(config.Storage)

const s3Config = config.Storage.AWSS3

// Auth.currentCredentials().then((info) => {
//   const cognitoIdentityId = info.data.IdentityId;
//   console.log(cognitoIdentityId)
// });

export { Auth as authRef, Hub as hubRef, s3Config }

export function refreshCognitoSession(cognitoUser, currentSession) {
	return new Promise((resolve, reject) => {
		cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
			if (err) {
				reject(err)
			} else {
				resolve(session)
			}
		})
	})
}

import React, { memo } from "react"
import PropTypes from "prop-types"
import NodePopup from "./NodePopup"
import { useEscapeDeselect } from "utils/custom-hooks"

const NodePopups = memo(({ layoutId, locIds }) => {
	useEscapeDeselect()

	if (!layoutId || locIds.length === 0) return null

	const nodePopups = []
	for (let i = 0; i < locIds.length; i++) {
		const { mLocId, nodeLocationIds, nav } = locIds[i]
		nodeLocationIds.forEach((nLocId) => {
			nodePopups.push(<NodePopup key={nLocId} nLocId={nLocId} mLocId={mLocId} layoutId={layoutId} nav={nav} />)
		})
	}
	return <>{nodePopups}</>
})

NodePopups.displayName = "NodePopups"

NodePopups.propTypes = {
	layoutId: PropTypes.string,
	locIds: PropTypes.array.isRequired,
}

export default NodePopups

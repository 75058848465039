import React, { Fragment } from "react"
import PropTypes from "prop-types"
import OverlaySelection from "./OverlaySelection"
import MasterSelection from "./MasterSelection"
import CompassButton from "./CompassButton"
import OverlaySummary from "./OverlaySummary"
import SummaryButton from "./SummaryButton"
import OverlayTable from "./OverlayTable"
import DeviceIdAssignmentOptionsButton from "./DeviceIdAssignmentOptionsButton"
import LayoutTimestamp from "./LayoutTimestamp"
import ButtonColumn from "./ButtonColumn"
import ClearNodeSelectionButton from "./ClearNodeSelectionButton"
import NodeLocationNavigator from "./NodeLocationNavigator"
import StatusKeys from "./StatusKeys"
import { overlays } from "constants/overlays"
import ToggleZigbeeNetworkMapButton from "./ToggleZigbeeNetworkMapButton"

const LayoutOverlay = ({ activeOverlay, dataView }) => {
	return (
		<Fragment>
			<OverlaySelection activeOverlay={activeOverlay} />
			<OverlaySummary />
			<OverlayTable />
			<ButtonColumn>
				<MasterSelection />
				<SummaryButton />
				{(activeOverlay === overlays.CHARGINGSTATUS || activeOverlay === overlays.DEVICEIDASSIGNMENT) && (
					<StatusKeys activeOverlay={activeOverlay} dataView={dataView} />
				)}
				{activeOverlay === overlays.DEVICEIDASSIGNMENT && <DeviceIdAssignmentOptionsButton />}
				<ClearNodeSelectionButton />
				<ToggleZigbeeNetworkMapButton />
			</ButtonColumn>
			<CompassButton />
			{activeOverlay === overlays.DEVICEIDASSIGNMENT && <NodeLocationNavigator activeOverlay={activeOverlay} />}
			{activeOverlay !== overlays.DEVICEIDASSIGNMENT && <LayoutTimestamp />}
		</Fragment>
	)
}

LayoutOverlay.propTypes = {
	activeOverlay: PropTypes.string,
	dataView: PropTypes.any,
}

export default LayoutOverlay

import { openDB } from "idb"

function createObjectStores(db) {
	if (!db.objectStoreNames.contains("userDetails")) {
		db.createObjectStore("userDetails", { keyPath: "id" })
	}
	if (!db.objectStoreNames.contains("layoutDetails")) {
		db.createObjectStore("layoutDetails", { keyPath: "uuid" })
	}
	if (!db.objectStoreNames.contains("masterDetails")) {
		const masterDetailsStore = db.createObjectStore("masterDetails", { keyPath: "id" })
		masterDetailsStore.createIndex("layoutId", "layoutId", { unique: false })
	}
	if (!db.objectStoreNames.contains("nodeDetails")) {
		const nodeDetailsStore = db.createObjectStore("nodeDetails", { keyPath: "mLocId" })
		nodeDetailsStore.createIndex("layoutId", "layoutId", { unique: false })
	}
	if (!db.objectStoreNames.contains("nodeIdChanges")) {
		db.createObjectStore("nodeIdChanges")
	}
	if (!db.objectStoreNames.contains("masterIdChanges")) {
		db.createObjectStore("masterIdChanges")
	}
	if (!db.objectStoreNames.contains("taskChanges")) {
		db.createObjectStore("taskChanges")
	}
	if (!db.objectStoreNames.contains("apiRequests")) {
		db.createObjectStore("apiRequests")
	}
}

const dbPromise = openDB("dbGcsMonitor", 6, {
	upgrade: async (db) => {
		createObjectStores(db)
	},
})

export default dbPromise

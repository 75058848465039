import { connect } from "react-redux"
import CoordinateUploader from "./CoordinateUploader"
import { compose } from "redux"
import { withSnackbar } from "notistack"
import { setConfigLocations } from "store/actions/reduxActions"
import { getLayoutMasterIds } from "utils"

const getLocationsByMaster = (state, props) => {
	const mLocIds = getLayoutMasterIds(state.layoutDetails, props.layoutId)
	if (mLocIds.length === 0) return []
	let locations = []
	mLocIds.forEach((mLocId) => {
		const nodeLocationIds = Object.keys(state.nodeDetails[mLocId] || {})
		for (let i = 0; i < nodeLocationIds.length; i++) {
			const nLocId = nodeLocationIds[i]
			locations.push({
				mLocId,
				nLocId,
				...((state.nodeDetails[mLocId] || {})[nLocId] || {}),
			})
		}
	})
	return locations
}

const mapStateToProps = (state, props) => ({
	locations: state.configLocations,
	locationsToImport: getLocationsByMaster(state, props),
})

const mapDispatchToProps = (dispatch) => ({
	setConfigLocations: (locations) => dispatch(setConfigLocations(locations)),
})

export default compose(connect(mapStateToProps, mapDispatchToProps), withSnackbar)(CoordinateUploader)

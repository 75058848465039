import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import DialogDevice from "./DialogDevice"

const getDisplayedDeviceID = (props) => {
	const params = new URLSearchParams(props.location.search)
	const deviceIds = params.get("device-details")
	if (!deviceIds) return []
	return deviceIds.split(":")
}

const handleClose = (props) => {
	const params = new URLSearchParams(props.location.search)
	params.delete("device-details")
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const handleOpenIdInput = (props) => {
	const params = new URLSearchParams(props.location.search)
	const deviceIds = params.get("device-details")
	if (!deviceIds) return null
	params.set("deviceid-input", deviceIds)
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const mapStateToProps = (state, props) => ({
	deviceIds: getDisplayedDeviceID(props),
})

const mapDispatchToProps = (dispatch, props) => ({
	handleClose: () => handleClose(props),
	handleOpenIdInput: () => handleOpenIdInput(props),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(DialogDevice)

import { withStyles } from "@material-ui/core/styles"
import React, { useCallback, useEffect, useState } from "react"
import Typography from "@material-ui/core/Typography"
import { TextField } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import PropTypes from "prop-types"

const styles = (_theme) => ({
	layoutNameTextField: {
		display: "block",
	},
	dangerButton: {
		backgroundColor: "#ff7171",
		display: "block",
		marginTop: "10px",
		padding: "10px",
	},
	deleteActionArea: {
		flexGrow: 1,
		minWidth: "230px",
	},
	deleteLayoutContainer: {
		display: "flex",
		alignItems: "center",
	},
	deleteActionDescription: {
		flexGrow: 0,
		paddingRight: "10px",
		boxSizing: "border-box",
	},
})

const DeleteLayout = (props) => {
	const { classes, layoutId, layoutDetails, jobsManager, enqueueSnackbar, close, removeLayout } = props
	const { name: layoutName } = layoutDetails
	const [isValid, setIsValid] = useState(false)

	// Instead of keeping a constantly changing layoutName state,
	// save renders by manually setting isValid state.
	const handleLayoutNameChange = useCallback(
		(e) => {
			const currentLayoutName = e.target.value
			if (currentLayoutName === layoutName && !isValid) {
				setIsValid(true)
			} else if (isValid) {
				setIsValid(false)
			}
		},
		[layoutName, isValid, setIsValid],
	)

	const unmounted = React.useRef(false)

	useEffect(() => {
		return () => {
			unmounted.current = true
		}
	}, [])

	const handlePostDelete = useCallback(async () => {
		enqueueSnackbar(`Layout ${layoutName} successfully deleted`, { variant: "success" })
		close()
	}, [close, enqueueSnackbar, layoutName])

	const handleDeleteLayout = useCallback(
		async (_e) => {
			if (!isValid) {
				alert("The layout names do not match.")
				return
			}

			if (confirm(`Are you absolutely sure you want to delete ${layoutName}? This action cannot be undone.`)) {
				jobsManager.reportJobStarted("delete-layout")

				try {
					// Unsubscribing prevents jobsManager from attempting to update the state of this component when unmounted
					jobsManager.unsubscribeAll()
					// Remove layout details from redux state (prevents it from appearing in search)
					removeLayout(layoutId)
						.then(() => {
							handlePostDelete()
						})
						.catch((error) => {
							if (error.status === 404) {
								console.warn(
									"Layout not found in API. It may have already been deleted or not imported yet. Pretending " +
										"like it was deleted successfully.",
								)
								handlePostDelete()
							} else {
								console.error("Could not remove layout from API:", error)
								enqueueSnackbar(
									"Could not delete layout. Please ensure you have the proper permissions, and that you are a staff user.",
									{
										variant: "error",
									},
								)
							}
						})
				} catch (err) {
					console.error("Could not delete layout:", err)
					enqueueSnackbar("Deleting layout failed", { variant: "error" })
				} finally {
					jobsManager.reportJobFinished("delete-layout")
				}
			}
		},
		[isValid, layoutName, jobsManager, removeLayout, layoutId, handlePostDelete, enqueueSnackbar],
	)

	return (
		<div className={classes.deleteLayoutContainer}>
			<Typography className={classes.deleteActionDescription} variant={"body1"}>
				In order to delete this layout, please type the name of the layout.
			</Typography>
			<div className={classes.deleteActionArea}>
				<TextField
					id="layoutNameConfirmation"
					label={layoutName}
					type="text"
					margin="dense"
					variant="outlined"
					onChange={handleLayoutNameChange}
					autoComplete={"off"}
				/>
				<Button
					onClick={handleDeleteLayout}
					className={classes.dangerButton}
					disabled={!isValid || !!jobsManager.getNbPendingJobs()}
				>
					Permanently delete
				</Button>
			</div>
		</div>
	)
}

DeleteLayout.propTypes = {
	classes: PropTypes.object,
	layoutId: PropTypes.string,
	layoutDetails: PropTypes.object,
	jobsManager: PropTypes.object.isRequired,
	close: PropTypes.func.isRequired,
	removeLayout: PropTypes.func.isRequired,
	enqueueSnackbar: PropTypes.func.isRequired,
}

export default withStyles(styles)(DeleteLayout)

import { connect } from "react-redux"
import { compose } from "redux"
import { withRouter } from "react-router"
import withMobileDialog from "@material-ui/core/withMobileDialog"
import Dialog from "./Dialog"

const getAssignMaster = (state, props, params) => {
	let step = params.get("step")
	if (step !== "master-areas" && step !== "master-coordinates") return false
	if (state.configAssign && state.configAssign["key"] === "master-area") {
		return "master-areas"
	} else if (state.configMasterCoordinates) {
		return "master-coordiantes"
	}
}

const mapStateToProps = (state, props) => {
	const { location } = props
	let urlParams = new URLSearchParams(location.search)
	return {
		assignMaster: getAssignMaster(state, props, urlParams),
		urlParams,
	}
}

const mapDispatchToProps = () => ({})

export default compose(
	withRouter,
	withMobileDialog({ breakpoint: "xs" }),
	connect(mapStateToProps, mapDispatchToProps),
)(Dialog)

import { connect } from "react-redux"
import EditMasterDetails from "./EditMasterDetails"
import { compose } from "redux"
import { withRouter } from "react-router"
import { withSnackbar } from "notistack"

import { createMasterDetails, updateMasterDetails } from "store/actions/setters"

const handleNewMasterRedirect = (props) => (newMasterLocationId) => {
	console.log(props)
	const params = new URLSearchParams(props.location.search)
	console.log(params)
	params.set("modify-master", `${props.layoutId}:${newMasterLocationId}`)
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const mapStateToProps = (state, props) => ({
	// currentDetails: state.masterDetails[props.mLocId],
	// isNewMaster: (
	//   !state.layoutDetails[props.layoutId].mLocIds ||
	//   !state.layoutDetails[props.layoutId].mLocIds.includes(props.mLocId)
	// )
	masterDetails: state.masterDetails[props.mLocId] || {},
})

const mapDispatchToProps = (dispatch, props) => ({
	handleNewMasterRedirect: (newMasterLocationId) => handleNewMasterRedirect(props)(newMasterLocationId),
	// updateMasterDetails: (details) => dispatch(updateMasterDetails(props.layoutId, props.mLocId, details)),
	// updateLayoutMasterList: (type, mLocId) => dispatch(updateLayoutMasterList(props.layoutId, type, mLocId)),
	createMasterDetails: (layoutId, name, masterDetails) => dispatch(createMasterDetails(layoutId, name, masterDetails)),
	updateMasterDetails: (mLocId, layoutId, masterDetails) =>
		dispatch(updateMasterDetails(mLocId, layoutId, masterDetails)),
})

export default compose(withSnackbar, withRouter, connect(mapStateToProps, mapDispatchToProps))(EditMasterDetails)

import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import DivIcon from "components/DivIcon"

const useStyles = makeStyles({
	content: {
		color: "white",
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		transform: "translate(0px, -10px) rotate(-45deg)",
		transformOrigin: "left",
		width: "40px",
		textAlign: "center",
		margin: 0,
		borderRadius: "7px",
	},
})

const NodeIdLabel = ({ position, label }) => {
	const classes = useStyles()

	if (position.length !== 2) return null

	return (
		<DivIcon position={position}>
			<p className={classes.content}>{label}</p>
		</DivIcon>
	)
}

NodeIdLabel.propTypes = {
	position: PropTypes.array.isRequired,
}

export default NodeIdLabel

import React, { Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
	header: {
		padding: theme.spacing(2),
		paddingBottom: theme.spacing(1),
		display: "flex",
	},
	headerTitle: {
		flex: 1,
	},
	headerChip: {
		marginRight: 30,
	},
	closeIcon: {
		position: "absolute",
		top: theme.spacing(1),
		right: theme.spacing(1),
		cursor: "pointer",
		zIndex: 2000,
	},
}))

const OverlaySummaryHeader = ({ setSummaryIsOpen }) => {
	const classes = useStyles()
	const closeSummary = () => {
		setSummaryIsOpen(false)
	}

	return (
		<Fragment>
			<div className={classes.header}>
				<Typography variant="h6" className={classes.headerTitle}>
					Summary
				</Typography>
				<CloseIcon className={classes.closeIcon} onClick={closeSummary} />
			</div>
		</Fragment>
	)
}

OverlaySummaryHeader.propTypes = {
	setSummaryIsOpen: PropTypes.func.isRequired,
}
export default OverlaySummaryHeader

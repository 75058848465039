import React, { useState, useEffect } from "react"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"

const CommandQueue = ({ commandQueue, taskInfo, setTaskInfo, command }) => {
	const [value, setValue] = useState("")

	useEffect(() => {
		if (taskInfo[command] && !commandQueue.includes(command)) {
			setValue("yes")
		} else if (taskInfo[command] && commandQueue.includes(command)) setValue("no")
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleChange = (event) => {
		const { value } = event.target
		setValue(value)

		if (value) {
			setTaskInfo({ [command]: "answered" })
		}

		if (value === "no" && !commandQueue.includes(command)) {
			setTaskInfo({ commandQueue: [...commandQueue, command] })
		} else if (value === "yes" && commandQueue.includes(command)) {
			let newQueue = commandQueue.filter((name) => name !== command)
			setTaskInfo({ commandQueue: newQueue })
		}
	}

	return (
		<FormControl component="fieldset">
			<RadioGroup aria-label="Yes Or No" name="yesOrNo" value={value} onChange={handleChange}>
				<FormControlLabel value="yes" color="primary" control={<Radio />} label="Yes" />
				<FormControlLabel value="no" control={<Radio />} label="No" />
			</RadioGroup>
		</FormControl>
	)
}

export default CommandQueue

import { connect } from "react-redux"
import RegisterUser from "./RegisterUser"

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	registerUser: (email, password) => {},
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUser)

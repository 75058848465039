import TextField from "@material-ui/core/TextField"

const seconds = "Seconds"
const millivolts = "mV"
const degreesTimesHundred = "Degrees x100"
const celsiusTimesHundred = "°C x100"
// NOTE: readOnly means that the parameter cannot be set through the "node parameters" feature, but can be read from the
//  master. This means that we can still read the value of parameters set through commands.
export const nodeParametersList = [
	{
		elementType: TextField,
		id: "lowBatteryLimit",
		label: "Low Battery Limit",
		unit: millivolts,
		valueType: "eInt",
		props: {
			type: "number",
		},
		// TODO: Validators
	},
	{
		elementType: TextField,
		id: "criticalBatteryLimit",
		label: "Critical Battery Limit",
		unit: millivolts,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "overvoltageThreshold",
		label: "Overvoltage Threshold",
		unit: millivolts,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "highCurrentLimit",
		label: "High Current Limit",
		unit: millivolts,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "lowBatteryStow",
		label: "Low Battery Stow",
		unit: "Degrees",
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "numberofAngles",
		label: "Number of Angles",
		unit: "",
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "motorOnTime",
		label: "Motor On-Time",
		unit: seconds,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "motorOffTime",
		label: "Motor Off-Time",
		unit: seconds,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "motorOnTimeStow",
		label: "Motor On-Time Stow",
		unit: seconds,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "motorOffTimeStow",
		label: "Motor Off-Time Stow",
		unit: seconds,
		valueType: "eFloat",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "angleTolerance",
		label: "Angle Tolerance",
		unit: degreesTimesHundred,
		valueType: "eFloat",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "startTolerance",
		label: "Start Tolerance",
		unit: degreesTimesHundred,
		valueType: "eFloat",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "startToleranceAntishade",
		label: "Start Tolerance Antishade",
		unit: degreesTimesHundred,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "motionTolerance",
		label: "Motion Tolerance",
		unit: degreesTimesHundred,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "noMotionTime",
		label: "No Motion Time",
		unit: seconds,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "directionTolerance",
		label: "Direction Tolerance",
		unit: degreesTimesHundred,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "hardwareCurrentLimit",
		label: "Hardware Current Limit",
		unit: "A",
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "otaTimeout",
		label: "OTA Timeout",
		unit: seconds,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "hotTempHiLimit",
		label: "Hot Temperature High Limit",
		unit: celsiusTimesHundred,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "hotTempLowLimit",
		label: "Hot Temperature Low Limit",
		unit: celsiusTimesHundred,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "coldTempHiLimit",
		label: "Cold Temperature High Limit",
		unit: celsiusTimesHundred,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "coldTempLowLimit",
		label: "Cold Temperature Low Limit",
		unit: celsiusTimesHundred,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "motionFailures",
		label: "Motion Failures",
		unit: "Number of Failures",
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "motorMinSpeed",
		label: "Motor Minimum Speed",
		unit: "% x10",
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "rampOnTime",
		label: "Ramp On-Time",
		unit: seconds,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "rampDownPropCons",
		label: "Ramp Down Prop Cons", // TODO: What?
		unit: "",
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "overcurrentTries",
		label: "Overcurrent Tries",
		unit: "Number of Tries",
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "overcurrentTime",
		label: "Overcurrent Time",
		unit: seconds,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "arrrStatusIntervalMinute",
		label: "arrrStatusIntervalMinute", // TODO: What?
		unit: "Minutes",
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "directionTime",
		label: "Direction Time", // TODO: What?
		unit: seconds,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "waitTimeMotorOff",
		label: "Wait Time Motor Off",
		unit: seconds,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "waitTimeChargerOff",
		label: "Wait Time Charger Off",
		unit: seconds,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "limitFailures",
		label: "Limit Failures",
		unit: "Number of Failures",
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "limitTolerance",
		label: "Limit Tolerance",
		unit: degreesTimesHundred,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "directionFailures",
		label: "Direction Failures",
		unit: "Number of Failures",
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "estopDisableTime",
		label: "estop Disable Time", // TODO: What is estop?
		unit: seconds,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "lowBattThresholdDuringMotion",
		label: "Low Battery Threshold During Motion",
		unit: "V",
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "nodeBattSocStowEnable",
		label: "Enable Battery State-of-Charge (SOC) Stow",
		unit: "",
		valueType: "eBool",
		props: {
			type: "boolean",
		},
	},
	{
		elementType: TextField,
		id: "nodeSocBattVoltOffset",
		label: "SOC Battery Voltage Offset",
		unit: millivolts,
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		elementType: TextField,
		id: "nodeBattSocStowTempHysteresis",
		label: "Battery SOC Stow Temperature Hysteresis",
		unit: "°C",
		valueType: "eInt",
		props: {
			type: "number",
		},
	},
	{
		id: "mechLimitWest",
		readOnly: true,
		label: "West Mechanical Limit",
		unit: "°",
	},
	{
		id: "mechLimitEast",
		readOnly: true,
		label: "East Mechanical Limit",
		unit: "°",
	},
	{
		id: "strongestAngle",
		readOnly: true,
		label: "Strongest Angle",
		unit: "°",
	},
]
export const nodeParameters = {}

for (const param of nodeParametersList) {
	nodeParameters[param.id] = param
}

import { connect } from "react-redux"
import { compose } from "redux"
import MastersSummary from "./MastersSummary"
import {
	gameChangeMasterAlarmEnums,
	getBitIndex,
	getMasterAlarmModesList,
	getMasterFlagModesList,
} from "constants/statusBits"
import { overlays } from "constants/overlays"
import { getMask, getMasterLocIds } from "utils/url"
import { withRouter } from "react-router-dom"

export const getDeviceType = (state, mLocId) => {
	return (state.masterDetails[mLocId] || {}).deviceType
}

export const getUrlTimestamp = (urlSearch = "") => {
	const params = new URLSearchParams(urlSearch)
	return parseInt(params.get("ts"), 10) || undefined
}

export const getUrlMask = (urlSearch = "") => {
	const params = new URLSearchParams(urlSearch)
	let mask = params.get("filter")
	if (!mask) return 0n
	return BigInt(`0b${mask}`)
}

// TODO: Deduplicate with NodeSummary/index.js:updateMasks
export const updateMasks = (urlSearch = "", eventCtrlKey, maskBit) => {
	const params = new URLSearchParams(urlSearch)
	let newMask

	if (eventCtrlKey) {
		// If the control key is pressed, we want to toggle the bit, leaving other bits untouched.
		newMask = getMask(urlSearch) // Set to the current mask
		// Toggle the bit
		newMask ^= 1n << BigInt(maskBit)
	} else {
		// Not toggling, but selecting a specific bit. We want to set only that bit to 1 and all other bits to 0.
		// Completely overwrite the new mask without regard for the existing mask:
		newMask = 1n << BigInt(maskBit)
	}

	if (newMask === 0n) {
		params.delete("filter")
	} else {
		params.set("filter", newMask.toString(2))
	}

	return `?${params.toString()}`
}

const handleFilterChange = (props) => (e, filterBit) => {
	e.persist()
	const { search, pathname } = props.location || {}
	const newSearch = updateMasks(search, e.ctrlKey || e.metaKey, filterBit)

	props.history.push({
		pathname,
		search: newSearch,
	})
}

const getActiveOverlay = (search) => {
	const params = new URLSearchParams(search)
	return params.get("overlay")
}

const getSummaryHeading = (activeOverlay) => {
	switch (activeOverlay) {
		case overlays.ALARMS:
			return ["Master Alarms", "Count"]
		case overlays.FLAGS:
			return ["Master Status Flags ", "Count"]
		default:
			return null
	}
}

const getAlarmsSummary = (state, props, activeMasters, allMasters) => {
	let summaryData = []
	let alarmCounts = {}
	let activeAlarmModes = 0n

	const nbMasterAlarms = Object.keys(gameChangeMasterAlarmEnums).length
	for (let x = 0; x < nbMasterAlarms; x++) {
		alarmCounts[1n << BigInt(x)] = 0
	}

	const userPrivileges = state.user.privileges
	let deviceType
	const masters = activeMasters.length === 0 ? allMasters : activeMasters

	masters.forEach((mLocId) => {
		deviceType = getDeviceType(state, mLocId)
		const urlSearch = (props.location || {}).search
		const timestamp = getUrlTimestamp(urlSearch) || state.masterDataLatestTimestamp[mLocId]
		const masterData = state.masterData[`${mLocId}#${timestamp}`] || {}
		let { alarms } = masterData
		let masterAlarms = alarms ? BigInt(`0x${alarms}`) : 0n

		if (masterAlarms) {
			for (let x = 0; x < 32; x++) {
				const mask = 1n << BigInt(x)
				if ((mask & masterAlarms) !== 0n) {
					alarmCounts[BigInt(1) << BigInt(x)]++
				}
			}
		}

		for (let x = 0; x < nbMasterAlarms; x++) {
			const mask = 1n << BigInt(x)
			if (alarmCounts[mask]) {
				activeAlarmModes |= mask
			}
		}
	})

	const masterAlarmModesList = getMasterAlarmModesList(userPrivileges, deviceType)

	for (let i = 0; i < masterAlarmModesList.length; i++) {
		if ((activeAlarmModes & masterAlarmModesList[i].bit) !== 0n) {
			summaryData.push({
				action: getBitIndex(masterAlarmModesList[i].bit),
				description: masterAlarmModesList[i].description,
				value: alarmCounts[masterAlarmModesList[i].bit],
			})
		}
	}

	return {
		summaryData,
	}
}

const getFlagsSummary = (state, props, activeMasters, allMasters) => {
	let summaryData = []

	let flagCounts = {}
	let activeFlagModes = 0n
	for (let x = 0; x < 32; x++) {
		flagCounts[1n << BigInt(x)] = 0
	}

	const userPrivileges = state.user.privileges
	let deviceType
	const masters = activeMasters.length === 0 ? allMasters : activeMasters

	masters.forEach((mLocId) => {
		deviceType = getDeviceType(state, mLocId)
		const urlSearch = (props.location || {}).search
		const timestamp = getUrlTimestamp(urlSearch) || state.masterDataLatestTimestamp[mLocId]
		const masterData = state.masterData[`${mLocId}#${timestamp}`] || {}
		let { flags } = masterData
		let masterFlags = flags ? BigInt(`0x${flags}`) : 0n

		if (masterFlags) {
			for (let x = 0; x < 32; x++) {
				const mask = 1n << BigInt(x)
				if ((mask & masterFlags) !== 0n) {
					flagCounts[mask]++
				}
			}
		}

		for (let x = 0; x < 32; x++) {
			const mask = 1n << BigInt(x)
			if (flagCounts[mask]) {
				activeFlagModes |= mask
			}
		}
	})

	const masterFlagModesList = getMasterFlagModesList(userPrivileges, deviceType)
	for (let i = 0; i < masterFlagModesList.length; i++) {
		if ((activeFlagModes & masterFlagModesList[i].bit) !== 0n) {
			summaryData.push({
				action: getBitIndex(masterFlagModesList[i].bit),
				description: masterFlagModesList[i].description,
				value: flagCounts[masterFlagModesList[i].bit],
			})
		}
	}

	return {
		summaryData,
	}
}

const mapStateToProps = (state, props) => {
	let summaryData = null
	let summaryHeading

	const activeOverlay = getActiveOverlay(props.location.search)
	let activeMasters = getMasterLocIds(props.location.search)
	const layoutDetails = state.layoutDetails[props.match.params.layoutId]
	if (!layoutDetails) {
		console.warn("Layout not loaded yet")
	}
	const allMasters = layoutDetails ? layoutDetails["mLocIds"] || [] : []

	summaryHeading = getSummaryHeading(activeOverlay)

	switch (activeOverlay) {
		case overlays.ALARMS:
			summaryData = getAlarmsSummary(state, props, activeMasters, allMasters).summaryData
			break
		case overlays.FLAGS:
			summaryData = getFlagsSummary(state, props, activeMasters, allMasters).summaryData
			break
		default:
			break
	}

	return {
		summaryHeading,
		summaryData,
		summaryIsOpen: state.summaryIsOpen,
		visibilityMask: getUrlMask((props.location || {}).search),
	}
}

const mapDispatchToProps = (dispatch, props) => ({
	handleFilterChange: handleFilterChange(props),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MastersSummary)

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeUserUpdates = /* GraphQL */ `
  subscription SubscribeUserUpdates($username: ID!) {
    subscribeUserUpdates(username: $username) {
      username
      userCreateDate
      userLastModifiedDate
      enabled
      userStatus
      nameLast
      nameFirst
      email
      company
      companyRole
      allLayouts
      expiration
      __typename
    }
  }
`;
export const subscribeCommandFeedback = /* GraphQL */ `
  subscription SubscribeCommandFeedback($mId: ID!, $name: String) {
    subscribeCommandFeedback(mId: $mId, name: $name) {
      mId
      name
      status
      message
      __typename
    }
  }
`;
export const subscribeLayoutDetails = /* GraphQL */ `
  subscription SubscribeLayoutDetails {
    subscribeLayoutDetails {
      address1
      address2
      city
      coordinates {
        latitude
        longitude
        __typename
      }
      deltaAction
      developerName
      id
      installerName
      labelZoomThreshold
      layoutUrl
      mLocIds
      markerRadius
      name
      nameAlternate
      customerName
      oAndMName
      ownerName
      powerCapacityDc
      published
      scadaName
      state
      utcOffset
      zip
      demo
      layoutNumber
      tz
      weathersmartEnabled
      __typename
    }
  }
`;
export const subscribeMasterLocation = /* GraphQL */ `
  subscription SubscribeMasterLocation {
    subscribeMasterLocation {
      area
      channel
      deltaAction
      deviceType
      id
      layoutId
      mId
      name
      label
      panid
      spaParamId
      nodeParamId
      xLoc
      yLoc
      localIp
      __typename
    }
  }
`;
export const subscribeNodeIdUpdate = /* GraphQL */ `
  subscription SubscribeNodeIdUpdate($layoutId: ID!) {
    subscribeNodeIdUpdate(layoutId: $layoutId) {
      layoutId
      mLocId
      nLocId
      nId
      timestamp
      serverTimestamp
      userId
      username
      mId
      masterName
      layoutName
      deltaAction
      __typename
    }
  }
`;
export const subscribeNodeSyncBitmasksUpdate = /* GraphQL */ `
  subscription SubscribeNodeSyncBitmasksUpdate($layoutId: ID!) {
    subscribeNodeSyncBitmasksUpdate(layoutId: $layoutId) {
      layoutId
      mLocId
      bitmasks
      fullSyncTimestamp
      __typename
    }
  }
`;
export const subscribeMasterIdUpdate = /* GraphQL */ `
  subscription SubscribeMasterIdUpdate($layoutId: ID!) {
    subscribeMasterIdUpdate(layoutId: $layoutId) {
      layoutId
      mLocId
      mId
      timestamp
      serverTimestamp
      userId
      username
      masterName
      layoutName
      deltaAction
      __typename
    }
  }
`;
export const subscribeReplacementCampaignUpdate = /* GraphQL */ `
  subscription SubscribeReplacementCampaignUpdate($layoutId: ID!) {
    subscribeReplacementCampaignUpdate(layoutId: $layoutId) {
      layoutId
      mLocId
      nLocId
      nId
      __typename
    }
  }
`;

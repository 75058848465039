import React from "react"
import PropTypes from "prop-types"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Fab from "@material-ui/core/Fab"
import ListIcon from "@material-ui/icons/List"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

const SummaryButton = ({ setSummaryIsOpen, summaryIsOpen, setDataTableIsOpen, dataTableIsOpen }) => {
	const theme = useTheme()
	const smallScreen = useMediaQuery(theme.breakpoints.down("xs"))
	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const summaryClick = () => {
		setDataTableIsOpen(false)
		setSummaryIsOpen(!(smallScreen ? summaryIsOpen.small : summaryIsOpen.large))
		setAnchorEl(null)
	}

	const tableClick = () => {
		setSummaryIsOpen(false)
		setDataTableIsOpen(!(smallScreen ? dataTableIsOpen.small : dataTableIsOpen.large))
		setAnchorEl(null)
	}

	return (
		<div>
			<Fab color="primary" size="small" aria-label="Toggle Summary" onClick={handleClick}>
				<ListIcon />
			</Fab>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<MenuItem selected={summaryIsOpen.small || summaryIsOpen.large} onClick={summaryClick}>
					Summary
				</MenuItem>
				<MenuItem selected={dataTableIsOpen.small || dataTableIsOpen.large} onClick={tableClick}>
					Data Table
				</MenuItem>
			</Menu>
		</div>
	)
}

SummaryButton.propTypes = {
	setSummaryIsOpen: PropTypes.func.isRequired,
}

export default SummaryButton

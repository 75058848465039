import React, { Fragment, useState } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import clsx from "clsx"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import Fade from "@material-ui/core/Fade"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import SuggestionsList from "./SuggestionsList"

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Fade ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
	content: {
		display: "flex",
		flexDirection: "column",
		marginTop: theme.spacing(1),
	},
	actions: {
		justifyContent: "space-between",
	},
	actionButton: {
		margin: theme.spacing(1),
		flex: 1,
	},
	fabClose: {
		position: "fixed",
		bottom: 0,
		right: 0,
		margin: theme.spacing(2),
		zIndex: 500,
	},
	fabText: {
		position: "fixed",
		bottom: 0,
		right: 40 + 24,
		margin: theme.spacing(2),
		zIndex: 500,
	},
	suggestionPaper: {
		border: "1px solid #d3d4d5",
	},
	toggle: {
		marginLeft: theme.spacing(1),
	},
}))

const DeviceIdSearch = ({
	handleClose,
	handleSearch,
	activeLocationIds,
	navLocId,
	navActive,
	handleNavToggle,
}) => {
	const [selectedNavItem, setSelectedNavItem] = useState(navLocId)
	const [inputNavValue, setInputNavValue] = useState("")
	const [selectedItems, setSelectedItem] = useState(activeLocationIds)
	const [inputValue, setInputValue] = useState("")
	const classes = useStyles()

	return (
		<Fragment>
			<form
				action="#"
				onSubmit={(e) => {
					e.preventDefault()
					handleSearch(selectedItems, selectedNavItem[0])
				}}
			>
				<DialogContent id="device-id-input-description" className={classes.content}>
					{Boolean(navActive || selectedNavItem.length) && (
						<SuggestionsList
							selectedItems={selectedNavItem}
							setSelectedItem={setSelectedNavItem}
							inputValue={inputNavValue}
							setInputValue={setInputNavValue}
							nav={true}
						/>
					)}
					<SuggestionsList
						selectedItems={selectedItems}
						setSelectedItem={setSelectedItem}
						inputValue={inputValue}
						setInputValue={setInputValue}
						nav={false}
					/>
					<FormControlLabel
						className={classes.toggle}
						control={
							<Switch
								checked={Boolean(navActive || selectedNavItem.length)}
								onChange={(e) => {
									if (!e.target.checked) {
										setSelectedNavItem([])
										setInputNavValue("")
									}
									handleNavToggle(e.target.checked)
								}}
								value="navActive"
								color="primary"
							/>
						}
						label="Find relative location"
					/>
				</DialogContent>
				<DialogActions className={classes.actions}>
					<Button onClick={handleClose} className={classes.actionButton}>
						Close
					</Button>
					<Button
						variant="contained"
						color="primary"
						disabled={selectedNavItem.length === 0 && selectedItems.length === 0}
						className={classes.actionButton}
						type="submit"
						value="Search"
					>
						Search
					</Button>
				</DialogActions>
			</form>
		</Fragment>
	)
}

DeviceIdSearch.propTypes = {
	handleClose: PropTypes.func.isRequired,
}

const useStylesDialog = makeStyles((theme) => ({
	dialogPaper: {
		margin: theme.spacing(1),
	},
	dialogWidth: {
		width: "calc(100% - 16px)",
	},
}))

const DialogDeviceIdSearch = ({ isOpen, handleClose, ...otherProps }) => {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
	const classes = useStylesDialog()

	return (
		<Dialog
			open={isOpen}
			TransitionComponent={Transition}
			keepMounted={false}
			onClose={handleClose}
			aria-labelledby="device-id-input-title"
			aria-describedby="device-id-input-description"
			fullWidth={true}
			fullScreen={isMobile}
			classes={{
				paper: clsx({ [classes.dialogPaper]: !isMobile }),
				paperFullWidth: clsx({ [classes.dialogWidth]: !isMobile }),
			}}
		>
			{isOpen && <DeviceIdSearch handleClose={handleClose} {...otherProps} />}
		</Dialog>
	)
}

export default DialogDeviceIdSearch

import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { Map, TileLayer, AttributionControl } from "react-leaflet"
import "leaflet/dist/leaflet.css"
import terminator from "@joergdietrich/leaflet.terminator"

import LayoutMarkers from "./LayoutMarkers"
import LayoutPopups from "./LayoutPopups"

const styles = {
	map: {
		height: "100%",
		width: "100%",
	},
}

const LayoutMap = ({ classes, layoutIds }) => {
	let mapRef = useRef()
	let termRef = useRef(terminator())

	const updateTerminator = () => {
		let tRef = termRef.current
		if (tRef) {
			const t2 = terminator()
			tRef.setLatLngs(t2.getLatLngs())
			tRef.redraw()
		}
	}

	useEffect(() => {
		let mRef = mapRef.current
		let tRef = termRef.current
		if (mRef && tRef) {
			tRef.addTo(mRef.leafletElement)
			const timer = setInterval(updateTerminator, 5000)
			return () => {
				clearInterval(timer)
				if (mRef) {
					tRef.remove(mRef.leafletElement)
				}
			}
		}
	}, [])

	return (
		<Map
			center={[35, -98.5795]}
			maxBounds={[
				[-90, -Infinity],
				[90, Infinity],
			]}
			maxBoundsViscosity={1.0}
			zoom={4.5}
			maxZoom={11}
			className={classes.map}
			zoomControl={false}
			doubleClickZoom={true}
			zoomSnap={0.1}
			minZoom={3}
			attributionControl={false}
			ref={mapRef}
		>
			<TileLayer
				url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
				attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ"
			/>
			<AttributionControl position="bottomright" prefix="" />
			<LayoutMarkers />
			<LayoutPopups />
		</Map>
	)
}

LayoutMap.propTypes = {
	classes: PropTypes.object.isRequired,
	layoutIds: PropTypes.array.isRequired,
}

export default withStyles(styles)(LayoutMap)

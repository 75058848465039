import { connect } from "react-redux"
import LayoutPopup from "./LayoutPopup"

const getCoord = (type, layoutDetails) => {
	if (!layoutDetails || !layoutDetails["coordinates"]) return null
	return layoutDetails["coordinates"][type]
}

const mapStateToProps = (state, props) => ({
	latitude: getCoord("latitude", state.layoutDetails[props.layoutId]),
	longitude: getCoord("longitude", state.layoutDetails[props.layoutId]),
	name: (state.layoutDetails[props.layoutId] || {})["name"],
})

const mapDispatchToProps = (dispatch, props) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPopup)

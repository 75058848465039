import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { compose } from "redux"
import { setSummaryIsOpen } from "store/actions/reduxActions"
import OverlaySummary from "./OverlaySummary"

const mapStateToProps = (state) => ({
	summaryIsOpen: state.summaryIsOpen,
	dataView: state.dataView,
	nodeDetails: state.nodeDetails,
	nIdsPendingUpload: state.nIdsPendingUpload,
})

const mapDispatchToProps = (dispatch) => ({
	setSummaryIsOpen(isOpen) {
		return dispatch(setSummaryIsOpen(isOpen))
	},
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(OverlaySummary)

import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"

const styles = (theme) => ({
	root: {
		flex: 1,
		zIndex: 1000,
		display: "flex",
		justifyContent: "flex-start",
		flexDirection: "column",
		margin: theme.spacing(2),
	},
})

const LayoutOverlay = ({
	classes,
	locationsLimited,
	displayAllNodes,
	setDisplayAllNodes,
	layoutZoomLevel,
	labelZoomThreshold,
	setLabelVisibilityThreshold,
	activeStepKey,
}) => {
	return (
		<div className={classes.root}>
			{!locationsLimited ? null : (
				<FormControlLabel
					control={
						<Switch
							checked={displayAllNodes}
							onChange={(e) => setDisplayAllNodes(e.target.checked)}
							value="displayAllNodes"
							color="primary"
						/>
					}
					label="Display All Node Markers"
				/>
			)}
			{activeStepKey === "master-areas" ? (
				<FormControlLabel
					control={
						<Switch
							checked={Boolean(labelZoomThreshold)}
							onChange={(e) => {
								console.warn(e.target.checked)
								if (e.target.checked) {
									setLabelVisibilityThreshold(layoutZoomLevel)
								} else {
									setLabelVisibilityThreshold(null)
								}
							}}
							value={Boolean(labelZoomThreshold)}
							color="primary"
						/>
					}
					label={`Label Visibility Threshold: ${
						labelZoomThreshold ? parseFloat(labelZoomThreshold).toFixed(1) : parseFloat(layoutZoomLevel).toFixed(1)
					}`}
				/>
			) : null}
		</div>
	)
}

LayoutOverlay.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LayoutOverlay)

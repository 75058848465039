import React, { useEffect, useState, useCallback } from "react"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		width: "100%",
		height: "100%",
		textAlign: "center",
		zIndex: 500,
		padding: 5,
		boxSizing: "border-box",
		"-webkit-animation": "fadein .25s",
		"-moz-animation": "fadein .25s",
		"-ms-animation": "fadein .25s",
		"-o-animation": "fadein .25s",
		animation: "fadein .25s",
	},
	dial: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		height: "100%",
		width: "100%",
	},
	pointer: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		height: "100%",
		width: "100%",
	},
	viewcone: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		height: "100%",
		width: "100%",
		zIndex: -1,
		"-webkit-animation": "fadein .25s",
		"-moz-animation": "fadein .25s",
		"-ms-animation": "fadein .25s",
		"-o-animation": "fadein .25s",
		animation: "fadein .25s",
	},
}))

const Compass = () => {
	const classes = useStyles()
	const [heading, setHeading] = useState(null)

	const headingCallback = useCallback((e) => {
		if (e.webkitCompassHeading !== undefined) {
			setHeading(parseFloat(e.webkitCompassHeading))
		} else if (e.alpha !== null) {
			setHeading(parseFloat(e.alpha))
		}
	}, [])

	useEffect(() => {
		if (window.DeviceOrientationEvent) {
			window.addEventListener("deviceorientation", headingCallback, true)
			return () => window.removeEventListener("deviceorientation", headingCallback)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={classes.root}>
			<svg className={classes.dial} viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg">
				<circle cx="65" cy="65" r="56" stroke="white" strokeWidth="1" fill="none" />
				<polyline points="63,10  67,10  65,14" fill="#b60000" />
				<polyline points="120,63  120,67  117,65" fill="white" />
				<polyline points="63,120  67,120  65,117" fill="white" />
				<polyline points="10,63  10,67  13,65" fill="white" />
				<text x="65" y="7.75" fontSize="10" textAnchor="middle" fill="#b60000">
					N
				</text>
				<text x="127" y="67" fontSize="6" textAnchor="middle" fill="white">
					E
				</text>
				<text x="65" y="129" fontSize="6" textAnchor="middle" fill="white">
					S
				</text>
				<text x="2.8" y="67" fontSize="6" textAnchor="middle" fill="white">
					W
				</text>
			</svg>
			<svg className={classes.pointer} viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg">
				<polyline points="60,60  70,60  65,15" fill="#b60000" />
				<polyline points="60,70  70,70  65,115" fill="white" />
				<circle cx="65" cy="65" r="7" stroke="#b60000" strokeWidth="7" fill="none" />
			</svg>
			{heading !== null && (
				<svg
					className={classes.viewcone}
					viewBox="0 0 130 130"
					version="1.1"
					style={{ transform: `rotate(${heading - 39}deg)` }}
					xmlns="http://www.w3.org/2000/svg"
				>
					<defs>
						<linearGradient id="grad1" gradientTransform="rotate(110)">
							<stop offset="5%" style={{ stopColor: "rgb(0,102,204)", stopOpacity: 0 }} />
							<stop offset="100%" style={{ stopColor: "rgb(0,102,204)", stopOpacity: 0.7 }} />
						</linearGradient>
					</defs>
					<path d="M61,74 L65,9, A65,65 0 0,1 109,31 z" fill="url(#grad1)" />
				</svg>
			)}
		</div>
	)
}

export default Compass

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import NodeLabels from "./NodeLabels"
import { getMasterLocIds } from "utils/url"

export const getActiveOverlay = (urlSearch = "") => {
	const params = new URLSearchParams(urlSearch)
	const overlay = params.get("overlay")
	return overlay || undefined
}

const mapStateToProps = (state, props) => ({
	mLocIds: getMasterLocIds(props.location.search),
	activeOverlay: getActiveOverlay(props.location.search),
})

const mapDispatchToProps = (_dispatch) => ({})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(NodeLabels)

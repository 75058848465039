import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import EditLayout from "components/LayoutManager/EditLayout"
import SelectMasterArea from "./SelectMasterArea"
import SelectMasterCoordinates from "./SelectMasterCoordinates"
import EditMasterDetails from "components/EditMasterDetails"
import EditSpaParameters from "components/DialogDevice/MasterDetails/EditSpaParameters"

const styles = (theme) => ({
	dialogPaperSmall: {
		minHeight: "80vh",
		maxHeight: "80vh",
		display: "flex",
		height: "100%",
	},
	dialogPaperLarge: {
		height: "100%",
	},
})

const GlobalDialog = ({ classes, history, location, urlParams, assignMaster, fullScreen }) => {
	const handleClose = (searchParams) => () => {
		let params = new URLSearchParams(location.search)
		searchParams.forEach((param) => params.delete(param))
		let queryParams = "?" + params.toString()
		history.push({
			...location,
			search: queryParams,
		})
	}

	let content = null
	let thisHandleClose = null

	const modifyLayout = urlParams.get("modify-layout")
	if (modifyLayout) {
		thisHandleClose = handleClose(["modify-layout", "tab"])
		content = <EditLayout key={modifyLayout} handleClose={thisHandleClose} layoutId={modifyLayout} />
	}

	const modifyMaster = urlParams.get("modify-master")
	if (modifyMaster) {
		const values = modifyMaster.split(":")
		if (values.length === 2) {
			thisHandleClose = handleClose(["modify-master"])
			content = (
				<EditMasterDetails key={modifyMaster} handleClose={thisHandleClose} layoutId={values[0]} mLocId={values[1]} />
			)
		}
	}

	const modifySpaParameters = urlParams.get("modify-spa-parameters")
	if (modifySpaParameters) {
		const values = modifySpaParameters.split(":")
		if (values.length === 3) {
			thisHandleClose = handleClose(["modify-spa-parameters"])
			content = (
				<EditSpaParameters
					key={modifySpaParameters}
					handleClose={thisHandleClose}
					layoutId={values[0]}
					mLocId={values[1]}
					spaParamId={values[2]}
				/>
			)
		}
	}

	if (assignMaster === "master-areas") {
		thisHandleClose = () => console.log("Assign master before close...")
		content = <SelectMasterArea />
	}

	if (assignMaster === "master-coordiantes") {
		thisHandleClose = () => console.log("Assign master before close...")
		content = <SelectMasterCoordinates />
	}

	return (
		<div>
			<Dialog
				scroll="paper"
				aria-labelledby="form-dialog-title"
				open={content !== null}
				fullScreen={fullScreen}
				maxWidth={fullScreen ? false : "sm"}
				fullWidth={true}
				onClose={thisHandleClose}
				classes={fullScreen ? { paper: classes.dialogPaperLarge } : { paper: classes.dialogPaperSmall }}
			>
				{content || <div />}
			</Dialog>
		</div>
	)
}

GlobalDialog.propTypes = {
	location: PropTypes.object.isRequired,
	fullScreen: PropTypes.bool.isRequired,
}

export default withStyles(styles)(GlobalDialog)

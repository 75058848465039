import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import LayoutManager from "./LayoutManager"

const mapStateToProps = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	const view = params.get("view")
	return {
		view,
	}
}

const mapDispatchToProps = (dispatch) => ({})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LayoutManager)

import { connect } from "react-redux"
import LayoutList from "./LayoutList"
import { withRouter } from "react-router"
import { compose } from "redux"
import {
	setLayoutListSort,
	setLayoutSecondarySelection,
	clearLayoutSecondarySelection,
} from "store/actions/reduxActions"
import { filterRows, sortRows } from "utils/table"
import { fetchLayoutDetailBySiteUuid, fetchLayoutDetails } from "../../../store/actions/fetchers"

function createData(layoutDetails) {
	return Object.values(layoutDetails).map((layout) => {
		const { id, published, name, customerName, state, demo } = layout
		return {
			id,
			published,
			layoutName: name || "Not Named",
			customerName,
			state,
			demo,
		}
	})
}

const columns = [
	{
		width: 200,
		flexGrow: 1.0,
		label: "Layout Name",
		dataKey: "layoutName",
		type: "string",
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "Customer",
		dataKey: "customerName",
		type: "string",
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "State",
		dataKey: "state",
		type: "string",
		disableSort: false,
	},
]

const getRows = (layoutDetails, filter, sortBy, sortDirection) => {
	let rows = createData(layoutDetails)
	if (filter !== "") {
		rows = filterRows(rows, filter)
	}
	if (sortBy && sortDirection) {
		rows = sortRows(rows, sortBy, sortDirection)
	}
	return rows
}

const mapStateToProps = (state, _props) => ({
	rows: getRows(
		state.layoutDetails,
		state.layoutListFilter,
		state.layoutListSort.sortBy,
		state.layoutListSort.sortDirection,
	),
	columns,
	sortBy: state.layoutListSort["sortBy"],
	sortDirection: state.layoutListSort["sortDirection"],
	userPrivileges: state.user.privileges,
	siteView: state.siteView,
})

const mapDispatchToProps = (dispatch, _props) => ({
	handleSortClick: (sortObject) => dispatch(setLayoutListSort(sortObject.sortBy, sortObject.sortDirection)),
	setLayoutSecondarySelection: (event) => dispatch(setLayoutSecondarySelection(event.rowData.id)),
	clearLayoutSecondarySelection: (event) => dispatch(clearLayoutSecondarySelection(event.rowData.id)),
	fetchLayoutDetailBySiteUuid: (event) => dispatch(fetchLayoutDetailBySiteUuid(event.rowData.id)),
	fetchLayoutDetails: () => dispatch(fetchLayoutDetails()),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LayoutList)

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import MasterPopup from "./MasterPopup"
import { overlays } from "constants/overlays"
import { setMasterLabelNotVisible } from "store/actions/reduxActions"

const getCenter = (state, props) => {
	const masterDetails = state.masterDetails[props.mLocId]
	if (!masterDetails) return []
	if (!masterDetails["xLoc"] || !masterDetails["yLoc"]) return []
	return [masterDetails["yLoc"], masterDetails["xLoc"]]
}

const getOtherProps = (state, props) => {
	const masterDetails = state.masterDetails[props.mLocId]
	if (!masterDetails) return {}
	const { name, mId } = masterDetails
	return { name, mId }
}

const handleClick = (props) => {
	const params = new URLSearchParams(props.location.search)
	if (params.get("overlay") === overlays.DEVICEIDASSIGNMENT) {
		params.set("deviceid-input", [props.layoutId, props.mLocId].join(":"))
	} else {
		params.set("device-details", [props.layoutId, props.mLocId].join(":"))
	}
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const mapStateToProps = (state, props) => {
	const componentsScanned = state.masterComponentScanned[props.mLocId]
	const isDeviceIdAssignment = props.location.search.split("&").includes("overlay=deviceid-assignement")

	return {
		isDeviceIdAssignment,
		componentsScanned,
		position: getCenter(state, props),
		radius: state.layoutDetails[props.layoutId]["markerRadius"]
			? parseInt(state.layoutDetails[props.layoutId]["markerRadius"]) + 1
			: 1.5,
		...getOtherProps(state, props),
	}
}

const mapDispatchToProps = (dispatch, props) => ({
	setMasterInactive: () => dispatch(setMasterLabelNotVisible(props.mLocId)),
	handleClick: () => handleClick(props),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MasterPopup)

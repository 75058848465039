import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import NodeMarkers from "./NodeMarkers"
import { listReplacementCampaignInfo, subscribeReplacementCampaignUpdates } from "store/actions/fetchers"

const getSelectedMasterLocationIds = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	let locIds = params.get("locIds")
	let navLocId = params.get("navLocId")
	let selectedLocIds = []
	if (locIds) {
		selectedLocIds = locIds.split(",").map((loc) => loc.split(":")[0])
	}
	if (navLocId) {
		const mLocId = navLocId.split(":")[0]
		if (!selectedLocIds.includes(mLocId)) {
			selectedLocIds.push(mLocId)
		}
	}
	return selectedLocIds
}

const getActiveOverlay = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	return params.get("overlay")
}

const getLayoutTimestamp = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	return parseInt(params.get("ts"))
}

const mapStateToProps = (state, props) => ({
	selectedMasterLocationIds: getSelectedMasterLocationIds(state, props),
	activeOverlay: getActiveOverlay(state, props),
	timestamp: getLayoutTimestamp(state, props),
	dataView: state.dataView,
})

const mapDispatchToProps = (dispatch) => ({
	listReplacementCampaignInfo: (layoutId) => dispatch(listReplacementCampaignInfo(layoutId)),
	subscribeReplacementCampaignUpdates: (layoutId) => dispatch(subscribeReplacementCampaignUpdates(layoutId)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(NodeMarkers)

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import MasterMarkers from "./MasterMarkers"

const getMasterIds = (state, props) => {
	if (!state.layoutDetails.hasOwnProperty(props.layoutId)) return []
	return state.layoutDetails[props.layoutId]["mLocIds"]
}

const getActiveOverlay = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	return params.get("overlay")
}

const getFilter = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	return params.get("filter")
}

const getLayoutTimestamp = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	return parseInt(params.get("ts"))
}

const mapStateToProps = (state, props) => ({
	mLocIds: getMasterIds(state, props),
	activeOverlay: getActiveOverlay(state, props),
	timestamp: getLayoutTimestamp(state, props),
	filter: getFilter(state, props),
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MasterMarkers)

import React, { Fragment, useCallback, useEffect, useRef, useState } from "react"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import { Link, Redirect } from "react-router-dom"
import PropTypes from "prop-types"
import { ZendeskAPI } from "react-zendesk"
import logo from "../../../images/logo-dark.png"

const useStyles = makeStyles((theme) => ({
	main: {
		width: "auto",
		display: "flex", // Fix IE 11 issue.
		flexDirection: "column",
		[theme.breakpoints.down(400 + theme.spacing(6))]: {
			width: "100%",
			boxSizing: "border-box",
			paddingLeft: theme.spacing(3),
			paddingRight: theme.spacing(3),
		},
		[theme.breakpoints.up(400 + theme.spacing(6))]: {
			width: 410,
			marginLeft: "auto",
			marginRight: "auto",
		},
	},
	logo: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		objectFit: "contain",
	},
	paper: {
		marginTop: 0,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: theme.spacing(2, 3, 3),
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		marginTop: theme.spacing(3),
	},
	forgotPassword: {
		marginTop: theme.spacing(2),
		marginBottom: 0,
		margin: "auto",
		display: "flex",
		alignItems: "center",
	},
	trademark: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		textAlign: "center",
		fontSize: "0.6rem",
		fontWeight: 300,
		color: "darkgray",
	},
}))

const getErrorField = (errCode) => {
	switch (errCode) {
		case "InvalidPasswordException":
		case "NotAuthorizedException":
		case "PasswordResetRequiredException":
			return "errorPassword"
		case "InternalErrorException":
		case "InvalidParameterException":
		case "LimitExceededException":
		case "ResourceNotFoundException":
		case "TooManyRequestsException":
		case "UserNotConfirmedException":
		case "UserNotFoundException":
		default:
			return "errorEmail"
	}
}

const SignIn = ({
	location,
	authorized,
	trailExpired,
	signIn,
	completeNewPassword,
	enqueueSnackbar,
	closeSnackbar,
}) => {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [newPassword, setNewPassword] = useState("")
	const [confirmNewPassword, setConfirmNewPassword] = useState("")
	const [errorFeedback, setErrorFeedback] = useState({})
	const [capsLockIsEnabled, setCapsLockIsEnabled] = useState(false)
	const [pendingResponse, setPendingResponse] = useState(false)
	const [newPasswordRequiredUser, setNewPasswordRequiredUser] = useState(null)
	const classes = useStyles()

	const unmounted = useRef(false)

	useEffect(() => {
		return () => {
			unmounted.current = true
		}
	}, [])

	useEffect(() => {
		ZendeskAPI("webWidget", "show")
	}, [])

	const resetErrorFeedbackAndPendingResponse = useCallback(() => {
		setErrorFeedback({})
		setPendingResponse(false)
	}, [])

	const handlePasswordChange = useCallback(
		(e) => {
			resetErrorFeedbackAndPendingResponse()
			setPassword(e.target.value)
		},
		[resetErrorFeedbackAndPendingResponse],
	)

	const handleEmailChange = useCallback(
		(e) => {
			resetErrorFeedbackAndPendingResponse()
			setEmail(e.target.value.toLowerCase())
		},
		[resetErrorFeedbackAndPendingResponse],
	)

	const handleNewPasswordChange = useCallback(
		(e) => {
			resetErrorFeedbackAndPendingResponse()
			setNewPassword(e.target.value)
		},
		[resetErrorFeedbackAndPendingResponse],
	)

	const handleConfirmNewPasswordChange = useCallback(
		(e) => {
			resetErrorFeedbackAndPendingResponse()
			setConfirmNewPassword(e.target.value)
		},
		[resetErrorFeedbackAndPendingResponse],
	)

	const submit = async (e) => {
		e.preventDefault()
		setErrorFeedback({})
		setPendingResponse(true)

		let timer = setTimeout(() => {
			if (unmounted.current) {
				return
			}
			setPendingResponse(false)
			setPassword("")
			setErrorFeedback({ errorEmail: "Request Timeout" })
		}, 20000)

		try {
			if (newPasswordRequiredUser === null) {
				// signIn ALSO handles the rest api sign in if successful
				const loginInfo = await signIn(email, password)
				clearTimeout(timer)
				if (unmounted.current) {
					return
				}
				if (
					loginInfo.challengeName === "SMS_MFA" ||
					loginInfo.challengeName === "SOFTWARE_TOKEN_MFA" ||
					loginInfo.challengeName === "MFA_SETUP"
				) {
					console.error("MFA not configured!")
				} else if (loginInfo.challengeName === "NEW_PASSWORD_REQUIRED") {
					setNewPasswordRequiredUser(loginInfo)
					enqueueSnackbar("A new password is required to proceed.", {
						variant: "info",
						persist: true,
						action: (key) => (
							<Button
								onClick={() => {
									closeSnackbar(key)
								}}
							>
								Dismiss
							</Button>
						),
					})
				} else if (loginInfo.code) {
					setErrorFeedback({ errorEmail: loginInfo.message || "An unexpected error occurred" })
					setPendingResponse(false)
					return
				} else {
					const groups =
						(((loginInfo["signInUserSession"] || {}).accessToken || {}).payload || {})["cognito:groups"] || []
					if (groups.length === 0) {
						setPassword("")
						setErrorFeedback({ errorEmail: "Account has no privileges" })
						enqueueSnackbar("Account has no privileges! Contact GameChange Administrator.", {
							variant: "error",
							persist: true,
							action: (key) => (
								<Button
									onClick={() => {
										closeSnackbar(key)
									}}
								>
									Dismiss
								</Button>
							),
						})
					}
				}
			} else {
				if (newPassword === confirmNewPassword) {
					await completeNewPassword(newPasswordRequiredUser, newPassword)
				} else {
					setErrorFeedback({
						errorConfirmPassword: "Passwords do not match",
					})
				}
				clearTimeout(timer)
			}
			setPendingResponse(false)
		} catch (err) {
			console.error(err)
			clearTimeout(timer)
			setErrorFeedback({ [getErrorField(err.code)]: err.message || "An error occurred." })
			setPassword("")
			setPendingResponse(false)
		}
	}

	if (authorized && !trailExpired) {
		if (location.state && location.state.from && location.state.from.pathname !== "/") {
			return <Redirect to={location.state.from.pathname} />
		} else {
			return (
				<Redirect
					to={{
						pathname: "/layouts",
						search: "?view=list",
					}}
				/>
			)
		}
	}

	return (
		<main className={classes.main}>
			<CssBaseline />
			<img className={classes.logo} alt="GameChange Logo" src={logo} />
			{pendingResponse && !trailExpired ? <LinearProgress /> : null}
			<Paper className={classes.paper}>
				<div className={classes.trademark}>
					Welcome to the GENIUS TRACKER&reg; monitoring and control system with GENIUSVISION&trade;,
					WEATHERSMART&trade;, POWERSMART&trade;, SMARTSTOW&trade;, THUNDERSMART&trade;, and SNOWSHIELD&trade; solar
					tracker control technology!
				</div>
				<Typography component="h1" variant="h5">
					Sign in
				</Typography>
				<form className={classes.form} onSubmit={submit}>
					{newPasswordRequiredUser !== null ? (
						<Fragment>
							<TextField
								key="newPassword"
								id="newPassword"
								label="New Password"
								type="password"
								margin="normal"
								variant="outlined"
								value={newPassword}
								onKeyDown={(e) => setCapsLockIsEnabled(e.getModifierState("CapsLock"))}
								onChange={handleNewPasswordChange}
								autoComplete="new-password"
								error={errorFeedback["errorPassword"] !== undefined}
								helperText={errorFeedback["errorPassword"] || (capsLockIsEnabled && "CapsLock Is Enabled")}
								autoFocus
								required
								fullWidth
							/>
							<TextField
								key="confirmNewPassword"
								id="confirmNewPassword"
								label="Confirm New Password"
								type="password"
								margin="normal"
								variant="outlined"
								value={confirmNewPassword}
								onKeyDown={(e) => setCapsLockIsEnabled(e.getModifierState("CapsLock"))}
								onChange={handleConfirmNewPasswordChange}
								autoComplete="confirm-new-password"
								error={errorFeedback.errorConfirmPassword !== undefined}
								helperText={errorFeedback.errorConfirmPassword || (capsLockIsEnabled && "CapsLock Is Enabled")}
								required
								fullWidth
							/>
						</Fragment>
					) : (
						<Fragment>
							<TextField
								key="email"
								id="email"
								label="Email Address"
								type="email"
								margin="normal"
								variant="outlined"
								value={email}
								onChange={handleEmailChange}
								autoComplete="email"
								error={errorFeedback.errorEmail !== undefined || trailExpired}
								helperText={trailExpired ? "Account Trail Expired." : errorFeedback.errorEmail}
								autoFocus
								required
								fullWidth
							/>
							<TextField
								key="password"
								id="password"
								label="Password"
								type="password"
								margin="normal"
								variant="outlined"
								value={password}
								onKeyDown={(e) => setCapsLockIsEnabled(e.getModifierState("CapsLock"))}
								onChange={handlePasswordChange}
								autoComplete="current-password"
								error={errorFeedback["errorPassword"] !== undefined}
								helperText={errorFeedback["errorPassword"] || (capsLockIsEnabled && "CapsLock Is Enabled")}
								required
								fullWidth
							/>
						</Fragment>
					)}
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						disabled={pendingResponse}
					>
						{newPasswordRequiredUser !== null ? "Submit" : "Sign in"}
					</Button>
					{newPasswordRequiredUser === null && (
						<Button color="primary" className={classes.forgotPassword} component={Link} to="/reset-password">
							Forgot Password
						</Button>
					)}
				</form>
			</Paper>
		</main>
	)
}

SignIn.propTypes = {
	location: PropTypes.any,
	authorized: PropTypes.any,
	trailExpired: PropTypes.any,
	signIn: PropTypes.func,
	completeNewPassword: PropTypes.func,
	enqueueSnackbar: PropTypes.func,
	closeSnackbar: PropTypes.any,
}

export default SignIn

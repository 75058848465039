// import { connect } from 'react-redux'
import { withSnackbar } from "notistack"
import CompassButton from "./CompassButton"

// const mapStateToProps = (state, props) => ({
//
// })
//
// const mapDispatchToProps = (dispatch, props) => ({
//
// })
//
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(CompassButton)
export default withSnackbar(CompassButton)

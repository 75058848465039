import React, { useState, useEffect } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import { makeStyles } from "@material-ui/core/styles"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepButton from "@material-ui/core/StepButton"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import UserAttributes from "./UserAttributes"
import UserGroups from "./UserGroups"
import LayoutList from "./LayoutList"

import API, { graphqlOperation } from "@aws-amplify/api"
import { getUser as getUserGql } from "graphql/queries"
import PropTypes from "prop-types"

const steps = ["User Attributes", "Layout Access", "Privilege Groups"]

const getStepContent = (step) => {
	switch (step) {
		case 0:
			return "Step 1: Edit User Attributes."
		case 1:
			return "Step 2: Assign layout access to user."
		case 2:
			return "Step 3: Assign privilege group to user."
		default:
			return "Unknown step"
	}
}

const useStyles = makeStyles((theme) => ({
	dialogPaper: {
		minHeight: "90vh",
		maxHeight: "90vh",
	},
	dialogContent: {
		display: "flex",
	},
	buttons: {
		display: "flex",
	},
	button: {
		marginRight: theme.spacing(1),
	},
	buttonLeft: {
		marginRight: theme.spacing(1),
		marginLeft: "auto",
	},
	completed: {
		display: "inline-block",
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}))

const UserDetails = ({ isOpen, handleClose, username, handleAttrsUpdate }) => {
	const classes = useStyles()
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
	const [activeStep, setActiveStep] = useState(0)
	const [userAttributes, setUserAttributes] = useState({})

	const getUser = async (username) => {
		try {
			setUserAttributes({})
			const result = await API.graphql(
				graphqlOperation(getUserGql, {
					username,
				}),
			)
			setUserAttributes(result.data.getUser)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		if (!username) {
			setUserAttributes({})
		} else if (username !== "new") {
			getUser(username)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [username])

	const handleNext = () => {
		if (activeStep === 0 && userAttributes.allLayouts) {
			setActiveStep((prevActiveStep) => prevActiveStep + 2)
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1)
		}
	}

	const handleBack = () => {
		if (activeStep === 2 && userAttributes.allLayouts) {
			setActiveStep((prevActiveStep) => prevActiveStep - 2)
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep - 1)
		}
	}

	const handleStep = (step) => () => {
		setActiveStep(step)
	}

	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="user-details-title"
			fullWidth={true}
			fullScreen={fullScreen}
			maxWidth={"md"}
			scroll={"paper"}
			classes={fullScreen ? {} : { paper: classes.dialogPaper }}
		>
			<DialogTitle id="user-details-title">User Details</DialogTitle>
			<Stepper nonLinear activeStep={activeStep}>
				{steps.map((label, index) => {
					return (
						<Step key={label}>
							<StepButton
								disabled={!userAttributes.username || (label === "Layout Access" && userAttributes.allLayouts)}
								onClick={handleStep(index)}
							>
								{label}
							</StepButton>
						</Step>
					)
				})}
			</Stepper>
			<DialogContent dividers={true} className={classes.dialogContent}>
				{activeStep === 0 && (
					<UserAttributes
						key={userAttributes.username}
						userAttributes={userAttributes}
						handleAttrsUpdate={handleAttrsUpdate}
					/>
				)}
				{activeStep === 1 && <LayoutList username={userAttributes.username} />}
				{activeStep === 2 && <UserGroups key={userAttributes.username} username={userAttributes.username} />}
			</DialogContent>
			<DialogActions>
				{
					<div>
						<Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
						<div className={classes.buttons}>
							<Button
								className={classes.buttonLeft}
								onClick={() => {
									handleClose()
									setActiveStep(0)
								}}
							>
								Close
							</Button>
							<Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
								Back
							</Button>
							<Button
								disabled={!userAttributes.username || activeStep === steps.length - 1}
								color="primary"
								onClick={handleNext}
								className={classes.button}
							>
								Next
							</Button>
						</div>
					</div>
				}
			</DialogActions>
		</Dialog>
	)
}

UserDetails.propTypes = {
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	username: PropTypes.string,
	handleAttrsUpdate: PropTypes.func,
}

export default UserDetails

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"

const styles = (theme) => ({
	stepWrapper: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},
	stepContent: {
		margin: theme.spacing(1),
	},
	paper: {
		textAlign: "center",
		padding: theme.spacing(1),
	},
})

const CoordinateAssignment = React.memo(function CoordinareAssignment({
	classes,
	masterNodeCounts,
	totalNodeCount,
	unassignedNodeCount,
	setCanContinue,
}) {
	useEffect(() => {
		// NOTE: Unassigned nodes will be lost. It will be necessary to reupload (not import) the node coordinates, but this
		// is superior to requiring all the nodes to be assigned right away. If this condition is true, it means that some
		// nodes have been assigned (not necessarily all of them), which is enough to continue.
		setCanContinue(unassignedNodeCount < totalNodeCount)
	}, [setCanContinue, totalNodeCount, unassignedNodeCount])

	return (
		<div>
			<Paper className={classes.paper}>
				<Typography variant="h6">Node Assignment Counts</Typography>
				<Typography>Total Node Count: {totalNodeCount}</Typography>
				{totalNodeCount !== 0 ? (
					<Typography color={unassignedNodeCount !== 0 ? "error" : "initial"}>
						Unassigned Node Count: {unassignedNodeCount}
					</Typography>
				) : null}
				{masterNodeCounts.length !== 0 ? null : (
					// Show this warning in case the user has somehow managed to skip required steps.
					<Typography color={"error"}>{"You have not completed all required steps!"}</Typography>
				)}
				{masterNodeCounts.map((master) => (
					<TextField
						id={master.mLocId}
						key={master.mLocId}
						label={master.name}
						type="number"
						margin="normal"
						variant="outlined"
						value={master.nodeCount}
						inputProps={{ step: "any" }}
						disabled
						fullWidth
					/>
				))}
			</Paper>
		</div>
	)
})

CoordinateAssignment.propTypes = {
	classes: PropTypes.object.isRequired,
	masterNodeCounts: PropTypes.array,
	totalNodeCount: PropTypes.number,
	unassignedNodeCount: PropTypes.number,
	setCanContinue: PropTypes.func.isRequired,
}

export default withStyles(styles)(CoordinateAssignment)

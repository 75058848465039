import React, { Fragment, useCallback, useMemo, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import DialogContent from "@material-ui/core/DialogContent"
import IconButton from "@material-ui/core/IconButton"
import ClearIcon from "@material-ui/icons/Close"
import Fab from "@material-ui/core/Fab"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import ReactQuagga, { useQuagga } from "components/ReactQuagga"
import { useSnackbar } from "notistack"
import PropTypes from "prop-types"
import { isHex } from "../../../../../utils/validation"
import ReactQrReader from "../../../../ReactQrReader"
// import QrCode2Icon from "@mui/icons-material/QrCode2"
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify"

const useStyles = makeStyles((theme) => ({
	content: {
		display: "flex",
		flexDirection: "column",
		marginp: theme.spacing(1),
		padding: 0,
		width: "25ch",
	},
	actions: {
		justifyContent: "space-between",
	},
	actionButton: {
		margin: theme.spacing(1),
		flex: 1,
	},
	fabClose: {
		position: "fixed",
		bottom: 0,
		right: 0,
		margin: theme.spacing(2),
		zIndex: 500,
	},
	fabText: {
		position: "fixed",
		bottom: 0,
		right: 40 + 24,
		margin: theme.spacing(2),
		zIndex: 500,
	},
	suggestionPaper: {
		border: "1px solid #d3d4d5",
	},
	toggle: {
		marginLeft: theme.spacing(1),
	},
	scannerContainer: {
		position: "relative",
	},
}))

// TODO: (Magnus - 3/31/2023) Unify TaskWizard/DeviceIdInput, DialogDeviceIdInput and ReplacementWizard/DeviceIdInput
const DeviceIdInput = ({ handleClose, layoutId, mLocId, nLocId, setTaskInfo, setNodeId }) => {
	const [deviceid, setDeviceId] = useState(null)
	const [deviceIdEntryMethod, setDeviceIdEntryMethod] = useState("text")
	const classes = useStyles()
	const scannerSupported = useQuagga()
	const { enqueueSnackbar } = useSnackbar()

	const handleDeviceIdDetected = useCallback((data) => {
		let deviceId = data.codeResult.code
		if (data.codeResult.code) {
			deviceId = deviceId.replace("0x", "").toUpperCase()
			deviceId = deviceId.replace(/[^A-F0-9]/g, "")
		}

		setDeviceId(deviceId || null)
	}, [])

	const handleSubmit = useCallback(() => {
		if (deviceid) {
			setNodeId(layoutId, mLocId, nLocId, deviceid)
			setTaskInfo({
				newNodeId: deviceid,
			})
		} else {
			enqueueSnackbar("Unable to save Node ID", {
				variant: "error",
			})
		}
	}, [deviceid, enqueueSnackbar, layoutId, mLocId, nLocId, setNodeId, setTaskInfo])

	const error = useMemo(() => {
		if (!deviceid) {
			const isValid = isHex(deviceid)
			if (!isValid) {
				return "Not a valid hex ID."
			}
			if (deviceid.length > 16) {
				return "Max 16 characters."
			}
		}
	}, [deviceid])

	// TODO: (Magnus - 3/31/2023) I don't understand the purpose of this...
	const isValid = useMemo(() => {
		if (error) return false
		if (!deviceid) return false
		if (deviceid.length !== 16 && deviceid.length !== 4) return false
		if (deviceid.length > 8) {
			const firstHalf = deviceid.slice(0, 8).padStart(8, "0")
			const secondHalf = deviceid.slice(8, 15).padStart(8, "0")
			if (
				// TODO: (Magnus - 3/31/2023) I don't understand the purpose of this...
				parseInt(firstHalf, 16).toString(16).toUpperCase().padStart(8, "0") !== firstHalf ||
				parseInt(secondHalf, 16).toString(16).toUpperCase().padStart(8, "0") !== secondHalf
			)
				return false
		} else {
			// TODO: (Magnus - 3/31/2023) ...or this. But these errors should be included in the `error` memo.
			if (parseInt(deviceid, 16).toString(16).toUpperCase().padStart(8, "0") !== deviceid.padStart(8, "0")) return false
		}

		return true
	}, [deviceid, error])

	const helperText = useMemo(() => {
		return error ?? (!!deviceid && !isValid) ? "Invalid Node ID" : "Node ID - no leading '0x'"
	}, [deviceid, error, isValid])

	const handleNodeIdKeyDown = useCallback((e) => {
		const isLetterOrDigitRegex = /^[a-zA-Z0-9]$/i
		if (e.key.match(isLetterOrDigitRegex) && !isHex(e.key)) {
			// Prevent invalid characters entirely
			e.stopPropagation()
			e.preventDefault()
		}
	}, [])

	const handleCameraError = useCallback(
		(err) => {
			if (err.message.includes("denied")) {
				enqueueSnackbar("Camera permission denied", {
					variant: "error",
				})
			}
			setDeviceIdEntryMethod("text")
		},
		[enqueueSnackbar],
	)

	const handleCameraClose = useCallback(() => {
		setDeviceIdEntryMethod("text")
		handleClose()
	}, [handleClose])

	if (
		scannerSupported &&
		(deviceIdEntryMethod === "barCode" || deviceIdEntryMethod === "qrCode") &&
		deviceid === null
	) {
		return (
			<Fragment>
				<Fab color="primary" aria-label="Close" onClick={handleCameraClose} className={classes.fabClose}>
					<ClearIcon />
				</Fab>
				<div className={classes.scannerContainer}>
					{deviceIdEntryMethod === "barCode" ? (
						<ReactQuagga onDetected={handleDeviceIdDetected} onError={handleCameraError} onClose={handleCameraClose} />
					) : (
						<ReactQrReader
							onDetected={(data) => {
								setDeviceId(data.original_radio_id || null)
							}}
							onError={handleCameraError}
							onClose={handleCameraClose}
						/>
					)}
				</div>
			</Fragment>
		)
	}

	let endAdornment = null
	if (deviceid) {
		endAdornment = (
			<InputAdornment position="end">
				<IconButton edge="end" aria-label="Clear" onClick={() => setDeviceId(null)}>
					<ClearIcon />
				</IconButton>
			</InputAdornment>
		)
	} else if (scannerSupported) {
		endAdornment = (
			<InputAdornment position="end">
				<IconButton edge="end" aria-label="Barcode scan" onClick={() => setDeviceIdEntryMethod("barCode")}>
					<FormatAlignJustifyIcon />
				</IconButton>
				{/*INFO: will be re-integrated once we find a suitable library replacement*/}
				{/*<IconButton edge="end" aria-label="Qr code scan" onClick={() => setDeviceIdEntryMethod("qrCode")}>*/}
				{/*	<QrCode2Icon />*/}
				{/*</IconButton>*/}
			</InputAdornment>
		)
	}

	return (
		<Fragment>
			<DialogContent id="device-id-input-description" className={classes.content}>
				<TextField
					id="deviceid-input"
					variant="outlined"
					className={classes.paper}
					type="text"
					label="Node ID"
					onKeyDown={handleNodeIdKeyDown}
					onChange={(e) => setDeviceId(e.target.value.toUpperCase())}
					value={deviceid || ""}
					error={!!deviceid && !isValid}
					helperText={helperText}
					autoFocus={deviceIdEntryMethod === "text" || !scannerSupported}
					InputProps={{
						endAdornment,
					}}
				/>
			</DialogContent>
			<Button
				onClick={handleSubmit}
				variant="contained"
				color="primary"
				type="button"
				disabled={!isValid}
				className={classes.actionButton}
			>
				Accept
			</Button>
		</Fragment>
	)
}

DeviceIdInput.propTypes = {
	handleClose: PropTypes.func.isRequired,
	layoutId: PropTypes.string.isRequired,
	mLocId: PropTypes.string.isRequired,
	nLocId: PropTypes.string.isRequired,
	setTaskInfo: PropTypes.func.isRequired,
	setNodeId: PropTypes.func.isRequired,
}

export default DeviceIdInput

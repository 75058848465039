import { connect } from "react-redux"
import { createSelector } from "reselect"
import CoordinateAssignment from "./CoordinateAssignment"
import { getLayoutMasterIds } from "utils"

const getMasterLocationIds = (state, props) => getLayoutMasterIds(state.layoutDetails, props.layoutId)
const getLocations = (state, props) => state.configLocations
const getMasterDetails = (state, props) => state.masterDetails
const getMasterNodeCounts = createSelector(
	[getMasterLocationIds, getLocations, getMasterDetails],
	(mLocIds, locations, masterDetails) => {
		const masterNodeCounts = []
		mLocIds.forEach((mLocId) => {
			if (masterDetails.hasOwnProperty(mLocId)) {
				const nodeCount = locations.reduce((count, node) => {
					if (node.mLocId === mLocId) return count + 1
					return count
				}, 0)
				masterNodeCounts.push({
					mLocId,
					name: masterDetails[mLocId].name,
					nodeCount,
				})
			}
		})
		return masterNodeCounts
	},
)

const getUnassignedNodeCount = createSelector([getLocations], (configLocations) => {
	let count = 0
	for (let i = 0; i < configLocations.length; i++) {
		if (!configLocations[i].hasOwnProperty("mLocId")) {
			count++
		}
	}
	return count
})

const mapStateToProps = (state, props) => ({
	masterNodeCounts: getMasterNodeCounts(state, props),
	totalNodeCount: state.configLocations.length,
	unassignedNodeCount: getUnassignedNodeCount(state, props),
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CoordinateAssignment)

import { connect } from "react-redux"
import SignIn from "./SignIn"
import { completeNewPassword, signInWithEmailAndPassword } from "store/actions/auth"
// import isBefore from 'date-fns/isBefore'
import { compose } from "redux"
import { withSnackbar } from "notistack"
import { privileges } from "constants/auth"
import { accessAllowed } from "utils/auth"

const getTrailExpired = (_state) => {
	return false
	// if((state.userProfile !== null) && state.userProfile.trialExpiration) {
	//   return !isBefore(new Date(), state.userProfile.trialExpiration.toDate())
	// }
	// else {
	//   return false;
	// }
}

const mapStateToProps = (state) => ({
	trailExpired: getTrailExpired(state),
	authorized: accessAllowed(state.user.privileges, privileges.VIEW),
})

const mapDispatchToProps = (dispatch) => ({
	signIn: (email, password) => dispatch(signInWithEmailAndPassword(email, password)),
	completeNewPassword: (user, newPassword) => {
		dispatch(completeNewPassword(user, newPassword))
	},
})

export default compose(connect(mapStateToProps, mapDispatchToProps), withSnackbar)(SignIn)

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import LayoutMap from "./LayoutMapConfig"
import ConfigStepper from "./ConfigStepper"

const styles = (theme) => ({
	root: {
		display: "flex",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column-reverse",
		},
		[theme.breakpoints.up("md")]: {
			flexDirection: "row",
		},
		width: "100%",
		height: "100%",
		overflow: "auto",
		"-webkit-overflow-scrolling": "touch",
	},
	splitOne: {
		display: "flex",
		margin: "3px",
		[theme.breakpoints.down("sm")]: {
			height: "300px",
			maxHeight: "35%",
			marginTop: "2px",
		},
		[theme.breakpoints.up("md")]: {
			width: "400px",
			marginRight: "2px",
		},
	},
	splitTwo: {
		display: "flex",
		margin: "3px",
		[theme.breakpoints.down("sm")]: {
			flex: 1,
			marginBottom: "2px",
		},
		[theme.breakpoints.up("md")]: {
			flex: 1,
			marginLeft: "2px",
		},
	},
})

const ConfigureLayout = ({ classes, layoutId, masterDetails, fetchMasterDetails, fetchNodeDetails }) => {
	useEffect(() => {
		if (layoutId) {
			fetchMasterDetails(layoutId)
		}
	}, [layoutId, fetchMasterDetails])

	useEffect(() => {
		if (layoutId && masterDetails) {
			fetchNodeDetails(layoutId, masterDetails)
		}
	}, [layoutId, masterDetails, fetchNodeDetails])

	return (
		<div className={classes.root}>
			<Paper className={classes.splitOne} elevation={1}>
				<ConfigStepper layoutId={layoutId} />
			</Paper>
			<Paper className={classes.splitTwo} elevation={1}>
				<LayoutMap />
			</Paper>
		</div>
	)
}

ConfigureLayout.propTypes = {
	classes: PropTypes.object.isRequired,
	layoutId: PropTypes.string,
	fetchMasterDetails: PropTypes.func.isRequired,
	fetchNodeDetails: PropTypes.any,
	masterDetails: PropTypes.object,
}

export default withStyles(styles)(ConfigureLayout)

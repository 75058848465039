import { connect } from "react-redux"
import { compose } from "redux"
import SiteSummary from "./SiteSummary"
import { overlays } from "constants/overlays"
import { withRouter } from "react-router-dom"

const getActiveOverlay = (search) => {
	const params = new URLSearchParams(search)
	return params.get("overlay")
}

const getSummaryHeading = (activeOverlay) => {
	switch (activeOverlay) {
		case overlays.SMART_STOW:
			return ["Smart Stows", "Status"]
		default:
			return null
	}
}
const getSiteSmartStowData = (siteInfo) => {
	return [
		{
			label: "WeatherSmart",
			value: siteInfo["weathersmartEnabled"] ? "Enabled" : "Disabled",
		},
		{
			label: "ThunderSmart",
			value: siteInfo["thundersmartEnabled"] ? "Enabled" : "Disabled",
		},
		{
			label: "Hailstow",
			value: siteInfo["hailstowEnabled"] ? "Enabled" : "Disabled",
		},

		{
			label: "Snow Shield",
			value: siteInfo["snowshieldEnabled"] ? "Enabled" : "Disabled",
		},
		{
			label: "Powerboost",
			value: siteInfo["powerboostEnabled"] ? "Enabled" : "Disabled",
		},
	]
}
const mapStateToProps = (state, props) => {
	let summaryData = null
	let summaryHeading

	const activeOverlay = getActiveOverlay(props.location.search)
	const siteInfo = state.layoutDetails[props.match.params.layoutId]

	summaryHeading = getSummaryHeading(activeOverlay)

	switch (activeOverlay) {
		case overlays.SMART_STOW:
			summaryData = getSiteSmartStowData(siteInfo)
			break
		default:
			break
	}

	return {
		summaryHeading,
		summaryData,
		summaryIsOpen: state.summaryIsOpen,
	}
}

export default compose(withRouter, connect(mapStateToProps))(SiteSummary)

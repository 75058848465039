import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import LayoutMapConfig from "./LayoutMapConfig"
import { setLayoutZoomLevel } from "store/actions/reduxActions"

const getLayoutUrl = (state, props) => {
	return state.layoutDetails[props.match.params.layoutId]?.layoutUrl || ""
}

const mapStateToProps = (state, props) => ({
	layoutUrl: getLayoutUrl(state, props),
	layoutId: props.match.params.layoutId,
})

const mapDispatchToProps = (dispatch) => ({
	setLayoutZoomLevel: (zoomLevel) => dispatch(setLayoutZoomLevel(zoomLevel)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LayoutMapConfig)

import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

import SearchBar from "./SearchBar"
import SearchList from "./SearchList"

function TabContainer({ children, dir }) {
	return (
		<Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
			{children}
		</Typography>
	)
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired,
	dir: PropTypes.string.isRequired,
}

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		width: "100%",
		display: "flex",
		flexDirection: "column",
		overflowY: "hidden",
	},
})

class Search extends React.Component {
	state = {
		value: 0,
	}

	handleChange = (event, value) => {
		this.setState({ value })
	}

	handleChangeIndex = (index) => {
		this.setState({ value: index })
	}

	render() {
		const { classes } = this.props

		return (
			<div className={classes.root}>
				<SearchBar />
				<SearchList />
			</div>
		)
	}
}

Search.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(Search)

import React from "react"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"

const NodeDataView = ({ nodeData }) => {
	return nodeData
		? nodeData.map((detail) => {
				return [
					<ListItem key={detail.id + "value"}>
						<ListItemText primary={detail.label} secondary={detail.dataFormatted} />
					</ListItem>,
					<Divider key={detail.id + "divider"} />,
				]
		  })
		: null
}

export default NodeDataView

export const csvToArray = (strData, strDelimiter) => {
	strDelimiter = strDelimiter || ","

	var objPattern = new RegExp(
		// Delimiters.
		"(\\" +
			strDelimiter +
			"|\\r?\\n|\\r|^)" +
			// Quoted fields.
			'(?:"([^"]*(?:""[^"]*)*)"|' +
			// Standard fields.
			'([^"\\' +
			strDelimiter +
			"\\r\\n]*))",
		"gi",
	)

	// Create an array to hold our data. Give the array
	// a default empty first row.
	var arrData = [[]]

	// Create an array to hold our individual pattern
	// matching groups.
	var arrMatches = null

	// Keep looping over the regular expression matches
	// until we can no longer find a match.
	while ((arrMatches = objPattern.exec(strData)) !== null) {
		// Get the delimiter that was found.
		var strMatchedDelimiter = arrMatches[1]

		// Check to see if the given delimiter has a length
		// (is not the start of string) and if it matches
		// field delimiter. If id does not, then we know
		// that this delimiter is a row delimiter.
		if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
			// Since we have reached a new row of data,
			// add an empty row to our data array.
			arrData.push([])
		}

		// Now that we have our delimiter out of the way,
		// let's check to see which kind of value we
		// captured (quoted or unquoted).
		let strMatchedValue = null
		if (arrMatches[2]) {
			// We found a quoted value. When we capture
			// this value, unescape any double quotes.
			strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"')
		} else {
			// We found a non-quoted value.
			strMatchedValue = arrMatches[3]
		}

		// Now that we have our value string, let's add
		// it to the data array.
		arrData[arrData.length - 1].push(strMatchedValue)
	}

	// Return the parsed data.
	return arrData
}

export const downloadToCsv = (data, columnHeaders, filename) => {
	// console.log(columnHeaders)
	// console.log(data)
	// console.log(filename)
	const columnDelimiter = ","
	const lineDelimiter = "\n"

	var fileContent = []

	const headerRow = ["data:text/csv;charset=utf-8"].concat(columnHeaders)
	fileContent.push(headerRow.join(columnDelimiter))
	fileContent = fileContent.concat(
		data.map((rowData) =>
			rowData
				.map((cell) => {
					if (cell === null) {
						return ""
					} else {
						return cell.toString()
					}
				})
				.join(columnDelimiter),
		),
	)

	var encodedData = encodeURI(fileContent.join(lineDelimiter))
	var link = document.createElement("a")
	link.setAttribute("href", encodedData)
	link.setAttribute("download", filename)
	link.click()
}

import React from "react"
import PropTypes from "prop-types"
import NodeIdLabel from "../NodeIdLabel"
import NodeLocLabel from "../NodeLocLabel"

const NodeLabelsByMaster = ({ mLocId, nLocIds, overlayNIds, overlayNLocIds }) => {
	const nodeLabels = []
	for (let i = 0; i < nLocIds.length; i++) {
		if (overlayNIds) {
			nodeLabels.push(<NodeIdLabel key={`nId#${mLocId}#${nLocIds[i]}`} nLocId={nLocIds[i]} mLocId={mLocId} />)
		}
		if (overlayNLocIds) {
			nodeLabels.push(<NodeLocLabel key={`nLocId#${mLocId}#${nLocIds[i]}`} nLocId={nLocIds[i]} mLocId={mLocId} />)
		}
	}
	return nodeLabels
}

NodeLabelsByMaster.propTypes = {
	mLocId: PropTypes.string.isRequired,
	nLocIds: PropTypes.array.isRequired,
}

export default NodeLabelsByMaster

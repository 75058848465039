import { connect } from "react-redux"
import { withRouter, matchPath } from "react-router"
import { compose } from "redux"
import ReplacementCampaignSummary from "./ReplacementCampaignSummary"
import { createReplacementCampaignInfo, deleteReplacementCampaignInfo } from "store/actions/setters"

const mapStateToProps = (state, props) => {
	const rtnMatchPath = matchPath(props.location.pathname, { path: "/layout/:layoutId" })
	const { layoutId } = rtnMatchPath.params
	const mLocIds = (state.layoutDetails[layoutId] || {})["mLocIds"] || []
	const mNames = mLocIds.map((mLocId) => (state.masterDetails[mLocId] || {})["name"])
	const nodeCount = mLocIds.map((mLocId) => Object.keys(state.nodeDetails[mLocId] || {}).length)
	const replacements = mLocIds.map((mLocId) => state.replacementCampaignInfo[mLocId])
	return {
		layoutId,
		mLocIds,
		mNames,
		nodeCount,
		replacements,
		replacementCampaignStatus: state.replacementCampaignStatus,
	}
}

const mapDispatchToProps = (dispatch) => ({
	startReplacementCampaign: (layoutId, mLocId, timestamp) =>
		dispatch(createReplacementCampaignInfo(layoutId, mLocId, timestamp)),
	deleteReplacementCampaignInfo: (layoutId, mLocId) => dispatch(deleteReplacementCampaignInfo(layoutId, mLocId)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ReplacementCampaignSummary)

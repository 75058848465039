import React, { memo } from "react"
import PropTypes from "prop-types"
import MasterPopup from "./MasterPopup"

const MasterPopups = memo(({ layoutId, mLocIds }) => {
	if (!layoutId || !mLocIds?.length) return null

	const masterPopups = []
	for (let i = 0; i < mLocIds.length; i++) {
		masterPopups.push(<MasterPopup key={mLocIds[i]} mLocId={mLocIds[i]} layoutId={layoutId} />)
	}
	return <>{masterPopups}</>
})

MasterPopups.displayName = "MasterPopups"

MasterPopups.propTypes = {
	layoutId: PropTypes.string,
	mLocIds: PropTypes.array,
}

export default MasterPopups

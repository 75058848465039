/*
  REDUX ACTIONS
*/
export const APP_INITIALIZE = "APP_INITIALIZE"
export const SET_LAYOUT_LIST_FILTER = "SET_LAYOUT_LIST_FILTER"
export const SET_LAYOUT_LIST_SORT = "SET_LAYOUT_LIST_SORT"
export const SET_CONFIG_LOCATIONS = "SET_CONFIG_LOCATIONS"
export const SET_CONFIG_TRANS = "SET_CONFIG_TRANS"
export const SET_CONFIG_MASTER_AREA = "SET_CONFIG_MASTER_AREA"
export const UPDATE_CONFIG_MASTER_AREA = "UPDATE_CONFIG_MASTER_AREA"
export const SET_CONFIG_MASTER_COORDINATES = "SET_CONFIG_MASTER_COORDINATES"
export const SET_WIZARD_INFO = "SET_WIZARD_INFO"
export const SET_TASK_INFO = "SET_TASK_INFO"
export const RESET_CONFIG_STEPPER = "RESET_CONFIG_STEPPER"
export const TOGGLE_DRAWER_IS_OPEN = "TOGGLE_DRAWER_IS_OPEN"
export const TOGGLE_SUMMARY_IS_OPEN = "TOGGLE_SUMMARY_IS_OPEN"
export const TOGGLE_ZIGBEE_NETWORK_IS_VISIBLE = "TOGGLE_ZIGBEE_NETWORK_IS_VISIBLE"
export const TOGGLE_DATA_TABLE_IS_OPEN = "TOGGLE_DATA_TABLE_IS_OPEN"
export const SET_DATA_VIEW = "SET_DATA_VIEW"
export const SET_TIME_LOCALE = "SET_TIME_LOCALE"
export const SET_ZOOM_LEVEL = "SET_ZOOM_LEVEL"
export const SET_CONFIG_ASSIGN = "SET_CONFIG_ASSIGN"
export const SET_NODE_DATA_STATUS = "SET_NODE_DATA_STATUS"
export const SET_MASTER_DATA_STATUS = "SET_MASTER_DATA_STATUS"
export const SET_REPLACEMENT_CAMPAIGN_STATUS = "SET_REPLACEMENT_CAMPAIGN_STATUS"
export const SET_SITE_VIEW = "SET_SITE_VIEW"
export const DISPLAY_NODEIDS = "DISPLAY_NODEIDS"
export const DISPLAY_NODE_LOCATION_IDS = "DISPLAY_NODE_LOCATION_IDS"
export const ADD_MASTER_LABEL = "ADD_MASTER_LABEL"
export const REMOVE_MASTER_LABEL = "REMOVE_MASTER_LABEL"
export const CLEAR_ALL_MASTER_LABELS = "CLEAR_ALL_MASTER_LABELS"
export const ADD_LAYOUT_ID = "ADD_MASTER_LABEL"
export const REMOVE_LAYOUT_ID = "REMOVE_LAYOUT_ID"
export const CLEAR_ALL_LAYOUT_IDS = "CLEAR_ALL_LAYOUT_IDS"

/*
  FIRESTORE SUBSCRIPTIONS
*/
export const RECEIVE_USER_PROFILE = "RECEIVE_USER_PROFILE"
export const REMOVE_LAYOUT_DETAILS = "REMOVE_LAYOUT_DETAILS"
export const RECEIVE_MASTER_DETAILS = "RECEIVE_MASTER_DETAILS"

/*
  AUTH
*/
export const RECEIVE_USER_AUTH = "RECEIVE_USER_AUTH"

/*
  APPSYNC SETTERS
*/

export const SET_LAYOUT_DETAILS = "SET_LAYOUT_DETAILS"
export const UPDATE_LAYOUT_DETAILS = "UPDATE_LAYOUT_DETAILS"
export const SET_MASTER_DETAILS = "SET_MASTER_DETAILS"
export const UPDATE_MASTER_DETAILS = "UPDATE_MASTER_DETAILS"
export const UPDATE_NODE_DETAILS = "UPDATE_NODE_DETAILS"
export const UPDATE_LATEST_NODE_DATA = "UPDATE_LATEST_NODE_DATA"
export const UPDATE_LATEST_MASTER_DATA = "UPDATE_LATEST_MASTER_DATA"
export const SET_REPLACEMENT_CAMPAIGN_INFO = "SET_REPLACEMENT_CAMPAIGN_INFO"
export const UPDATE_REPLACEMENT_CAMPAIGN_INFO = "UPDATE_REPLACEMENT_CAMPAIGN_INFO"
export const DELETE_REPLACEMENT_CAMPAIGN_INFO = "DELETE_REPLACEMENT_CAMPAIGN_INFO"
export const UPDATE_NODE_ID = "UPDATE_NODE_ID"
export const UPDATE_NODE_SYNC_BITMASKS = "UPDATE_NODE_SYNC_BITMASKS"
export const UPDATE_MASTER_ID = "UPDATE_MASTER_ID"
export const NODE_ID_ACCEPTED = "NODE_ID_ACCEPTED"
export const ONLINE_STATUS = "ONLINE_STATUS"
export const SET_COMMISSIONING_COMPONENT_SELECTION = "SET_COMMISSIONING_COMPONENT_SELECTION"
export const SET_REMEMBER_COMPONENT_SELECTION = "SET_REMEMBER_COMPONENT_SELECTION"
export const SET_NODE_COMPONENTS_SCANNED = "SET_NODE_COMPONENTS_SCANNED"
export const SET_MASTER_COMPONENTS_SCANNED = "SET_MASTER_COMPONENTS_SCANNED"

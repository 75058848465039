import InputDropdown from "./InputDropdown"
import React from "react"
import PropTypes from "prop-types"

const BoardVendorForm = ({ setTaskInfo, taskInfo, label, boardVendors, boardVendor }) => {
	return (
		<div>
			<p>
				If this is a Gen 2 site, please make sure the dip switch is moved for Gen 2 configuration. If Gen 3, please
				continue with installation.
			</p>
			<InputDropdown
				options={boardVendors}
				taskInfo={taskInfo}
				value={boardVendor}
				setTaskInfo={setTaskInfo}
				label={label}
				name={"boardVendor"}
			/>
		</div>
	)
}

BoardVendorForm.propTypes = {
	setTaskInfo: PropTypes.func.isRequired,
	taskInfo: PropTypes.object.isRequired,
	label: PropTypes.string,
	boardVendors: PropTypes.arrayOf(PropTypes.string),
	boardVendor: PropTypes.string,
}

export default BoardVendorForm

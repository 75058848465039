import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { KeyboardDatePicker } from "@material-ui/pickers"
import addDays from "date-fns/addDays"
import set from "date-fns/set"
import { useHistory, useLocation } from "react-router-dom"

import API, { graphqlOperation } from "@aws-amplify/api"
import { createUser as createUserGql, updateUserAttr as updateUserAttrGql } from "graphql/mutations"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		maxWidth: "500px",
		textAlign: "center",
		margin: "auto",
		overflow: "auto",
	},
	paper: {
		maxWidth: "500px",
		textAlign: "center",
		margin: "auto",
		[theme.breakpoints.up("sm")]: {
			marginTop: theme.spacing(5),
			marginBottom: theme.spacing(2),
		},
	},
	content: {
		padding: theme.spacing(2),
	},
	logo: {
		width: "90%",
	},
	button: {
		marginTop: theme.spacing(1),
	},
}))

const UserAttributeForm = ({ userAttributes, handleAttrsUpdate }) => {
	const { ...otherAttrs } = userAttributes

	delete otherAttrs.userCreateData
	delete otherAttrs.userLastModifiedDate
	delete otherAttrs.enabled
	delete otherAttrs.userStatus

	const classes = useStyles()

	const getExpiration = () => {
		return otherAttrs.expiration
			? new Date(otherAttrs.expiration * 1000)
			: set(addDays(new Date(), 1), {
					hours: 23,
					minutes: 59,
					seconds: 59,
					milliseconds: 0,
			  })
	}

	const [pendingResponse, setPendingResponse] = useState(false)
	const [errorFeedback, setErrorFeedback] = useState({})
	const [entries, setEntries] = useState({
		expires: userAttributes.username === undefined || !!otherAttrs.expiration,
		...otherAttrs,
		expiration: getExpiration(),
	})

	let { search, pathname } = useLocation()
	let history = useHistory()

	const handleChange = (event) => {
		event.persist()
		setErrorFeedback({})
		let value
		if (event.target.id === "email") {
			value = event.target.value.toLowerCase()
		} else {
			value = event.target.value
		}
		setEntries((currEntries) => ({ ...currEntries, [event.target.id]: value }))
	}

	const handleCheckboxChange = (event) => {
		let proceed = true

		if (event.currentTarget.checked && event.currentTarget.id === "allLayouts") {
			proceed = confirm(
				"You are giving this user access to all layouts, not just layouts belonging to their company. " +
					"Are you sure that this user should have access to all layouts?",
			)
		}

		if (proceed) {
			event.persist()
			setErrorFeedback({})
			setEntries((currEntries) => ({
				...currEntries,
				[event.target.id]: event.target.checked,
			}))
		} else {
			event.stopPropagation()
		}
	}

	const handleDateChange = (date) => {
		setEntries((currEntries) => ({
			...currEntries,
			expiration: set(date, {
				hours: 23,
				minutes: 59,
				seconds: 59,
				milliseconds: 0,
			}),
		}))
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		setPendingResponse(true)
		const { expires, ...input } = entries
		input["allLayouts"] = entries.allLayouts || false
		input["expiration"] = expires ? parseInt(entries.expiration.getTime() / 1000, 10) : 0

		if (userAttributes.username === undefined) {
			try {
				const { data } = await API.graphql(
					graphqlOperation(createUserGql, {
						input,
					}),
				)
				const params = new URLSearchParams(search)
				params.set("username", data.createUser.username)
				history.push({
					pathname,
					search: `?${params.toString()}`,
				})
			} catch (err) {
				console.error("Error in createUserGql:", err)
			}
		} else {
			try {
				delete input.userCreateDate
				delete input.email
				delete input.__typename
				const { data } = await API.graphql(
					graphqlOperation(updateUserAttrGql, {
						input: input,
					}),
				)
				handleAttrsUpdate(data.updateUserAttr)
			} catch (err) {
				console.error("Error in updateUserAttrGql:", err)
			}
		}
		setPendingResponse(false)
	}

	return (
		<div className={classes.root}>
			{pendingResponse && <LinearProgress />}
			<div className={classes.content}>
				<Typography component="h1" variant="h5">
					User Attributes
				</Typography>
				<form className={classes.form} onSubmit={handleSubmit}>
					<TextField
						id="nameFirst"
						label="First Name"
						type="text"
						margin="normal"
						variant="outlined"
						value={entries.nameFirst || ""}
						onChange={handleChange}
						autoComplete="given-name"
						autoFocus
						required
						fullWidth
					/>
					<TextField
						id="nameLast"
						label="Last Name"
						type="text"
						margin="normal"
						variant="outlined"
						value={entries.nameLast || ""}
						onChange={handleChange}
						autoComplete="family-name"
						required
						fullWidth
					/>
					<TextField
						id="company"
						label="Company Name"
						type="text"
						margin="normal"
						variant="outlined"
						value={entries.company || ""}
						onChange={handleChange}
						autoComplete="company"
						required
						fullWidth
					/>
					<TextField
						id="companyRole"
						label="Company Role"
						type="text"
						margin="normal"
						variant="outlined"
						value={entries.companyRole || ""}
						onChange={handleChange}
						autoComplete="companyRole"
						fullWidth
					/>
					<TextField
						id="email"
						label="Email Address"
						type="email"
						margin="normal"
						variant="outlined"
						disabled={otherAttrs.username && otherAttrs.email === entries.email}
						value={entries.email || ""}
						error={Boolean(errorFeedback.email)}
						helperText={errorFeedback.email}
						onChange={handleChange}
						autoComplete="email"
						required
						fullWidth
					/>
					<FormControlLabel
						control={
							<Checkbox
								id="allLayouts"
								checked={entries.allLayouts || false}
								onChange={handleCheckboxChange}
								value={entries.allLayouts || false}
								color="primary"
								disabled={!entries.email?.toLowerCase().endsWith("@gamechangesolar.com") && !entries.allLayouts}
							/>
						}
						label="All Layouts Accessible"
					/>
					<FormControlLabel
						control={
							<Checkbox
								id="expires"
								checked={entries.expires || false}
								onChange={handleCheckboxChange}
								value={entries.expires || false}
								color="primary"
							/>
						}
						label="Account Expires"
					/>
					{entries.expires && (
						<KeyboardDatePicker
							disableToolbar
							autoOk
							variant="inline"
							format="yyyy-MM-dd"
							margin="normal"
							id="expiration"
							label="Expiration Date"
							value={entries.expiration}
							required={entries.expires}
							onChange={handleDateChange}
							disablePast
							inputVariant="outlined"
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
						/>
					)}
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.button}
						disabled={pendingResponse}
					>
						Apply
					</Button>
				</form>
			</div>
		</div>
	)
}

UserAttributeForm.propTypes = {
	userAttributes: PropTypes.object.isRequired,
	handleAttrsUpdate: PropTypes.func.isRequired,
}

export default UserAttributeForm

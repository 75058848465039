import { connect } from "react-redux"
import ResetPassword from "./ResetPassword"
import { forgotPassword, forgotPasswordSubmit, signInWithEmailAndPassword } from "store/actions/auth"
import { compose } from "redux"
import { withSnackbar } from "notistack"

const mapStateToProps = (state) => ({
	authorized: state.user.privileges > 0,
})

const mapDispatchToProps = (dispatch) => ({
	forgotPassword: (email, password) => dispatch(forgotPassword(email, password)),
	forgotPasswordSubmit: (email, code, newPassword) => dispatch(forgotPasswordSubmit(email, code, newPassword)),
	signIn: (email, password) => dispatch(signInWithEmailAndPassword(email, password)),
})

export default compose(connect(mapStateToProps, mapDispatchToProps), withSnackbar)(ResetPassword)

import React, { Component } from "react"
import DeviceList from "./DeviceList"

class DeviceManager extends Component {
	constructor(props) {
		super(props)
		this.state = {
			devicesLoaded: false,
		}
	}

	loadMasters = () => {
		// const {
		//   subscribeMasterLocations
		// } = this.props;
		this.setState({
			devicesLoaded: false,
		})
		// subscribeMasterLocations()
	}

	componentDidMount = () => {
		this.loadMasters()
	}

	render() {
		const { devicesLoaded } = this.state
		return <DeviceList onRefresh={this.loadMasters} loading={!devicesLoaded} />
	}
}

export default DeviceManager

import React, { useEffect, useState, useMemo } from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Button from "@material-ui/core/Button"
import Controls from "../Controls"
import MasterDataView from "./MasterDataView"
import AssignNodeParameters from "./AssignNodeParameters"
import { privileges } from "constants/auth"
import { useAuthorization } from "utils/auth"
import PropTypes from "prop-types"
import Tooltip from "@material-ui/core/Tooltip"
import EditIcon from "@material-ui/icons/Edit"
import { TextField } from "@material-ui/core"
import { ZendeskAPI } from "react-zendesk"
import API, { graphqlOperation } from "@aws-amplify/api"
import {
	getNodeParameters as getNodeParametersGql,
	getSpaParameters as getSpaParametersGql,
} from "../../../graphql/queries"

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		padding: 0,
	},
	appBar: {
		position: "relative",
		flexShrink: 1,
	},
	title: {
		// marginLeft: theme.spacing(2),
		flex: 1,
	},
	content: {
		height: "100%",
		flex: 1,
		display: "flex",
		flexDirection: "column",
		overflowY: "auto",
	},
	config: {
		display: "flex",
		justifyContent: "center",
	},
	button: {
		margin: "10%",
		marginTop: theme.spacing(3),
	},
	buttons: {
		margin: "auto",
		marginTop: theme.spacing(3),
	},
	tab: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
	},
	configure: {
		height: "100%",
		flexDirection: "column",
		overflowY: "auto",
		flex: 1,
		display: "flex",
		margin: "auto",
		width: "50%",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	data: {
		overflowY: "auto",
	},
	labelEdit: {
		"& > *": {
			color: "gray",
		},
	},
}))
// these are pulled directly from default_spa_config.csv in the GUI
const DEFAULT_STRONGEST_ANGLE = 3000
const DEFAULT_MECH_LIMIT_EAST = 65491
const DEFAULT_MECH_LIMIT_WEST = 4500
const DEFAULT_SOC_STOW_ENABLE = 1
const DEFAULT_SOC_BATT_OFFSET = 0
const DEFAULT_SOC_TEMP_HYST = 2

function AssignMasterId({ mId, accessAllowed, handleOpenSpaParams, handleDownloadSpaParams, openIdInput }) {
	const classes = useStyles()

	return (
		<div className={classes.config}>
			<Button variant="contained" color="primary" className={classes.button} onClick={openIdInput}>
				{mId ? "Replace Master ID" : "Assign Master ID"}
			</Button>
			<Button
				variant="contained"
				color="primary"
				className={classes.button}
				onClick={handleOpenSpaParams}
				disabled={!accessAllowed(privileges.ADMIN)}
			>
				Configure Settings
			</Button>
			<Button
				variant="contained"
				color="primary"
				className={classes.button}
				onClick={handleDownloadSpaParams}
				disabled={!accessAllowed(privileges.ADMIN)}
			>
				Download GUI Config
			</Button>
		</div>
	)
}

AssignMasterId.propTypes = {
	mId: PropTypes.string,
	accessAllowed: PropTypes.func.isRequired,
	handleOpenSpaParams: PropTypes.func.isRequired,
	handleDownloadSpaParams: PropTypes.func.isRequired,
	openIdInput: PropTypes.func.isRequired,
}

const MasterDetails = ({
	userPrivileges,
	handleClose,
	mLocId,
	masterInfo,
	masterDetails,
	masterData,
	openIdInput,
	location,
	history,
	updateLabel,
}) => {
	const [activeTab, setActiveTab] = useState(0)
	const classes = useStyles()
	const accessAllowed = useAuthorization(userPrivileges)

	useEffect(() => {
		ZendeskAPI("webWidget", activeTab === 2 ? "hide" : "show")
	}, [activeTab])

	// adds master UUID to details list
	const masterDetailsAddUuid = useMemo(() => {
		if (!masterDetails) return
		const masterDetailsCopy = [...masterDetails]
		masterDetailsCopy.push({
			dataFormatted: mLocId,
			id: "uuid",
			label: "Master uuid",
		})
		return masterDetailsCopy
	}, [masterDetails, mLocId])

	const handleChangeTab = (event, newTab) => {
		setActiveTab(newTab)
	}

	const onCloseClick = () => {
		ZendeskAPI("webWidget", "hide")
		handleClose()
	}

	const handleOpenSpaParams = () => {
		const params = new URLSearchParams(location.search)
		const deviceIds = params.get("device-details")
		const { spaParamId } = masterInfo
		if (spaParamId) {
			params.set("modify-spa-parameters", `${deviceIds}:${spaParamId}`)
		} else {
			params.set("modify-spa-parameters", `${deviceIds}:new`)
		}
		history.push({
			pathname: location.pathname,
			search: "?" + params.toString(),
		})
	}

	const handleDownloadSpaParams = async () => {
		const { spaParamId, nodeParamId, mId } = masterInfo
		if (spaParamId && nodeParamId) {
			const spaResult = await API.graphql(
				graphqlOperation(getSpaParametersGql, {
					id: spaParamId,
				}),
			)
			const spaValues = spaResult.data["getSpaParameters"]

			//secured spa values, now need to retrieve Node params
			const nodeResult = await API.graphql(
				graphqlOperation(getNodeParametersGql, {
					id: nodeParamId,
				}),
			)
			const nodeValues = nodeResult.data["getNodeParameters"]
			if (spaValues && nodeValues) {
				let lines = ["TYPE,VALUE"]
				// Some values need scaling by 100
				const keysThatNeedScaling = [
					"mechLimitWest",
					"mechLimitEast",
					"overnightAngle",
					"strongestAngle",
					"snowStowAngle",
					"floodStowAngle",
				]
				// the GUI import REQUIRES a very specific order
				const orderedSpaKeys = [
					"utcOffset",
					"deltaUt1",
					"deltaT",
					"longitude",
					"latitude",
					"elevation",
					"pressure",
					"aveTemp",
					"slope",
					"azmRotation",
					"atmosRefraction",
					"rowSpacing",
					"panelLength",
					"planeInclinationAngle",
					"slopeDirection",
					"mechLimitWest",
					"mechLimitEast",
					"overnightAngle",
					"overnightMoveStartHour",
					"overnightMoveEndHour",
					"antishadingEnabled",
					"strongestAngle",
					"snowStowAngle",
					"floodStowAngle",
				]
				orderedSpaKeys.forEach((spaKey) => {
					if (keysThatNeedScaling.includes(spaKey)) {
						lines.push(`${spaKey},${nodeValues[spaKey] * 100}`)
					} else {
						lines.push(`${spaKey},${nodeValues[spaKey]}`)
					}
				})
				const orderedNodeKeys = [
					"lowBatteryLimit",
					"criticalBatteryLimit",
					"highCurrentLimit",
					"hardwareCurrentLimit",
					"overcurrentTries",
					"overcurrentTime",
					"motorOnTime",
					"motorOffTime",
					"rampOnTime",
					"rampDownPropCons",
					"motorOnTimeStow",
					"motorOffTimeStow",
					"mechLimitEast",
					"mechLimitWest",
					"angleTolerance",
					"motionTolerance",
					"noMotionTime",
					"motionFailures",
					"overvoltageThreshold",
					"lowBatteryStow",
					"strongestAngle",
					"waitTimeMotorOff",
					"arrrStatusIntervalMinute",
					"otaTimeout",
					"waitTimeChargerOff",
					"hotTempHiLimit",
					"hotTempLowLimit",
					"coldTempHiLimit",
					"coldTempLowLimit",
					"directionTolerance",
					"startTolerance",
					"startToleranceAntishade",
					"directionTime",
					"motorMinSpeed",
					"limitFailures",
					"limitTolerance",
					"directionFailures",
					"estopDisableTime",
					"nodeBattSocStowEnable",
					"nodeSocBattVoltOffset",
					"nodeBattSocStowTempHysteresis",
					"lowBattThresholdDuringMotion",
				]
				orderedNodeKeys.forEach((nodeKey) => {
					if (nodeValues[nodeKey] === true) {
						lines.push(`${nodeKey},1`)
					} else if (nodeValues[nodeKey] === false) {
						lines.push(`${nodeKey},0`)
					} else if (nodeValues[nodeKey] === undefined) {
						// These are hidden values that are SOMETIMES set in the db, sometimes not
						switch (nodeKey) {
							case "strongestAngle":
								lines.push(`${nodeKey},${DEFAULT_STRONGEST_ANGLE}`)
								break
							case "mechLimitEast":
								lines.push(`${nodeKey},${DEFAULT_MECH_LIMIT_EAST}`)
								break
							case "mechLimitWest":
								lines.push(`${nodeKey},${DEFAULT_MECH_LIMIT_WEST}`)
								break
							case "nodeSocBattVoltOffset":
								lines.push(`${nodeKey},${DEFAULT_SOC_BATT_OFFSET}`)
								break
							case "nodeBattSocStowEnable":
								lines.push(`${nodeKey},${DEFAULT_SOC_STOW_ENABLE}`)
								break
							case "nodeBattSocStowTempHysteresis":
								lines.push(`${nodeKey},${DEFAULT_SOC_TEMP_HYST}`)
								break
							default:
								throw new Error(`Unrecognized undefined value ${nodeKey}`)
						}
					} else {
						if (keysThatNeedScaling.includes(nodeKey)) {
							lines.push(`${nodeKey},${nodeValues[nodeKey] * 100}`)
						} else {
							lines.push(`${nodeKey},${nodeValues[nodeKey]}`)
						}
					}
				})
				// make a .csv file
				const csvData = "data:text;charset=utf-8," + lines.join("\n")
				const encodedUri = encodeURI(csvData)
				const link = document.createElement("a")
				link.setAttribute("href", encodedUri)
				link.setAttribute("download", `${mId}_spa_config.csv`)
				document.body.appendChild(link) // Required for FireFox
				link.click() // This will download the file
				document.body.removeChild(link)
			} else {
				alert("Master parameters are malformed. Ensure parameters are set before attempting download.")
			}
		} else {
			alert("Master is missing Master or Node parameters.")
		}
	}

	const { name, label, layoutId, mId } = masterInfo
	const [disableLabelEdit, setDisableLabelEdit] = useState(true)
	const [labelValue, setLabelValue] = useState(label)
	useEffect(() => {
		setLabelValue(label)
	}, [label])

	const handleLabelBlur = () => {
		if (labelValue !== label) {
			updateLabel(mLocId, layoutId, labelValue)
		}
		setDisableLabelEdit(true)
	}

	const handleEditLabel = async () => {
		setDisableLabelEdit(false)
		const labelInput = await getElementByIdAsync("labelInput")
		if (labelInput) {
			labelInput.focus()
		}
	}

	const handleChangeLabelInput = (value) => {
		// The rest-api sets a maximum length which we are matching here
		const maxLabelLength = 100
		setLabelValue(value.toString().replaceAll(",", "").slice(0, maxLabelLength))
	}

	const getElementByIdAsync = (id) =>
		new Promise((resolve) => {
			const getElement = () => {
				const element = document.getElementById(id)
				if (element) {
					resolve(element)
				} else {
					requestAnimationFrame(getElement)
				}
			}
			getElement()
		})

	return (
		<div className={classes.root}>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<Typography variant="h6" className={classes.title}>
						<div style={{ float: "left", marginRight: "20px" }}>{name ? name : "Name Unassigned"}</div>
						<div style={{ float: "left", marginRight: "20px", marginLeft: "20px" }}>
							{disableLabelEdit ? (
								<i>{labelValue || "no label"}</i>
							) : (
								<TextField
									id="labelInput"
									className={classes.labelEdit}
									value={labelValue ? labelValue : ""}
									disabled={disableLabelEdit}
									onChange={(e) => handleChangeLabelInput(e.target.value)}
									onBlur={handleLabelBlur}
								/>
							)}
							<div className={classes.actions} style={{ float: "right", marginRight: "20px" }}>
								<Tooltip key={"edit"} title="Edit label">
									<IconButton
										aria-label="Edit label"
										onClick={() => handleEditLabel()}
										style={{ padding: 0, paddingLeft: "12px", visibility: disableLabelEdit ? "visible" : "hidden" }}
									>
										<EditIcon style={{ color: "white" }} />
									</IconButton>
								</Tooltip>
							</div>
						</div>
					</Typography>
					<IconButton edge="end" color="inherit" onClick={onCloseClick} aria-label="Close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<div className={classes.content}>
				<AppBar position="static" color="default">
					<Tabs value={activeTab} onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
						<Tab label="Details" />
						<Tab
							disabled={!accessAllowed(privileges.CONTROL_STANDARD | privileges.CONTROL_CX) || !mId}
							label="Control"
						/>
						<Tab disabled={!accessAllowed(privileges.CONFIGURATION)} label="Configure" />
						<Tab disabled={!accessAllowed(privileges.ADMIN)} label="Node Parameters" />
					</Tabs>
				</AppBar>
				{activeTab === 0 && (
					<div className={classes.data}>
						<MasterDataView masterData={masterData} masterDetails={masterDetailsAddUuid} layoutId={layoutId} />
					</div>
				)}
				{activeTab === 1 && (
					<div className={classes.tab}>
						<Controls mLocId={mLocId} />
					</div>
				)}
				{activeTab === 2 && (
					<AssignMasterId
						accessAllowed={accessAllowed}
						handleOpenSpaParams={handleOpenSpaParams}
						handleDownloadSpaParams={handleDownloadSpaParams}
						openIdInput={openIdInput}
						mId={mId}
					/>
				)}
				{activeTab === 3 && (
					<div className={classes.data}>
						<AssignNodeParameters
							masterData={masterData}
							masterDetails={masterDetails}
							mLocId={mLocId}
							layoutId={layoutId}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

MasterDetails.propTypes = {
	userPrivileges: PropTypes.any,
	handleClose: PropTypes.func,
	mLocId: PropTypes.string.isRequired,
	masterInfo: PropTypes.object.isRequired,
	masterDetails: PropTypes.any,
	masterData: PropTypes.any,
	openIdInput: PropTypes.func,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	updateLabel: PropTypes.func.isRequired,
}

export default MasterDetails

import { connect } from "react-redux"
import { compose } from "redux"
import NodeMarkers from "./NodeMarkers"
import { withSnackbar } from "notistack"

const getMasterList = (state, props) => {
	if (!props.layoutId || !state.layoutDetails[props.layoutId]) return []
	return state.layoutDetails[props.layoutId].mLocIds || []
}

const mapStateToProps = (state, props) => ({
	locations: state.configLocations,
	masterList: getMasterList(state, props),
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(withSnackbar, connect(mapStateToProps, mapDispatchToProps))(NodeMarkers)

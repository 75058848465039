import React from "react"
import Zendesk from "react-zendesk"
import { colorPrimary } from "../../colors"

const ZENDESK_KEY = "5e12be05-0eb5-449b-97b4-a4cca61f8224"

// Customise our contact form appearance, launcher and add prefill content
const setting = {
	color: {
		theme: colorPrimary,
	},
	launcher: {
		chatLabel: {
			"en-US": "Support",
		},
	},
}

const ZendeskWidget = () => {
	return <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} />
}

ZendeskWidget.propTypes = {}

export default ZendeskWidget

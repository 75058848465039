import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Circle } from "react-leaflet"

const useStyles = makeStyles((theme) => ({
	defaultColor: {
		stroke: theme.palette.primary.main,
		fill: theme.palette.primary.main,
	},
	alarmColor: {
		stroke: theme.palette.error.main,
		fill: theme.palette.error.main,
	},
}))

const LayoutMarker = ({
	layoutId,
	latitude,
	longitude,
	published,
	setLayoutSecondarySelection,
	clearLayoutSecondarySelection,
}) => {
	const classes = useStyles()
	if (latitude === null || longitude === null) return null
	// if(!published) return null;

	return (
		<Circle
			key={layoutId}
			className={classes.defaultColor}
			center={[latitude, longitude]}
			radius={500}
			onMouseOver={setLayoutSecondarySelection}
			onMouseOut={() => setTimeout(clearLayoutSecondarySelection, 250)}
		/>
	)
}

LayoutMarker.propTypes = {
	layoutId: PropTypes.string.isRequired,
	latitude: PropTypes.number,
	longitude: PropTypes.number,
}

export default LayoutMarker

import { connect } from "react-redux"
import DeleteLayout from "./DeleteLayout"
import { withRouter } from "react-router"
import { compose } from "redux"
import { clearLayoutSecondarySelection, removeLayoutDetails } from "../../../../store/actions/reduxActions"
import { withSnackbar } from "notistack"
import api from "../../../../constants/api"
import { deleteCachedDetails } from "../../../../store/actions/fetchers"
import API, { graphqlOperation } from "@aws-amplify/api"
import { deleteLayoutDetails } from "../../../../graphql/mutations"

const mapStateToProps = (_state, _props) => ({})

const mapDispatchToProps = (dispatch, _props) => ({
	removeLayout: (layoutId) => {
		async function performRemoveLayoutDetails(layoutId) {
			dispatch(removeLayoutDetails(layoutId))
			dispatch(clearLayoutSecondarySelection(layoutId))
			await deleteCachedDetails("layoutDetails", [layoutId])
			await API.graphql(graphqlOperation(deleteLayoutDetails, { input: { id: layoutId } }))
			// Delete layout SVG file from S3 bucket
			// TODO: We need to check if any other layouts are using this SVG before deleting it
			// Storage.remove(svgUrl)
		}
		return api
			.deleteLayout(layoutId)
			.then(() => performRemoveLayoutDetails(layoutId))
			.catch(async (error) => {
				if (error.status === 404) {
					console.warn(
						"Layout not found in API. It may have already been deleted or not imported yet. " +
							"Pretending like it was deleted successfully.",
					)
					await performRemoveLayoutDetails(layoutId)
				} else {
					console.error("Could not remove layout from API:", error)
					throw error
				}
			})
	},
})

export default compose(withRouter, withSnackbar, connect(mapStateToProps, mapDispatchToProps))(DeleteLayout)

import React, { createRef, useState } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { ImageOverlay, Map } from "react-leaflet"
import leaflet from "leaflet"
import "leaflet/dist/leaflet.css"
import "leaflet-draw/dist/leaflet.draw.css"

import NodeMarkers from "./NodeMarkers"
import LayoutOverlay from "./LayoutOverlay"
import DrawLayer from "./DrawLayer"

const styles = {
	map: {
		height: "100%",
		width: "100%",
	},
}

const LayoutMapConfig = ({ classes, layoutUrl, layoutId, setLayoutZoomLevel }) => {
	const mapRef = createRef()
	const [displayAllNodes, setDisplayAllNodes] = useState(false)

	return (
		<Map
			ref={mapRef}
			center={[500, 500]}
			className={classes.map}
			crs={leaflet.CRS.Simple}
			bounds={[
				[0, 0],
				[1000, 1000],
			]}
			boundsOptions={{ padding: [50, 50] }}
			maxBounds={[
				[-1000, -1000],
				[2000, 2000],
			]}
			zoomControl={false}
			doubleClickZoom={true}
			zoomSnap={0.1}
			minZoom={-2}
			zoom={0}
			attributionControl={false}
			onZoomEnd={() => {
				if (mapRef.current && mapRef.current.leafletElement) {
					setLayoutZoomLevel(mapRef.current.leafletElement.getZoom())
				}
			}}
		>
			{
				<ImageOverlay
					key={layoutUrl}
					url={layoutUrl}
					bounds={[
						[0, 0],
						[1000, 1000],
					]}
				/>
			}
			{<DrawLayer />}
			{<LayoutOverlay displayAllNodes={displayAllNodes} setDisplayAllNodes={setDisplayAllNodes} />}
			{<NodeMarkers layoutId={layoutId} displayAllNodes={displayAllNodes} />}
		</Map>
	)
}

LayoutMapConfig.propTypes = {
	classes: PropTypes.object.isRequired,
	layoutUrl: PropTypes.string.isRequired,
	layoutId: PropTypes.string.isRequired,
	setLayoutZoomLevel: PropTypes.func.isRequired,
}

export default withStyles(styles)(LayoutMapConfig)

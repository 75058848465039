import React, { Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import Divider from "@material-ui/core/Divider"
import PropTypes, { arrayOf } from "prop-types"

const useStyles = makeStyles((theme) => ({
	container: {
		maxHeight: 420,
		[theme.breakpoints.down("xs")]: {
			maxHeight: 220,
		},
	},
	valueCell: {
		textAlign: "right",
	},
	clickable: {
		cursor: "pointer",
	},
}))

const SiteSummary = ({ summaryHeading, summaryData }) => {
	const classes = useStyles()
	return (
		<Fragment>
			{summaryHeading !== null && (
				<Fragment>
					<Divider />
					<Divider />
					<TableContainer className={classes.container}>
						<Table size="small" stickyHeader>
							<TableHead>
								<TableRow>
									{summaryHeading.map((headingDesc, ind, { length }) => (
										<TableCell key={headingDesc} className={ind === length - 1 && classes.valueCell}>
											{
												/** @type {string} */
												headingDesc
											}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{summaryData === null ? (
									<TableRow>
										<TableCell>No Data</TableCell>
										<TableCell />
									</TableRow>
								) : summaryData.length === 0 ? (
									<TableRow>
										<TableCell>None</TableCell>
										<TableCell />
									</TableRow>
								) : (
									summaryData.map((dataRow, index) => {
										return (
											<TableRow key={index} className={classes.notClickable}>
												<TableCell>{dataRow.label}</TableCell>
												<TableCell className={classes.valueCell}>{dataRow.value}</TableCell>
											</TableRow>
										)
									})
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Fragment>
			)}
		</Fragment>
	)
}

SiteSummary.propTypes = {
	summaryHeading: arrayOf(PropTypes.string),
	summaryData: arrayOf(PropTypes.object),
	visibilityMask: PropTypes.number,
}
export default SiteSummary

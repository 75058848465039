export const subscriptionTypes = {
	jobsStarted: "jobsStarted",
	allJobsFinished: "allJobsFinished",
}

class PendingJobsManager {
	_pendingJobs = new Set()
	_subscribers = {
		[subscriptionTypes.jobsStarted]: [],
		[subscriptionTypes.allJobsFinished]: [],
	}
	debug = false

	constructor(jobsStartedSubscribers = null, allJobsFinishedSubscribers = null, debug = false) {
		this.debug = debug

		const subscriptionTypeMapping = [
			[jobsStartedSubscribers, subscriptionTypes.jobsStarted],
			[allJobsFinishedSubscribers, subscriptionTypes.allJobsFinished],
		]

		subscriptionTypeMapping.forEach(([subscribers, subType]) => {
			if (subscribers) {
				if (typeof subscribers === "function") {
					subscribers = [subscribers]
				}
				subscribers.forEach((subscriber) => this._validateSubscriber(subscriber))
				if (debug) {
					subscribers.push(this._getDebugSubscriber(subType))
				}
				this._subscribers[subType] = subscribers
			}
		})
	}

	_getDebugSubscriber(subType) {
		switch (subType) {
			case subscriptionTypes.jobsStarted:
				return () => {
					console.debug("Jobs have started:", this._pendingJobs)
				}
			case subscriptionTypes.allJobsFinished:
				return () => {
					console.debug("All jobs have finished.")
				}
			default:
				throw new Error(`Unsupported subscription type: ${subType}`)
		}
	}

	_validateSubscriber(subscriber) {
		if (!(typeof subscriber === "function")) throw new Error(`subscriber is not callable: ${subscriber}`)
	}

	_callSubscribers(subscriptionType) {
		this._subscribers[subscriptionType].forEach((subscriber) => {
			subscriber()
		})
	}

	/**
	 * Adds a subscriber (callback function) to be called according to the provided subscription type
	 * (see subscriptionTypes).
	 * @param {string} subscriptionType
	 * @param {function} callback
	 */
	subscribe(subscriptionType, callback) {
		this._validateSubscriber(callback)
		this._subscribers[subscriptionType].push(callback)
	}

	unsubscribeAll() {
		this._subscribers = {
			[subscriptionTypes.jobsStarted]: [],
			[subscriptionTypes.allJobsFinished]: [],
		}
	}

	reportJobStarted(jobName) {
		if (this.debug) console.debug(`Job ${jobName} started`)
		if (this._pendingJobs.has(jobName)) throw new Error(`Job '${jobName}' is already running.`)
		this._pendingJobs.add(jobName)
		if (this._pendingJobs.size === 1) {
			this._callSubscribers(subscriptionTypes.jobsStarted)
		}
	}

	reportJobFinished(jobName) {
		if (this.debug) console.debug(`Job ${jobName} finished`)
		if (!this._pendingJobs.has(jobName)) throw new Error(`Job '${jobName}' does not exist.`)
		this._pendingJobs.delete(jobName)
		if (this._pendingJobs.size === 0) {
			this._callSubscribers(subscriptionTypes.allJobsFinished)
		}
	}

	getNbPendingJobs() {
		return this._pendingJobs.size
	}
}

export default PendingJobsManager

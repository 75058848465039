import { connect } from "react-redux"
import NodeLabelsByMaster from "./NodeLabelsByMaster"

const mapStateToProps = (state, props) => ({
	nLocIds: Object.keys(state.nodeDetails[props.mLocId] || {}),
	overlayNIds: state.overlayNIds,
	overlayNLocIds: state.overlayNLocIds,
})

const mapDispatchToProps = (state, props) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(NodeLabelsByMaster)

import API, { graphqlOperation } from "@aws-amplify/api"
import {
	getLatestNodeDataByMLocId,
	getNodeDataByMLocId,
	listLatestMasterDataByLayoutId,
	listMasterDataHistory,
	listMasterLocations as listMasterLocationsGql,
	getNodeLocations as getNodeLocationsByMasterId,
	listReplacementCampaignInfoByLayoutId as listReplacementCampaignInfoGql,
} from "graphql/queries"
import {
	subscribeMasterIdUpdate as subscribeMasterIdUpdateGql,
	subscribeNodeIdUpdate as subscribeNodeIdUpdateGql,
	subscribeNodeSyncBitmasksUpdate as subscribeNodeSyncBitmasksUpdateGql,
	subscribeReplacementCampaignUpdate as subscribeReplacementCampaignUpdateGql,
} from "graphql/subscriptions"
import * as actionTypes from "./types"
import offlineStorage from "offlineStorage"
import api from "../../constants/api"
import { convertTimezoneToUTCOffset } from "../../utils/timezone"
import { s3Config } from "../../config/aws"

const cacheLoaded = {
	layoutDetails: false,
	masterDetails: {},
	nodeDetails: {},
}

const listToDict = (keyId, valuesList) => {
	if (valuesList === null) {
		throw new Error("valuesList cannot be null")
	}
	const valuesDict = {}
	for (let i = 0; i < valuesList.length; i++) {
		const thisValue = valuesList[i]
		if (thisValue === null) {
			throw new Error("value cannot be null")
		}
		const keyIdValue = thisValue[keyId]
		if (keyIdValue === undefined) {
			throw new Error(`Key '${keyId}' is not present in object #${i}`)
		}
		valuesDict[thisValue[keyId]] = thisValue
	}
	return valuesDict
}

const fetchCachedLayoutDetails = async () => {
	if (!cacheLoaded["layoutDetails"]) {
		cacheLoaded["layoutDetails"] = true
		return (await offlineStorage).getAll("layoutDetails")
	}
	return []
}

const fetchCachedMasterDetails = async (layoutId) => {
	if (!cacheLoaded["masterDetails"][layoutId]) {
		cacheLoaded["masterDetails"][layoutId] = true
		return (await offlineStorage).getAllFromIndex("masterDetails", "layoutId", layoutId)
	}
	return []
}

const fetchCachedNodeDetails = async (layoutId) => {
	if (!cacheLoaded["nodeDetails"][layoutId]) {
		cacheLoaded["nodeDetails"][layoutId] = true
		return (await offlineStorage).getAllFromIndex("nodeDetails", "layoutId", layoutId)
	}
}

const setCachedDetails = async (storeName, items) => {
	try {
		const tx = (await offlineStorage).transaction(storeName, "readwrite")
		const store = tx.objectStore(storeName)
		await store.clear()
		for (let i = 0; i < items.length; i++) {
			await store.put(items[i])
		}
		return tx.done
	} catch (err) {
		console.error(`Could not cache ${storeName} items: ${items}.`, err)
		return items
	}
}
export const updateCachedDetails = async (storeName, items) => {
	try {
		const tx = (await offlineStorage).transaction(storeName, "readwrite")
		const store = tx.objectStore(storeName)
		const results = await Promise.allSettled(items.map((item) => store.put(item)))
		let anyRejected = false
		for (let i = 0; i < results.length; i++) {
			const result = results[i]
			if (result.status === "rejected") {
				anyRejected = true
				console.error(`Could not cache ${storeName} item: ${items[i]}.`, result.reason)
			}
		}
		if (anyRejected) {
			return items
		}
		return tx.done
	} catch (err) {
		console.error(`Could not cache ${storeName} items: ${items}.`, err)
		return items
	}
}

export const deleteCachedDetails = async (storeName, keys) => {
	try {
		const tx = (await offlineStorage).transaction(storeName, "readwrite")
		const store = tx.objectStore(storeName)
		await Promise.all(keys.map((key) => store.delete(key)))
		return tx.done
	} catch (err) {
		console.error(`Could not delete ${storeName} items: ${keys}.`, err)
		return keys
	}
}

const fetchNetworkMasterDetails = async (layoutId) => {
	let data
	try {
		const result = await API.graphql(
			graphqlOperation(listMasterLocationsGql, {
				layoutId,
			}),
		)
		data = result.data
	} catch (err) {
		data = err.data
		console.warn("Occurred while getting saved master details (will try to remedy):", err)
	}
	if (data) {
		const { listMasterLocations } = data
		if (listMasterLocations) {
			const { items } = listMasterLocations
			if (items) {
				return items
			}
		}
	}
	console.error("Could not get saved master data.")
	return []
}

const fetchNetworkNodeDetails = async (layoutId, mLocId) => {
	let data
	try {
		const result = await API.graphql(
			graphqlOperation(getNodeLocationsByMasterId, {
				layoutId,
				mLocId,
			}),
		)
		data = result.data
	} catch (err) {
		data = err.data
		console.error("listNodeLocationsByLayoutId", err)
	}
	if (data) {
		const { getNodeLocations } = data
		if (getNodeLocations) {
			return getNodeLocations
		}
	}
}

/**
 * This is used to cache the details of newly created / updated layouts.
 * Because we have two different responses from REST API and GraphQL and for the cache we are using Rest API format,
 * so to cache the new or update layout we need to convert GraphQL response to Rest API format.
 */
export function _convertSiteDetailsFromLocalToApiFormat(localData) {
	return {
		address: localData.address1,
		geo_location: localData.coordinates
			? { type: "Point", coordinates: [localData.coordinates.longitude, localData.coordinates.latitude] }
			: null,
		city: localData.city,
		owner: localData.customerName,
		uuid: localData.id,
		label_zoom_threshold: localData.labelZoomThreshold,
		layout_number: localData.layoutNumber,
		file: localData.layoutUrl ? `https://${s3Config.bucket}.s3.amazonaws.com/${localData.layoutUrl}` : null,
		masters: localData.mLocIds,
		marker_radius: localData.markerRadius,
		name: localData.name,
		power_capacity: localData.powerCapacityDc,
		published: localData.published,
		state: localData.state,
		timezone: localData.tz,
		utc_offset: localData.utcOffset,
		weathersmart_enabled: localData.weathersmartEnabled,
		hailstow_enabled: localData.hailstowEnabled,
		powerboost_enabled: localData.powerboostEnabled,
		snowshield_enabled: localData.snowshieldEnabled,
		thundersmart_enabled: localData.thundersmartEnabled,
	}
}

/**
 * Converts the site details from the API format to the local legacy format.
 * @param apiData The raw site data from the API
 * @returns The site data in the legacy format used by the gcs-monitor app and redux store
 */
function _convertSiteDetailsFromApiToLocalFormat(apiData) {
	const [longitude, latitude] = apiData["geo_location"]?.coordinates ?? [null, null]
	return {
		address1: apiData.address,
		coordinates: { latitude, longitude },
		customerName: typeof apiData?.owner === "string" ? apiData?.owner : "Unknown",
		id: apiData.uuid,
		labelZoomThreshold: apiData["label_zoom_threshold"],
		layoutNumber: apiData["layout_number"],
		layoutUrl: apiData.file,
		mLocIds: apiData.masters,
		markerRadius: apiData["marker_radius"],
		name: apiData.name,
		owner: typeof apiData?.owner === "string" ? apiData?.owner : "Unknown",
		powerCapacityDc: apiData["power_capacity"],
		published: apiData.published,
		state: apiData.state,
		tz: apiData.timezone,
		utcOffset:
			apiData.utc_offset !== null
				? apiData.utc_offset
				: apiData.timezone
				? convertTimezoneToUTCOffset(apiData.timezone)
				: "",
		weathersmartEnabled: apiData["weathersmart_enabled"],
		hailstowEnabled: apiData["hailstow_enabled"],
		powerboostEnabled: apiData["powerboost_enabled"],
		snowshieldEnabled: apiData["snowshield_enabled"],
		thundersmartEnabled: apiData["thundersmart_enabled"],
	}
}

function listToDictLayoutDetails(layouts) {
	return layouts.reduce((acc, layout) => {
		acc[layout.uuid] = _convertSiteDetailsFromApiToLocalFormat(layout)
		return acc
	}, {})
}

export const fetchLayoutDetails = () => async (dispatch, _getState) => {
	let layoutDetails

	const cachedResults = await fetchCachedLayoutDetails()
	if (cachedResults.length) {
		layoutDetails = listToDictLayoutDetails(cachedResults)

		await dispatch({
			type: actionTypes.SET_LAYOUT_DETAILS,
			layoutDetails,
			fromCache: true,
		})
	}

	const res = await api.getSiteLayouts()

	if (!res) {
		console.error("Could not get site layouts from REST API (no response)")
		return
	}

	const networkResults = res.ok ? res.body : []

	if (networkResults.length) {
		layoutDetails = listToDictLayoutDetails(networkResults)

		await dispatch({
			type: actionTypes.SET_LAYOUT_DETAILS,
			layoutDetails,
			fromCache: false,
		})
		return await setCachedDetails("layoutDetails", networkResults)
	}
}

export const fetchLayoutDetailBySiteUuid = (siteUuid) => async (dispatch, _getState) => {
	const res = await api.getSiteLayoutById(siteUuid)
	if (!res) {
		console.error("Could not get site layout by UUID from REST API (no response)")
		return
	}
	const layout = res.ok ? res.body : null

	if (layout) {
		const layoutDetails = _convertSiteDetailsFromApiToLocalFormat(layout)
		await dispatch({
			type: actionTypes.UPDATE_LAYOUT_DETAILS,
			layoutDetails,
		})
		return await updateCachedDetails("layoutDetails", [layout])
	}
}

export const fetchMasterDetails = (layoutId) => async (dispatch) => {
	let masterDetails

	let cachedResults = await fetchCachedMasterDetails(layoutId)
	cachedResults = cachedResults.filter((n) => n) // Remove falsy/null values

	if (cachedResults.length) {
		masterDetails = listToDict("id", cachedResults)
		await dispatch({
			type: actionTypes.SET_MASTER_DETAILS,
			masterDetails,
			fromCache: true,
		})
	}

	let networkResults = await fetchNetworkMasterDetails(layoutId)
	networkResults = networkResults.filter((n) => n !== null && n !== undefined) // Remove null values

	if (networkResults.length) {
		masterDetails = listToDict("id", networkResults)
		await dispatch({
			type: actionTypes.SET_MASTER_DETAILS,
			masterDetails,
			fromCache: false,
		})
		return await setCachedDetails("masterDetails", networkResults)
	}
}

export const fetchNodeDetails = (layoutId, masters) => async (dispatch) => {
	const filteredMasters = Object.values(masters).filter((master) => layoutId === master.layoutId)

	const cachedResults = filteredMasters.map((master) => fetchCachedNodeDetails(layoutId, master.id))
	if (cachedResults.length) {
		Promise.all(cachedResults)
			.then((result) => {
				const cachedResultsFiltered = result.filter((result) => result !== undefined).flat()
				dispatch({
					type: actionTypes.UPDATE_NODE_DETAILS,
					nodeDetails: cachedResultsFiltered,
					fromCache: true,
				})
			})
			.catch((err) => console.error("fetchCachedNodeDetails", err))
	}

	const requests = filteredMasters.map((master) => fetchNetworkNodeDetails(layoutId, master.id))
	if (requests.length) {
		Promise.all(requests)
			.then((result) => {
				const resultsFiltered = result.filter((result) => result !== undefined)
				dispatch({
					type: actionTypes.UPDATE_NODE_DETAILS,
					nodeDetails: resultsFiltered,
					fromCache: false,
				})
				return setCachedDetails("nodeDetails", result)
			})
			.catch((err) => console.error("fetchNetworkNodeDetails", err))
	}
}

export const fetchNodeData = (layoutId, mLocId, userPrivileges, deviceType, timestamp) => async (dispatch) => {
	await dispatch({
		type: actionTypes.SET_NODE_DATA_STATUS,
		mLocId,
		status: "pending",
	})
	let data
	try {
		let result
		if (timestamp) {
			result = await API.graphql(
				graphqlOperation(getNodeDataByMLocId, {
					layoutId,
					mLocId,
					timestamp,
				}),
			)
		} else {
			result = await API.graphql(
				graphqlOperation(getLatestNodeDataByMLocId, {
					layoutId,
					mLocId,
				}),
			)
		}
		data = result.data
	} catch (err) {
		console.error("geNodeDataByMLocId", err)
		await dispatch({
			type: actionTypes.SET_NODE_DATA_STATUS,
			mLocId,
			status: "error",
		})
		if (err.errors.filter((e) => e.message === "Network Error").length > 0) {
			return "networkError"
		} else {
			return "error"
		}
	}

	if (data && (data.getLatestNodeDataByMLocId || data.getNodeDataByMLocId)) {
		const item = data.getLatestNodeDataByMLocId || data.getNodeDataByMLocId
		if (item !== undefined) {
			await dispatch({
				type: actionTypes.UPDATE_LATEST_NODE_DATA,
				data: item,
				userPrivileges,
				deviceType,
			})
			return "resolved"
		} else {
			await dispatch({
				type: actionTypes.SET_NODE_DATA_STATUS,
				mLocId,
				status: "resolved",
			})
			return "empty"
		}
	} else {
		await dispatch({
			type: actionTypes.SET_NODE_DATA_STATUS,
			mLocId,
			status: "resolved",
		})
		return "empty"
	}
}

export const fetchMasterDataByLayoutId = (layoutId, userPrivileges, deviceType, timestamp) => async (dispatch) => {
	await dispatch({
		type: actionTypes.SET_MASTER_DATA_STATUS,
		status: "pending",
	})
	let data
	try {
		let result
		if (timestamp) {
			result = await API.graphql(
				graphqlOperation(listMasterDataHistory, {
					layoutId,
					timestamp,
				}),
			)
		} else {
			result = await API.graphql(
				graphqlOperation(listLatestMasterDataByLayoutId, {
					layoutId,
				}),
			)
		}
		data = result.data
	} catch (err) {
		console.error("listLatestMasterDataByLayoutId", err)
		await dispatch({
			type: actionTypes.SET_MASTER_DATA_STATUS,
			status: "error",
		})
		if (err.errors.filter((e) => e.message === "Network Error").length > 0) {
			return "networkError"
		} else {
			return "error"
		}
	}

	if (data && (data.listLatestMasterDataByLayoutId || data.listMasterDataHistory)) {
		const { items } = data.listLatestMasterDataByLayoutId || data.listMasterDataHistory
		if (items !== undefined) {
			await dispatch({
				type: actionTypes.UPDATE_LATEST_MASTER_DATA,
				data: items,
				userPrivileges,
				deviceType,
			})
			return "resolved"
		} else {
			await dispatch({
				type: actionTypes.SET_MASTER_DATA_STATUS,
				status: "resolved",
			})
			return "empty"
		}
	} else {
		await dispatch({
			type: actionTypes.SET_MASTER_DATA_STATUS,
			status: "resolved",
		})
		return "empty"
	}
}

export const listReplacementCampaignInfo = (layoutId) => async (dispatch) => {
	await dispatch({
		type: actionTypes.SET_REPLACEMENT_CAMPAIGN_STATUS,
		status: "pending",
	})
	let data
	try {
		const result = await API.graphql(
			graphqlOperation(listReplacementCampaignInfoGql, {
				layoutId,
			}),
		)
		data = result.data
	} catch (err) {
		console.log("listReplacementCampaignInfo", err)
		await dispatch({
			type: actionTypes.SET_REPLACEMENT_CAMPAIGN_STATUS,
			status: "error",
		})
		if (err.errors.filter((e) => e.message === "Network Error").length > 0) {
			return "networkError"
		} else {
			return "error"
		}
	}

	if (data && data.listReplacementCampaignInfoByLayoutId) {
		const { items } = data.listReplacementCampaignInfoByLayoutId
		if (items !== undefined) {
			const replacementCampaignInfo = {}
			items.forEach((master) => {
				replacementCampaignInfo[master.mLocId] = { ...master }
				replacementCampaignInfo[master.mLocId]["replacements"] = {}
				master["replacements"].forEach((replacement) => {
					replacementCampaignInfo[master.mLocId]["replacements"][replacement.index.toString().padStart(3, "0")] =
						replacement.id
				})
			})
			await dispatch({
				type: actionTypes.SET_REPLACEMENT_CAMPAIGN_INFO,
				data: replacementCampaignInfo,
			})
			return "resolved"
		} else {
			await dispatch({
				type: actionTypes.SET_REPLACEMENT_CAMPAIGN_STATUS,
				status: "resolved",
			})
			return "empty"
		}
	} else {
		await dispatch({
			type: actionTypes.SET_REPLACEMENT_CAMPAIGN_STATUS,
			status: "resolved",
		})
		return "empty"
	}
}

export const subscribeReplacementCampaignUpdates = (layoutId) => (dispatch) => {
	const subscription = API.graphql(
		graphqlOperation(subscribeReplacementCampaignUpdateGql, {
			layoutId,
		}),
	).subscribe({
		next: ({ value }) => {
			const { data } = value
			console.log(data)
			if (data && data.subscribeReplacementCampaignUpdate) {
				console.log("subscribeReplacementCampaign", data)
				dispatch({
					type: actionTypes.UPDATE_REPLACEMENT_CAMPAIGN_INFO,
					...data.subscribeReplacementCampaignUpdate,
					nLocId: data.subscribeReplacementCampaignUpdate.nLocId.toString().padStart(3, "0"),
				})
			}
		},
		error: (err) => {
			console.log("SUB ERROR:", err)
		},
	})
	return subscription.unsubscribe.bind(subscription)
}

export const subscribeNodeSyncBitmasksUpdate = (layoutId) => (dispatch) => {
	const subscription = API.graphql(
		graphqlOperation(subscribeNodeSyncBitmasksUpdateGql, {
			layoutId,
		}),
	).subscribe({
		next: async (data) => {
			if (data) {
				console.log("subscribeNodeSyncBitmasksUpdateGql", data)
				const nodeSyncBitmasksUpdate = ((data["value"] || {})["data"] || {})["subscribeNodeSyncBitmasksUpdate"]
				if (nodeSyncBitmasksUpdate) {
					const { mLocId, bitmasks } = nodeSyncBitmasksUpdate
					await dispatch({
						type: actionTypes.UPDATE_NODE_SYNC_BITMASKS,
						mLocId,
						bitmasks,
					})
				}
			}
		},
		error: (err) => {
			console.log("SUB ERROR:", err)
		},
	})
	return subscription.unsubscribe.bind(subscription)
}

export const subscribeNodeIdUpdates = (layoutId) => (dispatch) => {
	const subscription = API.graphql(
		graphqlOperation(subscribeNodeIdUpdateGql, {
			layoutId,
		}),
	).subscribe({
		next: async (data) => {
			if (data) {
				console.log("subscribeNodeIdUpdateGql", data)
				const nodeIdUpdate = ((data["value"] || {})["data"] || {})["subscribeNodeIdUpdate"]
				if (nodeIdUpdate) {
					const { mLocId, nLocId, nId, deltaAction } = nodeIdUpdate
					if (deltaAction === "UPDATE") {
						await dispatch({
							type: actionTypes.UPDATE_NODE_ID,
							mLocId,
							nLocId,
							nId,
							status: "accepted",
						})
					}
				}
			}
		},
		error: (err) => {
			console.log("SUB ERROR:", err)
		},
	})
	return subscription.unsubscribe.bind(subscription)
}

export const subscribeMasterIdUpdates = (layoutId) => (dispatch) => {
	const subscription = API.graphql(
		graphqlOperation(subscribeMasterIdUpdateGql, {
			layoutId,
		}),
	).subscribe({
		next: async (data) => {
			if (data) {
				console.log("subscribeMasterIdUpdateGql", data)
				const masterIdUpdate = ((data["value"] || {})["data"] || {})["subscribeMasterIdUpdate"]
				if (masterIdUpdate) {
					const { mLocId, mId, deltaAction } = masterIdUpdate
					if (deltaAction === "UPDATE") {
						const masterDetails = await (await offlineStorage).get("masterDetails", mLocId)
						await (await offlineStorage).put("masterDetails", { ...masterDetails, mId })
						console.log("subscribeMasterIdUpdates", masterDetails)
						await dispatch({
							type: actionTypes.UPDATE_MASTER_ID,
							mLocId,
							mId,
							status: "accepted",
						})
					}
				}
			}
		},
		error: (err) => {
			console.log("SUB ERROR:", err)
		},
	})
	return subscription.unsubscribe.bind(subscription)
}

export const subscribeOnlineStatus = () => (dispatch) => {
	const updateOnlineStatus = () => {
		dispatch({
			type: actionTypes.ONLINE_STATUS,
			status: navigator.onLine ? "online" : "offline",
		})
	}

	updateOnlineStatus()

	window.addEventListener("online", updateOnlineStatus)
	window.addEventListener("offline", updateOnlineStatus)
	return () => {
		window.removeEventListener("online", updateOnlineStatus)
		window.removeEventListener("offline", updateOnlineStatus)
	}
}

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import { setDataTableIsOpen } from "store/actions/reduxActions"
import OverlayTable from "./OverlayTable"

const mapStateToProps = (state, props) => ({
	dataTableIsOpen: state.dataTableIsOpen,
	nodeDetails: state.nodeDetails,
	nIdsPendingUpload: state.nIdsPendingUpload,
})

const mapDispatchToProps = (dispatch, props) => ({
	setDataTableIsOpen: (isOpen) => dispatch(setDataTableIsOpen(isOpen)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(OverlayTable)

/* Used to translate Node Markers during configuration */
export const getTranslatedCenter = (location, translations) => {
	let yLoc = location.yLoc
	let xLoc = location.xLoc
	if (translations.scale) {
		yLoc = yLoc * parseFloat(translations.scale)
		xLoc = xLoc * parseFloat(translations.scale)
	}
	if (translations.yTrans) {
		yLoc = yLoc + parseFloat(translations.yTrans)
	}
	if (translations.xTrans) {
		xLoc = xLoc + parseFloat(translations.xTrans)
	}
	return [yLoc, xLoc]
}

export const getLayoutMasterIds = (layouts, layoutId) => {
	if (!layouts.hasOwnProperty(layoutId)) return []

	return layouts[layoutId].mLocIds || []
}

export const findDuplicates = (data) => {
	var result = []
	for (var i = 0; i < data.length; i++) {
		if (data.indexOf(data[i], i + 1) > -1) {
			if (result.indexOf(data[i]) === -1) {
				result.push(data[i])
			}
		}
	}
	return result
}

export const textWithNumbersCompare = (a, b) => {
	const arrA = a.split(" ")
	const arrB = b.split(" ")
	for (let i = 0; i < Math.min(arrA.length, arrB.length); i++) {
		if (arrA[i] === arrB[i]) {
			continue
		}
		const numA = parseInt(arrA[i])
		const numB = parseInt(arrB[i])
		if (isNaN(numA) && isNaN(numB)) {
			return arrA[i] < arrB[i] ? -1 : 1
		}
		if (!isNaN(numA) && !isNaN(numB)) {
			return numA < numB ? -1 : 1
		} else if (isNaN(numA)) {
			return 1
		} else {
			return -1
		}
	}

	return arrA.length < arrB.length ? -1 : arrA.length > arrB.length ? 1 : 0
}

import React, { useCallback, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Curve } from "react-leaflet-curve/src"

export const getMidpointLatLng = (latLng1, latLng2) => {
	const offsetX = latLng2[1] - latLng1[1]
	const offsetY = latLng2[0] - latLng1[0]

	const r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2))
	const theta = Math.atan2(offsetY, offsetX)

	const offsetTheta = 0.4 // ~30 degree curve rise in radians

	const midpointR = r / 2 / Math.cos(offsetTheta)
	const midpointTheta = theta + offsetTheta

	const midpointX = midpointR * Math.cos(midpointTheta) + latLng1[1]
	const midpointY = midpointR * Math.sin(midpointTheta) + latLng1[0]

	return [midpointY, midpointX]
}

function findNextConnectedLine(lineIndex, allLines) {
	const [_start, end] = allLines[lineIndex]
	const [x2, y2] = end
	for (let i = 0; i < allLines.length; i++) {
		const otherLine = allLines[i]
		const [otherStart, _otherEnd] = otherLine
		const [otherX1, otherY1] = otherStart
		if (otherX1 === x2 && otherY1 === y2) {
			return i
		}
	}
}

function findPathToMasterRecursively(beginningLineIndex, allLines, result = null) {
	result = result || [beginningLineIndex]
	const nextLine = findNextConnectedLine(beginningLineIndex, allLines)
	if (nextLine !== undefined) {
		if (result.includes(nextLine)) {
			// NOTE: https://gamechangesolar.atlassian.net/browse/BE-65
			// Closed because it's not really a bug. The nodes were polled at different times, and during that time gap, the
			// firstHop values changed to make it appear like a closed loop. I think the network map should reflect a coherent
			// snapshot of the network, so I will keep the warning.
			console.warn(`Encountered closed loop for line #${nextLine}.`)
			return result // Otherwise, it will recurse infinitely.
		}
		result.push(nextLine)
		return findPathToMasterRecursively(nextLine, allLines, result)
	}
	return result
}

const ZigbeeNetwork = React.memo(function ZigbeeNetwork({ lines, zigbeeNetworkIsVisible }) {
	const [selectedCurves, setSelectedCurves] = useState([])

	const handleCurveClick = useCallback(
		(curveId) => {
			const indexOfCurve = selectedCurves.findIndex((id) => id === curveId)
			if (indexOfCurve === -1) {
				// Not selected yet. Select this curve and all curves connected to it on the left side (this should highlight
				// all curves all the way to the master).
				const pathToMaster = findPathToMasterRecursively(curveId, lines)
				setSelectedCurves(pathToMaster)
			} else {
				// Deselecting curves
				setSelectedCurves([])
			}
		},
		[lines, selectedCurves],
	)

	const lineComponents = useMemo(() => {
		const ret = []
		for (let i = 0; i < lines.length; i++) {
			const line = lines[i]
			const [start, end] = line
			const midpoint = getMidpointLatLng(start, end)
			// https://github.com/epozsh/react-leaflet-curve
			const curvedLine = ["M", start, "Q", midpoint, end]
			const selected = selectedCurves.includes(i)
			const options = {
				color: selected ? "yellow" : "green",
				fill: false,
				dashArray: 8,
				weight: 5,
				opacity: selected ? 1 : 0.75,
				animate: { duration: 20000, iterations: Infinity },
			}
			let key = `${line}${selected}`
			ret.push(<Curve key={key} positions={curvedLine} option={options} onClick={() => handleCurveClick(i)} />)
		}
		return ret
	}, [handleCurveClick, lines, selectedCurves])

	if (!zigbeeNetworkIsVisible || !lines) return null
	return <>{lineComponents}</>
})

ZigbeeNetwork.propTypes = {
	// Multiple lines with beginnings and ends, e.g. [ [[x1, y1], [x2, y2]], [[x1, y1], [x2, y2]] ]
	lines: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))).isRequired,
	zigbeeNetworkIsVisible: PropTypes.bool.isRequired,
}

export default ZigbeeNetwork

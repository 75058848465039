import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import LayoutOverlay from "./LayoutOverlay"
import { updateLayoutDetails } from "store/actions/setters"

const MAX_NODE_COUNT = 500

const getLabelZoomThreshold = (state, props) => {
	return (
		(state.layoutDetails[props.match.params.layoutId] &&
			state.layoutDetails[props.match.params.layoutId]["labelZoomThreshold"]) ||
		false
	)
}

const mapStateToProps = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	const overlay = params.get("overlay")
	const activeStepKey = params.get("step")
	return {
		overlay,
		locationsLimited: state.configLocations.length > MAX_NODE_COUNT,
		layoutZoomLevel: state.layoutZoomLevel,
		labelZoomThreshold: getLabelZoomThreshold(state, props),
		activeStepKey,
	}
}

const mapDispatchToProps = (dispatch, props) => ({
	setLabelVisibilityThreshold: (labelZoomThreshold) =>
		dispatch(updateLayoutDetails(props.match.params.layoutId, { labelZoomThreshold })),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LayoutOverlay)

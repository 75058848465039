import React from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import { Popup } from "react-leaflet"
import leaflet from "leaflet"
import CheckIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"
import RetryIcon from "@material-ui/icons/Replay"
import WifiOffIcon from "@material-ui/icons/WifiOff"

const styles = () => ({
	root: {
		pointerEvents: "none",
		"&:hover": {
			opacity: "1 !important",
		},
	},
	popup: {
		textAlign: "center",
		cursor: "pointer",
	},
	popupId: {
		fontWeight: "bold",
	},
	close: {
		position: "absolute",
		right: 0,
		top: 0,
		padding: 3,
		paddingRight: 8,
		cursor: "pointer",
	},
	componentList: {
		margin: "10px 0 0 0",
		padding: 0,
	},
	componentListItem: {
		listStyleType: "none",
		display: "flex",
		justifyContent: "space-between",
	},
	icon: {
		height: "15px",
		width: "15px",
	},
	dividingLine: {
		padding: "0",
		margin: "10px 0",
		width: "100%",
		height: "1px",
		backgroundColor: "black",
	},
})

const MasterPopup = ({
	classes,
	name,
	position,
	setMasterInactive,
	handleClick,
	componentsScanned,
	isDeviceIdAssignment,
}) => {
	return (
		<Popup
			position={position}
			closeButton={false}
			closeOnClick={false}
			autoClose={false}
			className={classes.root}
			autoPan={true}
			autoPanPadding={leaflet.point(100, 100)}
		>
			<div>
				<div className={classes.close} onClick={setMasterInactive}>
					x
				</div>
				<div className={classes.popup} onClick={handleClick}>
					<div className={classes.popupId}>{name}</div>
				</div>
			</div>
			<div>
				{componentsScanned && isDeviceIdAssignment && (
					<>
						<div className={classes.dividingLine}></div>
						<ul className={classes.componentList}>
							{Object.values(componentsScanned).map((component, index) => {
								return (
									<li key={index} className={classes.componentListItem}>
										<div>
											{component.status === "success" ? (
												<CheckIcon color={"primary"} className={classes.icon} />
											) : component.status === "pending" ? (
												<WifiOffIcon color={"error"} className={classes.icon} />
											) : (
												<CloseIcon color={"error"} className={classes.icon} />
											)}
											{component.name}
										</div>
										<div>{component.status === "failed" && <RetryIcon className={classes.icon} />}</div>
									</li>
								)
							})}
						</ul>
					</>
				)}
			</div>
		</Popup>
	)
}

MasterPopup.propTypes = {
	classes: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	handleClick: PropTypes.func.isRequired,
	position: PropTypes.array.isRequired,
	setMasterInactive: PropTypes.func.isRequired,
	isDeviceIdAssignment: PropTypes.bool,
	componentsScanned: PropTypes.object,
}

export default withStyles(styles)(MasterPopup)

import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { Route, Switch } from "react-router-dom"
import PrivilegedRoute from "./auth/PrivilegedRoute"
import AppBar from "./AppBar"
import { privileges } from "constants/auth"
import DeviceManager from "./DeviceManager"
import LayoutManager from "./LayoutManager"
import ConfigureLayout from "./ConfigureLayout"
import LayoutView from "./LayoutView"
import UsersList from "./UsersList"

const NoMatch = ({ location }) => (
	<div>
		<h3>
			404: No match for <code>{location.pathname}</code>
		</h3>
	</div>
)

const styles = {
	root: {
		flexGrow: 1,
		display: "flex",
		flexDirection: "column",
	},
}

const AppContainer = ({ classes, location }) => {
	return (
		<div className={classes.root}>
			<AppBar />
			<Switch>
				<PrivilegedRoute path="/layouts" requiredPrivilege={privileges.VIEW} component={LayoutManager} />
				<PrivilegedRoute path="/layout/:layoutId" requiredPrivilege={privileges.VIEW} component={LayoutView} />
				<PrivilegedRoute path="/device-manager" requiredPrivilege={privileges.ADMIN} component={DeviceManager} />
				<PrivilegedRoute path="/users-list" requiredPrivilege={privileges.ADMIN} component={UsersList} />
				<PrivilegedRoute
					path="/configure-layout/:layoutId"
					requiredPrivilege={privileges.ADMIN}
					component={ConfigureLayout}
				/>
				<Route component={NoMatch} />
			</Switch>
		</div>
	)
}

AppContainer.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppContainer)

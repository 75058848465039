import { useState, useCallback } from "react"

const useGraphSync = (
	options = {
		selection: false,
		zoom: false,
	},
) => {
	const [syncSelection, setSyncSelection] = useState({})
	const [syncXAxisRange, setSyncXAxisRange] = useState(null)

	const handleHighlight = useCallback(
		(e, x, points, row, seriesName, dygraphRef) => {
			if (options.selection) {
				setSyncSelection((currSelect) => ({
					row,
					seriesName: currSelect && currSelect.locked ? currSelect.seriesName : seriesName,
					locked: (currSelect && currSelect.locked) || dygraphRef.isSeriesLocked(),
				}))
			}
		},
		[options.selection],
	)

	const handleUnhighlight = useCallback(
		(e, dygraphRef) => {
			if (options.selection) {
				setSyncSelection((currSelect) => {
					if ((currSelect && currSelect.locked) || dygraphRef.isSeriesLocked()) return currSelect
					return null
				})
			}
		},
		[options.selection],
	)

	const handleGraphClick = useCallback(
		(e, x, points, dygraphRef) => {
			if (options.selection) {
				setSyncSelection((currSelect) => {
					if ((currSelect && currSelect.locked) || dygraphRef.isSeriesLocked()) return null
					return {
						row: false,
						seriesName: dygraphRef.getHighlightSeries(),
						locked: true,
					}
				})
			}
		},
		[options.selection],
	)

	const handleDraw = useCallback(
		(dygraph, isInitial) => {
			if (options.zoom && !isInitial) {
				setSyncXAxisRange(dygraph.xAxisRange())
			}
		},
		[options.zoom],
	)

	return {
		syncSelection,
		setSyncSelection,
		syncXAxisRange,
		handleHighlight,
		handleUnhighlight,
		handleGraphClick,
		handleDraw,
	}
}

export default useGraphSync

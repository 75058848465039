import PropTypes from "prop-types"
import React, { useCallback } from "react"
import FilteredItemList from "./FilteredItemList"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((_theme) => ({
	item: {
		backgroundColor: "rgb(255, 255, 255)",
		padding: "8px",
		margin: "5px",
		userSelect: "none",
		borderBottom: "1px solid #EEEEEE",
		borderRadius: "5px",
		"&:hover": {
			backgroundColor: "#F5F5F5",
		},
	},
	selected: {
		backgroundColor: "#EEEEEE !important",
		boxSizing: "border-box",
	},
	outer: {
		border: "1px solid #CCC",
		borderRadius: "4px",
		boxSizing: "border-box",
		padding: "10px",
		textAlign: "left",
		marginTop: "5px",
		marginBottom: "5px",
		width: "100%",
		overflowY: "scroll",
		maxHeight: "370px",
	},
	categoryHeader: {
		margin: 0,
		marginLeft: "5px",
		padding: "4px",
		paddingLeft: "8px",
		fontWeight: "bold",
		fontSize: "0.8em",
		userSelect: "none",
	},
}))

const SelectSingleItem = (props) => {
	const classes = useStyles()
	const { identifier, onValueChange, selected } = props
	const cssClasses = [classes.item]

	if (selected) {
		cssClasses.push(classes.selected)
	}

	return (
		<div className={cssClasses.join(" ")} onClick={() => onValueChange(identifier)}>
			{identifier}
		</div>
	)
}

SelectSingleItem.propTypes = {
	identifier: PropTypes.string.isRequired,
	onValueChange: PropTypes.func.isRequired,
	selected: PropTypes.bool,
}

const CategoryHeader = ({ name }) => {
	const classes = useStyles()
	return <div className={classes.categoryHeader}>{name}</div>
}

CategoryHeader.propTypes = {
	name: PropTypes.string.isRequired,
}

const SelectSingle = (props) => {
	// items is an array of arrays. In the inner array, the first element is the label and the second is the value.
	const { value, setValue, items, pluralName = "items" } = props
	const classes = useStyles()

	const handleItemClicked = useCallback(
		(item) => () => {
			setValue(item)
		},
		[setValue],
	)

	const getComponents = (items) => {
		return items.map((item) => {
			const extraProps = {}

			if (value === item) {
				extraProps["selected"] = true
			}

			return {
				componentType: SelectSingleItem,
				props: {
					identifier: item,
					onValueChange: handleItemClicked(item),
					...extraProps,
				},
				mapKey: item,
				filterKey: item,
			}
		})
	}

	let components

	if (Array.isArray(items)) {
		components = getComponents(items)
	} else {
		components = []
		// items is presumed to be an object
		for (const category of Object.keys(items)) {
			// In order to filter out empty categories, we give the category headers a filter key containing all child keys.
			const allChildKeys = items[category].join(" ")

			components.push({
				componentType: CategoryHeader,
				props: { name: category },
				mapKey: "+++" + category, // Used as the key in .map()
				filterKey: allChildKeys,
			})

			components = components.concat(getComponents(items[category]))
		}
	}

	return (
		<div className={classes.outer}>
			<FilteredItemList items={components} pluralName={pluralName} />
		</div>
	)
}

SelectSingle.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func,
	items: PropTypes.any,
	pluralName: PropTypes.string,
}

export default SelectSingle

import React, { Fragment } from "react"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import PropTypes from "prop-types"

const MasterDataView = ({ masterData, masterDetails }) => {
	return (
		<Fragment>
			{masterDetails
				? masterDetails.map((detail) => {
						return [
							<ListItem key={detail.id + "value"}>
								<ListItemText primary={detail.label} secondary={detail.dataFormatted} />
							</ListItem>,
							<Divider key={detail.id + "divider"} />,
						]
				  })
				: null}
			{masterData
				? masterData.map((detail) => {
						return [
							<ListItem key={detail.id + "value"}>
								<ListItemText primary={detail.label} secondary={detail.dataFormatted} />
							</ListItem>,
							<Divider key={detail.id + "divider"} />,
						]
				  })
				: null}
		</Fragment>
	)
}

MasterDataView.propTypes = {
	masterData: PropTypes.array,
	masterDetails: PropTypes.array,
}
export default MasterDataView

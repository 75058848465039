import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import InputBase from "@material-ui/core/InputBase"
import IconButton from "@material-ui/core/IconButton"
import SearchIcon from "@material-ui/icons/Search"
import CloseIcon from "@material-ui/icons/Close"

const styles = (theme) => ({
	root: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: "100%",
		margin: theme.spacing(1),
	},
	input: {
		marginLeft: 8,
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
	divider: {
		width: 1,
		height: 28,
		margin: 4,
	},
})

function SearchBar({ classes, filter, handleChange, handleClearFilter }) {
	const filterActive = filter !== ""

	return (
		<Paper className={classes.root} elevation={0}>
			<InputBase
				className={classes.input}
				placeholder="Search"
				onChange={(event) => handleChange(event.target.value)}
				value={filter}
			/>
			<IconButton
				className={classes.iconButton}
				aria-label="Search"
				disabled={!filterActive}
				onClick={filterActive ? handleClearFilter : null}
			>
				{filterActive ? <CloseIcon /> : <SearchIcon />}
			</IconButton>
		</Paper>
	)
}

SearchBar.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SearchBar)

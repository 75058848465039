import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import { Redirect } from "react-router"
import LayoutMap from "../LayoutMap"
import Graphs from "../Graphs"
import { privileges } from "constants/auth"
import { useAuthorization } from "utils/auth"
import PropTypes, { arrayOf } from "prop-types"

const useStyles = makeStyles(() => ({
	rootLayoutView: {
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		gridTemplateRows: "repeat(1, 1fr)",
		height: "100%",
		overflow: "hidden",
	},
	gridDual: {
		gridTemplateAreas: `
      'item0 item1'
      'item0 item1'
    `,
	},
	gridSingle: {
		gridTemplateAreas: `
      'item0 item0'
      'item0 item0'
    `,
	},
	item0: {
		gridArea: "item0",
	},
	item1: {
		gridArea: "item1",
	},
}))

const LayoutView = ({
	params,
	match,
	mLocIds,
	userPrivileges,
	subscribeNodeIdUpdates,
	subscribeMasterIdUpdates,
	subscribeNodeSyncBitmasksUpdate,
	fetchLayoutDetailsBySiteUuid,
}) => {
	const accessAllowed = useAuthorization(userPrivileges)
	const classes = useStyles()
	const layoutId = match.params.layoutId

	useEffect(() => {
		if (layoutId) {
			fetchLayoutDetailsBySiteUuid(layoutId)
		}
	}, [layoutId, fetchLayoutDetailsBySiteUuid])

	useEffect(() => {
		if (layoutId) {
			return subscribeNodeIdUpdates(layoutId)
		}
	}, [layoutId, subscribeNodeIdUpdates])

	useEffect(() => {
		if (layoutId) {
			return subscribeNodeSyncBitmasksUpdate(layoutId)
		}
	}, [layoutId, subscribeNodeSyncBitmasksUpdate])

	useEffect(() => {
		if (layoutId) {
			return subscribeMasterIdUpdates(layoutId)
		}
	}, [layoutId, subscribeMasterIdUpdates])

	let redirect = false
	const view = params.get("view")
	if (view === null) {
		params.set("view", "layout")
		redirect = true
	}
	const mLocationIds = params.get("locIds")
	if (!mLocationIds && mLocIds.length > 0) {
		params.set("locIds", mLocIds[0])
		redirect = true
	}
	if (redirect) {
		return <Redirect to={{ pathname: match.url, search: "?" + params.toString() }} />
	}

	let viewList = view.split(",")
	if (!accessAllowed(privileges.DATA_HISTORY)) {
		viewList = viewList.filter((view) => view !== "chart")
	}
	const splitView = viewList.length > 1

	return (
		<div
			className={clsx(classes.rootLayoutView, {
				[classes.gridDual]: splitView,
				[classes.gridSingle]: !splitView,
			})}
		>
			{viewList.includes("layout") ? (
				<div className={classes.item0}>
					<LayoutMap />
				</div>
			) : null}
			{viewList.includes("chart") ? (
				<div className={clsx({ [classes.item0]: !splitView, [classes.item1]: splitView })}>
					<Graphs />
				</div>
			) : null}
		</div>
	)
}

LayoutView.propTypes = {
	params: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	mLocIds: arrayOf(PropTypes.string).isRequired,
	userPrivileges: PropTypes.any.isRequired,
	subscribeNodeIdUpdates: PropTypes.func.isRequired,
	subscribeMasterIdUpdates: PropTypes.func.isRequired,
	subscribeNodeSyncBitmasksUpdate: PropTypes.func.isRequired,
	fetchLayoutDetailsBySiteUuid: PropTypes.func.isRequired,
}

export default LayoutView

import React, { useCallback, useState } from "react"
import TextField from "@material-ui/core/TextField"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((_theme) => ({
	outer: {
		backgroundColor: "rgb(250, 250, 250)",
		maxHeight: "300px",
		overflowY: "scroll",
		overflowX: "hidden",
		borderRadius: "5px",
		border: "1px solid #888888",
		padding: "5px",
	},
	// We use this instead of display: none or return null in order to preserve the dynamic width of the component.
	hiddenItem: {
		width: "100%",
		height: 0,
		marginBottom: 0,
		padding: 0,
		overflow: "hidden",
	},
	searchBar: {
		width: "100%",
	},
	searchTermInput: {
		width: "calc(100% - 10px)",
	},
}))

const FilteredItemList = (props) => {
	const classes = useStyles()
	const { items, pluralName = "items" } = props
	const [filter, setFilter] = useState("")

	const handleFilterChange = useCallback(
		(e) => {
			setFilter(e.target.value)
		},
		[setFilter],
	)

	const matchesFilter = (key) => {
		if (!key) return true
		return key.toLowerCase().includes(filter.toLowerCase())
	}

	return (
		<>
			<TextField
				className={classes.searchTermInput}
				label={`Filter ${pluralName}`}
				value={filter}
				onChange={handleFilterChange}
				variant="outlined"
				margin="dense"
			/>
			{items.map((item) => {
				const { componentType, filterKey, mapKey, props: componentProps } = item

				// If the filter key does not match the filter, use a hidden style for the item.
				const className = !filter || matchesFilter(filterKey) ? "" : classes.hiddenItem

				return (
					<div key={mapKey} className={className}>
						{React.createElement(componentType, componentProps)}
					</div>
				)
			})}
		</>
	)
}

FilteredItemList.propTypes = {
	items: PropTypes.array.isRequired,
	pluralName: PropTypes.string,
}

export default FilteredItemList

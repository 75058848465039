import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import NodePopup from "./NodePopup"
import { overlays } from "constants/overlays"
import { getMasterLocIds } from "utils/url"

const getCenter = (state, props) => {
	const nodeDetails = (state.nodeDetails[props.mLocId] || {})[props.nLocId]
	if (!nodeDetails || !nodeDetails["xLoc"] || !nodeDetails["yLoc"]) return []
	return [nodeDetails["yLoc"], nodeDetails["xLoc"]]
}

const getLatestTimeStamp = (state, props) => {
	const activeTimestamps = getMasterLocIds(props.location.search)
		.map((mLocId) => state.nodeDataActiveTimestamp[mLocId])
		.filter((timestamp) => timestamp !== undefined)

	let latestTimestamp = null
	if (activeTimestamps.length > 0) {
		latestTimestamp = Math.max(...Object.values(activeTimestamps))
	}
	return latestTimestamp
}

const getUrlTimestamp = (props) => {
	const params = new URLSearchParams(props.location.search)
	return parseInt(params.get("ts"))
}

const getTimestamp = (state, props) => {
	return getUrlTimestamp(props) || getLatestTimeStamp(state, props)
}

const getOtherProps = (state, props) => {
	const timestamp = getTimestamp(state, props)
	const nodeDetails = (state.nodeDetails[props.mLocId] || {})[props.nLocId]
	const nodeDataNodeId = ((state.nodeData[`${props.mLocId}#${timestamp}`] || {})[props.nLocId] || {})["nId"]
	const pendingNodeId = state.nIdsPendingUpload[`${props.mLocId}#${props.nLocId}`]
	if (!nodeDetails) return {}
	const { rowNum, fromSouth, nId } = nodeDetails
	return { rowNum, fromSouth, nId: pendingNodeId || nId, nodeDataNodeId }
}

const setNodeInactive = (props) => (e) => {
	let params = new URLSearchParams(props.location.search)
	if (props.nav) {
		params.delete("navLocId")
	} else {
		let activeIds = params.get("locIds")
		if (!activeIds) return null
		activeIds = activeIds.split(",").map((ids) => {
			let [mLocId, nLocIds = ""] = ids.split(":")
			if (mLocId === props.mLocId) {
				nLocIds = nLocIds.split(";").filter((id) => id !== props.nLocId && id !== "")
				if (nLocIds.length) {
					return `${mLocId}:${nLocIds.join(";")}`
				}
				return mLocId
			} else {
				return ids
			}
		})
		params.set("locIds", activeIds.join(","))
	}
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const handleClick = (props) => {
	const params = new URLSearchParams(props.location.search)
	if (params.get("overlay") === overlays.DEVICEIDASSIGNMENT) {
		params.set("deviceid-input", [props.layoutId, props.mLocId, props.nLocId].join(":"))
	} else {
		params.set("device-details", [props.layoutId, props.mLocId, props.nLocId].join(":"))
	}
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const mapStateToProps = (state, props) => {
	const nodeDetails = (state.nodeDetails[props.mLocId] || {})[props.nLocId]
	const currentNodeId = (nodeDetails || {})["uuid"]
	const componentsScanned = state.nodeComponentScanned[currentNodeId]
	const isDeviceIdAssignment = props.location.search.split("&").includes("overlay=deviceid-assignement")
	return {
		isDeviceIdAssignment,
		componentsScanned: componentsScanned,
		position: getCenter(state, props),
		radius: state.layoutDetails[props.layoutId]["markerRadius"]
			? parseInt(state.layoutDetails[props.layoutId]["markerRadius"])
			: 0.5,
		...getOtherProps(state, props),
	}
}

const mapDispatchToProps = (dispatch, props) => ({
	setNodeInactive: setNodeInactive(props),
	handleClick: () => handleClick(props),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(NodePopup)

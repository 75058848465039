import { deviceTypes } from "constants/deviceTypes"

export const auxNodeDataList = [
	"auxAnglePanel",
	"auxMotorCurrentPeak",
	"auxMotorCurrentAve",
	"auxTempMin",
	"auxTempMax",
	"auxAlarms",
]

export const alarmKey = {
	[deviceTypes.P4Q]: "",
	[deviceTypes.GAMECHANGE_GEN2]: "alarms",
	[deviceTypes.GAMECHANGE_GEN3]: "alarms",
	[deviceTypes.GAMECHANGE_GEN4]: "alarms",
	[deviceTypes.GAMECHANGE_GEN3_DUAL_PRIMARY]: "alarms",
	[deviceTypes.GAMECHANGE_GEN3_DUAL_AUX]: "auxAlarms",
}

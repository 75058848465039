import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import LayoutOverlay from "./LayoutOverlay"

const mapStateToProps = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	const activeOverlay = params.get("overlay")
	return {
		activeOverlay,
		dataView: state.dataView,
	}
}

const mapDispatchToProps = (dispatch) => ({})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LayoutOverlay)

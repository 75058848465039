import React, { useCallback } from "react"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import PropTypes from "prop-types"
import { TableCell, TextField } from "@material-ui/core"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"

const IntTable = (props) => {
	const { headers, value, onChange, errorCheck } = props

	const handleItemChanged = useCallback(
		(rowIndex, columnIndex, e) => {
			const input = e.target.value && parseInt(e.target.value)
			// Copy existing rows into new array of rows
			const newValue = value.map((row) => [...row]) // spread operator

			newValue[rowIndex][columnIndex] = input
			onChange(newValue)
		},
		[value, onChange],
	)

	const getNameOfConstant = (input) => {
		const constants = { 21111: "Full West", 23333: "Strongest Wind Stow", 29999: "Unused" }
		return constants[input]
	}

	return (
		<div>
			<Table>
				<TableHead>
					<TableRow>
						{headers.map((header) => {
							return <TableCell key={header}>{header}</TableCell>
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{(value || []).map((row, rowIndex) => {
						return (
							<TableRow key={rowIndex}>
								{row.map((cell, columnIndex) => {
									return (
										<TableCell key={rowIndex + "" + columnIndex}>
											<TextField
												type="number"
												value={cell}
												onChange={(e) => handleItemChanged(rowIndex, columnIndex, e)}
												error={errorCheck(columnIndex, cell)}
												helperText={errorCheck(columnIndex, cell) || getNameOfConstant(cell)}
											/>
										</TableCell>
									)
								})}
							</TableRow>
						)
					})}
				</TableBody>
			</Table>
		</div>
	)
}

IntTable.propTypes = {
	headers: PropTypes.arrayOf(PropTypes.string).isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string, // Initial value will be ""
		PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
	]).isRequired,
	onChange: PropTypes.func.isRequired,
	errorCheck: PropTypes.func.isRequired,
}

export default IntTable

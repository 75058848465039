import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

import { DropzoneArea } from "material-ui-dropzone"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import DownloadIcon from "@material-ui/icons/SaveAlt"
import { useSnackbar } from "notistack"

const useStyles = makeStyles((theme) => ({
	layoutSvg: {
		display: "flex",
		margin: "auto",
		width: "300px",
		height: "300px",
		maxWidth: "100%",
	},
	layoutSvgDiv: {
		position: "relative",
	},
	layoutRemoveIcon: {
		position: "absolute",
		right: 0,
	},
	layoutDownloadIcon: {
		position: "absolute",
		right: theme.spacing(6),
	},
}))

const LayoutSvgSelector = ({
	layoutUrl,
	onUrlRemove: handleUrlRemove,
	layoutFile,
	onFileSelect: handleFileSelect,
	onFileRemove: handleFileRemove,
}) => {
	const { enqueueSnackbar } = useSnackbar()
	const [selectedFileUrl, setSelectedFileUrl] = useState(null)
	const classes = useStyles()

	const handleSelectFile = ([file]) => {
		if (file) {
			handleFileSelect(file)
		}
	}

	useEffect(() => {
		if (layoutFile) {
			const reader = new FileReader()
			reader.onerror = (err) => {
				enqueueSnackbar("Failed to upload layout SVG", {
					variant: "error",
				})
				console.error(err)
			}
			reader.onload = () => {
				if (layoutFile) {
					// NOTE: layoutFile is a File object, which is an abstraction of a Blob object.
					// This creates a URL for the layoutFile object, which can be used to display the image.
					// The URL will have the form of "blob:{some identifier for a memory location}". It will be valid as long as
					// the blob is stored in memory and will function as any other web URL to remote images.
					setSelectedFileUrl(URL.createObjectURL(layoutFile))
				}
			}
			reader.readAsBinaryString(layoutFile)
		}
	}, [layoutFile, setSelectedFileUrl, enqueueSnackbar])

	const handleDeselectFile = () => {
		setSelectedFileUrl(null)
		handleUrlRemove()
		handleFileRemove()
	}

	const handleDownload = () => {
		if (layoutUrl) {
			window.open(layoutUrl, "_blank")
		}
	}

	if (selectedFileUrl) {
		return (
			<div className={classes.layoutSvgDiv}>
				<Tooltip className={classes.layoutRemoveIcon} title="Remove Layout SVG">
					<IconButton aria-label="Remove Layout SVG" onClick={handleDeselectFile}>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
				<img className={classes.layoutSvg} src={selectedFileUrl} alt="Layout SVG" />
			</div>
		)
	}
	if (layoutUrl) {
		return (
			<div className={classes.layoutSvgDiv}>
				<Tooltip className={classes.layoutRemoveIcon} title="Remove Layout SVG">
					<IconButton aria-label="Remove Layout SVG" onClick={handleUrlRemove}>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
				<Tooltip className={classes.layoutDownloadIcon} title="Download Layout SVG">
					<IconButton aria-label="Download Layout SVG" onClick={handleDownload}>
						<DownloadIcon />
					</IconButton>
				</Tooltip>
				<img className={classes.layoutSvg} src={layoutUrl} alt="Layout SVG" />
			</div>
		)
	} else {
		return (
			<DropzoneArea
				onChange={handleSelectFile}
				acceptedFiles={["image/svg+xml"]}
				fileLimit={1}
				dropzoneText="Drag and drop layout SVG here or click"
				showPreviewsInDropzone={false}
				showAlerts={false}
			/>
		)
	}
}

LayoutSvgSelector.propTypes = {
	layoutUrl: PropTypes.string,
	layoutFile: PropTypes.instanceOf(File),
	onUrlRemove: PropTypes.func.isRequired,
	onFileSelect: PropTypes.func.isRequired,
	onFileRemove: PropTypes.func.isRequired,
}

export default LayoutSvgSelector

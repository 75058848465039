import { connect } from "react-redux"
import SearchBar from "./SearchBar"
import { setLayoutListFilter } from "store/actions/reduxActions"

const mapStateToProps = (state, props) => ({
	filter: state.layoutListFilter,
})

const mapDispatchToProps = (dispatch, props) => ({
	handleChange: (newValue) => dispatch(setLayoutListFilter(newValue)),
	handleClearFilter: () => dispatch(setLayoutListFilter("")),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)

import { connect } from "react-redux"
import CreateNodeParameters from "./CreateNodeParameters"
import { compose } from "redux"
import { withRouter } from "react-router"
import { withSnackbar } from "notistack"
import { fetchMasterDetails } from "store/actions/fetchers"

const mapStateToProps = (state, props) => ({
	masterDetails: state.masterDetails,
})

const mapDispatchToProps = (dispatch, props) => ({
	fetchMasterDetails: (layoutId) => dispatch(fetchMasterDetails(layoutId)),
})

export default compose(withSnackbar, withRouter, connect(mapStateToProps, mapDispatchToProps))(CreateNodeParameters)

import React, { useMemo, useEffect, memo } from "react"
import PropTypes from "prop-types"
import NodeMarker from "../NodeMarker"
import { findDuplicates } from "utils"
import { useAuthorization } from "utils/auth"
import { privileges } from "constants/auth"

const NodeMarkersByMaster = memo(
	({
		layoutId,
		mLocId,
		activeOverlay,
		timestamp,
		nodeDetails,
		nIdsPendingUpload,
		userPrivileges,
		fetchNodeData,
		deviceType,
	}) => {
		const accessAllowed = useAuthorization(userPrivileges)

		useEffect(() => {
			if (layoutId && deviceType && accessAllowed(privileges.DATA_SNAPSHOT)) {
				fetchNodeData(layoutId, mLocId, userPrivileges, deviceType, timestamp)
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [layoutId, mLocId, deviceType, timestamp])

		const duplicateNodeIds = useMemo(() => {
			const nIds = []
			Object.keys(nodeDetails).forEach((nLocId) => {
				const nId = nIdsPendingUpload[`${mLocId}#${nLocId}`] || nodeDetails[nLocId].nId
				if (nId) {
					nIds.push(nId)
				}
			})
			return findDuplicates(nIds)
		}, [nodeDetails, nIdsPendingUpload, mLocId])

		const nodeMarkers = []
		const nLocIds = Object.keys(nodeDetails)
		for (const nLocId of nLocIds) {
			nodeMarkers.push(
				<NodeMarker
					key={`${mLocId}#${nLocId}`}
					duplicateNodeId={duplicateNodeIds.includes(
						nIdsPendingUpload[`${mLocId}#${nLocId}`] || nodeDetails[nLocId].nId,
					)}
					nLocId={nLocId}
					mLocId={mLocId}
					layoutId={layoutId}
					activeOverlay={activeOverlay}
					timestamp={timestamp}
				/>,
			)
		}
		return <>{nodeMarkers}</>
	},
)

NodeMarkersByMaster.displayName = "NodeMarkersByMaster"

NodeMarkersByMaster.propTypes = {
	layoutId: PropTypes.string,
	mLocId: PropTypes.string,
	activeOverlay: PropTypes.string.isRequired,
	timestamp: PropTypes.number.isRequired,
	nodeDetails: PropTypes.object.isRequired,
	nIdsPendingUpload: PropTypes.object.isRequired,
	userPrivileges: PropTypes.number.isRequired,
	fetchNodeData: PropTypes.func.isRequired,
	deviceType: PropTypes.string,
}

export default NodeMarkersByMaster

/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import { AutoSizer, Column, SortDirection, Table } from "react-virtualized"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import SearchBar from "./SearchBar"
import Checkbox from "@material-ui/core/Checkbox"
import { useSnackbar } from "notistack"

import API, { graphqlOperation } from "@aws-amplify/api"
import { setUserLayoutIds } from "graphql/mutations"
import { listUserLayoutIds } from "../../../graphql/queries"

const toolbarStyles = (theme) => ({
	root: {
		paddingRight: theme.spacing(1),
		paddingLeft: theme.spacing(1),
	},
})

let EnhancedTableToolbar = ({ classes }) => {
	return (
		<Toolbar className={classes.root}>
			<SearchBar />
		</Toolbar>
	)
}
EnhancedTableToolbar.propTypes = {
	classes: PropTypes.object.isRequired,
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const styles = (theme) => ({
	root: {
		height: "100%",
		width: "100%",
	},
	table: {
		fontFamily: theme.typography.fontFamily,
	},
	flexContainer: {
		display: "flex",
		alignItems: "center",
		boxSizing: "border-box",
	},
	tableRow: {
		cursor: "pointer",
	},
	tableRowHover: {
		"&:hover": {
			backgroundColor: theme.palette.grey[200],
		},
	},
	tableCell: {
		flex: 1,
	},
	noClick: {
		cursor: "initial",
	},
	grid: {
		outline: "none",
	},
})

class MuiVirtualizedTable extends React.PureComponent {
	getRowClassName = ({ index }) => {
		const { classes, rowClassName, onRowClick } = this.props

		return clsx(classes.tableRow, classes.flexContainer, rowClassName, {
			[classes.tableRowHover]: index !== -1 && onRowClick != null,
		})
	}

	cellRenderer = ({ cellData, columnIndex = null }) => {
		const { columns, classes, rowHeight, onRowClick } = this.props
		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, {
					[classes.noClick]: onRowClick == null,
				})}
				variant="body"
				style={{ height: rowHeight }}
				align={(columnIndex != null && columns[columnIndex].numeric) || false ? "right" : "left"}
			>
				{cellData}
			</TableCell>
		)
	}

	headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
		const { headerHeight, columns, classes, sort } = this.props
		const direction = {
			[SortDirection.ASC]: "asc",
			[SortDirection.DESC]: "desc",
		}
		const inner =
			!columns[columnIndex].disableSort && sort != null ? (
				<TableSortLabel active={dataKey === sortBy} direction={direction[sortDirection]}>
					{label}
				</TableSortLabel>
			) : (
				label
			)

		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
				variant="head"
				style={{ height: headerHeight }}
				align={columns[columnIndex].numeric || false ? "right" : "left"}
			>
				{inner}
			</TableCell>
		)
	}

	render() {
		const { classes, columns, ...tableProps } = this.props
		return (
			<div className={classes.root}>
				<AutoSizer>
					{({ height, width }) => (
						<Table
							className={classes.table}
							height={height}
							width={width}
							{...tableProps}
							rowClassName={this.getRowClassName}
							gridClassName={classes.grid}
						>
							{columns.map(({ cellContentRenderer = null, className, dataKey, ...other }, index) => {
								let renderer
								if (cellContentRenderer != null) {
									renderer = (cellRendererProps) =>
										this.cellRenderer({
											cellData: cellContentRenderer(cellRendererProps),
											columnIndex: index,
										})
								} else {
									renderer = this.cellRenderer
								}

								return (
									<Column
										key={dataKey}
										headerRenderer={(headerProps) =>
											this.headerRenderer({
												...headerProps,
												columnIndex: index,
											})
										}
										className={clsx(classes.flexContainer, className)}
										cellRenderer={renderer}
										dataKey={dataKey}
										{...other}
									/>
								)
							})}
						</Table>
					)}
				</AutoSizer>
			</div>
		)
	}
}

MuiVirtualizedTable.propTypes = {
	classes: PropTypes.object.isRequired,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			cellContentRenderer: PropTypes.func,
			dataKey: PropTypes.string.isRequired,
			width: PropTypes.number.isRequired,
			numeric: PropTypes.bool,
			disableSort: PropTypes.bool,
		}),
	).isRequired,
	headerHeight: PropTypes.number,
	onRowClick: PropTypes.func,
	rowClassName: PropTypes.string,
	rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
	sort: PropTypes.func,
}

MuiVirtualizedTable.defaultProps = {
	headerHeight: 56,
	rowHeight: 56,
}

const WrappedVirtualizedTable = withStyles(styles)(MuiVirtualizedTable)

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		width: "100%",
	},
}))

const LayoutList = ({ rows, columns, sortBy, sortDirection, userPrivileges, handleSortClick, username }) => {
	const classes = useStyles()
	const [layoutIds, setLayoutIds] = useState([])
	const [pendingResponse, setPendingResponse] = useState(false)
	const { enqueueSnackbar } = useSnackbar()
	const unmounted = useRef(false)
	rows.forEach((row) => {
		row.checkbox = (
			<Checkbox
				checked={layoutIds.includes(row.id)}
				color="primary"
				inputProps={{ "aria-labelledby": row.layoutName }}
			/>
		)
	})
	const filteredRows = useMemo(() => rows.filter((row) => row.published), [rows])

	useEffect(() => {
		return () => {
			unmounted.current = true
		}
	}, [])

	useEffect(() => {
		;(async (username) => {
			if (username) {
				try {
					const request = await API.graphql(
						graphqlOperation(listUserLayoutIds, {
							username,
						}),
					)
					const response = request.data
					setLayoutIds(response.listUserLayoutIds.items)
				} catch (ex) {
					console.log("ERROR: listUserLayoutIds", ex)
				}
			}
		})(username)
	}, [username])

	const handleSelectSite = useCallback(
		(event) => {
			const layoutId = event.rowData.id
			setLayoutIds(() => {
				if (layoutIds.includes(layoutId)) {
					return layoutIds.filter((id) => id !== layoutId)
				} else {
					return [...layoutIds, layoutId]
				}
			})
		},
		[layoutIds],
	)

	const handleSubmit = async (e) => {
		e.preventDefault()
		setPendingResponse(true)
		try {
			const { data } = await API.graphql(
				graphqlOperation(setUserLayoutIds, {
					input: { username, layoutIds: layoutIds },
				}),
			)

			if (data.listUserLayoutIds) {
				setLayoutIds(data.listUserLayoutIds.items)
			}
			enqueueSnackbar("Save Successful", {
				variant: "success",
			})
		} catch (err) {
			console.log("ERROR: setUserLayoutIds", err)
			err.errors.forEach((error) => {
				enqueueSnackbar(error.message, {
					variant: "error",
				})
			})
		}
		setPendingResponse(false)
	}

	return (
		<div className={classes.root}>
			<EnhancedTableToolbar userPrivileges={userPrivileges} />
			<WrappedVirtualizedTable
				rowCount={filteredRows.length}
				rowGetter={({ index }) => filteredRows[index]}
				onRowClick={(event) => handleSelectSite(event)}
				columns={columns}
				sort={handleSortClick}
				sortBy={sortBy}
				sortDirection={sortDirection}
			/>
			<Button
				type="submit"
				onClick={handleSubmit}
				fullWidth
				variant="contained"
				color="primary"
				className={classes.button}
				disabled={pendingResponse}
			>
				Apply
			</Button>
		</div>
	)
}

LayoutList.propTypes = {
	rows: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	sortBy: PropTypes.string.isRequired,
	sortDirection: PropTypes.func.isRequired,
	userPrivileges: PropTypes.number.isRequired,
	handleSortClick: PropTypes.func.isRequired,
	username: PropTypes.string.isRequired,
}

export default LayoutList

import React from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import { DropzoneArea } from "material-ui-dropzone"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"

import { csvToArray, downloadToCsv } from "utils/csvIo"

const styles = (theme) => ({
	stepWrapper: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},
	stepContent: {
		margin: theme.spacing(1),
	},
	paper: {
		textAlign: "center",
		padding: theme.spacing(1),
	},
	preferredButton: {
		backgroundColor: "#83bd3a",
		color: "white",
	},
})

// This is the shape of the downloadable CSV template.
const TEMPLATE_HEADER_PARTIAL = ["xLoc", "yLoc", "nLocId", "rowNum", "fromSouth", "nId", "mLocId"]
// This is the shape of the CSV file downloaded after importing nodes from the database.
// We will inspect the first row of the CSV file to determine which header to expect.
// This lets us easily export nodes and reimport them in another site without having to manually edit the CSV.
const TEMPLATE_HEADER_FULL = [
	"mLocId",
	"nLocId",
	"xLoc",
	"yLoc",
	"rowNum",
	"fromSouth",
	"distance",
	"role",
	"relative",
	"__typename",
	"nId",
	"pendingSync",
]
// Only these columns are required to create a node.
const REQUIRED_COLUMNS = ["xLoc", "yLoc"]

class CoordinateUploader extends React.Component {
	componentDidMount() {
		this.considerEnablingNextButton()
	}

	setCoordinatesValid(valid) {
		if (!valid) {
			// If the coordinates are deemed invalid, do not keep them.
			this.props.setConfigLocations([])
		}
		this.props.setCanContinue(valid)
	}

	considerEnablingNextButton() {
		console.info("Nb locations: " + this.props.locations.length)
		this.props.setCanContinue(this.props.locations.length > 0)
	}

	handleUpload = (files) => {
		// TODO: Perform assignment (same as happens when an area is assigned) on success
		// TODO: Find the 'update in render' memory leak
		if (files.length) {
			const reader = new FileReader()
			reader.onerror = this.handleError
			reader.onprogress = this.handleProgress
			reader.onloadstart = (_e) => console.log("upload started")
			reader.onload = (e) => {
				const content = csvToArray(e.target.result)
				const [parsedData, scaleCoords] = this.parseFile(content)
				if (parsedData !== false) {
					const coordinates = scaleCoords ? this.translateCoords(parsedData) : parsedData
					this.props.setConfigLocations(coordinates)
					this.setCoordinatesValid(true)
				} else {
					this.props.enqueueSnackbar("File Content Invalid!", {
						variant: "error",
					})
				}
			}
			reader.readAsText(files[0])
		}
	}

	handleProgress = (evt) => {
		if (evt.lengthComputable) {
			const percentLoaded = Math.round((evt.loaded / evt.total) * 100)
			console.log(percentLoaded, "% Loaded")
		}
	}

	handleError = (evt) => {
		switch (evt.target.error.code) {
			case evt.target.error.NOT_FOUND_ERR:
				this.props.enqueueSnackbar("File Not Found!", {
					variant: "error",
				})
				break
			case evt.target.error.NOT_READABLE_ERR:
				this.props.enqueueSnackbar("File is not readable!", {
					variant: "error",
				})
				break
			case evt.target.error.ABORT_ERR:
				this.props.enqueueSnackbar("File read abort!", {
					variant: "error",
				})
				break
			default:
				this.props.enqueueSnackbar("An error occurred reading this file!", {
					variant: "error",
				})
		}
		this.setCoordinatesValid(false)
	}

	/**
	 *
	 * @param headerRow
	 * @returns {[boolean, string[] | null]} - [false, null] if the header is invalid,
	 * [true, TEMPLATE_HEADER_FULL | TEMPLATE_HEADER_PARTIAL] if the header is valid. The second element in the returned
	 * list is either TEMPLATE_HEADER_FULL or TEMPLATE_HEADER_PARTIAL, depending on the header.
	 */
	checkHeader = (headerRow) => {
		for (const requiredColumn of REQUIRED_COLUMNS) {
			if (!headerRow.includes(requiredColumn)) {
				return [false, null]
			}
		}
		const templateHeader = headerRow[0] === "mLocId" ? TEMPLATE_HEADER_FULL : TEMPLATE_HEADER_PARTIAL
		for (const columnName of headerRow) {
			if (!templateHeader.includes(columnName)) {
				return [false, null]
			}
		}
		return [true, templateHeader]
	}

	parseValue = (type, value) => {
		let parsedValue = ""
		switch (type) {
			case "xLoc":
			case "yLoc":
				parsedValue = parseFloat(value)
				if (isNaN(parsedValue)) {
					return false
				}
				return parsedValue
			case "rowNum":
			case "fromSouth":
				parsedValue = parseInt(value, 10)
				if (isNaN(parsedValue)) {
					return false
				}
				return parsedValue
			case "nLocId":
				parsedValue = parseInt(value, 10)
				if (isNaN(parsedValue)) {
					return false
				}
				return parsedValue
			case "mLocId":
				if (value === "") {
					return false
				}
				return value
			case "nId":
				if (value === "") {
					return false
				}
				if (value.length < 16) {
					return value.padStart(4, "0")
				}
				if (value.length === 16 && value.slice(0, 6).toUpperCase() !== "FCC23D") {
					return false
				}
				return value
			default:
				return false
		}
	}

	/**
	 *
	 * @param fileArray
	 * @returns {[boolean | any[], boolean]} - [false, false] if the file is invalid, [parsedContentRows, shouldTranslateCoords]
	 * if the file is valid. `shouldTranslateCoords` is true if the imported CSV appears to be based on
	 * TEMPLATE_HEADER_PARTIAL. If it appears to be based on TEMPLATE_HEADER_FULL, it means the coordinates are already
	 * scaled, then exported (to a "full" CSV), and then re-imported. The coordinates are already exactly where they
	 * should be.
	 */
	parseFile = (fileArray) => {
		if (fileArray && fileArray.length > 1) {
			const headerRow = fileArray[0]
			const [headerIsValid, templateHeader] = this.checkHeader(headerRow)
			if (headerIsValid) {
				const shouldTranslateCoords = templateHeader === TEMPLATE_HEADER_PARTIAL
				const contentRows = fileArray.slice(1, -1)
				const parsedContentRows = []
				for (let i = 0; i < contentRows.length; i++) {
					const row = {}
					for (let x = 0; x < headerRow.length; x++) {
						const column = headerRow[x]
						const rawValue = contentRows[i][x]
						const parsedValue = this.parseValue(column, rawValue)
						if (parsedValue !== false) {
							row[column] = parsedValue
						} else if (REQUIRED_COLUMNS.includes(column)) {
							// Exit since this column cannot be invalid
							return [false, false]
						}
					}
					parsedContentRows.push(row)
				}
				return [parsedContentRows, shouldTranslateCoords]
			} else {
				console.warn("header invalid")
			}
		}
		return [false, false]
	}

	translateCoords = (fileArray) => {
		let maxX = fileArray[0]["xLoc"]
		let minX = fileArray[0]["xLoc"]
		let maxY = fileArray[0]["yLoc"]
		let minY = fileArray[0]["yLoc"]

		for (let i = 0; i < fileArray.length; i++) {
			maxX = Math.max(maxX, fileArray[i]["xLoc"])
			minX = Math.min(minX, fileArray[i]["xLoc"])
			maxY = Math.max(maxY, fileArray[i]["yLoc"])
			minY = Math.min(minY, fileArray[i]["yLoc"])
		}

		const translatedContent = []

		if (fileArray.length === 1) {
			// If there is only one node, maxX - minX = 0 and this translation doesn't make sense.
			translatedContent.push(fileArray[0])
		} else {
			const xDiff = maxX - minX
			const yDiff = maxY - minY
			const scaleFactor = xDiff > yDiff ? 1000.0 / xDiff : 1000.0 / yDiff

			for (let i = 0; i < fileArray.length; i++) {
				const xLoc = (fileArray[i]["xLoc"] - minX) * scaleFactor
				const yLoc = (fileArray[i]["yLoc"] - minY) * scaleFactor
				translatedContent.push({ ...fileArray[i], xLoc, yLoc })
			}
		}

		return translatedContent
	}

	handleLocationsDownload = () => {
		const { locations } = this.props
		downloadToCsv(
			locations.map((location) => Object.keys(location).map((key) => location[key])),
			[Object.keys(locations[0])],
			"Node Location Export.csv",
		)
	}

	handleTemplateDownload = () => {
		downloadToCsv([], [TEMPLATE_HEADER_PARTIAL], "Node Location Import Template.csv")
	}

	handleImport = () => {
		/*
		 TODO: Depending on how we implement creation of layouts with no required steps, this method will either be
			removed, or be called automatically in componentDidMount.
		*/
		const { locationsToImport, setConfigLocations, setCanContinue } = this.props
		setConfigLocations(locationsToImport)
		setCanContinue(true)
	}

	render() {
		// TODO: Refactor
		const { classes, locations, locationsToImport } = this.props
		if (locations.length === 0) {
			return (
				<div>
					{locationsToImport.length === 0 ? null : (
						<div className={classes.stepWrapper}>
							<Typography variant="subtitle1">Locations from Database:</Typography>
							<Button
								variant="contained"
								color="secondary"
								className={classes.stepContent + " " + classes.preferredButton}
								onClick={this.handleImport}
							>
								Import
							</Button>
						</div>
					)}
					<div className={classes.stepWrapper}>
						<Typography variant="subtitle1">Template CSV:</Typography>
						<Button
							variant="contained"
							color="secondary"
							className={classes.stepContent}
							onClick={this.handleTemplateDownload}
						>
							Download
						</Button>
					</div>
					<DropzoneArea
						onDrop={this.handleUpload}
						onDropRejected={() =>
							this.props.enqueueSnackbar("Wrong file type or file too large!", {
								variant: "error",
							})
						}
						acceptedFiles={[
							".csv",
							"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
							"application/vnd.ms-excel",
						]}
						filesLimit={100}
						dropzoneText="Drag and drop coordinate CSV here or click"
						showPreviewsInDropzone={false}
						showAlerts={false}
					/>
				</div>
			)
		} else {
			return (
				<div>
					<div className={classes.stepWrapper}>
						<Typography variant="subtitle1">Remove locations:</Typography>
						<Button
							variant="contained"
							color="secondary"
							className={classes.stepContent}
							onClick={() => this.setCoordinatesValid(false)}
						>
							Delete
						</Button>
					</div>
					<Paper className={classes.paper}>
						<Typography variant="h6">Node Location Info</Typography>
						<Typography>Node Count: {locations.length}</Typography>
					</Paper>
					<div className={classes.stepWrapper}>
						<Typography variant="subtitle1">Locations to CSV:</Typography>
						<Button
							variant="contained"
							color="secondary"
							className={classes.stepContent}
							onClick={this.handleLocationsDownload}
						>
							Download
						</Button>
					</div>
				</div>
			)
		}
	}
}

CoordinateUploader.propTypes = {
	classes: PropTypes.object.isRequired,
	locationsToImport: PropTypes.array,
	setConfigLocations: PropTypes.func,
	setCanContinue: PropTypes.func,
	locations: PropTypes.array,
	enqueueSnackbar: PropTypes.func,
}

export default withStyles(styles)(CoordinateUploader)

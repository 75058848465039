import React, { useState } from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormLabel from "@material-ui/core/FormLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import Divider from "@material-ui/core/Divider"
import green from "@material-ui/core/colors/green"

import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { Prompt } from "react-router-dom"

// import LayoutSvgUploader from '../LayoutSvgUploader'

const styles = (theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		overflow: "auto",
		flex: 1,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: 0,
		display: "flex",
		flexDirection: "column",
		overflowY: "auto",
		flex: 1,
	},
	divider: {
		margin: theme.spacing(1.5, 0),
	},
	wrapper: {
		margin: theme.spacing(0, 1),
		position: "relative",
	},
	actionWrapper: {
		justifyContent: "flex-end",
	},
	actionWrapperRight: {
		display: "flex",
	},
	buttonSuccess: {
		backgroundColor: green[500],
		"&:hover": {
			backgroundColor: green[700],
		},
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	title: {
		paddingBottom: 0,
	},
})

const deviceTypeOptions = [
	{
		value: "gen4",
		label: "Gen 4 - GameChange",
	},
	{
		value: "gen3-dual",
		label: "Gen 3 Dual Node - GameChange",
	},
	{
		value: "gen3",
		label: "Gen 3 - GameChange",
	},
	{
		value: "gen2",
		label: "Gen 2 - GameChange",
	},
]

const channelOptions = [
	{
		value: 12,
		label: "0x0C",
	},
	{
		value: 14,
		label: "0x0E",
	},
	{
		value: 15,
		label: "0x0F",
	},
	{
		value: 16,
		label: "0x10",
	},
	{
		value: 17,
		label: "0x11",
	},
	{
		value: 18,
		label: "0x12",
	},
	{
		value: 19,
		label: "0x13",
	},
	{
		value: 20,
		label: "0x14",
	},
	{
		value: 21,
		label: "0x15",
	},
	{
		value: 22,
		label: "0x16",
	},
	{
		value: 23,
		label: "0x17",
	},
	{
		value: 24,
		label: "0x18",
	},
]

const EditMasterDetails = ({
	classes,
	location: _1, // TODO: Remove
	history: _2, // TODO: Remove
	layoutId,
	handleClose,
	createMasterDetails,
	updateMasterDetails,
	enqueueSnackbar,
	tabIndex: _3, // TODO: Remove
	subscribeMasterLocations: _4, // TODO: Remove
	layoutDetails: _5, // TODO: Remove
	handleNewMasterRedirect,
	masterDetails,
	mLocId,
}) => {
	const [pendingResponse, setPendingResponse] = useState(false)
	const [success, setSuccess] = useState(true)
	const [values, setValues] = useState({})

	// TODO: DRY
	const handleChange = (name, type) => (val) => {
		let value = null
		switch (type) {
			case "string":
				value = val
				break
			case "eInt":
				value = parseInt(val.target.value)
				if (isNaN(value)) value = ""
				break
			case "eFloat":
				value = parseFloat(val.target.value)
				if (isNaN(value)) value = ""
				break
			case "object":
				value = val
				break
			default:
				value = val.target.value
				break
		}
		if (value === undefined) {
			setValues((values) => {
				const { [name]: _discarded, ...otherValues } = values
				return otherValues
			})
		} else {
			setValues((values) => ({ ...values, [name]: value }))
		}
		setPendingResponse(false)
		setSuccess(false)
	}

	const getValue = (name) => {
		if (values[name] !== undefined) {
			return values[name]
		}
		if (masterDetails[name] !== undefined && masterDetails[name] !== null) {
			return masterDetails[name]
		}
		return ""
	}

	const submit = async (e) => {
		e.preventDefault()
		const newMasterDetails = { ...values }
		let timer = setTimeout(() => {
			setPendingResponse(false)
			setSuccess(false)
			enqueueSnackbar("Save Timeout", {
				variant: "error",
			})
		}, 20000)

		let result = null
		try {
			console.log(newMasterDetails)
			if (mLocId === "new") {
				if (newMasterDetails["mId"] === undefined) {
					newMasterDetails["mId"] = 0
				}
				const { name, ...otherDetails } = newMasterDetails
				result = await createMasterDetails(layoutId, name, otherDetails)
				console.log("createMasterDetails", result)
			} else {
				console.log(newMasterDetails)
				result = await updateMasterDetails(mLocId, layoutId, newMasterDetails)
			}
			clearTimeout(timer)
			enqueueSnackbar("Save Successful", {
				variant: "success",
			})
			setPendingResponse(false)
			setSuccess(true)
			if (mLocId === "new" && result && result.id) {
				console.log(`new master Id${result.id}`)
				handleNewMasterRedirect(result.id)
			}
		} catch (err) {
			console.error(err)
			clearTimeout(timer)
			enqueueSnackbar("Save Error", {
				variant: "error",
			})
			setPendingResponse(false)
			setSuccess(false)
		}
	}

	return (
		<div className={classes.root}>
			<Prompt when={!success} message={"Continue without saving?"} />
			<DialogTitle className={classes.title} id="form-dialog-title">
				Master Details
			</DialogTitle>
			<form className={classes.form} onSubmit={submit}>
				<DialogContent>
					<TextField
						id="master-name"
						label="Master Name"
						type="text"
						margin="normal"
						variant="outlined"
						value={getValue("name")}
						onChange={handleChange("name")}
						required
						fullWidth
					/>
					<FormControl variant="outlined" fullWidth margin="normal">
						<InputLabel htmlFor="outlined-device-type">Device Type</InputLabel>
						<Select
							value={getValue("deviceType")}
							onChange={handleChange("deviceType")}
							input={<OutlinedInput labelWidth={90} name="device-type" id="outlined-device-type" />}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{deviceTypeOptions.map((deviceType) => (
								<MenuItem key={deviceType.value} value={deviceType.value}>
									{deviceType.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<Divider className={classes.divider} />
					<FormLabel component="legend">Zigbee Parameters</FormLabel>
					<FormGroup>
						<TextField
							id="panid"
							label="PANID"
							type="number"
							margin="normal"
							variant="outlined"
							value={getValue("panid")}
							onChange={handleChange("panid", "eInt")}
							inputProps={{ min: "1", max: "1500" }}
							fullWidth
						/>
						<FormControl variant="outlined" fullWidth margin="normal">
							<InputLabel htmlFor="outlined-channel">Channel</InputLabel>
							<Select
								value={getValue("channel")}
								onChange={handleChange("channel")}
								input={<OutlinedInput labelWidth={58} name="channel" id="outlined-channel" />}
							>
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								{channelOptions.map((channel) => (
									<MenuItem key={channel.label} value={channel.value}>
										{channel.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</FormGroup>
					<Divider className={classes.divider} />
					<FormLabel component="legend">Network</FormLabel>
					<FormGroup>
						<FormControl variant="outlined" fullWidth margin="normal">
							<InputLabel htmlFor="outlined-dhcp-enabled">DHCP Enabled</InputLabel>
							<Select
								value={getValue("dhcpEnabled")}
								onChange={handleChange("dhcpEnabled")}
								input={<OutlinedInput labelWidth={105} name="dhcpEnabled" id="outlined-dhcp-enabled" />}
							>
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								<MenuItem value={true}>True</MenuItem>
								<MenuItem value={false}>False</MenuItem>
							</Select>
						</FormControl>
						{/* TODO: .map() */}
						<TextField
							id="staticIp"
							label="Static IP"
							type="text"
							margin="normal"
							variant="outlined"
							value={getValue("staticIp")}
							onChange={handleChange("staticIp")}
							required={values["dhcpEnabled"] === false}
							fullWidth
						/>
						<TextField
							id="gateway"
							label="Gateway"
							type="text"
							margin="normal"
							variant="outlined"
							value={getValue("gateway")}
							onChange={handleChange("gateway")}
							fullWidth
						/>
						<TextField
							id="dns"
							label="DNS"
							type="text"
							margin="normal"
							variant="outlined"
							value={getValue("dns")}
							onChange={handleChange("dns")}
							fullWidth
						/>
					</FormGroup>
					<Divider className={classes.divider} />
					<FormLabel component="legend">Device</FormLabel>
					<FormGroup>
						<TextField
							id="localIp"
							label="Local IP"
							type="text"
							margin="normal"
							variant="outlined"
							value={getValue("localIp")}
							onChange={handleChange("localIp")}
							fullWidth
						/>
					</FormGroup>
				</DialogContent>
				<DialogActions className={classes.actionWrapper}>
					<div className={classes.actionWrapperRight}>
						<Button color="primary" onClick={handleClose}>
							Close
						</Button>
						<div className={classes.wrapper}>
							<Button type="submit" variant="contained" color="primary" disabled={pendingResponse || success}>
								{success ? "Saved" : "Save Changes"}
							</Button>
							{pendingResponse && <CircularProgress size={24} className={classes.buttonProgress} />}
						</div>
					</div>
				</DialogActions>
			</form>
		</div>
	)
}

EditMasterDetails.propTypes = {
	classes: PropTypes.object.isRequired,
	layoutId: PropTypes.string.isRequired,
	handleClose: PropTypes.func,
	createMasterDetails: PropTypes.func,
	updateMasterDetails: PropTypes.func,
	enqueueSnackbar: PropTypes.func,
	handleNewMasterRedirect: PropTypes.func,
	masterDetails: PropTypes.any.isRequired,
	mLocId: PropTypes.string.isRequired,
	// TODO: Remove below
	location: PropTypes.any,
	history: PropTypes.any,
	tabIndex: PropTypes.any,
	subscribeMasterLocations: PropTypes.any,
	layoutDetails: PropTypes.any,
}

export default withStyles(styles)(EditMasterDetails)

import { withStyles } from "@material-ui/core/styles"
import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { accessAllowed } from "../../../utils/auth"
import DeleteLayout from "./DeleteLayout"
import { privileges } from "../../../constants/auth"

const styles = (theme) => ({
	root: {
		height: "100%",
		width: "100%",
		display: "flex",
		flexDirection: "column",
	},
	container: {
		boxSizing: "border-box",
		fontFamily: theme.typography.fontFamily,
		height: "100%",
	},
	summary: {
		backgroundColor: "#e7e3e3",
	},
	summaryTitle: {
		width: "33%",
		flexShrink: 0,
	},
	summaryDescription: {},
	permissionDeniedContainer: {
		height: "100%",
		display: "flex",
		justifyContent: "center" /* align horizontal */,
		alignItems: "center" /* align vertical */,
	},
})

// NOTE: requiredPrivileges is given as a list of privileges, and if the user has
// *any* of the given permissions, they will be granted access (visually, at least).
const sections = [
	{
		id: "delete-layout",
		title: "Delete layout",
		description: "Permanently delete this layout",
		comp: DeleteLayout,
		requiredPrivileges: [privileges.ADMIN],
	},
	// TODO: Add transfer-ownership?
]

const DangerZone = (props, _state) => {
	const { classes, userPrivileges, layoutId, layoutDetails, jobsManager, close } = props
	const [expanded, setExpanded] = React.useState("")

	const handleChange = useCallback(
		(panel) => (event, newExpanded) => {
			setExpanded(newExpanded ? panel : false)
		},
		[setExpanded],
	)

	const accessibleSections = sections.filter(
		function (section) {
			let { requiredPrivileges } = section
			if (requiredPrivileges) {
				if (typeof requiredPrivileges === "number") {
					requiredPrivileges = [requiredPrivileges]
				}

				if (requiredPrivileges.some((privilege) => accessAllowed(userPrivileges, privilege))) {
					return true
				}
			} else {
				// No privileges required
				return true
			}
			return false
		},
		[userPrivileges],
	)

	if (!accessibleSections.length) {
		return (
			<div className={classes.container}>
				<div className={classes.permissionDeniedContainer}>
					<Typography variant={"subtitle2"}>You do not have permission to use this tab.</Typography>
				</div>
			</div>
		)
	}

	const commonProps = {
		layoutId,
		layoutDetails,
		jobsManager,
		close,
	}

	return (
		<div className={classes.container}>
			{accessibleSections.map(function (sectionData) {
				const { id, title, description, comp } = sectionData
				return (
					<Accordion key={id} expanded={expanded === id} onChange={handleChange(id)}>
						<AccordionSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
							<Typography className={classes.summaryTitle} color={"textPrimary"}>
								{title}
							</Typography>
							<Typography className={classes.summaryDescription} color={"textSecondary"}>
								{description}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>{React.createElement(comp, commonProps)}</AccordionDetails>
					</Accordion>
				)
			})}
		</div>
	)
}

export const sectionPropTypes = Object.freeze({
	classes: PropTypes.object.isRequired,
	layoutId: PropTypes.string.isRequired,
	layoutDetails: PropTypes.object.isRequired,
	jobsManager: PropTypes.shape({
		reportJobStarted: PropTypes.func.isRequired,
		reportJobFinished: PropTypes.func.isRequired,
		getNbPendingJobs: PropTypes.func.isRequired,
		unsubscribeAll: PropTypes.func.isRequired,
	}).isRequired,
	close: PropTypes.func.isRequired,
})

DangerZone.propTypes = {
	classes: PropTypes.object.isRequired,
	userPrivileges: PropTypes.number.isRequired,
	layoutId: PropTypes.string.isRequired,
	layoutDetails: PropTypes.object.isRequired,
	jobsManager: PropTypes.shape({
		reportJobStarted: PropTypes.func.isRequired,
		reportJobFinished: PropTypes.func.isRequired,
	}).isRequired,
	close: PropTypes.func.isRequired,
}

export default withStyles(styles)(DangerZone)

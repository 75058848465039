import React from "react"
import Dialog from "@material-ui/core/Dialog"
import Slide from "@material-ui/core/Slide"
import NodeDetails from "./NodeDetails"
import MasterDetails from "./MasterDetails"

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const FullScreenDialog = ({ deviceIds, handleClose, handleOpenIdInput }) => {
	let content = <div />
	if (deviceIds.length === 3) {
		content = (
			<NodeDetails
				layoutId={deviceIds[0]}
				mLocId={deviceIds[1]}
				nLocId={deviceIds[2]}
				handleClose={handleClose}
				openIdInput={handleOpenIdInput}
			/>
		)
	} else if (deviceIds.length === 2) {
		content = (
			<MasterDetails
				layoutId={deviceIds[0]}
				mLocId={deviceIds[1]}
				handleClose={handleClose}
				openIdInput={handleOpenIdInput}
			/>
		)
	}
	return (
		<Dialog fullScreen open={deviceIds.length > 0} onClose={handleClose} TransitionComponent={Transition}>
			{content}
		</Dialog>
	)
}

export default FullScreenDialog

import { connect } from "react-redux"
import Table from "components/Table"

function createData(masterDetails, layoutDetails) {
	let rows = []
	let id = 0
	Object.keys(masterDetails).forEach((mLocId) => {
		id += 1
		let layoutId = masterDetails[mLocId]["layoutId"]
		rows.push({
			id,
			layoutName: layoutDetails[layoutId]["name"],
			masterName: masterDetails[mLocId]["name"],
			panid: masterDetails[mLocId]["panid"],
			channel: masterDetails[mLocId]["channel"],
		})
	})
	return rows
}

const columns = [
	{
		width: 200,
		flexGrow: 1.0,
		label: "Layout Name",
		dataKey: "layoutName",
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "Master Name",
		dataKey: "masterName",
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "PANID",
		dataKey: "panid",
		numeric: true,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "Channel",
		dataKey: "channel",
		numeric: true,
	},
]

const mapStateToProps = (state, props) => ({
	rows: createData(state.masterDetails, state.layoutDetails),
	columns,
})

const mapDispatchToProps = (dispatch) => ({
	onRowClick: (event) => console.log(event),
})

export default connect(mapStateToProps, mapDispatchToProps)(Table)

import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import { FeatureGroup, Polygon } from "react-leaflet"
import { EditControl } from "react-leaflet-draw"
import classifyPoint from "robust-point-in-polygon"
import polylabel from "polylabel"
import { getTranslatedCenter } from "utils"
import DivIcon from "components/DivIcon"
import Typography from "@material-ui/core/Typography"
import equal from "fast-deep-equal"

const styles = (_theme) => ({
	label: {
		width: 90,
		textAlign: "center",
		opacity: 0.75,
		backgroundColor: "rgb(128, 128, 128, .5)",
		color: "white",
		transform: "translateX(-45px)",
		borderRadius: "15px",
	},
})

const assignNodesToMasters = (nodePoints, translations, masterAreas) => {
	const assignedNodes = []

	for (const nodePoint of nodePoints) {
		let assigned = false

		for (const [mLocId, area] of Object.entries(masterAreas)) {
			const [translatedNodeY, translatedNodeX] = getTranslatedCenter(nodePoint, translations)

			const nodeIsInsideMasterArea = classifyPoint(area, [translatedNodeY, translatedNodeX]) < 1
			if (nodeIsInsideMasterArea) {
				assignedNodes.push({ ...nodePoint, mLocId })
				assigned = true
				break
			}
		}

		if (!assigned) {
			const { mLocId: _, ...unassignedNode } = nodePoint
			assignedNodes.push(unassignedNode)
		}
	}

	return assignedNodes
}

const distanceBetweenPoints = (p1, p2) => {
	return Math.abs(Math.sqrt(Math.pow(p2["yLoc"] - p1["yLoc"], 2) + Math.pow(p2["xLoc"] - p1["xLoc"], 2)))
}

const sortByDistance = (point, pointsArray) => {
	return [...pointsArray].sort((a, b) => distanceBetweenPoints(point, a) - distanceBetweenPoints(point, b))
}

const getRowSpacing = (nodePoints) => {
	let rowSpacings = {}
	const numberOfNodes = nodePoints.length
	for (let i = 0; i < Math.min(numberOfNodes, 200); i++) {
		// A positive distance is required, even if there's only one node.
		let xDistance = 1
		if (numberOfNodes > 1) {
			xDistance = Math.abs(nodePoints[i].xLoc - sortByDistance(nodePoints[i], nodePoints)[1].xLoc)
		}
		if (Object.prototype.hasOwnProperty.call(rowSpacings, xDistance)) {
			rowSpacings[xDistance]++
		} else {
			rowSpacings[xDistance] = 1
		}
	}
	return Object.keys(rowSpacings).sort((a, b) => {
		return rowSpacings[b] - rowSpacings[a]
	})[0]
}

const assignRowNums = (rowSpacing, nodePoints) => {
	const halfRowSpacing = rowSpacing * 0.51
	let assignedNodes = []
	const nodesByMaster = {}
	for (let i = 0; i < nodePoints.length; i++) {
		const nodePoint = nodePoints[i]
		const mLocId = nodePoint["mLocId"]
		if (mLocId in nodesByMaster) {
			nodesByMaster[mLocId].push(nodePoint)
		} else {
			nodesByMaster[mLocId] = [nodePoint]
		}
	}
	for (const nodeCollection of Object.values(nodesByMaster)) {
		const sortedNodePoints = nodeCollection.sort((a, b) => {
			const xDist = a["xLoc"] - b["xLoc"]
			if (xDist >= 0 && xDist < halfRowSpacing) {
				return a["yLoc"] - b["yLoc"]
			}
			return a["xLoc"] - b["xLoc"]
		})
		let prevNode = { ...sortedNodePoints[0], rowNum: 1, fromSouth: 1 }
		assignedNodes.push(prevNode)
		for (let i = 1; i < sortedNodePoints.length; i++) {
			let rowNum = 0
			let fromSouth = 0
			if (sortedNodePoints[i]["xLoc"] === prevNode["xLoc"]) {
				rowNum = prevNode["rowNum"]
				fromSouth = prevNode["fromSouth"] + 1
			} else {
				rowNum = prevNode["rowNum"] + 1
				fromSouth = 1
			}
			prevNode = { ...sortedNodePoints[i], rowNum, fromSouth }
			assignedNodes.push(prevNode)
		}
	}
	return assignedNodes
}

const latLngToCoords = (latLngs) => {
	return latLngs[0].map((latLng) => {
		return [latLng["lat"], latLng["lng"]]
	})
}

const DEFAULT_DRAW_ITEMS = {
	polyline: false,
	rectangle: false,
	circle: false,
	marker: false,
	circlemarker: false,
	polygon: false,
}

const MasterAreaEditor = memo(
	({
		classes,
		locations,
		translations,
		masterAreas,
		masterAreaLabels,
		modifiedAreas,
		updateConfigMasterArea,
		setConfigLocations,
		setConfigAssignment,
		labelsVisible,
	}) => {
		const [deleteActive, setDeleteActive] = useState(false)
		const [editKey, setEditKey] = useState(0)
		const tempAreas = useMemo(() => {
			return { ...masterAreas, ...modifiedAreas }
		}, [masterAreas, modifiedAreas])
		const { layoutId: _, ...newAreas } = tempAreas
		const areas = []
		const labels = []
		for (const [mLocId, newArea] of Object.entries(newAreas)) {
			if (newArea.length) {
				const isUnassigned = mLocId.split("_").length > 1
				areas.push(
					<Polygon
						key={mLocId + editKey}
						mLocId={mLocId}
						color={"black"}
						positions={newArea}
						onClick={deleteActive ? () => {} : () => setConfigAssignment("master-area", mLocId)}
					/>,
				)
				if (labelsVisible) {
					labels.push(
						<DivIcon key={mLocId} position={polylabel([newArea], 1.0)}>
							<Typography
								className={classes.label}
								onClick={deleteActive ? null : () => setConfigAssignment("master-area", mLocId)}
							>
								{isUnassigned ? "Click to Assign" : masterAreaLabels[mLocId]}
							</Typography>
						</DivIcon>,
					)
				}
			}
		}

		/*
    Leaflet deals with event listeners by reference,
    so if you want to add a listener and then remove it, define it as a function.
    'useCallback' must be used to ensure reference isn't changed on consecutive renders
  */
		const handleCreated = useCallback(
			(modifiedAreas) => (e) => {
				const layer = e.layer
				const area = latLngToCoords(layer.getLatLngs())
				updateConfigMasterArea({ ["TEMPID_" + Object.keys(modifiedAreas).length]: area })
				layer.remove()
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[],
		)

		const handleEdit = useCallback((e) => {
			// console.log('Recalculate nodes assigned to master', e)
			const edits = {}
			e.layers.eachLayer((layer) => {
				edits[layer.options.mLocId] = latLngToCoords(layer.getLatLngs())
			})
			updateConfigMasterArea(edits)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const handleDelete = useCallback((e) => {
			// console.log('Recalculate nodes assigned to master', e)
			const edits = {}
			e.layers.eachLayer((layer) => {
				edits[layer.options.mLocId] = []
			})
			updateConfigMasterArea(edits)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const handleEditStart = useCallback(() => {
			setEditKey((editKey) => editKey + 1) // force redraw master area polygon <- fixes area render bug
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const prevMasterAreas = useRef()

		useEffect(() => {
			// The masterAreas prop is not memoized.
			// Therefore, the tempAreas object is not memoized.
			// Therefore, running this effect every time it changes would result in an infinite loop.
			// We have to check if tempAreas has changed before recalculating the node assignments.
			// NOTE: tempAreas is the currently saved master areas plus any modifications made by the user.
			if (equal(tempAreas, prevMasterAreas.current)) {
				return
			}
			prevMasterAreas.current = tempAreas

			// There are changes in the master areas, so we have to recalculate the node assignments.
			for (const areaKey in tempAreas) {
				if (areaKey.split("_").length === 2) {
					// If there is an underscore in the key, it means the area is unassigned.
					// We're not going to persist the node assignments until all areas are assigned.
					return
				}
			}
			const rowSpacing = getRowSpacing(locations)
			let nodes = assignNodesToMasters(locations, translations, newAreas)
			nodes = assignRowNums(rowSpacing, nodes)
			setConfigLocations(nodes)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [tempAreas])

		return (
			<>
				{labels}
				<FeatureGroup>
					{areas}
					<EditControl
						position="bottomleft"
						onCreated={handleCreated(modifiedAreas)}
						onEdited={handleEdit}
						onDeleted={handleDelete}
						onEditStart={handleEditStart}
						onDeleteStart={() => {
							setDeleteActive(true)
						}}
						onDeleteStop={() => {
							setDeleteActive(false)
						}}
						edit={{ remove: true }}
						draw={{
							...DEFAULT_DRAW_ITEMS,
							polygon: {
								allowIntersection: false,
								showArea: true,
							},
						}}
					/>
				</FeatureGroup>
			</>
		)
	},
)

MasterAreaEditor.displayName = "MasterAreaEditor"

MasterAreaEditor.propTypes = {
	classes: PropTypes.object.isRequired,
	locations: PropTypes.array.isRequired,
	translations: PropTypes.object.isRequired,
	masterAreas: PropTypes.object.isRequired,
	masterAreaLabels: PropTypes.object.isRequired,
	modifiedAreas: PropTypes.object.isRequired,
	updateConfigMasterArea: PropTypes.func.isRequired,
	setConfigLocations: PropTypes.func.isRequired,
	setConfigAssignment: PropTypes.func.isRequired,
	labelsVisible: PropTypes.bool.isRequired,
}

export default withStyles(styles)(MasterAreaEditor)

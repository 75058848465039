import { connect } from "react-redux"
import { compose } from "redux"
import { withRouter, matchPath } from "react-router"
import Controls from "./Controls"
import { getRecentMasterDataFromMLocId } from "../../../utils/stateSelectors"

const getUserInfo = (state) => {
	const { user } = state
	return {
		userId: user.id,
		username: `${user.nameFirst} ${user.nameLast}`,
	}
}

const getDeviceDetailsType = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	const deviceIds = params.get("device-details").split(":")
	if (deviceIds.length === 2) {
		return "master"
	} else if (deviceIds.length === 3) {
		return "node"
	}
}
const getLayoutInfo = (state, props) => {
	const rtnMatchPath = matchPath(props.location.pathname, { path: "/layout/:layoutId" })
	return {
		layoutId: rtnMatchPath.params.layoutId,
		layoutName: (state.layoutDetails[rtnMatchPath.params.layoutId] || {})["name"],
	}
}

const getMasterDetails = (state, layoutId) => {
	const mLocIds = (state.layoutDetails[layoutId] || {})["mLocIds"] || []
	const masterDetails = {}
	mLocIds.forEach((mLocId) => {
		const { mId, name, nodeParamId, spaParamId } = state.masterDetails[mLocId] || {}
		if (mId) {
			masterDetails[mLocId] = {
				mId,
				name,
				nodeParamId,
				spaParamId,
			}
		}
	})
	return masterDetails
}

const mapStateToProps = (state, props) => {
	const { layoutId, layoutName } = getLayoutInfo(state, props)
	const masterData = getRecentMasterDataFromMLocId(state, props)

	return {
		layoutId,
		layoutName,
		masterDetails: { ...getMasterDetails(state, layoutId) },
		nodeFwVersion: (masterData && masterData["fwNode"]) || "1.0.0",
		userId: state.userId,
		...getUserInfo(state),
		privileges: (state.user || {})["privileges"],
		deviceDetailsType: getDeviceDetailsType(state, props),
		deviceType: (state.masterDetails || {})[props.mLocId || {}].deviceType,
	}
}

export default compose(withRouter, connect(mapStateToProps))(Controls)

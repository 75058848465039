import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import { AutoSizer, Column, SortDirection, Table } from "react-virtualized"

const styles = (theme) => ({
	root: {
		height: "100%",
		width: "100%",
	},
	flexContainer: {
		display: "flex",
		alignItems: "center",
		boxSizing: "border-box",
	},
	table: {
		// temporary right-to-left patch, waiting for
		// https://github.com/bvaughn/react-virtualized/issues/454
		"& .ReactVirtualized__Table__headerRow": {
			flip: false,
			paddingRight: theme.direction === "rtl" ? "0px !important" : undefined,
		},
	},
	tableRow: {
		cursor: "pointer",
	},
	tableRowHover: {
		"&:hover": {
			backgroundColor: theme.palette.grey[200],
		},
	},
	tableCell: {
		flex: 1,
	},
	noClick: {
		cursor: "initial",
	},
	grid: {
		outline: "none",
	},
	resendInvitationBtn: {
		background: "#20447E",
		borderRadius: "15px",
		border: "none",
		color: "white",
		padding: "2px 5px",
		cursor: "pointer",
		fontWeight: "bold",
	},
})

class MuiVirtualizedTable extends React.PureComponent {
	static defaultProps = {
		headerHeight: 48,
		rowHeight: 48,
	}

	getRowClassName = ({ index }) => {
		const { classes, onRowClick } = this.props

		return clsx(classes.tableRow, classes.flexContainer, {
			[classes.tableRowHover]: index !== -1 && onRowClick != null,
		})
	}

	checkResendInvitation = (cellData) => {
		const { classes } = this.props
		if (cellData === "FORCE_CHANGE_PASSWORD") {
			return (
				<div>
					{cellData}
					<button className={clsx(classes.resendInvitationBtn)} onClick={this.props.onResendInvitation}>
						resend invitation
					</button>
				</div>
			)
		} else {
			return cellData
		}
	}

	cellRenderer = ({ cellData, columnIndex }) => {
		const { columns, classes, rowHeight, onRowClick } = this.props
		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, {
					[classes.noClick]: onRowClick == null,
				})}
				variant="body"
				style={{ height: rowHeight }}
				align={(columnIndex != null && columns[columnIndex].numeric) || false ? "right" : "left"}
			>
				{columns[columnIndex].formatter
					? columns[columnIndex].formatter(cellData)
					: this.checkResendInvitation(cellData)}
			</TableCell>
		)
	}

	headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
		const { headerHeight, columns, classes, sort } = this.props
		const direction = {
			[SortDirection.ASC]: "asc",
			[SortDirection.DESC]: "desc",
		}
		const inner =
			!columns[columnIndex].disableSort && sort != null ? (
				<TableSortLabel active={dataKey === sortBy} direction={direction[sortDirection]}>
					{label}
				</TableSortLabel>
			) : (
				label
			)

		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
				variant="head"
				style={{ height: headerHeight }}
				align={columns[columnIndex].numeric || false ? "right" : "left"}
			>
				{inner}
			</TableCell>
		)
	}
	render() {
		const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props
		return (
			<div className={classes.root}>
				<AutoSizer>
					{({ height, width }) => (
						<Table
							height={height}
							width={width}
							rowHeight={rowHeight}
							gridStyle={{
								direction: "inherit",
							}}
							headerHeight={headerHeight}
							className={classes.table}
							{...tableProps}
							rowClassName={this.getRowClassName}
							gridClassName={classes.grid}
						>
							{columns.map(({ dataKey, ...other }, index) => {
								return (
									<Column
										key={dataKey}
										headerRenderer={(headerProps) =>
											this.headerRenderer({
												...headerProps,
												columnIndex: index,
											})
										}
										className={classes.flexContainer}
										cellRenderer={this.cellRenderer}
										dataKey={dataKey}
										{...other}
									/>
								)
							})}
						</Table>
					)}
				</AutoSizer>
			</div>
		)
	}
}

MuiVirtualizedTable.propTypes = {
	classes: PropTypes.object.isRequired,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			dataKey: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			numeric: PropTypes.bool,
			width: PropTypes.number.isRequired,
			formatter: PropTypes.func,
			disableSort: PropTypes.func,
		}),
	).isRequired,
	headerHeight: PropTypes.number,
	onRowClick: PropTypes.func,
	rowHeight: PropTypes.number,
	username: PropTypes.func,
	onResendInvitation: PropTypes.func,
	sort: PropTypes.func,
}

export default withStyles(styles)(MuiVirtualizedTable)

import { connect } from "react-redux"
import NodeIdLabel from "./NodeIdLabel"

export const getPosition = (nodeDetailsByMLocId, mLocId, nLocId) => {
	const nodeDetails = (nodeDetailsByMLocId[mLocId] || {})[nLocId] || {}
	if (nodeDetails["xLoc"] === undefined || nodeDetails["yLoc"] === undefined) return []
	return [nodeDetails["yLoc"], nodeDetails["xLoc"]]
}

export const getNodeId = (nIdsPendingUpload, nodeDetailsByMLocId, mLocId, nLocId) => {
	if (mLocId === undefined || nLocId === undefined) return null

	const nId = (nodeDetailsByMLocId[mLocId] || {})[nLocId].nId
	const pendingNId = nIdsPendingUpload[`${mLocId}#${nLocId}`]

	return pendingNId || nId
}

export const getLabel = (state, props) => {
	const nId = getNodeId(state.nIdsPendingUpload, state.nodeDetails, props.mLocId, props.nLocId)
	return nId ? `0x${nId.slice(-4)}` : "N/A"
}

const mapStateToProps = (state, props) => ({
	position: getPosition(state.nodeDetails, props.mLocId, props.nLocId),
	label: getLabel(state, props),
})

const mapDispatchToProps = (dispatch, props) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(NodeIdLabel)

import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Button from "@material-ui/core/Button"
import NodeDataView from "./NodeDataView"
import TaskWizard from "./TaskWizard"
import Controls from "../Controls"
import { privileges } from "constants/auth"
import { useAuthorization } from "utils/auth"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
	},
	appBar: {
		position: "relative",
	},
	title: {
		// marginLeft: theme.spacing(2),
		flex: 1,
	},
	content: {
		height: "100%",
		flex: 1,
		display: "flex",
		flexDirection: "column",
		overflowY: "auto",
	},
	config: {
		display: "flex",
	},
	taskWizard: {
		overflowY: "auto",
	},
	button: {
		margin: "auto",
		marginTop: theme.spacing(3),
	},
	data: {
		overflowY: "auto",
	},
}))

const NodeDetails = ({ details, handleClose, openIdInput, nodeData, mLocId, mId, userPrivileges, nodeDataNodeId }) => {
	const [activeTab, setActiveTab] = useState(0)
	const classes = useStyles()
	const accessAllowed = useAuthorization(userPrivileges)

	const handleChangeTab = (event, newTab) => {
		setActiveTab(newTab)
	}

	let { nId } = details

	let currentNodeId
	if (nodeDataNodeId !== nId) {
		currentNodeId = nId
		nId = nodeDataNodeId
	}

	const getNodeIdHeader = () => {
		let ret = "NodeID: "

		if (currentNodeId && currentNodeId !== nId) {
			const replacedMessage = ` (replaced ${nId ? nId.slice(-4) : "unknown"})`
			ret += currentNodeId.slice(-4) + replacedMessage
		} else if (nId) {
			ret += nId.slice(-4)
		} else {
			ret += "Unassigned"
		}

		return ret
	}

	const getRowHeader = () => {
		return "Row: " + details["rowNum"]
	}

	const getFromSouthHeader = () => {
		return "From South: " + details["fromSouth"]
	}

	const headers = [getNodeIdHeader(), getRowHeader(), getFromSouthHeader()]

	return (
		<div className={classes.root}>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<Typography variant="h6" className={classes.title}>
						{headers.join(" | ")}
					</Typography>
					<IconButton edge="end" color="inherit" onClick={handleClose} aria-label="Close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<div className={classes.content}>
				<AppBar position="static" color="default">
					<Tabs value={activeTab} onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
						<Tab label="Details" />
						<Tab
							disabled={!accessAllowed(privileges.CONTROL_STANDARD | privileges.CONTROL_CX) || !mId}
							label="Control"
						/>
						<Tab disabled={!accessAllowed(privileges.CONFIGURATION)} label="Configure" />
						<Tab disabled={!accessAllowed(privileges.CONFIGURATION)} label="Troubleshoot" />
					</Tabs>
				</AppBar>
				{activeTab === 0 && (
					<div className={classes.data}>
						<NodeDataView nodeData={nodeData} />
					</div>
				)}
				{activeTab === 1 && (
					<div className={classes.tab}>
						<Controls mLocId={mLocId} nId={currentNodeId || nId} />
					</div>
				)}
				{activeTab === 2 && (
					<div className={classes.config}>
						<Button variant="contained" color="primary" className={classes.button} onClick={openIdInput}>
							{nId ? "Replace Node ID" : "Assign Node ID"}
						</Button>
					</div>
				)}
				{activeTab === 3 && (
					<div className={classes.taskWizard}>
						<TaskWizard />
					</div>
				)}
			</div>
		</div>
	)
}

NodeDetails.propTypes = {
	details: PropTypes.object.isRequired,
	handleClose: PropTypes.func.isRequired,
	openIdInput: PropTypes.func.isRequired,
	nodeData: PropTypes.array,
	mLocId: PropTypes.string.isRequired,
	mId: PropTypes.string,
	userPrivileges: PropTypes.number.isRequired,
	nodeDataNodeId: PropTypes.string,
}

export default NodeDetails
